import { Flex, Container, Box, Button, HStack, Menu, MenuButton, MenuItem, MenuList, Spacer, VStack, Text, Avatar, Input, InputRightElement, Table, TableContainer, Tbody, Td, Thead, Tr, useDisclosure, Portal, Center, InputGroup, Select, FormLabel } from "@chakra-ui/react";
import { useState, useEffect, ChangeEvent } from "react";
import { NumericFormat } from "react-number-format";
import { useNavigate, useParams } from "react-router-dom";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { RiEyeLine, RiSearch2Line } from "react-icons/ri";
import { Pagination, PaginationContainer, PaginationNext, PaginationPage, PaginationPageGroup, PaginationPrevious, usePagination } from "@ajna/pagination";
import transactionService from "../../services/transaction/transaction.service";
import { Ipage } from "../../models/audit/auditlog.model";
import Loading from "../../components/Loading";
import { ITransactionValue, ITransactionRes } from "../../models/transactions/transactions.model";
import { getFullDate } from "../../utils/getDate";
import { ViewTransactionModal } from "../../components/transactions/ViewTransactionsModal";
import { HiArrowNarrowLeft } from "react-icons/hi";
import AdminRoutes from "../routes";
import { BiNotification } from 'react-icons/bi';

const AccountTransactions = () => {
    let navigate = useNavigate();
    const [search, setSearch] = useState("");
    const [query, setQuery] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const { account_id } = useParams();
    const [isViewTransaction, setisViewTransaction] = useState(false);
    const [reload, setReload] = useState(false);
    const [transactionView, settransactionView] = useState<ITransactionValue>();
    const [page, setPage] = useState<Ipage>({ page_total: 0, total: 0, page_count: 0 });
    const [transactionList, settransactionList] = useState<ITransactionValue[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pageSize, setPageSize] = useState(25);
    const {
        currentPage,
        setCurrentPage,
        pagesCount,
        pages
    } = usePagination({
        pagesCount: page.page_count,
        initialState: { currentPage: 1, pageSize: 100 },
        total: page.total
    });
    const [start_date, setstart_date] = useState("");
    const [end_date, setend_date] = useState('');

    useEffect(() => {
        const url = `?accountId=${account_id}&pageNumber=${currentPage}&pageSize=${pageSize}&searchString=${query}&startDate=${start_date}&endDate=${end_date}`
        transactionService.getTransactionsOfAccount(url)
            .then((data) => {
                const transaction: ITransactionRes = data
                setPage({ page_total: transaction.pageSize, total: transaction.totalCount, page_count: transaction.totalPages })
                settransactionList(transaction.data.$values);
                setIsLoading(false)
            })
            .catch((err) => err)

    }, [currentPage, query, reload, pageSize]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (search.length > 0) {
                setQuery(search);
            } else {
                setQuery("");
            }
        }, 700);

        return () => clearTimeout(delayDebounceFn)
    }, [search])
    const openViewTransaction = (transaction: ITransactionValue) => {
        settransactionView(transaction)
        setisViewTransaction(true)
        setIsOpen(true)

    };
    const reloadData = () => {
        setReload(!reload);
    };
    const changePageSize = (e: any) => {
        setPageSize(e.target.value)
    }
    const dateChange = (event: ChangeEvent<HTMLInputElement>) => {
        let date = event.target.value.toLowerCase();
        setend_date(date);
        setReload(!reload);

    };
    const startdateChange = (event: ChangeEvent<HTMLInputElement>) => {
        let date = event.target.value.toLowerCase();
        setstart_date(date);


    };
    return (
        <>
            {
                isLoading ? <Loading /> :
                    <Box >
                        <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            pb="20px"
                        >
                            <Box color="#2B2B2B" fontWeight="800" fontSize="24px">
                                Transactions : {account_id}
                            </Box>
                            <Box
                                mb="20px"
                                display="flex"
                                alignItems="center"
                                border="1px solid #616161"
                                padding={"5px 12px"}
                                width="fit-content"
                                borderRadius="5px"
                                cursor="pointer"
                            >
                                <HiArrowNarrowLeft color="#616161" fontSize="20px" />
                                <Text
                                    fontSize="13px"
                                    color="#616161"
                                    pl="5px"
                                    onClick={() => navigate(AdminRoutes.portal.wallet)}
                                    fontWeight={'500'}
                                >
                                    Back to wallet
                                </Text>
                            </Box>
                        </Flex>

                        <Flex py='15px' justifyContent=''>
                            <InputGroup bg="white" width="30%" mr='20px'>
                                <Input height='40px' placeholder="Search transactions here" onChange={e => setSearch(e.target.value)} fontSize={12} size="sm" />
                                <InputRightElement pointerEvents="none" children={<RiSearch2Line color="green" size="0.9rem" />} />
                            </InputGroup>
                            <HStack>
                                <FormLabel fontSize={'12px'} mt={'5px'}> From:</FormLabel>

                                <Input
                                    width={"200px"}
                                    bg={"white"}
                                    type={"date"}
                                    fontSize={'12px'}
                                    onChange={(e) => startdateChange(e)}

                                />
                            </HStack>
                            <HStack ml={'10px'}>
                                <FormLabel fontSize={'12px'} mt={'5px'}> To:</FormLabel>
                                <Input
                                    width={"200px"}
                                    bg={"white"}
                                    type={"date"}
                                    fontSize={'12px'}
                                    onChange={(e) => dateChange(e)}

                                />
                            </HStack>
                        </Flex>
                        <Box width={'100%'}>
                            <TableContainer>
                                <Table size={'sm'}>
                                    <Thead py={'15px'}>
                                        <Tr bg="#3778D9" color={'white'} py={'15px'} >
                                            <Td py={'15px'}  textTransform={'capitalize'}>Date</Td>
                                            <Td  textTransform={'capitalize'} width={'120px'}>From</Td>
                                            <Td  textTransform={'capitalize'} width={'150px'}>Transaction-id</Td>
                                            <Td  textTransform={'capitalize'}>Amount</Td>
                                            <Td  textTransform={'capitalize'} width={'120px'}>To</Td>
                                            <Td  textTransform={'capitalize'}>Type</Td>
                                            <Td  textTransform={'capitalize'}>Status</Td>
                                            <Td  textTransform={'capitalize'}>Actions</Td>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {
                                            transactionList.length > 0 ? transactionList.map((transaction, index) => (
                                                <Tr key={index} bg="white" >
                                                    <Td>
                                                        <Text fontSize={'14px'}>{getFullDate(transaction.finishTimestamp)}</Text>
                                                    </Td>

                                                    <Td width={'120px'}>
                                                        <Box width={'120px'}>
                                                            <Text fontSize={'14px'}>{transaction.sourceOwner}</Text>
                                                            <Text fontSize={'14px'}>{transaction.source}</Text>
                                                            <Text fontSize={'14px'}>{transaction.sourceProductType}</Text>

                                                        </Box>

                                                    </Td>
                                                    <Td  >
                                                        <Box >

                                                            <Text fontSize={'14px'} >{transaction.id.slice(0, 18)}</Text>
                                                            <Text fontSize={'14px'} >{transaction.id.slice(18, transaction.id.length)}</Text>
                                                        </Box>

                                                    </Td>
                                                    <Td>
                                                        <Text fontSize={'14x'}>

                                                            <NumericFormat
                                                                value={transaction.amount}
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                                prefix={'₦'}
                                                            />
                                                        </Text>
                                                    </Td>
                                                    <Td width={'120px'}>
                                                        <Box width={'120px'}>

                                                            <Text fontSize={'14px'}>{transaction.destinationOwner}</Text>
                                                            <Text fontSize={'14px'}>{transaction.destination}</Text>
                                                            <Text fontSize={'14px'}>{transaction.destinationProductType}</Text>

                                                        </Box>

                                                    </Td>
                                                    <Td>
                                                        <Text fontSize={'14px'}>{transaction.typeId == 0 ? 'Movement' :
                                                            transaction.typeId == 1 ? 'Transfer' :
                                                                transaction.typeId == 2 ? 'Topup' : 'Payment'}</Text>
                                                    </Td>
                                                    <Td>
                                                        <Text fontSize={'14px'} bg={transaction.statusId == 0 ? 'yellow' :
                                                            transaction.statusId == 1 ? 'yellow' :
                                                                transaction.statusId == 2 ? 'Routed' :
                                                                    transaction.statusId == 3 ? 'green' :
                                                                        transaction.statusId == 4 ? 'red' :
                                                                            transaction.statusId == 5 ? 'red' :
                                                                                transaction.statusId == 6 ? 'red' :
                                                                                    transaction.statusId == 7 ? 'blue' :
                                                                                        transaction.statusId == 8 ? 'red' : 'red'
                                                        } color={'white'} textAlign={'center'} borderRadius={'15px'} py={'7px'} px={'12px'}>
                                                            {transaction.statusId == 0 ? 'Requested' :
                                                                transaction.statusId == 1 ? 'Processing' :
                                                                    transaction.statusId == 2 ? 'Routed' :
                                                                        transaction.statusId == 3 ? 'Success' :
                                                                            transaction.statusId == 4 ? 'Failed' :
                                                                                transaction.statusId == 5 ? 'Rejected' :
                                                                                    transaction.statusId == 6 ? 'Cancelled' :
                                                                                        transaction.statusId == 7 ? 'Validated' :
                                                                                            transaction.statusId == 8 ? 'Invalid' : 'Validation Required'
                                                            }</Text>
                                                    </Td>



                                                    <Td>
                                                        <HStack pl={'5px'} pr={'15px'} borderRadius={'20px'} bg={'#011F78'} onClick={() => { openViewTransaction(transaction) }} cursor={'pointer'} spacing={'0px'}>
                                                            <Center
                                                                color="white"
                                                                width="30px"
                                                                cursor={"pointer"}
                                                                height="30px"
                                                            >
                                                                <BiNotification />
                                                            </Center>
                                                            <Text fontWeight={'600'} color="white" fontSize={'14px'} >Details</Text>

                                                        </HStack>

                                                    </Td>
                                                </Tr>

                                            )) : <Box>No data</Box>
                                        }
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </Box>
                        <Flex alignContent={"flex-end"} justifyContent={'flex-end'} mt={4}>

                            <HStack>
                                <Text fontWeight={400} fontSize={'13px'}>Rows per page :</Text>
                                <Select
                                    variant="outline"
                                    size="sm"
                                    width={'70px'}
                                    value={pageSize}
                                    onChange={(e) => { changePageSize(e) }}
                                    fontSize={12}
                                >
                                    <option value={10}>
                                        10
                                    </option>
                                    <option value={25}>
                                        25
                                    </option>
                                    <option value={50}>
                                        50
                                    </option>
                                    <option value={100}>
                                        100
                                    </option>

                                </Select>

                            </HStack>
                            <Pagination
                                pagesCount={pagesCount}
                                currentPage={currentPage}
                                onPageChange={setCurrentPage}
                            >
                                <PaginationContainer>
                                    <PaginationPrevious><BsArrowLeft /></PaginationPrevious>
                                    <PaginationPageGroup>
                                        {pages.map((page: number) => (
                                            <PaginationPage
                                                key={`pagination_page_${page}`}
                                                page={page}
                                            />
                                        ))}
                                    </PaginationPageGroup>
                                    <PaginationNext><BsArrowRight /></PaginationNext>
                                </PaginationContainer>
                            </Pagination>

                        </Flex>

                        {isOpen && (
                            <Portal>
                                {" "}
                                <ViewTransactionModal
                                    isOpenViewTransaction={isOpen}
                                    onClosed={setIsOpen}
                                    isViewTransaction={isOpen}
                                    reload={reloadData}
                                    transaction={transactionView}
                                />{" "}
                            </Portal>
                        )}
                    </Box>
            }
        </>
    )
}

export default AccountTransactions;