
import { ILoginData } from "../../models/auth/login.model";
import getService from "../crud-requests/getService";
import postService from "../crud-requests/postService";



const CONFIG_API_URL = "https://callnpayapi20230120085359.azurewebsites.net/api/";
const CONFIG_API_URL_CLIENT = process.env.REACT_APP_API_CARD_BASE_URL;

export const destroyUserSession = () => {
  sessionStorage.removeItem("callnpay_token");
  sessionStorage.removeItem("callnpay_user");
  sessionStorage.removeItem('UserFromToken')
};

export const getLocalUserProfile = (): ILoginData | null => {
  let user = sessionStorage.getItem("callnpay_user");
  if (user) {
    let userModel: ILoginData = JSON.parse(user);
    return userModel;
  }
  return null;
};

export const getAuthToken = (): string | null => {
  const token = sessionStorage.getItem("callnpay_token")?.toString();
  console.log(token, 'token')
  if (token) {
    return token;
  }
  return "";
};
export const getRefreshToken = (): string | null => {
  let refreshToken = sessionStorage.getItem("callnpay_refresh");
  if (refreshToken) {
    return refreshToken
  }
  return "";
};
export const getClientToken = async (): Promise<string | null> => {
  const token = await sessionStorage.getItem("callnpay_client_token")?.toString();
  console.log(token, 'token')
  if (token) {
    return token;
  }
  return "";
};

class AuthService {
  async register(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}identity/user/register`,
      data
    );

    return response.data;
  }
  async login(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}identity/account/login`,
      data
    );
    return response.data;
  }
  async clientAuthenticatice() {
    const response = await postService.post(
      `${CONFIG_API_URL_CLIENT}Account/AccessToken`,
      {
        "clientID": "QX1208H",
        "clientKey": "pUJIICslzZNJc+lqXZKr4YxDUPnN14p8ZTYWkHpaUlE="
      }
    );
    return response.data;
  }

  async forgot_password(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}identity/account/forgotpassword`,
      data
    );

    return response.data;
  }
  async resetPassword(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}identity/account/resetpassword`,
      data
    );
    return response.data;
  }
  async newPhoneNumber(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}identity/account/newphonenumber`,
      data
    );
    return response.data;
  }
  async verifyPhoneNumber(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}identity/account/verifyphonenumber`,
      data
    );
    return response.data;
  }
  async confrimEmail(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}identity/account/confirmemail`,
      data
    );
    return response.data;
  }
  async confrimEmailWithCode(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}identity/account/verifyemailwithcode`,
      data
    );
    return response.data;
  }

  async generateCode(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}identity/account/generateonetimecode`,
      data
    );
    return response.data;
  }
  async verifyCode(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}identity/account/verifyonetimecode`,
      data
    );
    return response.data;
  }
  ///api/identity/account/generateonetimecode
}

export default new AuthService();
