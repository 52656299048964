import { Box, Flex, HStack, Button, InputGroup, Input, InputRightElement, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Avatar, Text, MenuItem, Menu, MenuButton, MenuList, Center, useDisclosure, Portal, Select } from '@chakra-ui/react'

import { useNavigate } from 'react-router-dom';
import { RiSearch2Line } from 'react-icons/ri';
import { BsArrowLeft, BsArrowRight, BsThreeDotsVertical } from 'react-icons/bs';
import { NumericFormat } from 'react-number-format';
import { useEffect, useState } from 'react';
import { CreateAgentModal } from '../../components/agent/CreateAgentModal';
import Loading from '../../components/Loading';
import agentService from '../../services/agent/agent.service';
import { IAgentsRes, IAgentsValue } from '../../models/agents/agents.model';
import { Pagination, PaginationContainer, PaginationNext, PaginationPage, PaginationPageGroup, PaginationPrevious, usePagination } from "@ajna/pagination";
import { Ipage } from '../../models/audit/auditlog.model';
import AdminRoutes from '../routes';

const Agents = () => {
    let navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isAgent, setisAgent] = useState(false);
    const [search, setSearch] = useState("");
    const [query, setQuery] = useState("");
    const [page, setPage] = useState<Ipage>({ page_total: 0, total: 0, page_count: 0 });
    const [agentsList, setagentsList] = useState<IAgentsValue[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [reload, setReload] = useState(false);
    const [pageSize, setPageSize] = useState(25);
    const {
        currentPage,
        setCurrentPage,
        pagesCount,
        pages
    } = usePagination({
        pagesCount: page.page_count,
        initialState: { currentPage: 1, pageSize: 100 },
        total: page.total
    });


    useEffect(() => {
        let url = `?pageNumber=${currentPage}&pageSize=${pageSize}&searchString=${query}`
        agentService.getAgentsSummary(url)
            .then((data) => {
                const agents: IAgentsRes = data
                setPage({ page_total: agents.pageSize, total: agents.totalCount, page_count: agents.totalPages })
                setagentsList(agents.data.$values);
                setIsLoading(false)
            })
            .catch((err) => err)

    }, [currentPage, query, reload, pageSize]);


    const viewCustomer = (username: string) => {
        navigate(AdminRoutes.portal.agentCustomerLink(username));

    }
    const changePageSize = (e: any) => {
        setPageSize(e.target.value)
    }
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (search.length > 0) {
                setQuery(search);
            } else {
                setQuery("");
            }
        }, 700);

        return () => clearTimeout(delayDebounceFn)
    }, [search])


    const openCreateAgentModal = async () => {
        setisAgent(true);
        onOpen();
    };
    const reloadData = () => {
        setReload(!reload);
    };
    return (
        <>
            {
                isLoading ? <Loading /> :
                    <Box >
                        <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            py="26px"
                        >
                            <Box color="#2B2B2B" fontWeight="800" fontSize="27px">
                                Agents
                            </Box>

                        </Flex>
                        <Flex py='15px' justifyContent=''>
                            <InputGroup bg="white" width="30%" mr='20px'>
                                <Input height='40px' placeholder="Search input here" fontSize={12} size="sm" />
                                <InputRightElement pointerEvents="none" children={<RiSearch2Line color="green" size="0.9rem" />} />
                            </InputGroup>

                        </Flex>
                        <Box width={'100%'}>
                            <TableContainer>
                                <Table size={'sm'}>
                                    <Thead py={'15px'}>
                                    <Tr bg="#3778D9" color={'white'} py={'15px'} >     <Td py={'15px'}  textTransform={'capitalize'}>Agent Name</Td>
                                            <Td  textTransform={'capitalize'}>#Customer Account</Td>
                                            <Td  textTransform={'capitalize'}>#Account</Td>
                                            <Td  textTransform={'capitalize'}>Balance</Td>
                                            <Td  textTransform={'capitalize'}>No of customers</Td>
                                            <Td  textTransform={'capitalize'}>Total Customer Balance</Td>

                                            <Td  textTransform={'capitalize'}>Actions</Td>
                                        </Tr>
                                    </Thead>
                                    <Tbody>

                                        {
                                            agentsList && agentsList.length > 0 ? agentsList.map((agent, index) => (
                                                <Tr key={index} bg="white" >
                                                    <Td>
                                                        <Box>
                                                            <HStack spacing='5px' mt={'10px'}>
                                                                <Avatar
                                                                    size="sm"
                                                                    name={`${agent.fullName}`}
                                                                />
                                                                <Box>
                                                                    <Text fontWeight='500' textTransform='capitalize' fontSize='14px' >
                                                                        {`${agent.fullName}`}
                                                                    </Text>
                                                                    <Text fontWeight='400' textTransform='capitalize' fontSize='12px' >
                                                                        {`${agent.userName}`}
                                                                    </Text>
                                                                </Box>
                                                            </HStack>

                                                        </Box>
                                                    </Td>
                                                    <Td>
                                                        <Text textAlign={'center'} fontSize={'14px'}>{agent.customerAccountCount}</Text>
                                                    </Td>
                                                    <Td>
                                                        <Text textAlign={'center'} fontSize={'14px'}> {agent.accountCount} </Text>
                                                    </Td>
                                                    <Td>
                                                        <Text textAlign={'center'} fontSize={'14x'}>

                                                            <NumericFormat
                                                                value={agent.balanceSum}
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                                prefix={"₦"}
                                                            />
                                                        </Text>
                                                    </Td>


                                                    <Td>
                                                        <Text textAlign={'center'} fontSize={'14px'} >{agent.customerCount}</Text>
                                                    </Td>

                                                    <Td>
                                                        <Text textAlign={'center'} fontSize={'14x'}>

                                                            <NumericFormat
                                                                value={agent.customerBalanceSum}
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                                prefix={"₦"}
                                                            />
                                                        </Text>
                                                    </Td>
                                                    <Td>
                                                        <Menu>
                                                            <MenuButton
                                                                bg='white'
                                                                color='#616161'
                                                                fontSize={13}

                                                            >
                                                                <BsThreeDotsVertical fontSize={'20px'} />
                                                            </MenuButton>
                                                            <MenuList fontSize={12} >
                                                                <MenuItem onClick={() => { viewCustomer(agent.userName) }}>View customer</MenuItem>

                                                            </MenuList>
                                                        </Menu>
                                                    </Td>
                                                </Tr>
                                            )) : <Box>No Data</Box>
                                        }



                                    </Tbody>
                                </Table>
                            </TableContainer>
                            <Flex alignContent={"flex-end"} justifyContent={'flex-end'} mt={4}>

                                <HStack>
                                    <Text fontWeight={400} fontSize={'13px'}>Rows per page :</Text>
                                    <Select
                                        variant="outline"
                                        size="sm"
                                        width={'70px'}
                                        value={pageSize}
                                        onChange={(e) => { changePageSize(e) }}
                                        fontSize={12}
                                    >
                                        <option value={10}>
                                            10
                                        </option>
                                        <option value={25}>
                                            25
                                        </option>
                                        <option value={50}>
                                            50
                                        </option>
                                        <option value={100}>
                                            100
                                        </option>

                                    </Select>

                                </HStack>
                                <Pagination
                                    pagesCount={pagesCount}
                                    currentPage={currentPage}
                                    onPageChange={setCurrentPage}
                                >
                                    <PaginationContainer>
                                        <PaginationPrevious><BsArrowLeft /></PaginationPrevious>
                                        <PaginationPageGroup>
                                            {pages.map((page: number) => (
                                                <PaginationPage
                                                    key={`pagination_page_${page}`}
                                                    page={page}
                                                />
                                            ))}
                                        </PaginationPageGroup>
                                        <PaginationNext><BsArrowRight /></PaginationNext>
                                    </PaginationContainer>
                                </Pagination>

                            </Flex>
                        </Box>
                        {isAgent && (
                            <Portal>
                                {" "}
                                <CreateAgentModal
                                    isOpenAgent={isOpen}
                                    onClosed={onClose}
                                    isAgent={isAgent}
                                    reload={reloadData}
                                />{" "}
                            </Portal>
                        )}
                    </Box>
            }
        </>
    )
}

export default Agents;