import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    Box,
    useToast,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Flex,
    Button,
    HStack,
    Textarea,
    Select,
    Portal,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { IHandleTransaction } from "../../models/transactions/handleTransaction";
import { IAccountsValue, IAccountsRes } from "../../models/accounts/accounts.model";
import accountsService from "../../services/accounts/accounts.service";
import { TransferPinModal } from "./TransferPinModal";
import { IRegRes } from "../../models/auth/registration.model";
import AdminRoutes from "../../pages/routes";
import transactionService from "../../services/transaction/transaction.service";
import { useNavigate } from "react-router-dom";
import { SuccefulModal } from "./SuccessfulModal";
import { NumericFormat } from "react-number-format";

export const MoveAmountModal: React.FC<any> = ({
    isOpenMoveAmount,
    onClosed,
    isMoveAmount = false,
    reload
}: {
    isMoveAmount: boolean;
    isOpenMoveAmount: any;
    onClosed: any;
    reload: any;
}) => {
    const toast = useToast();
    const cancelRef = useRef().current;
    const [OpenSuccess, setOpenSuccess] = useState(false)
    const navigate = useNavigate()
    const [accountList, setacctTypeData] = useState<IAccountsValue[]>();
    const [moveToList, setMoveToLis] = useState<IAccountsValue[]>();
    const [balance, setBalance] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(true);
    const [value, setValue] = useState({
        displayValue: '0',
        actualNumberValue: 0
      });
    useEffect(() => {
        accountsService.getOwnAccounts()
            .then((data) => {
                const acc_type: IAccountsRes = data
                setacctTypeData(acc_type.data.$values)
                setMoveToLis(acc_type.data.$values)
                setIsLoading(false)
            })
            .catch((err) => err)
    }, []);
    const formik = useFormik({
        validate: (values: any) => {
            const errors: any = {};
            if (!values.source) {
                errors.source = "select source";
            }
            if (!values.destination) {
                errors.destination = "select destination";
            }
            if (!value.actualNumberValue) {
                errors.amount = "amount is required";
            }
            if (balance < parseInt(value.actualNumberValue.toString())) {
                errors.amount = "Available balance is less than required amount";
            }
            return errors;
        },
        initialValues: {
            source: "",
            destination: "",
            amount: "",
            description: "",

        },
        onSubmit: async (values) => {

            try {
                const transaction: IHandleTransaction = {
                    source: values.source,
                    destination: values.destination,
                    amount: parseInt(value.actualNumberValue.toString()),
                    currency: 'NGN',
                    description: values.description,
                    receiptRequested: true,
                    typeId: 0,
                    verificationCode: '0000',
                }
                const handle_transaction: IRegRes = await transactionService.handleTransaction(transaction)
                console.log(handle_transaction.succeeded)
                if (handle_transaction.succeeded) {
                    toast({
                        title: "Move Amount",
                        description: "Amount moved successfully",
                        status: "success",
                    });

                    setOpenSuccess(true)


                } else {
                    toast({
                        title: "Move Amount",
                        description: `${handle_transaction.messages.$values[0]}`,
                        status: "error",
                    });
                }
            } catch (error: any) {
                toast({
                    title: "Move Amount",
                    description: `${error}`,
                    status: "error",
                });
            }
        }
    });
    const newReload = () => {
        onClosed(false)
        reload()
    }
    const updateMoveAcctList = (account: string) => {
        const selectedAcct = accountList && accountList.find((each) => each.id == account)
        if (selectedAcct) setBalance(selectedAcct.balance)
        const acctList = accountList?.filter((each) => each.id !== account)
        setMoveToLis(acctList)
    }  
    const handleChange = (event: any) => {
        const strNumber = event.target.value
            .replace(/[^\d.]/g, '') // Remove non-numeric and non-decimal point characters
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'); // Add thousand separators

        const parts = strNumber.split('.');
        const integerPart = parts[0] || '0';
        const decimalPart = parts[1] || '00';

        // Ensure two decimal places
        const formattedNumber = `${integerPart}.${decimalPart.slice(0, 2)}`;

        setValue({
            displayValue: formattedNumber,
            actualNumberValue: parseFloat(formattedNumber.replace(/,/g, '')),
        });
    };

    return (
        <>
            <Modal
                isOpen={isOpenMoveAmount && isMoveAmount}
                onClose={onClosed}
                scrollBehavior="inside"
                closeOnOverlayClick={true}
                size="lg"
            >
                <ModalOverlay />
                <ModalContent borderRadius={5} px={6}>
                    <ModalHeader>
                        {
                            <Text
                                fontSize={'18px'}
                                pb={3}
                                textTransform="capitalize"

                            >
                                Move Amount
                            </Text>
                        }
                    </ModalHeader>
                    <ModalCloseButton size="sm" />
                    <ModalBody pb={'30px'}>
                        <Box mt={2}>
                            <form onSubmit={formik.handleSubmit}>
                                <FormControl
                                    mb={'20px'}
                                    isInvalid={
                                        formik.errors.source && formik.touched.source
                                            ? true
                                            : false
                                    }
                                >
                                    <FormLabel
                                        fontWeight="normal"
                                        color="brand.3"
                                        fontSize={13}
                                    >
                                        Move From
                                    </FormLabel>
                                    <Select
                                        variant="outline"
                                        bg="inputText.500"
                                        size="sm"
                                        placeholder="select account"
                                        value={formik.values.source}
                                        name="source"
                                        onClick={() => { updateMoveAcctList(formik.values.source) }}
                                        onChange={formik.handleChange}
                                        fontSize={12}
                                        color="brand.subtitle"
                                    >
                                        {accountList && accountList.map((each) => (
                                            <option key={each.id} value={each.id}>
                                                {" "}
                                                {`${each.type}-${each.id}`}
                                            </option>
                                        ))}
                                    </Select>
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.source}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl
                                    isInvalid={
                                        formik.errors.destination && formik.touched.destination
                                            ? true
                                            : false
                                    }
                                >
                                    <FormLabel
                                        fontWeight="normal"
                                        color="brand.3"
                                        fontSize={13}
                                    >
                                        Move To
                                    </FormLabel>
                                    <Select
                                        variant="outline"
                                        bg="inputText.500"
                                        size="sm"
                                        placeholder="select account"
                                        value={formik.values.destination}
                                        name="destination"
                                        onChange={formik.handleChange}
                                        fontSize={12}
                                        color="brand.subtitle"
                                    >
                                        {moveToList && moveToList.map((each) => (
                                            <option key={each.id} value={each.id}>
                                                {" "}
                                                {`${each.type}-${each.id}`}{" "}
                                            </option>
                                        ))}
                                    </Select>
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.destination}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl
                                    mt={3}
                                    isInvalid={
                                        formik.errors.amount && formik.touched.amount ? true : false
                                    }
                                >
                                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                        Amount
                                    </FormLabel>
                                    <Input
                                        type="text"
                                        id="amount"
                                        name="amount"
                                        placeholder="Enter amount"
                                        onBlur={handleChange}
                                        value={value.displayValue}
                                        onChange={(e) => setValue({ ...value, displayValue: e.target.value })}
                                        fontSize={13}
                                    />
                                    <HStack mt='5px'>
                                        <Text fontSize={'13px'}>Available balance =</Text>
                                        <Text fontSize={'13px'} color={formik.errors.amount ? 'red' : 'green'}>
                                            <NumericFormat
                                                value={balance}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"₦"}
                                            /></Text>
                                    </HStack>
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.amount}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl
                                    id="description"
                                    mt={3}
                                    isInvalid={
                                        formik.errors.description && formik.touched.description
                                            ? true
                                            : false
                                    }
                                >
                                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                        Description
                                    </FormLabel>
                                    <Textarea
                                        p={4}
                                        fontSize={12}
                                        size="sm"
                                        minH="50px"
                                        placeholder=" Description"
                                        name="description"
                                        onChange={formik.handleChange}
                                        value={formik.values.description}
                                    />
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.description}
                                    </FormErrorMessage>
                                </FormControl>


                                <Flex mt={12} justifyContent={'space-between'} >


                                    <Button

                                        px={6}
                                        colorScheme="blue"
                                        fontSize={'14px'}
                                        variant={'outline'}
                                        ref={cancelRef}
                                        width={'49%'}
                                        onClick={() => onClosed()}
                                    >
                                        Cancel
                                    </Button>
                                    <Button

                                        type="submit"
                                        isLoading={formik.isSubmitting}
                                        color={"white"}
                                        width={'49%'}
                                        bg={"#011F78"}
                                        px={6}
                                        fontSize={'14px'}
                                    >
                                        Confirm
                                    </Button>


                                </Flex>
                            </form>
                        </Box>
                        {OpenSuccess && (
                            <Portal>
                                {" "}
                                <SuccefulModal
                                    isOpenSuccess={OpenSuccess}
                                    onClosed={setOpenSuccess}
                                    isSuccess={OpenSuccess}
                                    reload={newReload}
                                />{" "}
                            </Portal>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
