import getService from "../crud-requests/getService";
import postService from "../crud-requests/postService";



const CONFIG_API_URL = "https://callnpayapi20230120085359.azurewebsites.net/api/";

class RolesService {
  async getroles() {
    const response = await getService.get(
      `${CONFIG_API_URL}identity/role/getroles`,

    );
    return response.data;
  }
}

export default new RolesService();