/* eslint-disable class-methods-use-this */
import axios, { AxiosRequestConfig } from "axios";
import axiosInstance from "../intercerptors";

const headers = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET",
};

class GetClientHelper {
  async get(url: string, headers  : any)  {
    const response = await axiosInstance.get(url, { ...headers });
    return response;
  }
}
export default new GetClientHelper();

 