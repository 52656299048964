import { Box, Flex, HStack, Button, InputGroup, Input, InputRightElement, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Avatar, Text, MenuItem, Menu, MenuButton, MenuList, Center, Checkbox, Portal, useDisclosure } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';
import { RiSearch2Line } from 'react-icons/ri';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { NumericFormat } from 'react-number-format';
import { useEffect, useState } from 'react';
import { EditAccountTypeModal } from './EditAccountTypeModal';
import accountsService from '../../services/accounts/accounts.service';
import { IAccountTypeRes, IAccountTypeValue } from '../../models/accounts/accountType.model';
import Loading from '../Loading';
import { CreateAcctTypeModal } from './CreateAcctTypeModal';
const AccountsType = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isAccountType, setisAccountType] = useState(false);
    const [isEditAccountType, setisEdiAccountType] = useState(false);
    const [reload, setReload] = useState(false);
    const [acctTypeData, setacctTypeData] = useState<IAccountTypeValue[]>();
    const [isLoading, setIsLoading] = useState(true);
    const [selectacctType, setSelectAcctType] = useState<IAccountTypeValue>();

    useEffect(() => {
        accountsService.getAccountsTypes()
            .then((data) => {
                const acc_type: IAccountTypeRes = data
                setacctTypeData(acc_type.data.$values)
                setIsLoading(false)
            })
            .catch((err) => err)
    }, [reload]);
    const openEditAccountTypeModal =  (acct : IAccountTypeValue) => {
        setSelectAcctType(acct)
        setisAccountType(false);
        setisEdiAccountType(true)
    };
    const openCreateAcctType =  () => {
        setisAccountType(true);
        setisEdiAccountType(false)
    };
    const reloadData = () => {
        setReload(!reload);
    };
    return (
        <>
         {
            isLoading ? <Loading /> : 
            <Box>
            <Flex py='15px' justifyContent='space-between'>
                <InputGroup bg="white" width="30%" mr='20px'>
                    <Input height='40px' placeholder="Search input here" fontSize={12} size="sm" />
                    <InputRightElement pointerEvents="none" children={<RiSearch2Line color="green" size="0.9rem" />} />
                </InputGroup>
                <Button
                                cursor="pointer"
                                color={"white"}
                                bg={"#011F78"}
                                fontSize={'13px'}
                                onClick={() => { openCreateAcctType() }}
                            >
                               Creat account tyoe
                            </Button>

            </Flex>
            <Box width={'100%'}>
                <TableContainer>
                    <Table size={'sm'}>
                        <Thead py={'15px'}>
                            <Tr bg="#3778D9" color={'white'} py={'15px'} >

                                <Td  textTransform={'capitalize'}>Type</Td>
                                <Td  textTransform={'capitalize'}>Description</Td>
                                <Td  textTransform={'capitalize'}>Code</Td>
                                <Td  textTransform={'capitalize'}>For customers</Td>
                                <Td  textTransform={'capitalize'}>For agents</Td>
                                <Td  textTransform={'capitalize'}>Savings Enabled</Td>
                                <Td  textTransform={'capitalize'}>Actions</Td>
                            </Tr>
                        </Thead>
                        <Tbody>

                          {
                            acctTypeData && acctTypeData.length > 0 ? acctTypeData.map((each,index) => (
                                <Tr key={index} bg="white" >

                                <Td>
                                    <Text fontSize={'14px'} bg={'blue'} color={'white'} textAlign={'center'} borderRadius={'15px'} py={'10px'} px={'5px'}>{each.name}</Text>
                                </Td>
                                <Td>
                                    <Text fontSize={'14px'} >{each.description}</Text>
                                </Td>
                                <Td>
                                    <Text fontSize={'14x'}>

                                        <NumericFormat
                                            value={each.code}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={""}
                                        />
                                    </Text>
                                </Td>
                                <Td>
                                    <Checkbox isChecked={each.isAvailableForCustomers}></Checkbox>
                                </Td>
                                <Td>
                                    <Checkbox isChecked={each.isAvailableForAgents}></Checkbox>
                                </Td>
                                <Td>
                                    <Checkbox isChecked={each.isSavingEnabled}></Checkbox>
                                </Td>
                                <Td>
                                    <Menu>
                                        <MenuButton
                                            bg='white'
                                            color='#616161'
                                            fontSize={13}

                                        >
                                            <BsThreeDotsVertical fontSize={'20px'} />
                                        </MenuButton>
                                        <MenuList fontSize={12} >
                                            <MenuItem fontSize={'14px'} onClick={() => { openEditAccountTypeModal(each) }} color={'#202020'}>Edit</MenuItem>
                                        </MenuList>
                                    </Menu>
                                </Td>
                            </Tr>
                            )) :<Box>No data</Box> 
                          }
                    


                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
            {isAccountType && (
                <Portal>
                    {" "}
                    <CreateAcctTypeModal
                        isOpenAccountType={isAccountType}
                        onClosed={setisAccountType}
                        isAccountType={isAccountType}
                        reload={reloadData}
                    />{" "}
                </Portal>
            )}
            {isEditAccountType && (
                <Portal>
                    {" "}
                    <EditAccountTypeModal
                        isOpenEditAccountType={isEditAccountType}
                        onClosed={setisEdiAccountType}
                        isEditAccountType={isEditAccountType}
                        reload={reloadData}
                        acctType={selectacctType}
                    />{" "}
                </Portal>
            )}
        </Box>
         }
        </>
    )
}

export default AccountsType