import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    Box,
    useToast,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Flex,
    Button,
    HStack,
    Textarea,
    Select,
    Portal,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { IHandleTransaction } from "../../models/transactions/handleTransaction";
import { IAccountsValue, IAccountsRes } from "../../models/accounts/accounts.model";
import accountsService from "../../services/accounts/accounts.service";
import agentService from "../../services/agent/agent.service";
import NumericInput from 'react-numeric-input';
import { NumericFormat } from "react-number-format";
import { ICustomerAgentRes, ICustomerAgentValue } from "../../models/agents/customerAgents.model";
import { ICustomersWithAccountRes, ICustomersWithAccountValue } from "../../models/audit/customerwithaccount.model";
import { ISearchUsersData } from "../../models/users/searchUsers.model";
import savingsService from "../../services/savings/savings.service";
import Loading from "../Loading";

export const CreateSavingModal: React.FC<any> = ({
    isOpenSaving,
    onClosed,
    isSaving = false,
    accountId,
    reload,
    username
}: {
    isSaving: boolean;
    isOpenSaving: any;
    onClosed: any;
    reload: any;
    accountId : string
    username : string
}) => {
    const toast = useToast();
    const cancelRef = useRef().current;
    const [openPin, setOpenPin] = useState(false)
    const [savingsTargetList, setacctTypeData] = useState<any[]>();
    const [isLoading, setIsLoading] = useState(true);
    const [users, setUsers] = useState<any[]>([]);
    const [savingOption, setsavingOption] = useState<any[]>();
    const [searchInput, setSearchInput] = useState('');
    const [value, setValue] = useState({
        displayValue: '0',
        actualNumberValue: 0
    });
  
    useEffect(() => {
        savingsService.getUserAccountWithSavingOption(`?userName=${username}`)
        .then((data) => {
            const acc_type: any = data
            setsavingOption(acc_type.data.$values)
            setIsLoading(false)
        })
        .catch((err) => err)
            savingsService.getSavingTargets()
            .then((data) => {
                const acc_type: any = data
                setacctTypeData(acc_type.data.$values)
                setIsLoading(false)
            })
            .catch((err) => err)
      
    }, []);
    const formik = useFormik({
        validate: (values: any) => {
            const errors: any = {};
          
          
            return errors;
        },
        initialValues: {
           
            targetId: "",
            amount: "",
            maturityDate: "",
            account: "",
            startDate: ''
        },
        onSubmit: async (values) => {
            const transaction: any = {
              
              
                targetAmount: parseInt(value.actualNumberValue.toString()),
                maturityDate: values.maturityDate,
                startDate: values.startDate,
                targetId: values.targetId,
                accountId : values.account
            }
           
            try {
                const create_saving_target: any = await savingsService.createSaving(transaction)
                console.log(create_saving_target.succeeded)
                if (create_saving_target.succeeded) {
                    toast({
                        title: "Saving",
                        description: "Saving created  successfully",
                        status: "success",
                    });
                    onClosed(!isSaving)
                    reload()

                } else {
                    toast({
                        title: "Saving",
                        description: `${create_saving_target.messages.$values[0]}`,
                        status: "error",
                    });
                }
            } catch (error: any) {
                toast({
                    title: "Saving",
                    description: `${error}`,
                    status: "error",
                });
            }
            //
            // {
            //     "targetAmount": 0,
            //     "startDate": "2023-12-04T15:28:04.567Z",
            //     "maturityDate": "2023-12-04T15:28:04.567Z",
            //     "accountId": "string",
            //     "targetId": 0
            //   }
        }
    });


    const handleChange2 = (event: any) => {
        const strNumber = event.target.value
            .replace(/[^\d.]/g, '') // Remove non-numeric and non-decimal point characters
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'); // Add thousand separators

        const parts = strNumber.split('.');
        const integerPart = parts[0] || '0';
        const decimalPart = parts[1] || '00';

        // Ensure two decimal places
        const formattedNumber = `${integerPart}.${decimalPart.slice(0, 2)}`;

        setValue({
            displayValue: formattedNumber,
            actualNumberValue: parseFloat(formattedNumber.replace(/,/g, '')),
        });
    };


    return (
        <>
            <Modal
                isOpen={isOpenSaving && isSaving}
                onClose={onClosed}
                scrollBehavior="inside"
                closeOnOverlayClick={true}
                size="lg"
            >
                <ModalOverlay />
                <ModalContent borderRadius={5} px={6}>
                    <ModalHeader>
                        {
                            <Text
                                fontSize={'18px'}
                                pb={3}
                                textTransform="capitalize"

                            >
                               Create Saving
                            </Text>
                        }
                    </ModalHeader>
                    <ModalCloseButton size="sm" />
                    <ModalBody pb={'30px'}>
                      { isLoading ? <Loading /> :  
                      <Box mt={2}>
                            <form onSubmit={formik.handleSubmit}>
                            <FormControl
                                    isInvalid={
                                        formik.errors.targetId && formik.touched.targetId
                                            ? true
                                            : false
                                    }
                                    mb={'10px'}
                                >
                                    <FormLabel
                                        fontWeight="normal"
                                        color="brand.3"
                                        fontSize={13}
                                    >
                                       Account
                                    </FormLabel>
                                    <Select
                                        variant="outline"
                                        bg="inputText.500"
                                        size="sm"
                                        placeholder="select account"
                                        value={formik.values.account}
                                        name="account" 
                                        onChange={formik.handleChange}
                                        fontSize={12}
                                        color="brand.subtitle"
                                    >
                                        {savingOption && savingOption.map((each) => (
                                           <option key={each.id} value={each.id}>
                                           {" "}
                                           {`${each.type}-${each.id}`}
                                       </option>
                                        ))}
                                    </Select>
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.targetId}
                                    </FormErrorMessage>
                                </FormControl>     
                                <FormControl
                                    isInvalid={
                                        formik.errors.targetId && formik.touched.targetId
                                            ? true
                                            : false
                                    }
                                    mb={'10px'}
                                >

                                    <FormLabel
                                        fontWeight="normal"
                                        color="brand.3"
                                        fontSize={13}
                                    >
                                         Savings Target
                                    </FormLabel>
                                    <Select
                                        variant="outline"
                                        bg="inputText.500"
                                        size="sm"
                                        placeholder="select savings target"
                                        value={formik.values.targetId}
                                        name="targetId" 
                                        onChange={formik.handleChange}
                                        fontSize={12}
                                        color="brand.subtitle"
                                    >
                                        {savingsTargetList && savingsTargetList.map((each) => (
                                            <option key={each.id} value={each.id}>
                                                {" "}
                                                {`${each.name}`}
                                            </option>
                                        ))}
                                    </Select>
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.targetId}
                                    </FormErrorMessage>
                                </FormControl>                            
                                <FormControl
                                   mb={'10px'}
                                    isInvalid={
                                        formik.errors.amount && formik.touched.amount ? true : false
                                    }
                                >
                                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                        Amount
                                    </FormLabel>
                                    <Input
                                        type="text"
                                        id="amount"
                                        name="amount"
                                        placeholder="Enter amount"
                                        onBlur={handleChange2}
                                        value={value.displayValue}
                                        onChange={(e) => setValue({ ...value, displayValue: e.target.value })}
                                        fontSize={13}
                                    />
                                   
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.amount}
                                    </FormErrorMessage>
                                </FormControl>
                                <HStack>
                                <FormControl
                                    id="startDate"
                                    mt={'10px'}
                                    isInvalid={
                                        formik.errors.startDate && formik.touched.maturityDate
                                            ? true
                                            : false
                                    }
                                >
                                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                        Start Date
                                    </FormLabel>
                                    <Input
                                      type="date"
                                        fontSize={12}
                                        placeholder=" start date"
                                        name="startDate"
                                        onChange={formik.handleChange}
                                        value={formik.values.startDate}
                                    />
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.startDate}
                                    </FormErrorMessage>
                                </FormControl>

                                <FormControl
                                    id="maturityDate"
                                    mt={'10px'}
                                    isInvalid={
                                        formik.errors.maturityDate && formik.touched.maturityDate
                                            ? true
                                            : false
                                    }
                                >
                                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                        Maturity Date
                                    </FormLabel>
                                    <Input
                                      type="date"
                                        fontSize={12}
                                        placeholder=" maturityDate"
                                        name="maturityDate"
                                        onChange={formik.handleChange}
                                        value={formik.values.maturityDate}
                                    />
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.maturityDate}
                                    </FormErrorMessage>
                                </FormControl>

                                </HStack>
                            

                                <Flex mt={12} justifyContent={'space-between'} >


                                    <Button

                                        px={6}
                                        colorScheme="blue"
                                        fontSize={'14px'}
                                        variant={'outline'}
                                        ref={cancelRef}
                                        width={'49%'}
                                        onClick={() => onClosed()}
                                    >
                                        Cancel
                                    </Button>
                                    <Button

                                        type="submit"
                                        isLoading={formik.isSubmitting}
                                        color={"white"}
                                        width={'49%'}
                                        bg={"#011F78"}
                                        px={6}
                                        fontSize={'14px'}
                                    >
                                        Confirm
                                    </Button>


                                </Flex>
                            </form>
                        </Box>}
                      
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
