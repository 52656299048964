import { Flex, Container, Box, Button, HStack, Img, Text, useToast, FormControl, Input, Avatar } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { HiArrowNarrowLeft } from "react-icons/hi";
import accountsService from "../../services/accounts/accounts.service";

import collo from '../../img/savings.png'
import pocket from '../../img/icon.png'
import gift from '../../img/wallet_phone.png'
import { IAvailableAcctRes, IAvailableAcctValue } from "../../models/accounts/accountType.model";
import Loading from "../../components/Loading";
import AdminRoutes from "../routes";
import { ILoginData } from "../../models/auth/login.model";
import { getLocalUserProfile } from "../../services/auth/auth.service";
import { IRegRes } from "../../models/auth/registration.model";
import usersService from "../../services/user/users.service";
import { ISearchUsersData } from "../../models/users/searchUsers.model";

const AddAccount = () => {
    const navigate = useNavigate()
    const toast = useToast()
    const { username } = useParams()
    const [availableAcctTypeData, setavailableAcctTypeData] = useState<IAvailableAcctValue[]>();
    const [selectAcct, setSelectAcct] = useState<number>();
    const [isSelected, setIsSelected] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [userProfile, setUserProfile] = useState<ILoginData | null>();
    const [searchInput, setSearchInput] = useState('');
    const [selectedName, setSelectedName] = useState('');
    const [users, setUsers] = useState<string[]>([]);

    useEffect(() => {

        if (searchInput) {
            usersService.getUsersByUserName(`?userNamePattern=${searchInput}`).then((data) => {
                const user_data: ISearchUsersData = data.data
                const mappedUser = user_data.$values.map((data => data.userName))
                setUsers(mappedUser)
            })
        } else {
            setUsers([])
        }

    }, [searchInput]);
    // Function to handle input change
    const handleInputChange = (event: any) => {
        setSearchInput(event.target.value);
    };

    // Function to handle name selection
    const handleNameSelect = (name: string) => {
        setSelectedName(name);
    };

    useEffect(() => {
        accountsService.getAccountsTypes()
            .then((data) => {
                const acc_type: IAvailableAcctRes = data
                setavailableAcctTypeData(acc_type.data.$values)
                setIsLoading(false)
            })
            .catch((err) => err)
        const user = getLocalUserProfile();
        setUserProfile(user);
    }, []);

    const handleAccountSelect = (accountId: number) => {
        setSelectAcct(accountId);
        setIsSelected(true)
    };

    const handleSubmit = async () => {
        try {
            const data = {
                username: selectedName,
                typeId: selectAcct,

            }
            const create_acct: IRegRes = await accountsService.createAccount(data)
            console.log(create_acct.succeeded)
            if (create_acct.succeeded) {
                toast({
                    title: "Account",
                    description: `Account created successfully`,
                    status: "success",
                });
                navigate(AdminRoutes.portal.wallet)
            } else {
                toast({
                    title: "Account",
                    description: `${create_acct.messages.$values[0]}`,
                    status: "error",
                });
            }
        } catch (error: any) {
            toast({
                title: "Account",
                description: `${error}`,
                status: "error",
            });
        }
    }
    return (
        <>
            {
                isLoading ? <Loading /> :
                    <Box >
                        <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            pb="20px"
                        >
                            <Box color="#2B2B2B" fontWeight="800" fontSize="24px">
                                Add Account
                            </Box>

                            <Box
                                mb="20px"
                                display="flex"
                                alignItems="center"
                                border="1px solid #616161"
                                padding={"5px 12px"}
                                width="fit-content"
                                borderRadius="5px"
                                cursor="pointer"
                            >
                                <HiArrowNarrowLeft color="#616161" fontSize="20px" />
                                <Text
                                    fontSize="14px"
                                    color="#616161"
                                    pl="5px"
                                    onClick={() => navigate(AdminRoutes.portal.accounts)}
                                    fontWeight={700}
                                >
                                    Back to accounts
                                </Text>
                            </Box>
                        </Flex>

                        <Box mx={'auto'} mt='40px' width={'80%'} boxShadow={'base'} borderRadius={'15px'} py='20px' bg={'white'} px={'30px'}>
                            <Text mb={'20px'} color="#353535" fontSize={'16px'} fontWeight={'600'}>
                                Select user
                            </Text>
                            <FormControl mb={'20px'}>
                                <Input
                                    type="text"
                                    size={'sm'}
                                    placeholder="Search by username"
                                    value={searchInput}
                                    onChange={handleInputChange}
                                />
                                <Box>
                                    {
                                        users.map((user, index) => (
                                            <Text px={'20px'} py={'10px'} boxShadow={'base'} borderRadius={'10px'} bg={'lightgrey'} mb={'5px'} fontSize={'13px'} cursor={'pointer'} onClick={() => handleNameSelect(user)} key={index}>
                                                {user}
                                            </Text>
                                        ))
                                    }
                                </Box>
                            </FormControl>
                            <HStack spacing='5px' mt={'10px'}>
                                <Avatar
                                    size="sm"
                                    name={selectedName}
                                />
                                <Box>
                                    <Text fontWeight='500' textTransform='capitalize' fontSize='14px' >
                                        {selectedName}
                                    </Text>
                                </Box>
                            </HStack>
                        </Box>
                        <Box mx={'auto'} mt='40px' width={'80%'} boxShadow={'base'} borderRadius={'15px'} py='20px' bg={'white'} px={'30px'}>
                            <Text mb={'20px'} color="#353535" fontSize={'16px'} fontWeight={'600'}>
                                Select Account
                            </Text>
                            <Flex
                                flexWrap={'wrap'}
                                mb="20px"
                            >
                                {
                                    availableAcctTypeData && availableAcctTypeData.length > 0 ? availableAcctTypeData.map((each, index) => (
                                        <Container
                                            bg="white"
                                            key={index}
                                            px={'20px'}
                                            py={'15px'}
                                            mr={'30px'}
                                            width={'330px'}
                                            mt={'20px'}
                                            cursor={'pointer'}
                                            border={selectAcct ? '1px solid' : 'none'}
                                            borderColor={selectAcct == each.id ? '#011F78' : 'white'}
                                            borderRadius={'10px'}
                                            onClick={() => { handleAccountSelect(each.id) }}
                                            boxShadow="base"
                                        >
                                            <Box pb={3} pt={1}>
                                                <Flex color="#011F78" >
                                                    <Img src={each.name == "Collo Saving" ? collo : each.name == 'Gift Card' ? gift : pocket} />
                                                </Flex>
                                                <Text color="#011F78" textAlign={'left'} fontWeight="600" fontSize={'15px'}>

                                                    {each.name}
                                                </Text>
                                                <Text color="#353535" textAlign={'left'} fontWeight="400" fontSize={'13px'}>
                                                    You can now do all your regular
                                                    savings in one place now
                                                </Text>

                                            </Box>
                                        </Container>

                                    )) : <Box>No data</Box>
                                }
                            </Flex>
                            <Button

                                color={"white"}
                                width={'100%'}
                                bg={"#011F78"}
                                fontSize={'13px'}
                                type="button"
                                isDisabled={isSelected == false}
                                onClick={() => { handleSubmit() }}
                            >
                                Add account
                            </Button>
                        </Box>

                    </Box>
            }
        </>
    )
}

export default AddAccount;