import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    Box,
    useToast,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Flex,
    Button,
    HStack,
    Textarea,
    Select,
    Portal,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { IHandleTransaction } from "../../models/transactions/handleTransaction";
import { IAccountsValue, IAccountsRes } from "../../models/accounts/accounts.model";
import accountsService from "../../services/accounts/accounts.service";
import { TransferPinModal } from "./TransferPinModal";
import agentService from "../../services/agent/agent.service";
import { useNavigate } from "react-router-dom";
import { IRegRes } from "../../models/auth/registration.model";
import AdminRoutes from "../../pages/routes";
import transactionService from "../../services/transaction/transaction.service";

export const GenerateStatementModal: React.FC<any> = ({
    isOpenGenerateStatement,
    onClosed,
    isGenerateStatement = false,
    reload,
    account
}: {
    isGenerateStatement: boolean;
    isOpenGenerateStatement: any;
    onClosed: any;
    reload: any;
    account: IAccountsValue;
}) => {
    const toast = useToast();
    const cancelRef = useRef().current;
    const navigate = useNavigate()
    const [accountList, setacctTypeData] = useState<IAccountsValue[]>();
    const [selectedAccount, setSelectedAccount] = useState<IAccountsValue>();
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        setSelectedAccount(account)
        accountsService.getOwnAccounts()
            .then((data) => {
                if (account) {
                    setacctTypeData([account])
                    formik.setValues({
                        lastdays: '',
                        source: account.id
                    })
                    setIsLoading(false)
                } else {
                    const acc_type: IAccountsRes = data
                    setacctTypeData(acc_type.data.$values)
                    setIsLoading(false)
                }

            })
            .catch((err) => err)

    }, [selectedAccount?.$id]);
    const formik = useFormik({
        validate: (values: any) => {
            const errors: any = {};
            if (!values.source) {
                errors.source = "select source";
            }

            if (!values.lastdays) {
                errors.lastdays = "lastdays is required";
            }
            return errors;
        },
        initialValues: {
            source: "",
            lastdays: "",
        },
        onSubmit: async (values) => {
            try {
                const url = `?accountId=${values.source}&lastdays=${values.lastdays}`
                const generate_statement: any = await transactionService.generateAccountStatement(url)

                if (generate_statement.succeeded) {


                    // Decode base64 to binary data
                    const byteArray: any = new Uint8Array(
                        atob(generate_statement.data.pdfContentBase64String)
                            .split('')
                            .map((char) => char.charCodeAt(0))
                    );
                    // Create a Blob from binary data
                    const blob = new Blob([byteArray], { type: 'application/pdf' });

                    // Create a URL for the Blob
                    const blobUrl = URL.createObjectURL(blob);

                    // Create an anchor element for downloading
                    const anchor = document.createElement('a');
                    anchor.href = blobUrl;
                    anchor.download = 'account-statement.pdf';

                    // Programmatically click the anchor element to initiate download
                    anchor.click();

                    // Clean up the URL.createObjectURL when done
                    URL.revokeObjectURL(blobUrl);
                    toast({
                        title: "Account Statement",
                        description: `acccount statement generated successfully`,
                        status: "success",
                    });
                    navigate(AdminRoutes.portal.wallet)
                } else {
                    toast({
                        title: "Account Statement",
                        description: `${generate_statement.messages.$values[0]}`,
                        status: "error",
                    });
                }
            } catch (error: any) {
                toast({
                    title: "Account Statement",
                    description: `${error}`,
                    status: "error",
                });
            }



        }
    });


    return (
        <>
            <Modal
                isOpen={isOpenGenerateStatement && isGenerateStatement}
                onClose={onClosed}
                scrollBehavior="outside"
                closeOnOverlayClick={true}
                size="lg"
            >
                <ModalOverlay />
                <ModalContent borderRadius={5} px={6}>
                    <ModalHeader>
                        {
                            <Text
                                fontSize={'18px'}
                                pb={3}
                                textTransform="capitalize"

                            >
                                Generate Statement
                            </Text>
                        }
                    </ModalHeader>
                    <ModalCloseButton size="sm" />
                    <ModalBody pb={'30px'}>
                        <Box mt={2}>
                            <form onSubmit={formik.handleSubmit}>
                                <FormControl
                                    mb={'20px'}
                                    isInvalid={
                                        formik.errors.source && formik.touched.source
                                            ? true
                                            : false
                                    }
                                >
                                    <FormLabel
                                        fontWeight="normal"
                                        color="brand.3"
                                        fontSize={13}
                                    >
                                        Select Account
                                    </FormLabel>
                                    <Select
                                        variant="outline"
                                        bg="inputText.500"
                                        size="sm"
                                        placeholder="select account"
                                        value={formik.values.source}
                                        name="source"
                                        onChange={formik.handleChange}
                                        fontSize={12}
                                        color="brand.subtitle"
                                    >
                                        {accountList && accountList.map((each) => (
                                            <option key={each.id} value={each.id}>
                                                {" "}
                                                {`${each.type}-${each.id}`}
                                            </option>
                                        ))}
                                    </Select>
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.source}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl
                                    mt={3}
                                    isInvalid={
                                        formik.errors.lastdays && formik.touched.lastdays ? true : false
                                    }
                                >
                                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                        How many days back?
                                    </FormLabel>
                                    <Input
                                        type="number"
                                        id="lastdays"
                                        name="lastdays"
                                        placeholder="Enter lastdays"
                                        onChange={formik.handleChange}
                                        fontSize={13}
                                    />
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.lastdays}
                                    </FormErrorMessage>
                                </FormControl>


                                <Flex mt={12} justifyContent={'space-between'} >


                                    <Button

                                        px={6}
                                        colorScheme="blue"
                                        fontSize={'14px'}
                                        variant={'outline'}
                                        ref={cancelRef}
                                        width={'49%'}
                                        onClick={() => onClosed()}
                                    >
                                        Cancel
                                    </Button>
                                    <Button

                                        type="submit"
                                        isLoading={formik.isSubmitting}
                                        color={"white"}
                                        width={'49%'}
                                        bg={"#011F78"}
                                        px={6}
                                        fontSize={'14px'}
                                    >
                                        Confirm
                                    </Button>


                                </Flex>
                            </form>
                        </Box>

                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
