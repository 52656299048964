import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Box,
  useToast,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Flex,
  Button,
  HStack,
  Select,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import {
  IAccountsValue,
  IAccountsRes,
} from "../../models/accounts/accounts.model";
import accountsService from "../../services/accounts/accounts.service";

import Loading from "../Loading";
import giftCardService from "../../services/gift-card/giftCard.service";
import authService from "../../services/auth/auth.service";
import { BsPlus } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { FaBullseye } from "react-icons/fa6";

interface IRequestCardDets {
  customerID: string;
  totalCards: string;
  cardType: string;
  amountPerCard: string;
}
export const RequestGiftCardModal: React.FC<any> = ({
  isOpenGiftCard,
  onClosed,
  isGiftCard = false,
  reload,
  username,
}: {
  isGiftCard: boolean;
  isOpenGiftCard: any;
  onClosed: any;
  reload: any;
  username: string;
}) => {
  const toast = useToast();
  const cancelRef = useRef().current;
  const [accountList, setacctTypeData] = useState<IAccountsValue[]>();
  const [activeSaving, setActiveSaving] = useState<any[]>();
  const [balance, setBalance] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [value, setValue] = useState({
    displayValue: "0",
    actualNumberValue: 0,
  });
  const [cardTypeList, setcardTypeList] = useState<any[]>([]);
  const [giftRequestList, setToplicList] = useState<IRequestCardDets[]>([
    {
      customerID: "",
      totalCards: "",
      cardType: "",
      amountPerCard: "",
    },
  ]);

  const handleChangeGiftRequest = (
    index: number,
    field: keyof IRequestCardDets,
    value: string
  ) => {
    setToplicList((prevGiftRequest) => {
      const updatedGiftRequest = [...prevGiftRequest];
      updatedGiftRequest[index][field] = value;
      return updatedGiftRequest;
    });
  };

  const handleAddGiftRequest = () => {
    setToplicList((prevGiftRequest) => [
      ...prevGiftRequest,
      {
        customerID: "",
        totalCards: "",
        cardType: "",
        amountPerCard: "",
      },
    ]);
  };
  const handleRemoveGiftRequest = (index: number) => {
    setToplicList((prevGiftRequest) => {
      const updatedGiftRequest = [...prevGiftRequest];
      updatedGiftRequest.splice(index, 1);
      return updatedGiftRequest;
    });
  };
  useEffect(() => {
    accountsService
      .getOwnAccounts()
      .then((data) => {
        const acc_type: IAccountsRes = data;
        setacctTypeData(acc_type.data.$values);
      })
      .catch((err) => err);

    authService
      .clientAuthenticatice()
      .then(async (data) => {
        console.log(data);
        sessionStorage.setItem("callnpay_client_token", data.data);
        const cardTypesApi: any = await giftCardService.getCardTypeList(
          data.data
        );
        if (cardTypesApi) {
          const allowedCards = cardTypesApi.data.filter(
            (each: { isTransactional: boolean; oneTimeUse: boolean }) =>
              !each.isTransactional && each.oneTimeUse
          );
          setcardTypeList(allowedCards);
          setIsLoading(false);
        }
      })
      .catch((err) => err);
  }, []);
  const formik = useFormik({
    validate: (values: any) => {
      const errors: any = {};
      if (!values.source) {
        errors.source = "select source";
      }

      if (balance < parseInt(value.actualNumberValue.toString())) {
        errors.amount = "Available balance is less than required amount";
      }
      return errors;
    },
    initialValues: {
      source: "",
      totalCards: "",
      cardType: "",
      amount: "",
    },
    onSubmit: async (values) => {},
  });

  const submitCardRequest = async () => {
    if (giftRequestList.length == 1) {
      setIsSubmitting(true);
      try {
        const request_gift_card: any = await giftCardService.requestCard(
          giftRequestList[0]
        );
        console.log(request_gift_card.succeeded);
        if (request_gift_card.statusCode) {
          setIsSubmitting(false);
          toast({
            title: "GiftCard",
            description: "GiftCard request successful",
            status: "success",
          });
          onClosed(!isGiftCard);
          reload();
          sessionStorage.removeItem("callnpay_client_token");
        } else {
          toast({
            title: "GiftCard",
            description: `${request_gift_card.message}`,
            status: "error",
          });
          setIsSubmitting(false);
        }
      } catch (error: any) {
        toast({
          title: "GiftCard",
          description: `${error}`,
          status: "error",
        });
        setIsSubmitting(false);
      }
    } else {
      try {
        setIsSubmitting(true);
        const request_gift_card: any = await giftCardService.requestCardBulk(
          giftRequestList
        );
        console.log(request_gift_card.succeeded);
        if (request_gift_card.statusCode) {
          setIsSubmitting(false);
          toast({
            title: "GiftCard Request",
            description: "GiftCard request successful",
            status: "success",
          });
          onClosed(!isGiftCard);
          reload();
          sessionStorage.removeItem("callnpay_client_token");
        } else {
          setIsSubmitting(false);
          toast({
            title: "GiftCard Request",
            description: `${request_gift_card.message}`,
            status: "error",
          });
        }
      } catch (error: any) {
        setIsSubmitting(false);
        toast({
          title: "GiftCard Request",
          description: `${error}`,
          status: "error",
        });
      }
    }
  };

  const handleChange2 = (event: any) => {
    const strNumber = event.target.value
      .replace(/[^\d.]/g, "") // Remove non-numeric and non-decimal point characters
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"); // Add thousand separators

    const parts = strNumber.split(".");
    const integerPart = parts[0] || "0";
    const decimalPart = parts[1] || "00";

    // Ensure two decimal places
    const formattedNumber = `${integerPart}.${decimalPart.slice(0, 2)}`;

    setValue({
      displayValue: formattedNumber,
      actualNumberValue: parseFloat(formattedNumber.replace(/,/g, "")),
    });
  };

  return (
    <>
      <Modal
        isOpen={isOpenGiftCard && isGiftCard}
        onClose={onClosed}
        scrollBehavior="inside"
        closeOnOverlayClick={true}
        size="lg"
      >
        <ModalOverlay />
        <ModalContent borderRadius={5} px={6}>
          <ModalHeader>
            {
              <Text fontSize={"18px"} pb={3} textTransform="capitalize">
                GiftCard
              </Text>
            }
          </ModalHeader>
          <ModalCloseButton size="sm" />
          <ModalBody pb={"30px"}>
            {isLoading ? (
              <Loading />
            ) : (
              <Box mt={2}>
                <form onSubmit={formik.handleSubmit}>
                  {giftRequestList.map((giftRequest, index) => (
                    <Box bg="white" px={"10px"} py={"7px"} key={index}>
                      <FormControl
                        isInvalid={
                          formik.errors.source && formik.touched.source
                            ? true
                            : false
                        }
                        mb={"20px"}
                      >
                        <FormLabel
                          fontWeight="normal"
                          color="brand.3"
                          fontSize={13}
                        >
                          Select Account
                        </FormLabel>
                        <Select
                          variant="outline"
                          bg="inputText.500"
                          size="sm"
                          placeholder="select account"
                          value={giftRequest.customerID}
                          onChange={(event) =>
                            handleChangeGiftRequest(
                              index,
                              "customerID",
                              event.target.value
                            )
                          }
                          name="source"
                          fontSize={12}
                          color="brand.subtitle"
                        >
                          {accountList &&
                            accountList.map((each) => (
                              <option key={each.id} value={each.id}>
                                {" "}
                                {`${each.type}-${each.id}`}
                              </option>
                            ))}
                        </Select>
                        <FormErrorMessage fontSize={12}>
                          {formik.errors.source}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={
                          formik.errors.source && formik.touched.source
                            ? true
                            : false
                        }
                        mb={"20px"}
                      >
                        <FormLabel
                          fontWeight="normal"
                          color="brand.3"
                          fontSize={13}
                        >
                          Card Type
                        </FormLabel>
                        <Select
                          variant="outline"
                          bg="inputText.500"
                          size="sm"
                          placeholder="select card type"
                          name="cardType"
                          value={giftRequest.cardType}
                          onChange={(event) =>
                            handleChangeGiftRequest(
                              index,
                              "cardType",
                              event.target.value
                            )
                          }
                          fontSize={12}
                        >
                          {cardTypeList &&
                            cardTypeList.map((each) => (
                              <option
                                key={each.cardTypeID}
                                value={each.cardTypeID}
                              >
                                {" "}
                                {`${each.cardTypeName}-₦${each.costPrice}`}
                              </option>
                            ))}
                        </Select>
                        <FormErrorMessage fontSize={12}>
                          {formik.errors.cardType}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        mt={"10px"}
                        isInvalid={
                          formik.errors.totalCards && formik.touched.totalCards
                            ? true
                            : false
                        }
                      >
                        <FormLabel fontWeight="normal" fontSize={13}>
                          No of Cards
                        </FormLabel>
                        <Input
                          type="number"
                          fontSize={12}
                          placeholder="Total cards"
                          name="totalCards"
                          size={"sm"}
                          value={giftRequest.totalCards}
                          onChange={(event) =>
                            handleChangeGiftRequest(
                              index,
                              "totalCards",
                              event.target.value.toString()
                            )
                          }
                        />
                        <FormErrorMessage fontSize={12}>
                          {formik.errors.totalCards}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        mt={3}
                        isInvalid={
                          formik.errors.amount && formik.touched.amount
                            ? true
                            : false
                        }
                      >
                        <FormLabel
                          fontWeight="normal"
                          color="brand.3"
                          fontSize={13}
                        >
                          Amount
                        </FormLabel>
                        <Input
                          type="number"
                          id="amount"
                          name="amount"
                          size={"sm"}
                          placeholder="Amount per card"
                          value={giftRequest.amountPerCard}
                          onChange={(event) =>
                            handleChangeGiftRequest(
                              index,
                              "amountPerCard",
                              event.target.value.toString()
                            )
                          }
                          fontSize={13}
                        />

                        <FormErrorMessage fontSize={12}>
                          {formik.errors.amount}
                        </FormErrorMessage>
                      </FormControl>

                      <Flex my={"7px"} justifyContent={"flex-end"}>
                        <HStack
                          onClick={() => {
                            handleRemoveGiftRequest(index);
                          }}
                          _hover={{ bg: "#F5F5F5" }}
                          mr="15px"
                          cursor={"pointer"}
                          boxShadow={"base"}
                          py={"2px"}
                          px={"6px"}
                          borderRadius={"5px"}
                          spacing={"5px"}
                          color={"green"}
                        >
                          <MdDelete fontSize={"18px"} color="red" />
                        </HStack>
                        {giftRequestList.length - 1 == index && (
                          <HStack
                            onClick={() => {
                              handleAddGiftRequest();
                            }}
                            _hover={{ bg: "#F5F5F5" }}
                            cursor={"pointer"}
                            boxShadow={"base"}
                            py={"2px"}
                            px={"6px"}
                            borderRadius={"5px"}
                            spacing={"5px"}
                            color={"011F78"}
                          >
                            <BsPlus fontSize={"20px"} />
                          </HStack>
                        )}
                      </Flex>
                    </Box>
                  ))}

                  <Flex mt={12} justifyContent={"space-between"}>
                    <Button
                      px={6}
                      colorScheme="blue"
                      fontSize={"14px"}
                      variant={"outline"}
                      ref={cancelRef}
                      width={"49%"}
                      onClick={() => onClosed()}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        submitCardRequest();
                      }}
                      isLoading={formik.isSubmitting}
                      color={"white"}
                      width={"49%"}
                      bg={"#011F78"}
                      px={6}
                      fontSize={"14px"}
                    >
                      Confirm
                    </Button>
                  </Flex>
                </form>
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
