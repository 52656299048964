import getService from "../crud-requests/getService";
import postService from "../crud-requests/postService";



const CONFIG_API_URL = "https://callnpayapi20230120085359.azurewebsites.net/api/";

class AuditService {
  async auditLogs(url: string) {
    const response = await getService.get(
      `${CONFIG_API_URL}admin/audit/getall${url}`,

    );
    return response.data;
  }
}

export default new AuditService();