import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    Box,
    useToast,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Flex,
    Button,
    HStack,
    Textarea,
    Checkbox,
    Stack,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { IAccountTypeValue } from "../../models/accounts/accountType.model";
import accountsService from "../../services/accounts/accounts.service";

export const EditAccountTypeModal: React.FC<any> = (
{
    isOpenEditAccountType,
    onClosed,
    isEditAccountType = false,
    reload,
    acctType
}: {
    isEditAccountType: boolean;
    isOpenEditAccountType: any;
    onClosed: any;
    reload: any;
    acctType : IAccountTypeValue
}) => {
    const toast = useToast();
    const cancelRef = useRef().current;
    const [selectedAccount, setSelectedAccount] = useState<IAccountTypeValue>();
    const [forCustomer, setforCustomer] = useState(false)
    const [forAgent, setForAgent] = useState(false)
    const [savingsEnabled, setSavingsEnabled] = useState(false)
  
    useEffect(() => {
        setSelectedAccount(acctType)
        
                if (acctType) {
                    
                    formik.setValues({
                        name: acctType.name,
                        description: acctType.description,
                        code : acctType.code
                    })
                   setforCustomer(acctType.isAvailableForCustomers)
                   setForAgent(acctType.isAvailableForAgents)
                   setSavingsEnabled(acctType.isSavingEnabled)
                } 

           

    }, [selectedAccount?.id]);
    const formik = useFormik({
        validate: (values: any) => {
            const errors: any = {};
            if (!values.name) {
                errors.name = "name is required";
            }
           

            return errors;
        },
        initialValues: {
            name: "",       
            description: "",
            code :""
           
            
        },
        onSubmit: async (values) => {
            const transaction: any = {
                id : selectedAccount?.id,
                name: values.name,
                description: values.description,
                code: values.code,
                isAvailableForCustomers: forCustomer,
                isAvailableForAgents: forAgent,
                isSavingEnabled : savingsEnabled,
                stylingColor: 0,
            }
           

            try {
                const create_acct_type: any = await accountsService.updateAccountType(transaction)
                console.log(create_acct_type.succeeded)
                if (create_acct_type.succeeded) {
                    toast({
                        title: "Account Type",
                        description: "account type updated successfully",
                        status: "success",
                    });
                    onClosed(!isEditAccountType)
                    reload()

                } else {
                    toast({
                        title: "Account Type",
                        description: `${create_acct_type.messages.$values[0]}`,
                        status: "error",
                    });
                }
            } catch (error: any) {
                toast({
                    title: "Account Type",
                    description: `${error}`,
                    status: "error",
                });
            }
        },
    });

    return (
        <>
            <Modal
                isOpen={isOpenEditAccountType && isEditAccountType}
                onClose={onClosed}
                scrollBehavior="outside"
                closeOnOverlayClick={true}
                size="lg"
            >
                <ModalOverlay />
                <ModalContent borderRadius={5} px={6}>
                    <ModalHeader>
                        {
                            <Text
                                fontSize={'18px'}
                                pb={3}
                                textTransform="capitalize"
                                
                                
                            >
                               Edit account type
                            </Text>
                        }
                    </ModalHeader>
                    <ModalCloseButton size="sm" />
                    <ModalBody pb={'30px'}>
                        <Box mt={2}>
                            <form onSubmit={formik.handleSubmit}>
                                <FormControl
                                    mt={3}
                                    isInvalid={
                                        formik.errors.name && formik.touched.name ? true : false
                                    }
                                >
                                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                        Name
                                    </FormLabel>
                                    <Input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={formik.values.name}
                                        placeholder="Enter name"
                                        onChange={formik.handleChange}
                                        fontSize={13}
                                    />
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.name}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl
                                    mt={3}
                                    isInvalid={
                                        formik.errors.code && formik.touched.code ? true : false
                                    }
                                >
                                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                       Code
                                    </FormLabel>
                                    <Input
                                        type="text"
                                        id="code"
                                        name="code"
                                        placeholder="Enter code"
                                        onChange={formik.handleChange}
                                        value={formik.values.code}
                                        fontSize={13}
                                    />
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.code}
                                    </FormErrorMessage>
                                </FormControl>
                           
                                <FormControl
                                    mt={3}
                                    mb={'10px'}
                                    isInvalid={
                                        formik.errors.description && formik.touched.description ? true : false
                                    }
                                >
                                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                        Description
                                    </FormLabel>
                                    <Input
                                        type="text"
                                        id="description"
                                        name="description"
                                        placeholder="Description"
                                        value={formik.values.description}
                                        onChange={formik.handleChange}
                                        fontSize={13}
                                    />
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.description}
                                    </FormErrorMessage>
                                </FormControl>
                              
                                <Box >
                                       <Stack fontSize={'12px'} color={'#575757'} spacing={5} direction='row'>
                                        <Checkbox size={'sm'} fontSize={'12px'} isChecked={forCustomer}   onChange={(e) => setforCustomer(e.target.checked)} color={'#575757'} colorScheme='blue' >
                                           Available for customer
                                        </Checkbox>
                                        <Checkbox  size={'sm'} fontSize={'12px'}isChecked={forAgent} color={'#575757'}  onChange={(e) => setForAgent(e.target.checked)}colorScheme='blue' >
                                            Available for agent
                                        </Checkbox>
                                        <Checkbox   size={'sm'} fontSize={'12px'} isChecked={savingsEnabled} color={'#575757'} onChange={(e) => setSavingsEnabled(e.target.checked)} colorScheme='blue' >
                                            Savings enabled
                                        </Checkbox>
                                       
                                    </Stack>
                                </Box>
                                <Flex mt={12} justifyContent={'space-between'} >


                                    <Button

                                        px={6}
                                        colorScheme="blue"
                                        fontSize={'14px'}
                                        variant={'outline'}
                                        ref={cancelRef}
                                        width={'49%'}
                                        onClick={() => onClosed()}
                                    >
                                        Cancel
                                    </Button>
                                    <Button

                                        type="submit"
                                        isLoading={formik.isSubmitting}
                                        color={"white"}
                                        width={'49%'}
                                        bg={"#011F78"}
                                        px={6}
                                        fontSize={'14px'}
                                    >
                                        Save changes
                                    </Button>


                                </Flex>
                            </form>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
