import getService from "../crud-requests/getService";
import postService from "../crud-requests/postService";
import putService from "../crud-requests/putService";



const CONFIG_API_URL = "https://callnpayapi20230120085359.azurewebsites.net/api/";

class UserProfileService {
  async userProfile() {
    const response = await getService.get(
      `${CONFIG_API_URL}identity/user/getprofile`,

    );
    return response.data;
  }
  async userProfilePhoto() {
    const response = await getService.get(
      `${CONFIG_API_URL}identity/user/getprofilephoto`,

    );
    return response.data;
  }
  ///api/
  async updateUserProfile(data: any) {
    const response = await putService.put(
      `${CONFIG_API_URL}identity/user/updateprofile`,
      data
    );
    return response.data;
  }
  async setPin(data: any) {
    const response = await putService.put(
      `${CONFIG_API_URL}identity/account/newpin`,
      data
    );
    return response.data;
  }
  async changePin(data: any) {
    const response = await putService.put(
      `${CONFIG_API_URL}identity/account/changepin`,
      data
    );
    return response.data;
  }
  async hasPin() {
    const response = await getService.get(
      `${CONFIG_API_URL}identity/account/haspin`
    );
    return response.data;
  }

  async uploadProfilePhoto(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}identity/user/profilephoto`,
      data
    );
    return response.data;
  }
  async changePassword(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}identity/account/newpassword`,
      data
    );
    return response.data;
  }

}

export default new UserProfileService();