import getService from "../crud-requests/getService";
import getServiceClient from "../crud-requests/getServiceClient";
import postService from "../crud-requests/postService";



const CONFIG_API_URL = process.env.REACT_APP_API_CARD_BASE_URL ;

class GiftCardService {

    async requestCard(data: any) {
        const response = await postService.post(
          `${CONFIG_API_URL}GiftCard/RequestCard`,
          data
        );
        return response.data;
      } 
      async requestCardBulk(data: any) {
        const response = await postService.post(
          `${CONFIG_API_URL}GiftCard/RequestCardBulk`,
          data
        );
        return response.data;
      } 
      async getCardTypeList( token : string) {
        console.log(token,'token for getcard')
        const response = await getServiceClient.get(
            `${CONFIG_API_URL}CardValidate/CardTypes`,{
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET",
              'X-Token' : `${token}`,
              'clientid' : 'QX1208H'
            }
        );
        return response.data;
    }
      async myRequests(id: string) {
        const response = await getService.get(
          `${CONFIG_API_URL}GiftCard/MyCardRequests/${id}`
        );
        return response.data;
      }
      async myRequestsDetails(id: string) {
        const response = await getService.get(
          `${CONFIG_API_URL}GiftCard/MyCardRequests/Details/${id}`
        );
        return response.data;
      }
  }
  
  export default new GiftCardService();
