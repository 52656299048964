import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    Box,
    useToast,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Flex,
    Button,
    HStack,
    Textarea,
    Checkbox,
    Stack,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useRef, useState } from "react";
import accountsService from "../../services/accounts/accounts.service";
import savingsService from "../../services/savings/savings.service";

export const CreateSavingTargetModal: React.FC<any> = ({
    isOpenSavingTarget,
    onClosed,
    isSavingTarget = false,
    reload
}: {
    isSavingTarget: boolean;
    isOpenSavingTarget: any;
    onClosed: any;
    reload: any;
}) => {
    const toast = useToast();
    const cancelRef = useRef().current;
  
    const formik = useFormik({
        validate: (values: any) => {
            const errors: any = {};
            if (!values.name) {
                errors.name = "name is required";
            }


            return errors;
        },
        initialValues: {
            name: "",
            description: "",
         


        },
        onSubmit: async (values) => {
            const transaction: any = {
                name: values.name,
                description: values.description,
              
            }


            try {
                const create_saving_target: any = await savingsService.createSavingTarget(transaction)
                console.log(create_saving_target.succeeded)
                if (create_saving_target.succeeded) {
                    toast({
                        title: "Saving Target",
                        description: "Saving target created successfully",
                        status: "success",
                    });
                    onClosed(!isSavingTarget)
                    reload()

                } else {
                    toast({
                        title: "Saving Target",
                        description: `${create_saving_target.messages.$values[0]}`,
                        status: "error",
                    });
                }
            } catch (error: any) {
                toast({
                    title: "Saving Target",
                    description: `${error}`,
                    status: "error",
                });
            }
        },
    });

    return (
        <>
            <Modal
                isOpen={isOpenSavingTarget && isSavingTarget}
                onClose={onClosed}
                scrollBehavior="outside"
                closeOnOverlayClick={true}
                size="lg"
            >
                <ModalOverlay />
                <ModalContent borderRadius={5} px={6}>
                    <ModalHeader>
                        {
                            <Text
                                fontSize={'18px'}
                                pb={3}
                                textTransform="capitalize"


                            >
                                Create Saving Target
                            </Text>
                        }
                    </ModalHeader>
                    <ModalCloseButton size="sm" />
                    <ModalBody pb={'30px'}>
                        <Box mt={2}>
                            <form onSubmit={formik.handleSubmit}>
                                <FormControl
                                    mt={3}
                                    isInvalid={
                                        formik.errors.name && formik.touched.name ? true : false
                                    }
                                >
                                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                        Name
                                    </FormLabel>
                                    <Input
                                        type="text"
                                        id="name"
                                        name="name"
                                        placeholder="Enter name"
                                        onChange={formik.handleChange}
                                        fontSize={13}
                                    />
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.name}
                                    </FormErrorMessage>
                                </FormControl>
                          
                                <FormControl
                                    mt={3}
                                    mb={'10px'}
                                    isInvalid={
                                        formik.errors.description && formik.touched.description ? true : false
                                    }
                                >
                                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                        Description
                                    </FormLabel>
                                    <Input
                                        type="text"
                                        id="description"
                                        name="description"
                                        placeholder="Description"
                                        onChange={formik.handleChange}
                                        fontSize={13}
                                    />
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.description}
                                    </FormErrorMessage>
                                </FormControl>

                             
                                <Flex mt={12} justifyContent={'space-between'} >


                                    <Button

                                        px={6}
                                        colorScheme="blue"
                                        fontSize={'14px'}
                                        variant={'outline'}
                                        ref={cancelRef}
                                        width={'49%'}
                                        onClick={() => onClosed()}
                                    >
                                        Cancel
                                    </Button>
                                    <Button

                                        type="submit"
                                        isLoading={formik.isSubmitting}
                                        color={"white"}
                                        width={'49%'}
                                        bg={"#011F78"}
                                        px={6}
                                        fontSize={'14px'}
                                    >
                                        Save changes
                                    </Button>


                                </Flex>
                            </form>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
