import { Box, Flex, HStack, Button, InputGroup, Input, InputRightElement, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Avatar, Text, MenuItem, Menu, MenuButton, MenuList, Center } from '@chakra-ui/react'

import { useNavigate } from 'react-router-dom';
import { RiSearch2Line } from 'react-icons/ri';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { NumericFormat } from 'react-number-format';
import { BiEditAlt } from 'react-icons/bi';
import rolesService from '../../services/roles/roles.service';
import { useEffect, useState } from 'react';
import { IRolesData, IRolesRes } from '../../models/roles/roles.model';
import Loading from '../../components/Loading';

const Roles = () => {
    let navigate = useNavigate();
    const [rolesdata, setrolesdata] = useState<IRolesData>();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        rolesService.getroles()
            .then((data) => {
                const roles: IRolesRes = data
                setrolesdata(roles.data)
                setIsLoading(false)
            })
            .catch((err) => err)
    }, []);
    return (
        <>
           {
            isLoading ? <Loading/> :
            <Box >
            <Flex
                justifyContent="space-between"
                alignItems="center"
                py="26px"
            >
                <Box color="#2B2B2B" fontWeight="800" fontSize="27px">
                    Roles
                </Box>
                <HStack>
                    <Button
                        cursor="pointer"
                        color={"white"}
                        bg={"#011F78"}
                        type="submit"
                        fontSize={'13px'}
                    >
                        Create Role
                    </Button>
                </HStack>
            </Flex>
            <Flex py='15px' justifyContent=''>
                <InputGroup bg="white" width="30%" mr='20px'>
                    <Input height='40px' placeholder="Search input here" fontSize={12} size="sm" />
                    <InputRightElement pointerEvents="none" children={<RiSearch2Line color="green" size="0.9rem" />} />
                </InputGroup>

            </Flex>
            <Box width={'100%'}>
                <TableContainer>
                    <Table size={'md'}>
                        <Thead py={'15px'}>
                            <Tr bg="#3778D9" color={'white'} py={'15px'} >
                                <Td py={'15px'}  textTransform={'capitalize'}>Role ID</Td>
                                <Td  textTransform={'capitalize'}>Role Name</Td>
                                <Td  textTransform={'capitalize'}>Role Description</Td>
                                <Td  textTransform={'capitalize'}>Edit Role</Td>
                                   </Tr>
                        </Thead>
                        <Tbody>

                            {
                                rolesdata && rolesdata.$values.length > 0 ? rolesdata.$values.map((role, index) => (
                                    <Tr key={index} bg="white" >
                                        <Td>
                                            <Text fontSize={'14px'}>{role.$id}</Text>
                                        </Td>
                                        <Td>
                                            <Text fontSize={'14px'}>{role.name}</Text>
                                        </Td>
                                        <Td>
                                            <Text fontSize={'14px'}>{role.description}</Text>
                                        </Td>
                                        <Td>
                                            <HStack>
                                                <BiEditAlt />
                                                <Text>Edit</Text>
                                            </HStack>
                                        </Td>




                                    </Tr>
                                )) : <Box>No data</Box>
                            }


                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
           }
        </>
    )
}

export default Roles;