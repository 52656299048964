import deleteService from "../crud-requests/deleteService";
import getService from "../crud-requests/getService";
import postService from "../crud-requests/postService";
import putService from "../crud-requests/putService";



const CONFIG_API_URL = "https://callnpayapi20230120085359.azurewebsites.net/api/";

class SystemService {
  async getparameters() {
    const response = await getService.get(
      `${CONFIG_API_URL}admin/systemparameter/getparameters`,

    );
    return response.data;
  }
  async getparametersNumber() {
    const response = await getService.get(
      `${CONFIG_API_URL}admin/systemparameter/getparameterasnumber`,

    );
    return response.data;
  }
  async updateParameter(data: any) {
    const response = await putService.put(
      `${CONFIG_API_URL}admin/systemparameter/updateparameter`,
      data
    );
    return response.data;
  }
  async deleteParameter(id: any) {
    const response = await deleteService.delete(
      `${CONFIG_API_URL}admin/systemparameter/deleteparameter?paramName=${id}`,
    );
    return response.data;
  }
  async createParameter(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}admin/systemparameter/createparameter`,
      data
    );
    return response.data;
  }
}

export default new SystemService();