import {
    Container,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Select,
    Flex,
    Button,
    Box,
    Text,
    Stack,
    InputGroup,
    useToast,
    RadioGroup,
    Radio,
    Avatar,
    HStack,
    InputLeftElement,
    Image,
    Checkbox

} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import nig from '../img/nig.svg'
import { ILoginRes } from "../../models/auth/login.model";
import authService, { getLocalUserProfile } from "../../services/auth/auth.service";
import AdminRoutes from "../routes";
import { MdLock } from "react-icons/md";
import userProfileService from "../../services/user/userProfile.service";
const ChangePassword = () => {
    const route = useNavigate();
    const toast = useToast();
    const validate = (values: any) => {
        const errors: any = {};
        if (!values.currentPassword) {
            errors.currentPassword = "currentPassword is required";
        }
        if (!values.password) {
            errors.password = "Password is required";
        }
        return errors;
    };

    const formik = useFormik({
        initialValues: { currentPassword: "", password: "", confirmPassword :"" },
        validate,
        onSubmit: async (values) => {
            try {
                const data = {
                    username :getLocalUserProfile()?.userName,
                    currentPassword: values.currentPassword,
                    newPassword: values.password,
                    confirmNewPassword : values.confirmPassword
                }

                const change_password: ILoginRes = await userProfileService.changePassword(data)

                if (change_password.succeeded) {
                    toast({
                        title: "Password",
                        description: `password changed successfully`,
                        status: "success",
                    });
                    route(AdminRoutes.portal.dashboard)
                } else {
                    toast({
                        title: "Password",
                        description: `${change_password.messages.$values[0]}`,
                        status: "error",
                    });
                }
            } catch (error: any) {
                toast({
                    title: "Password",
                    description: `${error}`,
                    status: "error",
                });
            }
        },
    });

    return (
        <>
            <Box minHeight="100vh" pb="30px" >
                <Container
                    width={{ lg: "80%", md: "80%", sm: "95%", base: "95%" }}
                    maxWidth={"84%"}
                    px={0}
                    right={0}
                    bg="F9F8F8"
                    mx="auto"
                >
                    <Text mb={'20px'} fontWeight="700" fontSize="22px" color="#1E1E1E">
                        Change Password
                    </Text>
                    <Box
                        bg="white"
                        px="40px"
                        py="25px"
                        boxShadow="sm"
                        rounded="lg"
                        height="auto"
                        mb="30px"
                    >
                    
                        <Flex py={'30px'} justifyContent={'center'} >
                            <Box >
                                <Box>
                                    <form onSubmit={formik.handleSubmit}>
                                        <FormControl
                                            mb="20px"
                                            isInvalid={
                                                formik.errors.currentPassword && formik.touched.currentPassword
                                                    ? true
                                                    : false
                                            }
                                        >
                                            <FormLabel color="brand.subtitle" fontSize={15}>
                                                Current Password{" "}

                                            </FormLabel>
                                            <InputGroup>
                                                <InputLeftElement
                                                    color="brand.subtitle"
                                                    pointerEvents="none"
                                                    children={<MdLock />}
                                                />
                                                <Input
                                                    width={["100%", "100%", "520px", "520px"]}
                                                    type="text"
                                                    name="currentPassword"
                                                    id="currentPassword"
                                                    placeholder="Enter  password"
                                                    onChange={formik.handleChange}
                                                    fontSize={14}
                                                />
                                            </InputGroup>
                                            <FormErrorMessage fontSize={12}>
                                                {formik.errors.currentPassword}
                                            </FormErrorMessage>
                                        </FormControl>

                                        <FormControl
                                            mb="20px"
                                            isInvalid={
                                                formik.errors.password && formik.touched.password
                                                    ? true
                                                    : false
                                            }
                                        >
                                            <FormLabel color="brand.subtitle" fontSize={15}>
                                              New  Password{" "}

                                            </FormLabel>
                                            <InputGroup>
                                                <InputLeftElement
                                                    color="brand.subtitle"
                                                    pointerEvents="none"
                                                    children={<MdLock />}
                                                />
                                                <Input
                                                    width={["100%", "100%", "520px", "520px"]}
                                                    type="text"
                                                    name="password"
                                                    id="password"
                                                    placeholder="Enter  password"
                                                    onChange={formik.handleChange}
                                                    fontSize={14}
                                                />
                                            </InputGroup>
                                            <FormErrorMessage fontSize={12}>
                                                {formik.errors.password}
                                            </FormErrorMessage>
                                        </FormControl>

                                        <FormControl
                                            mb="20px"
                                            isInvalid={
                                                formik.errors.confirmPassword && formik.touched.confirmPassword
                                                    ? true
                                                    : false
                                            }
                                        >
                                            <FormLabel color="brand.subtitle" fontSize={15}>
                                              Confirm  Password{" "}

                                            </FormLabel>
                                            <InputGroup>
                                                <InputLeftElement
                                                    color="brand.subtitle"
                                                    pointerEvents="none"
                                                    children={<MdLock />}
                                                />
                                                <Input
                                                    width={["100%", "100%", "520px", "520px"]}
                                                    type="text"
                                                    name="confirmPassword"
                                                    id="confirmPassword"
                                                    placeholder="confirm  password"
                                                    onChange={formik.handleChange}
                                                    fontSize={14}
                                                />
                                            </InputGroup>
                                            <FormErrorMessage fontSize={12}>
                                                {formik.errors.password}
                                            </FormErrorMessage>
                                        </FormControl>
                                        <Stack spacing="12px">
                                            <Button
                                                cursor="pointer"
                                                w="100%"
                                                color={"white"}
                                                bg={"#011F78"}
                                                type="submit"
                                                isLoading={formik.isSubmitting}
                                                fontSize={14}
                                            >
                                                Save changes
                                            </Button>
                                        </Stack>
                                    </form>
                                </Box>
                            </Box>
                        </Flex>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default ChangePassword;
