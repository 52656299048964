import getService from "../crud-requests/getService";
import postService from "../crud-requests/postService";



const CONFIG_API_URL = "https://callnpayapi20230120085359.azurewebsites.net/api/";

class AgentService {
  async createAccountForCustomer(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}agent/createaccountforcustomer`,
      data
    );
    return response.data;
  }
  ///api/agent/getagentcustomersummarybyagentusername
  async getCustomers() {
    const response = await getService.get(
      `${CONFIG_API_URL}agent/getcustomers`,

    );
    return response.data;
  }
  async getDependantAccounts() {
    const response = await getService.get(
      `${CONFIG_API_URL}agent/getdependantaccounts`,

    );
    return response.data;
  }
  ///api/agent/getdependantaccounts
  async getAgentsCustomersByUsername(username: string) {
    const response = await getService.get(
      `${CONFIG_API_URL}agent/getagentcustomersummarybyagentusername/${username}`,

    );
    return response.data;
  }
  async getCustomersWithAccount() {
    const response = await getService.get(
      `${CONFIG_API_URL}agent/getcustomerswithaccount`,

    );
    return response.data;
  }
  async getMatchedCustomerWithAccount(search: any) {
    const response = await getService.get(
      `${CONFIG_API_URL}agent/getmatchedcustomerswithaccount${search}`,

    );
    return response.data;
  }
  async getAgentsSummary(url: string) {
    const response = await getService.get(
      `${CONFIG_API_URL}agent/getagentssummary${url}`,

    );
    return response.data;
  }
  async getAgentsCustomerSummary() {
    const response = await getService.get(
      `${CONFIG_API_URL}agent/getagentcustomersummary`,

    );
    return response.data;
  }
  async getDependantAccount(url: any) {
    const response = await getService.get(
      `${CONFIG_API_URL}agent/getdependantaccounts${url}`,

    );
    return response.data;
  }
  async getAvailableAccountTypeCustomer() {
    const response = await getService.get(
      `${CONFIG_API_URL}agent/getavailableaccounttypesforcustomer`,

    );
    return response.data;
  }
  async getAvailableAccountTypeAgent() {
    const response = await getService.get(
      `${CONFIG_API_URL}agent/getavailableaccounttypesforagent`,

    );
    return response.data;
  }
}


export default new AgentService();