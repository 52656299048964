const AdminRoutes  ={
    Register : '/callnpay/register',
    CompleteProfile : '/callnpay/complete-profile',
    Login :'/',
    forgotPassword : '/callnpay/forgot-password',
    emailSent :'/callnpay/email-sent',
    setPassword :'/callnpay/set-password',
    newPhone :'/callnpay/set-phone',
    verifyPhone :'/callnpay/verify-phone',
    portal : {
        dashboard : '/callnpay/admin/dashboard',
        messages : '/callnpay/admin/messages',
        accounts : '/callnpay/admin/accounts',
        addAccount : '/callnpay/admin/accounts/add-account',
        wallet : '/callnpay/admin/account-wallet',
        createWallet : '/callnpay/admin/account-wallet/create',
        users : '/callnpay/admin/users',
        changePassword : '/callnpay/admin/user/change-password',
        changePin : '/callnpay/admin/user/change-pin',
        setPin : '/callnpay/admin/user/set-pin',
        transactions : '/callnpay/admin/transactions',
        adminTransactions : '/callnpay/admin/all-transactions',
        agents : '/callnpay/admin/agents',
        customers : '/callnpay/admin/customers',
        agentCustomer : '/callnpay/admin/agents/:username/customers',
        agentCustomerLink: (username: string) => `/callnpay/admin/agents/${username}/customers`,
        accountTransaction : '/callnpay/admin/transactions/:account_id/account',
        accountTransactionLink: (account_id: string) => `/callnpay/admin/transactions/${account_id}/account`,
        roles : '/callnpay/admin/roles',
        profile : '/callnpay/admin/profile',
        aduitLogs : '/callnpay/admin/aduit-logs',
        debugInfo : '/callnpay/admin/debug-info',
        savings : '/callnpay/admin/savings',
        customerSavings : '/callnpay/admin/savings/:username/customers',
        customerSavingsLink: (username: string) => `/callnpay/admin/savings/${username}/customers`,
        savingTarget : '/callnpay/admin/savings-target',
        systemParameter : '/callnpay/admin/system-parameter',
        accountBoard : '/callnpay/admin/account-board',
        customerBoard : '/callnpay/admin/customer-board',
        adminBoard : '/callnpay/admin/admin-board'
    }
}

export default AdminRoutes;