import { Flex, Container, Box, Button, HStack, Menu, MenuButton, MenuItem, MenuList, Spacer, VStack, Text } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import { BsArrowUp } from "react-icons/bs";
import Chart from "react-google-charts";
import dashboardService from "../../services/dashboard/dashboard.service";
import { IAccountsValue, IAccountsRes } from "../../models/accounts/accounts.model";
import accountsService from "../../services/accounts/accounts.service";
import AdminRoutes from "../../pages/routes";
import { IAccountDashboardRes } from "../../models/dashboard/accountDashboard.model";
import Loading from "../Loading";


export const options_line = {

    legend: 'none',

};
export const options = {


    colors: ['blue', 'red', 'green']
};
const UserAccountsBoard = () => {
    let navigate = useNavigate()
    const [acctTypeData, setacctTypeData] = useState<IAccountsValue[]>();
    const [isLoading, setIsLoading] = useState(true);
    const [totalSum, setTotalSum] = useState<any>()
    const [creditSum, setCreditSum] = useState<any>()
    const [debitSum, setDebitSum] = useState<any>()
    const [countData, setCountData] = useState<any[]>()
    const [transactionData, setTransactionData] = useState<any[]>()


    useEffect(() => {

        dashboardService.aacountDashboard()
            .then((data) => {
                const acctRes: IAccountDashboardRes = data
                const totaltransaction = acctRes.data.totalTransactionSummary.$values
                const debittransaction = acctRes.data.debitTransactionSummary.$values
                const credittransaction = acctRes.data.creditTransactionSummary.$values
                // Create an object to store grouped values
                const grouped: any = {};

                // Function to add values to the grouped object
                function addToGroup(id: number, value: number, legend: string) {
                    if (!grouped[id]) {
                        grouped[id] = [legend];
                    }
                    grouped[id].push(value);

                }
                // Iterate through each array and add values to the grouped object
                totaltransaction.forEach(({ month, counter, year }) => addToGroup(month, counter, `${year}/${month}`));
                debittransaction.forEach(({ month, counter, year }) => addToGroup(month, counter, `${year}/${month}`));
                credittransaction.forEach(({ month, counter, year }) => addToGroup(month, counter, `${year}/${month}`));

                // Convert the grouped object values into an array
                const result = Object.values(grouped);
                const newData: any[] = [["Month", "Total Count", "Debit Count", "Credit Count"]]
                const countArray: any[][] = [...newData, ...result]
                setCountData([...countArray])

                // Create an object to store grouped values
                const groupedTransaction: any = {};

                // Function to add values to the grouped object
                function addToGroupTransaction(id: number, value: number, legend: string) {
                    if (!groupedTransaction[id]) {
                        groupedTransaction[id] = [legend];
                    }
                    groupedTransaction[id].push(value);
                }
                // Iterate through each array and add values to the grouped object
                totaltransaction.forEach(({ month, sumOfAmount, year }) => addToGroupTransaction(month, sumOfAmount, `${year}/${month}`));
                debittransaction.forEach(({ month, sumOfAmount, year }) => addToGroupTransaction(month, sumOfAmount, `${year}/${month}`));
                credittransaction.forEach(({ month, sumOfAmount, year }) => addToGroupTransaction(month, sumOfAmount, `${year}/${month}`));

                // Convert the grouped object values into an array
                const resulttransaction = Object.values(groupedTransaction);
                const newDatatransaction: any[] = [["Month", "Total Amount", "Debit Amount", "Credit Amount"]]
                const countArraytransaction: any[][] = [...newDatatransaction, ...resulttransaction]
                console.log(countArraytransaction, 'arry trnsaction')
                setTransactionData([...countArraytransaction])

                const totalTransactionsum = acctRes.data.totalTransactionSummary.$values.reduce((accumulator, currentValue) => {
                    accumulator.counter += currentValue.counter;
                    accumulator.sumOfAmount += currentValue.sumOfAmount;
                    return accumulator;
                }, { counter: 0, sumOfAmount: 0 });
                console.log(totalTransactionsum, 'sum total')
                setTotalSum(totalTransactionsum)
                const creditTransactionsum = acctRes.data.creditTransactionSummary.$values.reduce((accumulator, currentValue) => {
                    accumulator.counter += currentValue.counter;
                    accumulator.sumOfAmount += currentValue.sumOfAmount;
                    return accumulator;
                }, { counter: 0, sumOfAmount: 0 });
                setCreditSum(creditTransactionsum)
                const debitTransactionsum = acctRes.data.debitTransactionSummary.$values.reduce((accumulator, currentValue) => {
                    accumulator.counter += currentValue.counter;
                    accumulator.sumOfAmount += currentValue.sumOfAmount;
                    return accumulator;
                }, { counter: 0, sumOfAmount: 0 });
                setDebitSum(debitTransactionsum)

            })
            .catch((err) => err)
            .finally(() => {
                setIsLoading(false)
            })
        accountsService.getOwnAccounts()
            .then((data) => {
                const acc_type: IAccountsRes = data
                setacctTypeData(acc_type.data.$values)

            })
            .catch((err) => err)
    }, []);


    return (
        <>
            {
                isLoading ? <Loading /> :
                    <Box >
                        <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            pb="20px"
                        >
                            <Box color="#2B2B2B" fontWeight="800" fontSize="24px">
                                Account wallet
                            </Box>

                        </Flex>
                        <Flex
                            justifyContent="space-between"

                            mb="20px"
                        >
                            {
                                acctTypeData && acctTypeData.length > 0 ? acctTypeData.map((acct, index) => (
                                    <Container
                                        bg="white"
                                        key={index}
                                        px={'20px'}
                                        py={'15px'}
                                        boxShadow="base"
                                        mr={acctTypeData.length - 1 == index ? '0px' : '30px'}
                                    >
                                        <Box pb={3} pt={1} onClick={() => { navigate(AdminRoutes.portal.dashboard) }}>
                                            <Text color="#011F78" fontSize='14px'>
                                                {acct.type}
                                            </Text>
                                            <Text color="#353535" fontWeight="semibold" fontSize={'24px'}>
                                                <NumericFormat
                                                    value={acct.balance}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    prefix={"₦"}
                                                />
                                            </Text>
                                            <HStack>

                                                <Text color="#011F78" fontSize={'12px'}>
                                                    {acct.id}
                                                </Text>
                                            </HStack>
                                        </Box>
                                    </Container>
                                )) : <Box mr={'20px'} bg={'white'} py={'50px'} px={'50px'} width={'100%'}>No accounts</Box>
                            }


                        </Flex>
                        <Flex
                            justifyContent="space-between"

                            mb="20px"
                        >
                            <Container
                                bg="white"

                                px={'20px'}
                                py={'15px'}
                                boxShadow="base"
                                m={0}
                            >
                                <Box pb={3} pt={1}>
                                    <Text color="#929292" fontSize={13}>
                                        Total Transactions Count/Value
                                    </Text>
                                    <HStack>
                                        <Text color="#353535" fontWeight="semibold" fontSize={'24px'}>

                                            {totalSum.counter}/
                                        </Text>
                                        <Text color="#353535" fontWeight="semibold" fontSize={'24px'}>

                                            <NumericFormat
                                                value={totalSum.sumOfAmount}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"₦"}
                                            />
                                        </Text>
                                    </HStack>

                                </Box>
                            </Container>

                            <Spacer mx={3} />
                            <Container
                                bg="white"

                                px={'20px'}
                                py={'15px'}
                                boxShadow="base"
                                m={0}
                            >
                                <Box pb={3} pt={1} onClick={() => { navigate(AdminRoutes.portal.dashboard) }}>
                                    <Text color="#929292" fontSize='14px'>
                                        Credit Count/Value
                                    </Text>
                                    <HStack>
                                        <Text color="#353535" fontWeight="semibold" fontSize={'24px'}>

                                            {creditSum.counter}/
                                        </Text>
                                        <Text color="#353535" fontWeight="semibold" fontSize={'24px'}>

                                            <NumericFormat
                                                value={creditSum.sumOfAmount}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"₦"}
                                            />
                                        </Text>
                                    </HStack>
                                </Box>
                            </Container>

                            <Spacer mx={3} />
                            <Container
                                px={'20px'}
                                py={'15px'}
                                boxShadow="base"
                                bg="white"
                                m={0}
                            >
                                <Box pb={3} pt={1} onClick={() => { navigate(AdminRoutes.portal.dashboard) }}>
                                    <HStack spacing={3}>
                                        <Text textAlign={'left'} color="#929292" fontSize='14px'>
                                            Debit Count/Value
                                        </Text>
                                    </HStack>
                                    <HStack>
                                        <Text color="#353535" fontWeight="semibold" fontSize={'24px'}>

                                            {debitSum.counter}/
                                        </Text>
                                        <Text color="#353535" fontWeight="semibold" fontSize={'24px'}>

                                            <NumericFormat
                                                value={debitSum.sumOfAmount}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"₦"}
                                            />
                                        </Text>
                                    </HStack>
                                </Box>
                            </Container>


                        </Flex>

                        <Box pt="25px"
                            px={'20px'}
                            pb={'20px'}
                            bg="white"
                            maxH={'400px'}>
                            <Text fontSize={'16px'}>Transaction Counts</Text>

                            <Chart
                                chartType="Bar"
                                width="100%"
                                height="350px"
                                data={countData}
                                options={options}
                            />
                        </Box>

                        <Box
                            mt={'20px'}
                            px={'20px'}
                            pb={'20px'}
                            maxH={'400px'}
                            pt="25px"
                            bg="white" >
                            <Text fontSize={'16px'}>Transaction Values</Text>

                            <Chart
                                chartType="LineChart"
                                width="100%"
                                height="350px"
                                data={transactionData}
                                options={options}

                            />
                        </Box>


                    </Box>
            }
        </>
    )
}

export default UserAccountsBoard;