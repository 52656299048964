import { Avatar, Flex, HStack, Text, Image, Box, IconButton, Menu, MenuButton, MenuItem, MenuList, Center, Portal } from "@chakra-ui/react"
import { BiNotification } from 'react-icons/bi';

import { FiMaximize, FiMinimize } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";
import { destroyUserSession, getLocalUserProfile } from "../../services/auth/auth.service";
import AdminRoutes from "../../pages/routes";
import { ILoginData, IUserToken } from "../../models/auth/login.model";
import userProfileService from "../../services/user/userProfile.service";
import { IProfilePhotoRes } from "../../models/users/profilephoto.model";
import usersService from "../../services/user/users.service";
import { ChangePasswordModal } from "../users/modal/ChangePassword";
import { ChangePinModal } from "../users/modal/ChangePinModal";
import { SetPinModal } from "../users/modal/SetPinModal";
import ProfileModal from "../users/modal/ProfileModal";


const AdminDashboardHeader = ({ toggle, toggleFunct }: { toggle: boolean; toggleFunct: any }) => {
    const [userProfile, setUserProfile] = useState<IUserToken>();
    const [userPhoto, setUserPhoto] = useState<IProfilePhotoRes | undefined>();
    const [isOpen, setIsOpen] = useState(false);
    const [messageCount, setMessageCount] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const router = useNavigate();
    let location = useLocation()
    const [ischangePassword, setIsChangePassword] = useState(false);
    const [isSetPin, setIsSetPin] = useState(false);
    const [isChangePin, setIsChangePin] = useState(false);
    const [isOpenProfile, setIsOpenProfile] = useState(false);


    const openSetPin = () => {
        setIsSetPin(true)
        setIsChangePin(false)
        setIsChangePassword(false)
        setIsOpenProfile(false)

    };
    const openChangePin = () => {
        setIsSetPin(false)
        setIsChangePin(true)
        setIsChangePassword(false)
        setIsOpenProfile(false)
    };
    const openChangePassword = async () => {
        setIsSetPin(false)
        setIsChangePin(false)
        setIsChangePassword(true)
        setIsOpenProfile(false)
    };
    useEffect(() => {
        userProfileService.userProfilePhoto()
            .then((data) => {
                setUserPhoto(data)
                setIsLoading(false)
            })
        usersService.getMessageCount().then(
            (data) => {
                setIsLoading(false)
                setMessageCount(data.data)
            }
        )
            .catch((err) => err)
        const userLogged = sessionStorage.getItem('UserFromToken')
        if (userLogged) {
            const parsedUser = JSON.parse(userLogged)
            setUserProfile(parsedUser)
        }
    }, []);


    const handleLogout = () => {
        destroyUserSession();
        router(AdminRoutes.Login);
    };

    return (

        <Box >
            <Box pr={12}>

                <Flex height="45px" bg="#FFFFFF" color='#616161' direction="row" mt='10px' alignItems="center" justifyContent="space-between" >
                    {
                        toggle ? <IconButton
                            variant="ghost"
                            mr={'20px'}
                            aria-label="bell"
                            borderRadius={20}
                            onClick={() => toggleFunct()}
                            icon={<FiMaximize fontSize={'20px'} color="#898989" />}
                        />
                            : <IconButton
                                variant="ghost"
                                mr={'20px'}
                                aria-label="bell"
                                borderRadius={20}
                                onClick={() => toggleFunct()}
                                icon={<FiMinimize fontSize={'20px'} color="green" />}
                            />
                    }
                    <HStack>
                        <IconButton
                            variant="ghost"
                            mr={'20px'}
                            aria-label="bell"
                            borderRadius={20}
                            onClick={() => setIsOpen(true)}
                            icon={<BiNotification fontSize={'24px'} color="#898989" />}
                        />
                        <Avatar
                            size="sm"
                            name={userProfile && `${userProfile.firstname} ${userProfile.lastname}`}
                            src={userPhoto && userPhoto.data}
                        />

                        <Menu>
                            <MenuButton>
                                <HStack fontSize={13}>
                                    <Flex direction="column" alignItems="flex-end">
                                        <Box>
                                            <Text fontSize={'14px'} color={'#202020'}>
                                                {" "}
                                                {userProfile && `${userProfile.firstname} ${userProfile.lastname}`}
                                            </Text>
                                            <Text textAlign={'left'} mt={'-7px'} fontSize={'12px'} color={'#898989'}>
                                                {" "}
                                                {userProfile && userProfile.email}
                                            </Text>
                                        </Box>
                                    </Flex>

                                </HStack>
                            </MenuButton>
                            <MenuList fontSize={13} borderRadius={0} color="brand.bodyText">
                                <MenuItem>
                                    <HStack fontSize={13}>
                                        <Avatar
                                            size="sm"
                                            name={userProfile && `${userProfile.firstname} ${userProfile.lastname}`} src={userPhoto && userPhoto.data}

                                        />
                                        <Flex direction="column" alignItems="flex-end">
                                            <Box>
                                                <Text fontSize={'14px'} color={'#202020'}>
                                                    {" "}
                                                    {userProfile && `${userProfile.firstname} ${userProfile.lastname}`}
                                                </Text>
                                                <Text textAlign={'left'} mt={'-7px'} fontSize={'12px'} color={'#898989'}>
                                                    {" "}
                                                    {userProfile && userProfile.email}
                                                </Text>
                                            </Box>
                                        </Flex>

                                    </HStack>
                                </MenuItem>

                                <MenuItem fontSize={'14px'} onClick={() => setIsOpenProfile(true)} color={'#202020'}>Profile</MenuItem>
                                <MenuItem fontSize={'14px'} color={'#202020'} onClick={() => router(AdminRoutes.portal.messages)}>
                                    <Flex>
                                        <Text mr={'5px'}>Messages</Text>
                                        <Center bg={'red'} width={'20px'} height={'20px'} borderRadius={'10px'}>
                                            <Text color={'white'} borderRadius={'10px'}>{messageCount}</Text>
                                        </Center>

                                    </Flex></MenuItem>
                                <MenuItem fontSize={'14px'} color={'#202020'} onClick={() => openSetPin()}>Set Pin</MenuItem>
                                <MenuItem fontSize={'14px'} color={'#202020'} onClick={() => openChangePin()}>Change Pin</MenuItem>
                                <MenuItem fontSize={'14px'} onClick={() => 
                                openChangePassword()} color={'#202020'}>Change password</MenuItem>
                                <MenuItem fontSize={'14px'} color={'red'} onClick={() => handleLogout()}>Log out</MenuItem>
                            </MenuList>
                        </Menu>
                    </HStack>
                </Flex>

            </Box>
            {isOpenProfile && <ProfileModal setIsOpen={setIsOpenProfile} />}
            {ischangePassword && (
                <Portal>
                    {" "}
                    <ChangePasswordModal
                        isOpenPassword={ischangePassword}
                        onClosed={setIsChangePassword}
                        isPassword={ischangePassword}

                    />{" "}
                </Portal>
            )}
            {isSetPin && (
                <Portal>
                    {" "}
                    <SetPinModal
                        isOpenSetPin={isSetPin}
                        onClosed={setIsSetPin}
                        isSetPin={isSetPin}

                    />{" "}
                </Portal>
            )}
            {isChangePin && (
                <Portal>
                    {" "}
                    <ChangePinModal
                        isOpenChangePin={isChangePin}
                        onClosed={setIsChangePin}
                        isChangePin={isChangePin}

                    />{" "}
                </Portal>
            )}
        </Box>

    )
}

export default AdminDashboardHeader;