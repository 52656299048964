import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    Box,
    useToast,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Flex,
    Button,
    HStack,
    Textarea,
    Select,
    Portal,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { IHandleTransaction } from "../../models/transactions/handleTransaction";
import { IAccountsValue, IAccountsRes } from "../../models/accounts/accounts.model";
import accountsService from "../../services/accounts/accounts.service";
import { TransferPinModal } from "./TransferPinModal";
import agentService from "../../services/agent/agent.service";
import { useNavigate } from "react-router-dom";
import { IRegRes } from "../../models/auth/registration.model";
import AdminRoutes from "../../pages/routes";
import transactionService from "../../services/transaction/transaction.service";

export const AccountTransactionModal: React.FC<any> = ({
    isOpenAccountTransaction,
    onClosed,
    isAccountTransaction = false,
    reload,
    
}: {
    isAccountTransaction: boolean;
    isOpenAccountTransaction: any;
    onClosed: any;
    reload: any;
    
}) => {
    const toast = useToast();
    const cancelRef = useRef().current;
    const navigate = useNavigate()
    const [accountList, setacctTypeData] = useState<IAccountsValue[]>();
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
       
        accountsService.getOwnAccounts()
            .then((data) => {
                const acc_type: IAccountsRes = data
                setacctTypeData(acc_type.data.$values)
                setIsLoading(false)
            })
            .catch((err) => err)

    }, []);
    const formik = useFormik({
        validate: (values: any) => {
            const errors: any = {};
            if (!values.source) {
                errors.source = "select source";
            }


            return errors;
        },
        initialValues: {
            source: "",

        },
        onSubmit: async (values) => {
          viewAccountTransaction(values.source)


        }
    });
    const viewAccountTransaction = (account_id: string) => {
        navigate(AdminRoutes.portal.accountTransactionLink(account_id));

    }

    return (
        <>
            <Modal
                isOpen={isOpenAccountTransaction && isAccountTransaction}
                onClose={onClosed}
                scrollBehavior="outside"
                closeOnOverlayClick={true}
                size="lg"
            >
                <ModalOverlay />
                <ModalContent borderRadius={5} px={6}>
                    <ModalHeader>
                        {
                            <Text
                                fontSize={'18px'}
                                pb={3}
                                textTransform="capitalize"

                            >
                                View Account Transaction
                            </Text>
                        }
                    </ModalHeader>
                    <ModalCloseButton size="sm" />
                    <ModalBody pb={'30px'}>
                        <Box mt={2}>
                            <form onSubmit={formik.handleSubmit}>
                                <FormControl
                                    mb={'20px'}
                                    isInvalid={
                                        formik.errors.source && formik.touched.source
                                            ? true
                                            : false
                                    }
                                >
                                    <FormLabel
                                        fontWeight="normal"
                                        color="brand.3"
                                        fontSize={13}
                                    >
                                        Select Account
                                    </FormLabel>
                                    <Select
                                        variant="outline"
                                        bg="inputText.500"
                                        size="sm"
                                        placeholder="select account"
                                        value={formik.values.source}
                                        name="source"
                                        onChange={formik.handleChange}
                                        fontSize={12}
                                        color="brand.subtitle"
                                    >
                                        {accountList && accountList.map((each) => (
                                            <option key={each.id} value={each.id}>
                                                {" "}
                                                {`${each.type}-${each.id}`}
                                            </option>
                                        ))}
                                    </Select>
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.source}
                                    </FormErrorMessage>
                                </FormControl>


                                <Flex mt={12} justifyContent={'space-between'} >


                                    <Button

                                        px={6}
                                        colorScheme="blue"
                                        fontSize={'14px'}
                                        variant={'outline'}
                                        ref={cancelRef}
                                        width={'49%'}
                                        onClick={() => onClosed()}
                                    >
                                        Cancel
                                    </Button>
                                    <Button

                                        type="submit"
                                        isLoading={formik.isSubmitting}
                                        color={"white"}
                                        width={'49%'}
                                        bg={"#011F78"}
                                        px={6}
                                        fontSize={'14px'}
                                    >
                                        Confirm
                                    </Button>


                                </Flex>
                            </form>
                        </Box>

                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
