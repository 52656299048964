import {
    Box,
    Button,
    Center,
    Flex,
    FormControl,
    FormErrorMessage,
    Text,
    FormLabel,
    Input,
    InputGroup,
    InputLeftElement,
    useToast,
    Link,
    HStack,
    Image,
    Stack,
    InputRightElement,
    Portal,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { MdLock } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import AuthLayout from "./AuthLayout";
import AdminRoutes from "../routes";
import authService from "../../services/auth/auth.service";
import { ILoginRes } from "../../models/auth/login.model";
import { useEffect, useState } from "react";
import { BiHide, BiShow } from "react-icons/bi";
import { Buffer } from 'buffer';
import { IUserProfileRes } from "../../models/users/userProfile.model";
import userProfileService from "../../services/user/userProfile.service";
import { GenerateOneTimeCode } from "../../components/auth/GenerateOneTimeCodeModal";

const Login = () => {
    const route = useNavigate();
    const toast = useToast();
    const [show, setShow] = useState(false)
    const [isGenerate, setisGenerate] = useState(false);
    const [parameter, setParameter] = useState<any>();
    const handleClick = () => setShow(!show)
    useEffect(() => {
        // Get the current URL
        const currentUrl = window.location.href;

        // Create a URLSearchParams object to parse the query parameters
        const urlParams = new URLSearchParams(currentUrl);

        // Get the values of userId and code from the query parameters
        const userId = urlParams.get('userId');
        const code = urlParams.get('code');

        if (userId && code) {
            authService.confrimEmail({ userId: userId, code: code })
                .then((data) => {
                    if (data.succeeded) {
                        toast({
                            title: "Confirm Email",
                            description: `user verified successfully`,
                            status: "success",
                        });

                    } else {
                        toast({
                            title: "Confirm Email",
                            description: `${data.messages.$values[0]}`,
                            status: "error",
                        });
                    }
                })
                .catch((err) => err)
        }

    }, []);
    const openGenerateCodeModal = (userId: string) => {
        setParameter(userId)
        setisGenerate(true)

    };
    const validate = (values: any) => {
        const errors: any = {};
        if (!values.username) {
            errors.username = "username is required";
        }
        if (!values.password) {
            errors.password = "Password is required";
        }
        return errors;
    };

    function getClaimsFromJwt(token: string): Claim[] {
        const claims: Claim[] = [];
        const payload: string = token.split('.')[1];
        const jsonPayload: string = Buffer.from(payload, 'base64').toString('utf-8');
        const keyValuePairs: Record<string, any> = JSON.parse(jsonPayload);

        if (keyValuePairs) {
            const roles: string | undefined = keyValuePairs[ClaimTypes.Role];
            if (roles !== undefined) {
                if (roles.trim().startsWith("[")) {
                    const parsedRoles: string[] = JSON.parse(roles);
                    claims.push(...parsedRoles.map(role => ({ type: ClaimTypes.Role, value: role })));
                } else {
                    claims.push({ type: ClaimTypes.Role, value: roles });
                }

                delete keyValuePairs[ClaimTypes.Role];
            }

            for (const key of Object.keys(keyValuePairs)) {
                claims.push({ type: key, value: keyValuePairs[key].toString() });
            }
        }

        const aRoles = claims[4].value.split(',')
        //Login.tsx:77 UserAdministrator,ParameterAdministrator,AccountAdministrator,Customer,RoleAdministrator,TransactionAdministrator,Basic,AuditReader,ParameterViewer,Agent,Administrator claims
        //Login.tsx:78 1694047537 claims
        const UserPayload = {
            username: claims[0].value,
            email: claims[1].value,
            firstname: claims[2].value,
            lastname: claims[3].value,
            assignedRoles: aRoles,
        }
        sessionStorage.setItem('UserFromToken', JSON.stringify(UserPayload))
        return claims;
    }

    interface Claim {
        type: string;
        value: string;
    }

    enum ClaimTypes {
        Role = "role" // need this enum value to match your claim type for roles.
    }

    const formik = useFormik({
        initialValues: { username: "", password: "" },
        validate,
        onSubmit: async (values) => {
            try {
                const data = {
                    username: values.username,
                    password: values.password,
                }
                const login_user: ILoginRes = await authService.login(data)

                if (login_user.succeeded) {
                    toast({
                        title: "Login",
                        description: `user successfully logged in`,
                        status: "success",
                    });
                    sessionStorage.setItem("callnpay_user", JSON.stringify(login_user.data));
                    sessionStorage.setItem("callnpay_token", login_user.data.token);
                    sessionStorage.setItem("callnpay_refresh", login_user.data.refreshToken);
                    const user_profile: IUserProfileRes = await userProfileService.userProfile()
                    if (user_profile.succeeded) {
                        user_profile.data.isProfileComplete == true ? route(AdminRoutes.portal.dashboard) : route(AdminRoutes.CompleteProfile)
                    }
                    getClaimsFromJwt(login_user.data.token)

                } else if (login_user.data.mustChangePhoneNumber) {
                    route(AdminRoutes.newPhone)
                    toast({
                        title: "Login",
                        description: `kindly setup your phone number`,
                        status: "warning",
                    });
                }
                else if (login_user.data.mustReactivateAccount) {
                    //
                    openGenerateCodeModal(login_user.data.userId)
                    toast({
                        title: "Account Deactivated",
                        description: `You must reactivate your account before logging in`,
                        status: "warning",
                    });
                }
                else {
                    toast({
                        title: "Login",
                        description: `${login_user.messages.$values[0]}`,
                        status: "error",
                    });
                }
            } catch (error: any) {
                toast({
                    title: "Login",
                    description: `${error}`,
                    status: "error",
                });
            }
        },
    });


    return (
        <>
            <AuthLayout>
                <Flex mx="auto" className="resizable_div" height="95vh">
                    <Box >
                        <Box
                            ml={["30px", "30px", "60px", "60px"]}
                            mr={["30px", "30px", "0px", "0px"]}
                            mt={'100px'}
                            mb="15px"
                        >


                            <Box mb={'40px'}>
                                <Text
                                    mb={'10px'}
                                    fontSize={["20px", "20px", "24px", "24px"]}
                                    fontWeight="700"
                                    color="#011F78"
                                >
                                    Login
                                </Text>
                                <Text

                                    fontSize={["10px", "10px", "14px", "14px"]}
                                    fontWeight="400"
                                    color="#90979E"
                                >
                                    Enter username and password to access your account
                                </Text>
                            </Box>


                            <form onSubmit={formik.handleSubmit}>
                                <FormControl
                                    mb="20px"
                                    isInvalid={
                                        formik.errors.username && formik.touched.username ? true : false
                                    }
                                >
                                    <FormLabel color="#757575" fontSize={15}>
                                        Username

                                    </FormLabel>


                                    <Input
                                        w={["300px", "300px", "520px", "520px"]}
                                        type="text"
                                        id="username"
                                        name="username"
                                        placeholder="Enter username"
                                        onChange={formik.handleChange}
                                        fontSize={14}
                                    />


                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.username}
                                    </FormErrorMessage>
                                </FormControl>

                                <FormControl
                                    mb="20px"
                                    isInvalid={
                                        formik.errors.password && formik.touched.password
                                            ? true
                                            : false
                                    }
                                >
                                    <FormLabel color="brand.subtitle" fontSize={15}>
                                        Password{" "}

                                    </FormLabel>
                                    <InputGroup>
                                        <InputLeftElement
                                            color="brand.subtitle"
                                            pointerEvents="none"
                                            children={<MdLock />}
                                        />
                                        <Input
                                            w={["100%", "100%", "520px", "520px"]}
                                            type={show ? 'text' : 'password'}
                                            name="password"
                                            id="password"
                                            placeholder="Enter  password"
                                            onChange={formik.handleChange}
                                            fontSize={14}
                                        />
                                        <InputRightElement width='4.5rem'>
                                            <Center cursor={'pointer'} h='1.75rem' onClick={handleClick}>
                                                {show ? <BiHide /> : <BiShow />}
                                            </Center>
                                        </InputRightElement>
                                    </InputGroup>
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.password}
                                    </FormErrorMessage>
                                </FormControl>
                                <Flex justifyContent={'space-between'}>
                                    <Center >
                                        <Box mb={2} fontWeight={'600'} alignSelf="center" color="#011F78" fontSize={14}>
                                            <Link onClick={() => { route(AdminRoutes.Register) }}>Register</Link>
                                        </Box>
                                    </Center>
                                    <Center >
                                        <Box mb={2} fontWeight={'600'} alignSelf="center" color="#011F78" fontSize={14}>
                                            <Link onClick={() => { route(AdminRoutes.forgotPassword) }}>Forgot Password?</Link>
                                        </Box>
                                    </Center>
                                </Flex>

                                <Stack spacing="12px">
                                    <Button
                                        cursor="pointer"
                                        w="100%"
                                        color={"white"}
                                        bg={"#011F78"}
                                        type="submit"
                                        isLoading={formik.isSubmitting}
                                        fontSize={14}
                                    >
                                        Login
                                    </Button>
                                </Stack>
                            </form>
                        </Box>
                    </Box>
                    {isGenerate && (
                        <Portal>
                            {" "}
                            <GenerateOneTimeCode
                                isOpen={isGenerate}
                                onClosed={setisGenerate}
                                isGenerate={isGenerate}
                                
                                userId={parameter}
                            />{" "}
                        </Portal>
                    )}
                </Flex>

            </AuthLayout>
        </>
    );
};

export default Login;
