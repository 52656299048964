import { Box, Flex, Button, InputGroup, Input, InputRightElement, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Avatar, Text, MenuItem, Menu, MenuButton, MenuList, Center, Tab, TabList, TabPanel, TabPanels, Tabs, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Portal, useToast, HStack, Select } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import { RiSearch2Line } from 'react-icons/ri'
import { NumericFormat } from 'react-number-format'
import usersService from '../../services/user/users.service'
import { IActiveUsersData, IActiveUsersRes, IActiveUsersValue } from '../../models/users/activeUsers.model'
import EditUserModal from './modal/EditUserModal'
import Loading from '../Loading'
import { Ipage } from '../../models/audit/auditlog.model'
import { Pagination, PaginationContainer, PaginationNext, PaginationPage, PaginationPageGroup, PaginationPrevious, usePagination } from "@ajna/pagination";
import { BsArrowLeft, BsArrowRight, BsCheckLg, BsThreeDotsVertical } from 'react-icons/bs'
import { getFullDate } from '../../utils/getDate'
import { MdDelete } from 'react-icons/md'

const DeactivatedUsers = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [deactivatedUserData, setdeactivatedUserData] = useState<IActiveUsersValue[]>();
    const [isLoading, setIsLoading] = useState(true);
    const [isOpenAlert, setIsOpenAlert] = useState(false);
    const [parameterName, setParameterName] = useState<string>();
    const onCloseAlert = () => setIsOpenAlert(false);
    const [isOpenAlertDelete, setIsOpenAlertDelete] = useState(false);
    const onCloseAlertDelete = () => setIsOpenAlertDelete(false);
    const [deleteName, setDeleteName] = useState<string>();
    const [reload, setReload] = useState(false);
    const [search, setSearch] = useState("");
    const [pageSize, setPageSize] = useState(25);
    const [query, setQuery] = useState("");
    const [page, setPage] = useState<Ipage>({ page_total: 0, total: 0, page_count: 0 });
    const {
        currentPage,
        setCurrentPage,
        pagesCount,
        pages
    } = usePagination({
        pagesCount: page.page_count,
        initialState: { currentPage: 1, pageSize: 100 },
        total: page.total
    });
    const toast = useToast()
    const cancelRef = useRef(null);
    useEffect(() => {
        const url = `?pageNumber=${currentPage}&pageSize=${pageSize}&searchString=${query}`

        usersService.getDeactivatedUsers(url)
            .then((data) => {
                const deactivatedUser: IActiveUsersRes = data
                setPage({ page_total: deactivatedUser.pageSize, total: deactivatedUser.totalCount, page_count: deactivatedUser.totalPages })


                setdeactivatedUserData(deactivatedUser.data.$values)
                setIsLoading(false)
            })
            .catch((err) => err)
    }, [currentPage, query, reload, pageSize]);
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (search.length > 0) {
                setQuery(search);
            } else {
                setQuery("");
            }
        }, 700);

        return () => clearTimeout(delayDebounceFn)
    }, [search])
    const openActivateUser = (param: string) => {
        setParameterName(param)
        setIsOpenAlertDelete(false)
        setIsOpenAlert(true)
    };
    const openDeleteUser = (param: string) => {
        setDeleteName(param)
        setIsOpenAlert(false)
        setIsOpenAlertDelete(true)

    };
    const changePageSize = (e: any) => {
        setPageSize(e.target.value)
    }
    const activateUser = async () => {
        try {
            const delete_param: any = await usersService.toggleActivation({ username: parameterName, activation: true })
            console.log(delete_param.succeeded)
            if (delete_param.succeeded) {
                toast({
                    title: "User Activation",
                    description: "User activated successfully",
                    status: "success",
                });
                setReload(!reload)
                onCloseAlert();

            } else {
                toast({
                    title: "User Activation",
                    description: `${delete_param.messages.$values[0]}`,
                    status: "error",
                });
            }
        } catch (error: any) {
            toast({
                title: "User Activation",
                description: `${error}`,
                status: "error",
            });
        }
    }
    const deleteUser = async () => {
        try {
            const delete_param: any = await usersService.deleteUser(`?userName=${deleteName}`)
            console.log(delete_param.succeeded)
            if (delete_param.succeeded) {
                toast({
                    title: "Delete User",
                    description: "User deleted successfully",
                    status: "success",
                });
                setReload(!reload)
                onCloseAlertDelete();

            } else {
                toast({
                    title: "Delete User",
                    description: `${delete_param.messages.$values[0]}`,
                    status: "error",
                });
            }
        } catch (error: any) {
            toast({
                title: "User confirmation",
                description: `${error}`,
                status: "error",
            });
        }
    }
    return (
        <>
            {
                isLoading ? <Loading /> :
                    <Box>
                        <Flex py='15px' justifyContent=''>
                            <InputGroup bg="white" width="30%" mr='20px'>
                                <Input height='40px' placeholder="Search input here" onChange={e => setSearch(e.target.value)} fontSize={12} size="sm" />
                                <InputRightElement pointerEvents="none" children={<RiSearch2Line color="green" size="0.9rem" />} />
                            </InputGroup>

                        </Flex>
                        <Box width={'100%'}>
                            <TableContainer>
                                <Table size={'sm'}>
                                    <Thead py={'15px'}>
                                        <Tr bg="#3778D9"  color={'white'} py={'15px'} >
                                            <Td py={'15px'}  textTransform={'capitalize'}>Name</Td>
                                            <Td  textTransform={'capitalize'}>Username</Td>
                                            <Td  textTransform={'capitalize'}>Email</Td>
                                            <Td  textTransform={'capitalize'}>Phone No.</Td>
                                            <Td  textTransform={'capitalize'}>Status</Td>
                                            <Td  textTransform={'capitalize'}>Last login</Td>
                                            <Td  textTransform={'capitalize'}>Actions</Td>
                                        </Tr>
                                    </Thead>
                                    <Tbody>

                                        {
                                            deactivatedUserData && deactivatedUserData.length > 0 ? deactivatedUserData.map((user, index) => (
                                                <Tr key={index} bg="white" >
                                                    <Td>
                                                        <HStack spacing='5px' mt={'10px'}>
                                                            <Avatar
                                                                size="sm"
                                                                name={`${user.firstName} ${user.lastName}`}
                                                            />
                                                            <Box>
                                                                <Text fontWeight='500' textTransform='capitalize' fontSize='13px' >
                                                                    {`${user.firstName} ${user.lastName}`}
                                                                </Text>
                                                            </Box>
                                                        </HStack>
                                                    </Td>
                                                    <Td>
                                                        <Text fontSize={'13px'}>{user.userName}</Text>
                                                    </Td>


                                                    <Td>
                                                        <Text fontSize={'13px'} >{user.email}</Text>
                                                    </Td>
                                                    <Td>
                                                        <Text fontSize={'13px'}>

                                                            {user.phoneNumber ? user.phoneNumber : 'Nil'}
                                                        </Text>
                                                    </Td>
                                                    <Td>
                                                        <Text fontSize={'13px'} bg={'red'} color={'white'} textAlign={'center'} borderRadius={'15px'} py={'7px'} px={'15px'}>Deactivated</Text>
                                                    </Td>
                                                    <Td>
                                                        <Text fontSize={'13px'} >{user.lastLogin ? getFullDate(user.lastLogin) : 'Nil'}</Text></Td>

                                                    <Td>
                                                        <HStack>
                                                            <Center
                                                                border="1px solid"
                                                                borderRadius="10px"
                                                                borderColor="green"
                                                                bg={'green'}
                                                                width="30px"
                                                                cursor={"pointer"}
                                                                onClick={() => openActivateUser(user.userName)}
                                                                height="30px"
                                                            >
                                                                <BsCheckLg color='white' />
                                                            </Center>



                                                            <Center
                                                                border="1px solid"
                                                                borderRadius="10px"
                                                                onClick={() => openDeleteUser(user.userName)}
                                                                cursor={"pointer"}
                                                                borderColor="#E5E5E5"
                                                                width="30px"
                                                                height="30px"
                                                            >
                                                                <MdDelete color='red' />
                                                            </Center>
                                                        </HStack>
                                                    </Td>
                                                </Tr>
                                            )) : <Box>No data</Box>
                                        }




                                    </Tbody>
                                </Table>
                            </TableContainer>
                            <Flex alignContent={"flex-end"} justifyContent={'flex-end'} mt={4}>

                                <HStack>
                                    <Text fontWeight={400} fontSize={'13px'}>Rows per page :</Text>
                                    <Select
                                        variant="outline"
                                        size="sm"
                                        width={'70px'}
                                        value={pageSize}
                                        onChange={(e) => { changePageSize(e) }}
                                        fontSize={12}
                                    >
                                        <option value={10}>
                                            10
                                        </option>
                                        <option value={25}>
                                            25
                                        </option>
                                        <option value={50}>
                                            50
                                        </option>
                                        <option value={100}>
                                            100
                                        </option>

                                    </Select>

                                </HStack>
                                <Pagination
                                    pagesCount={pagesCount}
                                    currentPage={currentPage}
                                    onPageChange={setCurrentPage}
                                >
                                    <PaginationContainer>
                                        <PaginationPrevious><BsArrowLeft /></PaginationPrevious>
                                        <PaginationPageGroup>
                                            {pages.map((page: number) => (
                                                <PaginationPage
                                                    key={`pagination_page_${page}`}
                                                    page={page}
                                                />
                                            ))}
                                        </PaginationPageGroup>
                                        <PaginationNext><BsArrowRight /></PaginationNext>
                                    </PaginationContainer>
                                </Pagination>

                            </Flex>

                        </Box>
                        <Portal>
                            <AlertDialog
                                isOpen={isOpenAlert}
                                leastDestructiveRef={cancelRef}
                                onClose={onCloseAlert}
                            >
                                <AlertDialogOverlay>
                                    <AlertDialogContent>
                                        <AlertDialogHeader fontSize="md" fontWeight="semibold">
                                            Activate {parameterName}
                                        </AlertDialogHeader>

                                        <AlertDialogBody fontSize={15}>
                                            Are you sure? You can't undo this action afterwards.
                                        </AlertDialogBody>

                                        <AlertDialogFooter>
                                            <Button size={"sm"} ref={cancelRef} onClick={onCloseAlert}>
                                                Cancel
                                            </Button>
                                            <Button
                                                size={"sm"}
                                                colorScheme="green"
                                                onClick={activateUser}
                                                ml={3}
                                            >
                                                Activate
                                            </Button>
                                        </AlertDialogFooter>
                                    </AlertDialogContent>
                                </AlertDialogOverlay>
                            </AlertDialog>
                        </Portal>
                        <Portal>
                            <AlertDialog
                                isOpen={isOpenAlertDelete}
                                leastDestructiveRef={cancelRef}
                                onClose={onCloseAlertDelete}
                            >
                                <AlertDialogOverlay>
                                    <AlertDialogContent>
                                        <AlertDialogHeader fontSize="md" fontWeight="semibold">
                                            Delete {deleteName}
                                        </AlertDialogHeader>

                                        <AlertDialogBody fontSize={15}>
                                            Are you sure? You can't undo this action afterwards.
                                        </AlertDialogBody>

                                        <AlertDialogFooter>
                                            <Button size={"sm"} ref={cancelRef} onClick={onCloseAlertDelete}>
                                                Cancel
                                            </Button>
                                            <Button
                                                size={"sm"}
                                                colorScheme="red"
                                                onClick={deleteUser}
                                                ml={3}
                                            >
                                                Delete
                                            </Button>
                                        </AlertDialogFooter>
                                    </AlertDialogContent>
                                </AlertDialogOverlay>
                            </AlertDialog>
                        </Portal>

                    </Box>
            }
        </>
    )
}

export default DeactivatedUsers