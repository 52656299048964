import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Text, Box, useToast, Flex, Button,
    Image
} from "@chakra-ui/react"

import { useEffect, useRef } from "react";
import complete from '../../img/complete.png'




export const SuccefulModal: React.FC<any> = ({ isOpenSuccess, onClosed, isSuccess = false, reload }: { isSuccess: boolean, isOpenSuccess: any, onClosed: any, reload: any }) => {
    const toast = useToast();
    const cancelRef = useRef().current;



    useEffect(() => {


    }, [])
    const closeIt = () => {
        onClosed()
        reload()
    }

    return (
        <>
            <Modal isOpen={isOpenSuccess && isSuccess} onClose={onClosed} scrollBehavior="outside" closeOnOverlayClick={true} size="lg">
                <ModalOverlay />
                <ModalContent width='100%' borderRadius='20px' px='2px' >
                    <ModalHeader >

                    </ModalHeader>

                    <ModalBody >
                        <Flex flexDirection='column' justifyContent='center' px='30px' mt={4}>
                          <Flex justifyContent={'center'}>
                          <Image height={'100px'}   className="animate__animated animate__heartBeat  " width={'100px'} src={complete} alt='submited' />
                          
                          </Flex>
                            <Text mt='20px' fontWeight='600' fontSize='21px' color='#353535' textAlign='center'>Transaction successful</Text>
                            <Text mb='30px' fontSize='13px' color='#555555' textAlign='center'>Transaction has been carried out successfully. Amount will be credited shortly.</Text>
                            <Button size="sm" px={6} color='black' bg='#F5F5F5' fontSize={12} ref={cancelRef} onClick={() => closeIt()}>
                                Dismiss
                            </Button>

                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>

            </Modal>


        </>
    )




}
