
import {
    Container,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,

    Flex,
    Button,
    Box,
    Text,
    Stack,
    InputGroup,
    useToast,
    Checkbox,
    TabPanel,
    Tab,
    TabList,
    TabPanels,
    Tabs,
    Wrap,
    WrapItem

} from "@chakra-ui/react";
import { RiCloseLine } from "react-icons/ri";
import Select from 'react-select';
import 'animate.css';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import userProfileService from "../../../services/user/userProfile.service";
import usersService from "../../../services/user/users.service";
import { useFormik } from "formik";
import { IActiveUsersValue } from "../../../models/users/activeUsers.model";
import { IStateRes } from "../../../models/auth/registration.model";
import { IUserByNameData, IUserByNameRes } from "../../../models/users/userbyname.model";
import { getFullDateNumeric } from "../../../utils/getDate";
import { IRolesData } from "../../../models/roles/roles.model";
import rolesService from "../../../services/roles/roles.service";

const EditUserModal = ({ setIsOpen, user, reload }: { setIsOpen: any, user: IActiveUsersValue | undefined, reload: any }) => {
    const toast = useToast()
    const [theUser, setUser] = useState<IUserByNameData>();
    const [selectedState, setselectedState] = useState<any>(null);
    const [selectedCountry, setselectedCountry] = useState<any>(null);
    const [selectedGender, setselectedGender] = useState<any>(null);
    const [countries, setCountries] = useState<any[]>();
    const [userStates, setUserStates] = useState<any[]>();
    const [genderList, setGenderList] = useState<any[]>([{ value: 0, label: 'Male' }, { value: 1, label: 'Female' }]);
    const [userRoles, setUserRoles] = useState<string[]>([])
    const [rolesI, setRoles] = useState<string[]>();
    const [isLoading, setIsLoading] = useState(true);
    const handleChangeState = (selectedState: any) => {
        setselectedState(selectedState);
    };
    const handleChangeGender = (selectedGender: any) => {
        setselectedGender(selectedGender);
    };
    const handleChangeCountry = (selectedCountry: any) => {
        setselectedCountry(selectedCountry);
    };
    useEffect(() => {
        rolesService.getroles()
            .then((data) => {
                const roles: IRolesData = data.data;
                const mappedRoles = roles.$values.map(obj => obj.name);
                setRoles(mappedRoles)
                setIsLoading(false)
            })
            .catch((err) => err)
        if (user) {

            usersService.getUserByName(`?userName=${user.userName}`).then((data) => {
                let user_data: IUserByNameData = data.data
                setUser(data.data)
                setUserRoles(user_data.assignedRoles.$values)
                user_data.gender == 0 ? setselectedGender(genderList[0]) : setselectedGender(genderList[1])
                usersService.getUserCountries().then((data) => {
                    let countryList: any[] = []
                    const country_res: IStateRes = data
                    country_res.data.$values.forEach(element => {
                        countryList.push({ value: element.$id, label: element.description })
                    });
                    setselectedCountry(countryList.find(val => val.value == user_data.country))
                    setCountries(countryList)
                    setIsLoading(false)

                })
                usersService.getUserStates().then((data) => {
                    let stateList: any[] = []
                    const state_res: IStateRes = data
                    state_res.data.$values.forEach(element => {
                        stateList.push({ value: element.$id, label: element.name })
                    });

                    setselectedState(stateList.find(val => val.value == user_data.state))
                    setUserStates(stateList)
                    setIsLoading(false)

                })
                formik.setValues({
                    zipcode: user_data.zipCode,
                    city: user_data.city,
                    date: getFullDateNumeric(user_data.dateOfBirth),
                    address1: user_data.addressLine1,
                    address2: user_data.addressLine2,
                    firstname: user_data.firstName,
                    lastname: user_data.lastName,
                    username: user_data.userName,
                    email: user_data.email,
                    phone: user_data.phoneNumber,
                })
            })
        }


    }, [theUser?.$id]);

    const formik = useFormik({
        validate: (values: any) => {
            const errors: any = {};

            return errors;
        },
        initialValues: {

            zipcode: "",
            city: "",
            date: "",
            address1: "",
            address2: "",
            firstname: "",
            lastname: "",
            username: "",
            email: "",
            phone: "",
        },
        onSubmit: async (values) => {
            try {
                const data = {
                    userName: values.username,
                    email: values.email,
                    firstName: values.firstname,
                    lastName: values.lastname
                }
                const update_profile: any = await usersService.updateUser(data)
                console.log(update_profile.succeeded)
                if (update_profile.succeeded) {
                    toast({
                        title: "User Update",
                        description: `User updated successfully`,
                        status: "success",
                    });
                    reload(false)
                    setIsOpen(false)
                } else {
                    toast({
                        title: "User Update",
                        description: `${update_profile.messages.$values[0]}`,
                        status: "error",
                    });
                }
            } catch (error: any) {
                toast({
                    title: "User Update",
                    description: `${error}`,
                    status: "error",
                });
            }
        },
    });

    const assignRole = (role: string, checked: boolean, rolename: string) => {
        let roles: string[] = [];
        console.log(role,checked,'role selected')
        userRoles !== undefined ? roles = [...userRoles] : roles = [];
        if (checked) {
            roles.push(role)
            setUserRoles([...userRoles, role])
        } else if (!checked) {
            let ind = roles.indexOf(role)
            let useroleindex = userRoles.indexOf(role)
            userRoles.splice(useroleindex, 1)
            setUserRoles([...userRoles])
            roles.splice(ind, 1)
            console.log(roles)
        }
       

    }
    return (
        <>
            <Box className="animate__animated animate__fadeIn  bg_profile"  >
                <Box width='45vw' bg={'white'} height={'120vh'} overflowY={'initial'}>
                    <Flex pt={'10px'} px='10px' height='50px' justifyContent={'space-between'}>
                        <Text>Edit User</Text>
                        <Text cursor={'pointer'} onClick={() => { setIsOpen(false) }}><RiCloseLine /></Text>
                    </Flex>
                    <Tabs colorScheme='blue'>
                        <TabList>
                            <Tab fontSize={'14px'}>Edit Profile</Tab>
                            <Tab fontSize={'14px'}>Edit Roles and Account</Tab>

                        </TabList>


                        <TabPanels>
                            <TabPanel>
                                <Container
                                    width={{ lg: "97%", md: "97%", sm: "95%", base: "100%" }}
                                    maxWidth={"100%"}
                                    px={0}
                                    right={0}
                                    bg="F9F8F8"
                                    mx="auto"
                                >
                                    <Box
                                        bg="white"
                                        px="40px"
                                        py="25px"
                                        height="auto"

                                    >

                                        <Box mt={1}>
                                            <form onSubmit={formik.handleSubmit}>
                                                <Stack
                                                    mb={["10px", "10px", "10px", "10px"]}
                                                    direction={["column", "column", "row", "row"]}
                                                >
                                                    <FormControl
                                                        mb={["10px", "10px", "10px", "10px"]}
                                                        isInvalid={
                                                            formik.errors.firstname && formik.touched.firstname
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        <FormLabel
                                                            fontWeight="normal"
                                                            color="brand.3"
                                                            fontSize={13}
                                                        >
                                                            First Name
                                                        </FormLabel>
                                                        <Input
                                                            bg="inputText.500"
                                                            placeholder="first name"
                                                            value={formik.values.firstname}
                                                            name="firstname"
                                                            onChange={formik.handleChange}
                                                            fontSize={12}
                                                        />
                                                        <FormErrorMessage fontSize={12}>
                                                            {formik.errors.firstname}
                                                        </FormErrorMessage>
                                                    </FormControl>
                                                    <FormControl
                                                        mb={["10px", "10px", "10px", "10px"]}
                                                        isInvalid={
                                                            formik.errors.lastname && formik.touched.lastname
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        <FormLabel
                                                            fontWeight="normal"
                                                            color="brand.3"
                                                            fontSize={13}
                                                        >
                                                            Last Name

                                                        </FormLabel>
                                                        <Input
                                                            bg="inputText.500"
                                                            placeholder="lastname"
                                                            value={formik.values.lastname}
                                                            name="lastname"
                                                            onChange={formik.handleChange}
                                                            fontSize={12}
                                                        />
                                                        <FormErrorMessage fontSize={12}>
                                                            {formik.errors.lastname}
                                                        </FormErrorMessage>
                                                    </FormControl>

                                                </Stack>
                                                <Stack
                                                    mb={["10px", "10px", "10px", "10px"]}
                                                    direction={["column", "column", "row", "row"]}
                                                >
                                                    <FormControl
                                                        mb={["10px", "10px", "10px", "10px"]}
                                                        isInvalid={
                                                            formik.errors.username && formik.touched.username
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        <FormLabel
                                                            fontWeight="normal"
                                                            color="brand.3"
                                                            fontSize={13}
                                                        >
                                                            Username

                                                        </FormLabel>
                                                        <Input
                                                            bg="inputText.500"
                                                            placeholder="username"
                                                            value={formik.values.username}
                                                            name="username"
                                                            onChange={formik.handleChange}
                                                            fontSize={12}
                                                        />
                                                        <FormErrorMessage fontSize={12}>
                                                            {formik.errors.username}
                                                        </FormErrorMessage>
                                                    </FormControl>
                                                    <FormControl
                                                        mb={["10px", "10px", "10px", "10px"]}
                                                        isInvalid={
                                                            formik.errors.email && formik.touched.email
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        <FormLabel
                                                            fontWeight="normal"
                                                            color="brand.3"
                                                            fontSize={13}
                                                        >
                                                            Email

                                                        </FormLabel>
                                                        <Input
                                                            placeholder="10002"
                                                            value={formik.values.email}
                                                            name="email"
                                                            onChange={formik.handleChange}
                                                            fontSize={12}
                                                            type="email"
                                                        />
                                                        <FormErrorMessage fontSize={12}>
                                                            {formik.errors.email}
                                                        </FormErrorMessage>
                                                    </FormControl>
                                                </Stack>


                                                <Stack
                                                    mb={["10px", "10px", "10px", "10px"]}
                                                    direction={["column", "column", "row", "row"]}
                                                >
                                                    <FormControl mb={'15px'} isInvalid={(formik.errors.city && formik.touched.city) ? true : false} >
                                                        <Select
                                                            value={selectedCountry}
                                                            onChange={handleChangeCountry}
                                                            options={countries}
                                                            isSearchable={true}
                                                            placeholder={'Country'}
                                                        />
                                                    </FormControl>

                                                    <FormControl mb={'15px'} >
                                                        <Select
                                                            value={selectedState}
                                                            placeholder={'State'}
                                                            onChange={handleChangeState}
                                                            options={userStates}
                                                            isSearchable={true}
                                                        />
                                                    </FormControl>
                                                </Stack>
                                                <Stack
                                                    mb={["10px", "10px", "10px", "10px"]}
                                                    direction={["column", "column", "row", "row"]}
                                                >
                                                    <FormControl
                                                        mb={["10px", "10px", "10px", "10px"]}

                                                    >
                                                        <FormLabel
                                                            fontWeight="normal"
                                                            color="brand.3"
                                                            fontSize={13}
                                                        >
                                                            Zipcode

                                                        </FormLabel>
                                                        <Input
                                                            bg="inputText.500"
                                                            placeholder="10002"
                                                            value={formik.values.zipcode}
                                                            name="zipcode"
                                                            onChange={formik.handleChange}
                                                            fontSize={12}
                                                        />
                                                        <FormErrorMessage fontSize={12}>
                                                            {formik.errors.zipcode}
                                                        </FormErrorMessage>
                                                    </FormControl>
                                                    <FormControl
                                                        mb={["10px", "10px", "10px", "10px"]}
                                                        isInvalid={
                                                            formik.errors.city && formik.touched.city
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        <FormLabel
                                                            fontWeight="normal"
                                                            color="brand.3"
                                                            fontSize={13}
                                                        >
                                                            City

                                                        </FormLabel>
                                                        <Input
                                                            bg="inputText.500"
                                                            placeholder="Enter city"
                                                            value={formik.values.city}
                                                            name="city"
                                                            onChange={formik.handleChange}
                                                            fontSize={12}
                                                        />
                                                        <FormErrorMessage fontSize={12}>
                                                            {formik.errors.city}
                                                        </FormErrorMessage>
                                                    </FormControl>

                                                </Stack>
                                                <Stack
                                                    mb={["10px", "10px", "10px", "10px"]}
                                                    direction={["column", "column", "row", "row"]}
                                                >
                                                    <FormControl
                                                        mb={["10px", "10px", "10px", "10px"]}
                                                        isInvalid={
                                                            formik.errors.address1 && formik.touched.address1
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        <FormLabel
                                                            fontWeight="normal"
                                                            color="brand.3"
                                                            fontSize={13}
                                                        >
                                                            Address Line 1

                                                        </FormLabel>
                                                        <Input
                                                            bg="inputText.500"
                                                            placeholder="Enter Address 1"
                                                            value={formik.values.address1}
                                                            name="address1"
                                                            onChange={formik.handleChange}
                                                            fontSize={12}
                                                        />
                                                        <FormErrorMessage fontSize={12}>
                                                            {formik.errors.address1}
                                                        </FormErrorMessage>
                                                    </FormControl>
                                                    <FormControl
                                                        mb={["10px", "10px", "10px", "10px"]}
                                                        isInvalid={
                                                            formik.errors.address2 && formik.touched.address2
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        <FormLabel
                                                            fontWeight="normal"
                                                            color="brand.3"
                                                            fontSize={13}
                                                        >
                                                            Address Line 2

                                                        </FormLabel>
                                                        <Input
                                                            bg="inputText.500"
                                                            placeholder="Enter Address 2"
                                                            value={formik.values.address2}
                                                            name="address2"
                                                            onChange={formik.handleChange}
                                                            fontSize={12}
                                                        />
                                                        <FormErrorMessage fontSize={12}>
                                                            {formik.errors.address2}
                                                        </FormErrorMessage>
                                                    </FormControl>

                                                </Stack>
                                                <Stack
                                                    mb={["10px", "10px", "10px", "10px"]}
                                                    direction={["column", "column", "row", "row"]}
                                                >
                                                    <FormControl
                                                        mb={["10px", "10px", "10px", "10px"]}
                                                        isInvalid={
                                                            formik.errors.date && formik.touched.date ? true : false
                                                        }
                                                    >
                                                        <FormLabel
                                                            fontWeight="normal"
                                                            color="brand.3"
                                                            fontSize={13}
                                                        >
                                                            Date of Birth{" "}
                                                            <Text as="span" color="red">
                                                                *
                                                            </Text>
                                                        </FormLabel>
                                                        <InputGroup>
                                                            <Input
                                                                placeholder="Date of Birth"
                                                                type="date"
                                                                bg="inputText.500"
                                                                name="date"
                                                                value={formik.values.date}
                                                                onChange={formik.handleChange}
                                                                fontSize={12}
                                                            />
                                                            {/* <InputRightElement pointerEvents="none" children={<VscCalendar fontSize={20} color="#7883A7" />} /> */}
                                                        </InputGroup>
                                                        <FormErrorMessage fontSize={12}>
                                                            {formik.errors.date}
                                                        </FormErrorMessage>
                                                    </FormControl>
                                                    <FormControl
                                                        mb={["10px", "10px", "10px", "10px"]}

                                                    >
                                                        <FormLabel
                                                            fontWeight="normal"
                                                            color="brand.3"
                                                            fontSize={13}
                                                        >
                                                            Gender
                                                        </FormLabel>
                                                        <FormControl mb={'15px'} isInvalid={(formik.errors.city && formik.touched.city) ? true : false} >
                                                            <Select
                                                                value={selectedGender}
                                                                onChange={handleChangeGender}
                                                                options={genderList}
                                                                isSearchable={true}
                                                                placeholder={'Gender'}
                                                            />
                                                        </FormControl>
                                                        <FormErrorMessage fontSize={12}>

                                                        </FormErrorMessage>
                                                    </FormControl>
                                                </Stack>




                                                <Flex mt={8} justifyContent={'center'}>
                                                    <Button


                                                        color={"white"}
                                                        width={'100%'}
                                                        bg={"#011F78"}
                                                        type="submit"
                                                        isLoading={formik.isSubmitting}
                                                        fontSize={"14px"}
                                                        borderRadius={'4px'}
                                                    >
                                                        Save changes
                                                    </Button>
                                                </Flex>
                                            </form>
                                        </Box>
                                    </Box>
                                </Container>
                            </TabPanel>
                            <TabPanel>
                                <Container
                                    width={{ lg: "95%", md: "95%", sm: "95%", base: "100%" }}
                                    maxWidth={"95%"}
                                    px={0}
                                    right={0}
                                    bg="F9F8F8"
                                    mx="auto"
                                >

                                    <Box mb={'30px'} >
                                        <Text mb={'15px'} fontSize={'14px'} color={'#000000'} fontWeight={'400'}>User Roles</Text>
                                        <Wrap spacing='30px'>
                                            {
                                                rolesI?.map((role, index) => (
                                                    <WrapItem>
                                                        <Checkbox color={'#575757'} isChecked={userRoles.includes(role)}
                                                            value={role}
                                                            onChange={(e) =>
                                                                assignRole(e.target.value, e.target.checked, role)
                                                            } colorScheme='blue' >
                                                            <Text fontSize={'13px'}> {role}</Text>
                                                        </Checkbox>
                                                    </WrapItem>
                                                ))
                                            }
                                         
                                        </Wrap>

                                    </Box>

                                    <Box mt={'20px'}>
                                        <Text mb={'7px'} fontSize={'14px'} color={'#000000'} fontWeight={'400'}>Account to sell (Select any to restrict account type creation)</Text>
                                        <Stack fontSize={'12px'} color={'#575757'} spacing={5} direction='row'>
                                            <Checkbox color={'#575757'} colorScheme='blue' >
                                                <Text fontSize={'13px'}> Push notification</Text>
                                            </Checkbox>
                                            <Checkbox color={'#575757'} colorScheme='blue' >
                                                <Text fontSize={'13px'}> Push notification</Text>
                                            </Checkbox>
                                            <Checkbox color={'#575757'} colorScheme='blue' >
                                                <Text fontSize={'13px'}> Push notification</Text>
                                            </Checkbox>

                                        </Stack>
                                    </Box>
                                    <Flex mt={8} justifyContent={'center'}>
                                        <Button


                                            color={"white"}
                                            width={'100%'}
                                            bg={"#011F78"}
                                            type="submit"
                                            isLoading={formik.isSubmitting}
                                            fontSize={"14px"}
                                            borderRadius={'4px'}
                                        >
                                            Save changes
                                        </Button>
                                    </Flex>
                                </Container>

                            </TabPanel>


                        </TabPanels>
                    </Tabs>

                </Box>


            </Box >

        </>
    );
};

export default EditUserModal;