import { Flex, Container, Box, Button, HStack, Menu, MenuButton, MenuItem, MenuList, Spacer, VStack, Text } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import AdminRoutes from "../routes";
import { BsArrowUp } from "react-icons/bs";
import Chart from "react-google-charts";
import dashboardService from "../../services/dashboard/dashboard.service";
import { IAccountsValue, IAccountsRes } from "../../models/accounts/accounts.model";
import accountsService from "../../services/accounts/accounts.service";
import UserAccountsBoard from "../../components/dashboard/UserDashboard";


const AccountsBoard = () => {
   
     
    return (
        <>
           <UserAccountsBoard />
        </>
    )
}

export default AccountsBoard;