import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    Box,
    useToast,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Flex,
    Button,
    HStack,
    Textarea,
    Checkbox,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import usersService from "../../services/user/users.service";
import { IStateRes } from "../../models/auth/registration.model";
import Select from 'react-select';
export const CreateCustomerModal: React.FC<any> = ({
    isOpenCustomer,
    onClosed,
    isCustomer = false,
    reload
}: {
    isCustomer: boolean;
    isOpenCustomer: any;
    onClosed: any;
    reload: any;
}) => {
    const toast = useToast();
    const cancelRef = useRef().current;
    const [forCustomer, setforCustomer] = useState(false)
    const [genderList, setGenderList] = useState<any[]>([{ value: 0, label: 'Male' }, { value: 1, label: 'Female' }]);
    const [countries, setCountries] = useState<any[]>();
    const [userStates, setUserStates] = useState<any[]>();
    const [isLoading, setIsLoading] = useState(true);
    const [selectedState, setselectedState] = useState<any>(null);
    const [selectedCountry, setselectedCountry] = useState<any>(null);
    const [selectedGender, setselectedGender] = useState<any>(null);
    const handleChangeState = (selectedState: any) => {
        setselectedState(selectedState);
    };
    const handleChangeGender = (selectedGender: any) => {
        setselectedGender(selectedGender);
    };
    const handleChangeCountry = (selectedCountry: any) => {
        setselectedCountry(selectedCountry);
    };
    useEffect(() => {
        usersService.getUserCountries().then((data) => {
            let countryList: any[] = []
            const country_res: IStateRes = data
            country_res.data.$values.forEach(element => {
                countryList.push({ value: element.$id, label: element.description })
            });

            setCountries(countryList)
            setIsLoading(false)

        })
        usersService.getUserStates().then((data) => {
            let stateList: any[] = []
            const state_res: IStateRes = data
            state_res.data.$values.forEach(element => {
                stateList.push({ value: element.$id, label: element.name })
            });

            setUserStates(stateList)
            setIsLoading(false)

        })

    }, []);

    const formik = useFormik({
        validate: (values: any) => {
            const errors: any = {};
            if (!values.username) {
                errors.username = "username is required";
            }
            if (!values.email) {
                errors.email = "email is required";
            }

            return errors;
        },
        initialValues: {
            username: "",
            email: "",
            firstName: "",
            lastName: "",
            address1: "",
            address2: "",
            address3: "",
            city: '',
            zipcode: '',
            dob: '',
            gender: "",
            dialingCode: "",
            phone: ""
        },
        onSubmit: async (values) => {
            try {
                const data = {

                    email: values.email,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    country: parseInt(selectedCountry.value),
                    state: parseInt(selectedState.value),
                    zipCode: values.zipcode,
                    city: values.city,
                    addressLine1: values.address1,
                    addressLine2: values.address2,
                    addressLine3: values.address3,
                    photoUrl: '',
                    dateOfBirth: values.dob,
                    gender: selectedGender.value,
                    countryCallingCode: values.dialingCode,
                    phoneNumber: values.phone
                }

                const new_param: any = {
                    username: values.username,
                    email: values.email,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    clientConfirmationUrl: 'https://callanpay.vochus.com/',

                }
                if (forCustomer) {
                    const create_customer: any = await usersService.createOfflineCustomer(data)
                    console.log(create_customer.succeeded)
                    if (create_customer.succeeded) {
                        toast({
                            title: "Create Customer",
                            description: "Customer created successfully",
                            status: "success",
                        });
                        onClosed(!isCustomer)
                        reload()
                    } else {
                        toast({
                            title: "Create Customer",
                            description: `${create_customer.messages.$values[0]}`,
                            status: "error",
                        });
                    }
                } else {
                    const create_customer: any = await usersService.createCustomer(new_param)
                    console.log(create_customer.succeeded)
                    if (create_customer.succeeded) {
                        toast({
                            title: "Create Customer",
                            description: "Customer created successfully",
                            status: "success",
                        });
                        onClosed(!isCustomer)
                        reload()
                    } else {
                        toast({
                            title: "Create Customer",
                            description: `${create_customer.messages.$values[0]}`,
                            status: "error",
                        });
                    }
                }


            } catch (error: any) {
                toast({
                    title: "Create Customer",
                    description: `${error}`,
                    status: "error",
                });
            }

        },
    });

    return (
        <>
            <Modal
                isOpen={isOpenCustomer && isCustomer}
                onClose={onClosed}
                scrollBehavior="inside"
                closeOnOverlayClick={true}
                size="xl"
            >
                <ModalOverlay />
                <ModalContent borderRadius={5} px={6}>
                    <ModalHeader>
                        {
                            <Text
                                fontSize={'18px'}
                                pb={3}
                                textTransform="capitalize"


                            >
                                Create User
                            </Text>
                        }
                    </ModalHeader>
                    <ModalCloseButton size="sm" />
                    <ModalBody pb={'30px'}>
                        <Box mt={2}>
                            <Checkbox fontSize={'12px'} isChecked={forCustomer} color={'#575757'} onChange={(e) => setforCustomer(e.target.checked)} colorScheme='blue' >
                                Offline Customer
                            </Checkbox>
                            <form onSubmit={formik.handleSubmit}>
                                <HStack>
                                    {
                                        !forCustomer &&
                                        <FormControl
                                            mt={3}
                                            isInvalid={
                                                formik.errors.username && formik.touched.username ? true : false
                                            }
                                        >
                                            <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                                Username
                                            </FormLabel>
                                            <Input
                                                type="text"
                                                id="username"
                                                name="username"
                                                placeholder="Enter username"
                                                onChange={formik.handleChange}
                                                fontSize={13}
                                            />
                                            <FormErrorMessage fontSize={12}>
                                                {formik.errors.username}
                                            </FormErrorMessage>
                                        </FormControl>
                                    }

                                    <FormControl
                                        mt={3}
                                        isInvalid={
                                            formik.errors.email && formik.touched.email ? true : false
                                        }
                                    >
                                        <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                            Email
                                        </FormLabel>
                                        <Input
                                            type="email"
                                            id="email"
                                            name="email"
                                            placeholder="Enter email"
                                            onChange={formik.handleChange}
                                            fontSize={13}
                                        />
                                        <FormErrorMessage fontSize={12}>
                                            {formik.errors.email}
                                        </FormErrorMessage>
                                    </FormControl>
                                </HStack>
                                <HStack>
                                    <FormControl
                                        mt={3}
                                        isInvalid={
                                            formik.errors.username && formik.touched.username ? true : false
                                        }
                                    >
                                        <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                            First Name
                                        </FormLabel>
                                        <Input
                                            type="text"
                                            id="firstName"
                                            name="firstName"
                                            placeholder="Enter first name"
                                            onChange={formik.handleChange}
                                            fontSize={13}
                                        />
                                        <FormErrorMessage fontSize={12}>
                                            {formik.errors.firstName}
                                        </FormErrorMessage>
                                    </FormControl>
                                    <FormControl
                                        mt={3}
                                        isInvalid={
                                            formik.errors.username && formik.touched.username ? true : false
                                        }
                                    >
                                        <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                            Last Name
                                        </FormLabel>
                                        <Input
                                            type="text"
                                            id="lastName"
                                            name="lastName"
                                            placeholder="Enter last name"
                                            onChange={formik.handleChange}
                                            fontSize={13}
                                        />
                                        <FormErrorMessage fontSize={12}>
                                            {formik.errors.lastName}
                                        </FormErrorMessage>
                                    </FormControl>
                                </HStack>


                                {
                                    forCustomer && (
                                        <Box>
                                            <HStack>
                                            <FormControl
                                                    mt={3}
                                                    isInvalid={
                                                        formik.errors.dialingCode && formik.touched.dialingCode ? true : false
                                                    }
                                                >
                                                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                                        Nigeria Dialing Code
                                                    </FormLabel>
                                                    <Input
                                                        type="text"
                                                        id="dialingCode"
                                                        name="dialingCode"
                                                        placeholder="+234"
                                                        onChange={formik.handleChange}
                                                        fontSize={13}
                                                    />
                                                    <FormErrorMessage fontSize={12}>
                                                        {formik.errors.dialingCode}
                                                    </FormErrorMessage>
                                                </FormControl>
                                                <FormControl
                                                    mt={3}
                                                    isInvalid={
                                                        formik.errors.phone && formik.touched.phone ? true : false
                                                    }
                                                >
                                                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                                        Phone
                                                    </FormLabel>
                                                    <Input
                                                        type="number"
                                                        id="phone"
                                                        name="phone"
                                                        placeholder="not more than 11 digits"
                                                        onChange={formik.handleChange}
                                                        fontSize={13}
                                                    />
                                                    <FormErrorMessage fontSize={12}>
                                                        {formik.errors.phone}
                                                    </FormErrorMessage>
                                                </FormControl>
                                           
                                            </HStack>

                                            <HStack mt={'20px'} width={'500px'}>
                                                <FormControl mb={'15px'} isInvalid={(formik.errors.city && formik.touched.city) ? true : false} >
                                                    <Select
                                                        value={selectedCountry}
                                                        onChange={handleChangeCountry}
                                                        options={countries}
                                                        isSearchable={true}
                                                        placeholder={'Country'}
                                                    />
                                                </FormControl>

                                                <FormControl mb={'15px'} >
                                                    <Select
                                                        value={selectedState}
                                                        placeholder={'State'}
                                                        onChange={handleChangeState}
                                                        options={userStates}
                                                        isSearchable={true}
                                                    />
                                                </FormControl>
                                                <FormControl mb={'15px'} isInvalid={(formik.errors.zipcode && formik.touched.zipcode) ? true : false} >
                                                    <Input bg="inputText.500" placeholder="Zip code" value={formik.values.zipcode} name="zipcode" onChange={formik.handleChange} fontSize={'13px'} />
                                                    <FormErrorMessage fontSize={12} >{formik.errors.zipcode}</FormErrorMessage>
                                                </FormControl>

                                            </HStack>
                                            <HStack width={'500px'}>
                                                <FormControl mb={'15px'} isInvalid={(formik.errors.city && formik.touched.city) ? true : false} >
                                                    <Input placeholder="City" value={formik.values.city} name="city" fontSize={'13px'} onChange={formik.handleChange} />
                                                    <FormErrorMessage fontSize={12} >{formik.errors.city}</FormErrorMessage>
                                                </FormControl>
                                                <FormControl mb={'15px'} isInvalid={(formik.errors.address1 && formik.touched.address1) ? true : false} >
                                                    <Input bg="inputText.500" placeholder="Address 1" value={formik.values.address1} name="address1" onChange={formik.handleChange} fontSize={'13px'} />
                                                    <FormErrorMessage fontSize={12} >{formik.errors.address1}</FormErrorMessage>
                                                </FormControl>


                                            </HStack>
                                            <HStack width={'500px'}>

                                                <FormControl mb={'15px'} isInvalid={(formik.errors.address2 && formik.touched.address2) ? true : false} >
                                                    <Input bg="inputText.500" placeholder="Address 2" value={formik.values.address2} name="address2" onChange={formik.handleChange} fontSize={'13px'} />
                                                    <FormErrorMessage fontSize={12} >{formik.errors.address2}</FormErrorMessage>
                                                </FormControl>
                                                <FormControl mb={'15px'} isInvalid={(formik.errors.address3 && formik.touched.address3) ? true : false} >
                                                    <Input bg="inputText.500" placeholder="Address 3" value={formik.values.address3} name="address3" onChange={formik.handleChange} fontSize={'13px'} />
                                                    <FormErrorMessage fontSize={12} >{formik.errors.address3}</FormErrorMessage>
                                                </FormControl>


                                            </HStack>
                                            <HStack width={'500px'}>

                                                <FormControl mb={'15px'} isInvalid={(formik.errors.city && formik.touched.city) ? true : false} >
                                                    <Select
                                                        value={selectedGender}
                                                        onChange={handleChangeGender}
                                                        options={genderList}
                                                        isSearchable={true}
                                                        placeholder={'Gender'}
                                                    />
                                                </FormControl>
                                                <FormControl mb={'15px'} isInvalid={(formik.errors.dob && formik.touched.dob) ? true : false} >
                                                    <Input bg="inputText.500" type="date" placeholder="Date of birth" value={formik.values.dob} name="dob" onChange={formik.handleChange} fontSize={'13px'} />
                                                    <FormErrorMessage fontSize={12} >{formik.errors.dob}</FormErrorMessage>
                                                </FormControl>


                                            </HStack>

                                        </Box>
                                    )
                                }

                                <Flex mt={12} justifyContent={'space-between'} >


                                    <Button

                                        px={6}
                                        colorScheme="blue"
                                        fontSize={'14px'}
                                        variant={'outline'}
                                        ref={cancelRef}
                                        width={'49%'}
                                        onClick={() => onClosed()}
                                    >
                                        Cancel
                                    </Button>
                                    <Button

                                        type="submit"
                                        isLoading={formik.isSubmitting}
                                        color={"white"}
                                        width={'49%'}
                                        bg={"#011F78"}
                                        px={6}
                                        fontSize={'14px'}
                                    >
                                        Confirm
                                    </Button>


                                </Flex>
                            </form>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
