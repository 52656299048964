import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    Box,
    useToast,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Flex,
    Button,
    HStack,
    Textarea,
    InputGroup,
    InputLeftElement,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useRef, useState } from "react";
import { MdLock } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ILoginRes } from "../../../models/auth/login.model";
import AdminRoutes from "../../../pages/routes";
import userProfileService from "../../../services/user/userProfile.service";


export const ChangePinModal: React.FC<any> = ({
    isOpenChangePin,
    onClosed,
    isChangePin = false,
    reload
}: {
    isChangePin: boolean;
    isOpenChangePin: any;
    onClosed: any;
    reload: any;
}) => {
    const toast = useToast();
    const cancelRef = useRef().current;
    const route = useNavigate();
    const validate = (values: any) => {
        const errors: any = {};
        if (!values.confirmPin) {
            errors.confirmPin = "confirm pin is required";
        }
        if (!values.pin) {
            errors.pin = "pin is required";
        }
        return errors;
    };

    const formik = useFormik({
        initialValues: { confirmPin: "", pin: "", currentPin: "" },
        validate,
        onSubmit: async (values) => {
            try {
                const data = {
                    confirmNewPIN: values.confirmPin.toString(),
                    newPin: values.pin.toString(),
                    currentPin: values.currentPin.toString()
                }
                const set_pin: ILoginRes = await userProfileService.changePin(data)

                if (set_pin.succeeded) {
                    toast({
                        title: "Pin",
                        description: `Pin changed successfully`,
                        status: "success",
                    });

                    route(AdminRoutes.portal.dashboard)
                } else {
                    toast({
                        title: "Pin",
                        description: `${set_pin.messages.$values[0]}`,
                        status: "error",
                    });
                }
            } catch (error: any) {
                toast({
                    title: "Pin",
                    description: `${error}`,
                    status: "error",
                });
            }
        },
    });
    return (
        <>
            <Modal
                isOpen={isOpenChangePin && isChangePin}
                onClose={onClosed}
                scrollBehavior="outside"
                closeOnOverlayClick={true}
                size="lg"
            >
                <ModalOverlay />
                <ModalContent borderRadius={5} px={6}>
                    <ModalHeader>
                        {
                            <Text
                                fontSize={'18px'}
                                pb={3}
                                textTransform="capitalize">
                                Change Pin
                            </Text>
                        }
                    </ModalHeader>
                    <ModalCloseButton size="sm" />
                    <ModalBody pb={'30px'}>
                        <Box mt={2}>
                            <form onSubmit={formik.handleSubmit}>

                                <FormControl
                                    mb="20px"
                                    isInvalid={
                                        formik.errors.pin && formik.touched.pin
                                            ? true
                                            : false
                                    }
                                >
                                    <FormLabel color="brand.subtitle" fontSize={15}>
                                        Current  pin{" "}

                                    </FormLabel>
                                    <InputGroup>
                                        <InputLeftElement
                                            color="brand.subtitle"
                                            pointerEvents="none"
                                            children={<MdLock />}
                                        />
                                        <Input
                                            width={["100%", "100%", "520px", "520px"]}
                                            type="number"
                                            name="currentPin"
                                            id="currentPin"
                                            placeholder="Enter  pin"
                                            onChange={formik.handleChange}
                                            fontSize={14}
                                        />
                                    </InputGroup>
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.currentPin}
                                    </FormErrorMessage>
                                </FormControl>

                                <FormControl
                                    mb="20px"
                                    isInvalid={
                                        formik.errors.pin && formik.touched.pin
                                            ? true
                                            : false
                                    }
                                >
                                    <FormLabel color="brand.subtitle" fontSize={15}>
                                        New  pin{" "}

                                    </FormLabel>
                                    <InputGroup>
                                        <InputLeftElement
                                            color="brand.subtitle"
                                            pointerEvents="none"
                                            children={<MdLock />}
                                        />
                                        <Input
                                            width={["100%", "100%", "520px", "520px"]}
                                            type="number"
                                            name="pin"
                                            id="pin"
                                            placeholder="Enter  pin"
                                            onChange={formik.handleChange}
                                            fontSize={14}
                                        />
                                    </InputGroup>
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.pin}
                                    </FormErrorMessage>
                                </FormControl>

                                <FormControl
                                    mb="20px"
                                    isInvalid={
                                        formik.errors.pin && formik.touched.pin
                                            ? true
                                            : false
                                    }
                                >
                                    <FormLabel color="brand.subtitle" fontSize={15}>
                                        Confirm  Pin{" "}

                                    </FormLabel>
                                    <InputGroup>
                                        <InputLeftElement
                                            color="brand.subtitle"
                                            pointerEvents="none"
                                            children={<MdLock />}
                                        />
                                        <Input
                                            width={["100%", "100%", "520px", "520px"]}
                                            type="number"
                                            name="confirmPin"
                                            id="confirmPin"
                                            placeholder="confirm  pin"
                                            onChange={formik.handleChange}
                                            fontSize={14}
                                        />
                                    </InputGroup>
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.confirmPin}
                                    </FormErrorMessage>
                                </FormControl>
                                <Flex mt={12} justifyContent={'space-between'} >


                                <Button

                                    px={6}
                                    colorScheme="blue"
                                    fontSize={'14px'}
                                    variant={'outline'}
                                    ref={cancelRef}
                                    width={'49%'}
                                    onClick={() => onClosed()}
                                >
                                    Cancel
                                </Button>
                                <Button

                                    type="submit"
                                    isLoading={formik.isSubmitting}
                                    color={"white"}
                                    width={'49%'}
                                    bg={"#011F78"}
                                    px={6}
                                    fontSize={'14px'}
                                >
                                    Confirm
                                </Button>


                            </Flex>
                            </form>
                           
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
