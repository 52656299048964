import { Box, Flex, HStack, Button, InputGroup, Input, InputRightElement, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Avatar, Text, MenuItem, Menu, MenuButton, MenuList, Center, Portal, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';
import { RiSearch2Line } from 'react-icons/ri';
import { BsArrowLeft, BsArrowRight, BsThreeDotsVertical } from 'react-icons/bs';
import { NumericFormat } from 'react-number-format';
import { useState, useEffect, useRef } from 'react';
import { Pagination, PaginationContainer, PaginationNext, PaginationPage, PaginationPageGroup, PaginationPrevious, usePagination } from "@ajna/pagination";

import Loading from '../components/Loading';
import systemparameterService from '../services/systemparameter/systemparameter.service';
import { Ipage } from '../models/audit/auditlog.model';
import { CreateSystemParameter } from '../components/system-parameter/CreateSystemParameter';
import { EditSystemParameter } from '../components/system-parameter/EditSystemParameter';
import { MdDelete } from 'react-icons/md';
import { BiEditAlt } from 'react-icons/bi';

const SystemParameter = () => {
    let navigate = useNavigate();
    const toast = useToast()
    const [search, setSearch] = useState("");
    const [query, setQuery] = useState("");
    const [page, setPage] = useState<Ipage>({ page_total: 0, total: 0, page_count: 0 });
    const [systemParameterList, setSystemParameterList] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [reload, setReload] = useState(false);
    const [isEditParam, setIsEditParam] = useState(false);
    const [isSystemParam, setisSystemParam] = useState(false);
    const [parameter, setParameter] = useState<any>();
    const [parameterName, setParameterName] = useState<string>();
    const [isOpenAlert, setIsOpen] = useState(false);
    const onCloseAlert = () => setIsOpen(false);
    const {
        currentPage,
        setCurrentPage,
        pagesCount,
        pages
    } = usePagination({
        pagesCount: page.page_count,
        initialState: { currentPage: 1, pageSize: 100 },
        total: page.total
    });
    const cancelRef = useRef(null);
    useEffect(() => {
        // let url = `?pageNumber=${currentPage}&pageSize=${100}&searchString=${query}`
        systemparameterService.getparameters()
            .then((data) => {
                const system_param: any = data
                setPage({ page_total: system_param.pageSize, total: system_param.totalCount, page_count: system_param.totalPages })
                setSystemParameterList(system_param.data.$values);
                setIsLoading(false)
            })
            .catch((err) => err)

    }, [currentPage, query, reload]);



    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (search.length > 0) {
                setQuery(search);
            } else {
                setQuery("");
            }
        }, 700);

        return () => clearTimeout(delayDebounceFn)
    }, [search])
    const openSystemParemeter = () => {
        setIsEditParam(false)
        setisSystemParam(true)
        setIsOpen(false)

    };
    const openEditParam = (param: any) => {
        setParameter(param)
        setIsEditParam(true)
        setisSystemParam(false)
        setIsOpen(false)

    };
    const openDeleteParam = (param: string) => {
        setParameterName(param)
        setIsEditParam(false)
        setisSystemParam(false)
        setIsOpen(true)
    };
    const reloadData = () => {
        setReload(!reload);
    };

    const deleteParameter = async () => {
        try {
            const delete_param: any = await systemparameterService.deleteParameter(parameterName)
            console.log(delete_param.succeeded)
            if (delete_param.succeeded) {
                toast({
                    title: "System Parameter",
                    description: "parameter deleted successfully",
                    status: "success",
                });
                setReload(!reload)
                onCloseAlert();

            } else {
                toast({
                    title: "System Parameter",
                    description: `${delete_param.messages.$values[0]}`,
                    status: "error",
                });
            }
        } catch (error: any) {
            toast({
                title: "System Parameter",
                description: `${error}`,
                status: "error",
            });
        }
    }
    return (
        <>
            {
                isLoading ? <Loading /> :
                    <Box >
                        <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            py="26px"
                        >
                            <Box color="#2B2B2B" fontWeight="800" fontSize="27px">
                                System Parameter
                            </Box>
                            <HStack>
                                <Button
                                    cursor="pointer"
                                    color={"white"}
                                    bg={"#011F78"}
                                    type="submit"
                                    fontSize={'13px'}
                                    onClick={() => { openSystemParemeter() }}
                                >
                                    Add new
                                </Button>
                            </HStack>
                        </Flex>
                        <Flex py='15px' justifyContent=''>
                            <InputGroup bg="white" width="30%" mr='20px'>
                                <Input height='40px' placeholder="Search input here" onChange={e => setSearch(e.target.value)} fontSize={12} size="sm" />
                                <InputRightElement pointerEvents="none" children={<RiSearch2Line color="green" size="0.9rem" />} />
                            </InputGroup>

                        </Flex>
                        <Box width={'100%'}>
                            <TableContainer>
                                <Table size={'md'}>
                                    <Thead py={'15px'}>
                                    <Tr bg="#3778D9" color={'white'} py={'15px'} >     <Td py={'15px'}  textTransform={'capitalize'}>Name</Td>
                                            <Td  textTransform={'capitalize'}>Value</Td>
                                            <Td  textTransform={'capitalize'}>Type</Td>
                                            <Td  textTransform={'capitalize'}>Data Type</Td>
                                            <Td  textTransform={'capitalize'}>Actions</Td>

                                        </Tr>
                                    </Thead>
                                    <Tbody>

                                        {
                                            systemParameterList.map((each: any, index) => (
                                                <Tr key={index} bg="white" >
                                                    <Td>
                                                        <Text fontSize={'14px'}>{each.name}</Text>
                                                    </Td>

                                                    <Td>
                                                        <Text fontSize={'14px'} >{each.value}</Text>
                                                    </Td>
                                                    <Td>
                                                        <Text fontSize={'14px'} >{each.type}</Text>
                                                    </Td>

                                                    <Td>
                                                        <Text fontSize={'14px'} >{each.dataType}</Text>
                                                    </Td>
                                                    <Td>
                                                        <HStack spacing={'20px'}>
                                                            <Center onClick={() => { openEditParam(each) }}>
                                                                <BiEditAlt  fontSize={'24px'}/>
                                                            </Center>
                                                            <Center onClick={() => { openDeleteParam(each.name) }}>
                                                                <MdDelete fontSize={'24px'} color='red' />
                                                            </Center>

                                                        </HStack>
                                                    </Td>

                                                </Tr>
                                            ))
                                        }

                                    </Tbody>
                                </Table>
                            </TableContainer>

                        </Box>
                        <Flex alignContent={"flex-end"} justifyContent={'flex-end'} mt={4}>
                            <Pagination
                                pagesCount={pagesCount}
                                currentPage={currentPage}
                                onPageChange={setCurrentPage}
                            >
                                <PaginationContainer>
                                    <PaginationPrevious><BsArrowLeft /></PaginationPrevious>
                                    <PaginationPageGroup>
                                        {pages.map((page: number) => (
                                            <PaginationPage
                                                key={`pagination_page_${page}`}
                                                page={page}
                                            />
                                        ))}
                                    </PaginationPageGroup>
                                    <PaginationNext><BsArrowRight /></PaginationNext>
                                </PaginationContainer>
                            </Pagination>

                        </Flex>

                        {isSystemParam && (
                            <Portal>
                                {" "}
                                <CreateSystemParameter
                                    isOpenSystemParam={isSystemParam}
                                    onClosed={setisSystemParam}
                                    isSystemParam={isSystemParam}
                                    reload={reloadData}
                                />{" "}
                            </Portal>
                        )}
                        {isEditParam && (
                            <Portal>
                                {" "}
                                <EditSystemParameter
                                    isOpenEditParam={isEditParam}
                                    onClosed={setIsEditParam}
                                    isEditParam={isEditParam}
                                    reload={reloadData}
                                    parameter={parameter}
                                />{" "}
                            </Portal>
                        )}


                        <Portal>
                            <AlertDialog
                                isOpen={isOpenAlert}
                                leastDestructiveRef={cancelRef}
                                onClose={onCloseAlert}
                            >
                                <AlertDialogOverlay>
                                    <AlertDialogContent>
                                        <AlertDialogHeader fontSize="md" fontWeight="semibold">
                                            Delete Parameter
                                        </AlertDialogHeader>

                                        <AlertDialogBody fontSize={15}>
                                            Are you sure? You can't undo this action afterwards.
                                        </AlertDialogBody>

                                        <AlertDialogFooter>
                                            <Button size={"sm"} ref={cancelRef} onClick={onCloseAlert}>
                                                Cancel
                                            </Button>
                                            <Button
                                                size={"sm"}
                                                colorScheme="red"
                                                onClick={deleteParameter}
                                                ml={3}
                                            >
                                                Delete
                                            </Button>
                                        </AlertDialogFooter>
                                    </AlertDialogContent>
                                </AlertDialogOverlay>
                            </AlertDialog>
                        </Portal>
                    </Box>
            }
        </>
    )
}

export default SystemParameter;