import { createBrowserRouter, RouterProvider } from "react-router-dom";
import './App.css';
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import AdminRoutes from "./pages/routes";
import ForgotPassword from "./pages/auth/ForgotPassword";
import EmailSent from "./pages/auth/EmailSent";
import SetPassword from "./pages/auth/SetPassword";
import { AdminContainer } from "./components/admin/AdminContainer";
import AdminDashboard from "./pages/Dashboard";
import AccountsWallet from "./pages/wallets/AccountsWallet";
import Accounts from "./pages/accounts/AdminAccounts";
import Transactions from "./pages/transactions/Transactions";
import Users from "./pages/users/Users";
import Agents from "./pages/agents/Agents";
import Roles from "./pages/roles/Roles";
import AuditLogs from "./pages/AuditTrail";
import AdminProfile from "./pages/users/Profile";
import AgentCustomer from "./pages/agents/AnAgentCustomers";
import AccountsBoard from "./pages/accounts/AccountBoard";
import AdminTransactions from "./pages/transactions/AdminTransactions";
import ChangePassword from "./pages/users/ChangePassword";
import SetPhoneNumber from "./pages/auth/SetPhoneNumber";
import VerifyPhoneNumber from "./pages/auth/VerifyPhoneNumber";
import CreateWallet from "./pages/wallets/CreateWallet";
import CompleteProfile from "./pages/auth/CompleteProfile";
import SetPin from "./pages/users/SetPin";
import ChangePin from "./pages/users/ChangePin";
import SystemParameter from "./pages/SystemParameter";
import MyCustomers from "./pages/agents/MyCustomers";
import AccountTransactions from "./pages/transactions/AccountTransaction";
import MessagesAdmin from "./pages/users/Messages";
import AddAccount from "./pages/accounts/AddAccount";
import SavingsTarget from "./pages/savings/SavingsTarget";
import Savings from "./pages/savings/Savings";
import CustomerSavings from "./pages/agents/CustomerSaving";


const router = createBrowserRouter([
  {
    path: AdminRoutes.Login,
    element: <Login />,
  },
  {
    path: AdminRoutes.Register,
    element: <Register />,
  },
  {
    path: AdminRoutes.forgotPassword,
    element: <ForgotPassword />,
  },
  {
    path: AdminRoutes.emailSent,
    element: <EmailSent />,
  },
  {
    path: AdminRoutes.setPassword,
    element: <SetPassword />,
  },
  {
    path: AdminRoutes.newPhone,
    element: <SetPhoneNumber />,
  },
  {
    path: AdminRoutes.verifyPhone,
    element: <VerifyPhoneNumber />,
  },
  {
    path: AdminRoutes.CompleteProfile,
    element: <CompleteProfile />,
  },
  {
    path: '/callnpay/admin',
    element: <AdminContainer />,

    children: [
      {
        path: AdminRoutes.portal.dashboard,
        element: <AdminDashboard />,
      },
      {
        path: AdminRoutes.portal.messages,
        element: <MessagesAdmin />,
      },
      {
        path: AdminRoutes.portal.users,
        element: <Users />,
      },
      {
        path: AdminRoutes.portal.wallet,
        element: <AccountsWallet />,
      },
      {
        path: AdminRoutes.portal.createWallet,
        element: <CreateWallet />,
      },
      {
        path: AdminRoutes.portal.agents,
        element: <Agents />,
      },
      {
        path: AdminRoutes.portal.agentCustomer,
        element: <AgentCustomer />,
      },
      {
        path: AdminRoutes.portal.accountBoard,
        element: <AccountsBoard />,
      },
      {
        path: AdminRoutes.portal.addAccount,
        element: <AddAccount />,
      },
      {
        path: AdminRoutes.portal.roles,
        element: <Roles />,
      },
      {
        path: AdminRoutes.portal.aduitLogs,
        element: <AuditLogs />,
      },
      {
        path: AdminRoutes.portal.accounts,
        element: <Accounts />,
      },
      {
        path: AdminRoutes.portal.accountTransaction,
        element: <AccountTransactions />,
      },
      {
        path: AdminRoutes.portal.transactions,
        element: <Transactions />,
      },
      {
        path: AdminRoutes.portal.customers,
        element: <MyCustomers />,
      },
      {
        path: AdminRoutes.portal.adminTransactions,
        element: <AdminTransactions />,
      },
      {
        path: AdminRoutes.portal.savings,
        element: <Savings />,
      },
      {
        path: AdminRoutes.portal.customerSavings,
        element: <CustomerSavings />,
      },
      {
        path: AdminRoutes.portal.savingTarget,
        element: <SavingsTarget />,
      },
      {
        path: AdminRoutes.portal.profile,
        element: <AdminProfile />,
      },
      {
        path: AdminRoutes.portal.changePassword,
        element: <ChangePassword />,
      },
      {
        path: AdminRoutes.portal.changePin,
        element: <ChangePin />,
      },
      {
        path: AdminRoutes.portal.setPin,
        element: <SetPin />,
      },
      {
        path: AdminRoutes.portal.systemParameter,
        element: <SystemParameter />,
      },
    
    ],
  },
]);
function App() {
  return <RouterProvider router={router} />;
}

export default App;
