import deleteService from "../crud-requests/deleteService";
import getService from "../crud-requests/getService";
import postService from "../crud-requests/postService";
import putService from "../crud-requests/putService";



const CONFIG_API_URL = "https://callnpayapi20230120085359.azurewebsites.net/api/";

class UserService {
  async createAgent(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}identity/user/createagent`,
      data
    );
    return response.data;
  }
  async createCustomer(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}identity/user/createcustomer`,
      data
    );
    return response.data;
  }
  async createOfflineCustomer(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}identity/user/createofflinecustomer`,
      data
    );
    return response.data;
  }
  ///api/identity/user/createofflinecustomer
  async toggleActivation(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}identity/user/toggleactivation`,
      data
    );
    return response.data;
  }
  ///api/identity/user/toggleactivation
  async getUserByName(username: string) {
    const response = await getService.get(
      `${CONFIG_API_URL}identity/user/getuserbyname${username}`,

    );
    return response.data;
  }
  async getUsers() {
    const response = await getService.get(
      `${CONFIG_API_URL}identity/user/getusers`,

    );
    return response.data;
  }
  //userNamePattern
  ///api/identity/user/getusersbyusername
  async getUsersByUserName(name: string) {
    const response = await getService.get(
      `${CONFIG_API_URL}identity/user/getusersbyusername${name}`,

    );
    return response.data;
  }
  async getActiveUsers(url: string) {
    const response = await getService.get(
      `${CONFIG_API_URL}identity/user/getactiveusers${url}`,

    );
    return response.data;
  }
  async getMessageCount() {
    const response = await getService.get(
      `${CONFIG_API_URL}identity/user/getunreadmessagecount`,

    );
    return response.data;
  }
  async getMessages(url: string) {
    const response = await getService.get(
      `${CONFIG_API_URL}identity/user/getinappmessages${url}`,

    );
    return response.data;
  }

  async getUnConfirmedUsers(url: string) {
    const response = await getService.get(
      `${CONFIG_API_URL}identity/user/getunconfirmedusers${url}`,

    );
    return response.data;
  }
  async getDeactivatedUsers(url: string) {
    const response = await getService.get(
      `${CONFIG_API_URL}identity/user/getdeactivatedusers${url}`,

    );
    return response.data;
  }
  async getOfflineUsers(url: string) {
    const response = await getService.get(
      `${CONFIG_API_URL}identity/user/getofflineusers${url}`,

    );
    return response.data;
  }
  async getUserCountries() {
    const response = await getService.get(
      `${CONFIG_API_URL}identity/user/getcountries`,

    );
    return response.data;
  }
  async getProfilePhoto() {
    const response = await getService.get(
      `${CONFIG_API_URL}identity/user/getprofilephoto`,

    );
    return response.data;
  }
  async getProductsToSell(name: string) {
    const response = await getService.get(
      `${CONFIG_API_URL}identity/user/getproductstosell${name}`,

    );
    return response.data;
  }
  async getOwnProductsToSell() {
    const response = await getService.get(
      `${CONFIG_API_URL}identity/user/getownproductstosell`,

    );
    return response.data;
  }
  async getUserStates() {
    const response = await getService.get(
      `${CONFIG_API_URL}identity/user/getstates`,

    );
    return response.data;
  }
  async updateUser(data: any) {
    const response = await putService.put(
      `${CONFIG_API_URL}identity/user/updateuser`,
      data
    );
    return response.data;
  }
  async updateUserProductToSell(data: any) {
    const response = await putService.put(
      `${CONFIG_API_URL}identity/user/updateproductstosell`,
      data
    );
    return response.data;
  }
  async updateUserRoles(data: any) {
    const response = await putService.put(
      `${CONFIG_API_URL}identity/user/updateuserroles`,
      data
    );
    return response.data;
  }
  async confirmUser(data: any) {
    const response = await putService.put(
      `${CONFIG_API_URL}identity/user/confirmuser`,
      data
    );
    return response.data;
  }
  async deleteUser(username: any) {
    const response = await deleteService.delete(
      `${CONFIG_API_URL}identity/user/deleteuser/${username}`,
    );
    return response.data;
  }
  async deleteUnconfirmedUser(username: any) {
    const response = await deleteService.delete(
      `${CONFIG_API_URL}identity/user/deleteunconfirmeduser${username}`,
    );
    return response.data;
  }

  async verifyPin(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}identity/account/validatepin`, data
    );
    return response.data;
  }
  async refreshToken(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}identity/account/refreshtoken`, data
    );
    return response.data;
  }

  ///api/identity/account/validatepin

}

export default new UserService();