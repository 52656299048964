import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    Box,
    useToast,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Flex,
    Button,
    HStack,
    Textarea,
    Select,
    Portal,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { IRegRes } from "../../models/auth/registration.model";
import AdminRoutes from "../../pages/routes";
import { useNavigate } from "react-router-dom";
import systemparameterService from "../../services/systemparameter/systemparameter.service";

export const CreateSystemParameter: React.FC<any> = ({
    isOpenSystemParam,
    onClosed,
    isSystemParam = false,
    reload
}: {
    isSystemParam: boolean;
    isOpenSystemParam: any;
    onClosed: any;
    reload: any;
}) => {
    const toast = useToast();
    const cancelRef = useRef().current;
    const navigate = useNavigate()

    useEffect(() => {

    }, []);
    const formik = useFormik({
        validate: (values: any) => {
            const errors: any = {};
            if (!values.name) {
                errors.name = "Enter name";
            }
            if (!values.value) {
                errors.value = "enter value";
            }
           
            return errors;
        },
        initialValues: {
            name: "",
            value: "",
            type: "",
            dataType: "",

        },
        onSubmit: async (values) => {
            try {
                const new_param: any = {
                    name: values.name,
                    value: values.value.toString(),
                    type: 1,
                    dataType: 1,

                }
                const create_param: IRegRes = await systemparameterService.createParameter(new_param)
                console.log(create_param.succeeded)
                if (create_param.succeeded) {
                    toast({
                        title: "System Parameter",
                        description: "parameter created successfully",
                        status: "success",
                    });
                    onClosed(!isSystemParam)
                    reload()
                    navigate(AdminRoutes.portal.systemParameter)
                } else {
                    toast({
                        title: "System Parameter",
                        description: `${create_param.messages.$values[0]}`,
                        status: "error",
                    });
                }
            } catch (error: any) {
                toast({
                    title: "System Parameter",
                    description: `${error}`,
                    status: "error",
                });
            }



        }
    });
   


    return (
        <>
            <Modal
                isOpen={isOpenSystemParam && isSystemParam}
                onClose={onClosed}
                scrollBehavior="outside"
                closeOnOverlayClick={true}
                size="lg"
            >
                <ModalOverlay />
                <ModalContent borderRadius={5} px={6}>
                    <ModalHeader>
                        {
                            <Text
                                fontSize={'18px'}
                                pb={3}
                                textTransform="capitalize"

                            >
                                System Parameter
                            </Text>
                        }
                    </ModalHeader>
                    <ModalCloseButton size="sm" />
                    <ModalBody pb={'30px'}>
                        <Box mt={2}>
                            <form onSubmit={formik.handleSubmit}>
                                <FormControl
                                    mb={'20px'}
                                    isInvalid={
                                        formik.errors.name && formik.touched.name
                                            ? true
                                            : false
                                    }
                                >
                                    <FormLabel
                                        fontWeight="normal"
                                        color="brand.3"
                                        fontSize={13}
                                    >
                                        Name
                                    </FormLabel>
                                    <Input
                                        type="text"
                                        id="name"
                                        name="name"
                                        placeholder="Enter name"
                                        onChange={formik.handleChange}
                                        fontSize={13}
                                    />
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.name}
                                    </FormErrorMessage>
                                </FormControl>

                                <FormControl
                                    mt={3}
                                    isInvalid={
                                        formik.errors.value && formik.touched.value ? true : false
                                    }
                                >
                                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                        Value
                                    </FormLabel>
                                    <Input
                                        type="number"
                                        id="value"
                                        name="value"
                                        placeholder="Enter value"
                                        onChange={formik.handleChange}
                                        fontSize={13}
                                    />
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.value}
                                    </FormErrorMessage>
                                </FormControl>
                               

                                <Flex mt={12} justifyContent={'space-between'} >


                                    <Button

                                        px={6}
                                        colorScheme="blue"
                                        fontSize={'14px'}
                                        variant={'outline'}
                                        ref={cancelRef}
                                        width={'49%'}
                                        onClick={() => onClosed()}
                                    >
                                        Cancel
                                    </Button>
                                    <Button

                                        type="submit"
                                        isLoading={formik.isSubmitting}
                                        color={"white"}
                                        width={'49%'}
                                        bg={"#011F78"}
                                        px={6}
                                        fontSize={'14px'}
                                    >
                                        Confirm
                                    </Button>


                                </Flex>
                            </form>
                        </Box>

                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
