import { Box, Flex, HStack, Button, Tab, TabList, TabPanel, TabPanels, Tabs, Portal, useDisclosure } from '@chakra-ui/react'

import { useNavigate } from 'react-router-dom';
import ActiveUsers from '../../components/users/ActiveUsers';
import DeactivatedUsers from '../../components/users/DeactivatedUsers';
import OfflineUsers from '../../components/users/OfflineUsers';
import UnconfirmedUsers from '../../components/users/UnconfirmedUsers';
import { useState } from 'react';
import { CreateAgentModal } from '../../components/agent/CreateAgentModal';

const Users = () => {
    let navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isAgent, setisAgent] = useState(false);
    const [reload, setReload] = useState(false);
    const openCreateAgentModal = () => {
        setisAgent(true);
        onOpen();
    };
    const reloadData = () => {
        setReload(!reload);
    };
    return (
        <>
            <Box >
                <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    py="26px"
                >
                    <Box color="#2B2B2B" fontWeight="800" fontSize="27px">
                        Users
                    </Box>
                    <HStack>
                        <Button
                            cursor="pointer"
                            color={"white"}
                            bg={"#011F78"}
                            fontSize={'13px'}
                            onClick={() => { openCreateAgentModal() }}
                        >
                            Create Agent
                        </Button>
                    </HStack>
                </Flex>
                <Tabs colorScheme='blue'>
                    <TabList>
                        <Tab>Active Users</Tab>
                        <Tab>Deactivated Users</Tab>
                        <Tab>Offline Users</Tab>
                        <Tab>Unconfirmed Users</Tab>

                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <ActiveUsers />
                        </TabPanel>
                        <TabPanel>
                            <DeactivatedUsers />
                        </TabPanel>
                        <TabPanel>
                            <OfflineUsers />
                        </TabPanel>
                        <TabPanel>
                            <UnconfirmedUsers />
                        </TabPanel>
                    </TabPanels>
                </Tabs>

                {isAgent && (
                    <Portal>
                        {" "}
                        <CreateAgentModal
                            isOpenAgent={isOpen}
                            onClosed={onClose}
                            isAgent={isAgent}
                            reload={reloadData}
                        />{" "}
                    </Portal>
                )}
            </Box>
        </>
    )
}

export default Users;