import {
  Box,
  Flex,
  HStack,
  Button,
  InputGroup,
  Input,
  InputRightElement,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Avatar,
  Text,
  MenuItem,
  Menu,
  MenuButton,
  MenuList,
  Center,
  Select,
  Portal,
} from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";
import { RiSearch2Line } from "react-icons/ri";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

import { useState, useEffect } from "react";
import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
  usePagination,
} from "@ajna/pagination";
import Loading from "../../components/Loading";
import { Ipage } from "../../models/audit/auditlog.model";
import savingsService from "../../services/savings/savings.service";
import { CreateSavingTargetModal } from "../../components/savings/CreateSavingTargetModal";

const SavingsTarget = () => {
  let navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [query, setQuery] = useState("");
  const [page, setPage] = useState<Ipage>({
    page_total: 0,
    total: 0,
    page_count: 0,
  });
  const [isSavingTarget, setisSavingTarget] = useState(false);
  const [savingTargetList, setsavingTargetList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
    pagesCount: page.page_count,
    initialState: { currentPage: 1, pageSize: 100 },
    total: page.total,
  });

  useEffect(() => {
    //let url = `?pageNumber=${currentPage}&pageSize=${pageSize}&searchString=${query}`;
    savingsService
      .getSavingTargets()
      .then((data) => {
        const audit: any = data;
        setPage({
          page_total: audit.pageSize,
          total: audit.totalCount,
          page_count: audit.totalPages,
        });
        setsavingTargetList(audit.data.$values);
        setIsLoading(false);
      })
      .catch((err) => err);
  }, [currentPage, query, reload, pageSize]);



  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search.length > 0) {
        setQuery(search);
      } else {
        setQuery("");
      }
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const changePageSize = (e: any) => {
    setPageSize(e.target.value);
  };

  const openCreateSavingTarget =  () => {
    setisSavingTarget(true);
};

const reloadData = () => {
    setReload(!reload);
};
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Box>
          <Flex justifyContent="space-between" alignItems="center" py="26px">
            <Box color="#2B2B2B" fontWeight="800" fontSize="27px">
             Savings Target
            </Box>
            <Button
              cursor="pointer"
              color={"white"}
              bg={"#011F78"}       
              fontSize={"13px"}
              onClick={() => {openCreateSavingTarget()}}
            >
              Create Saving Target
            </Button>
          </Flex>
          <Flex py="15px" justifyContent="">
            <InputGroup bg="white" width="30%" mr="20px">
              <Input
                height="40px"
                placeholder="Search input here"
                onChange={(e) => setSearch(e.target.value)}
                fontSize={12}
                size="sm"
              />
              <InputRightElement
                pointerEvents="none"
                children={<RiSearch2Line color="green" size="0.9rem" />}
              />
            </InputGroup>
          </Flex>
          <Box width={"100%"}>
            <TableContainer>
              <Table size={"sm"}>
                <Thead py={"15px"}>
                  <Tr bg="#3778D9" color={"white"} py={"15px"}>
                    {" "}
                    <Td py={"15px"} textTransform={"capitalize"}>
                      S/N
                    </Td>
                    <Td textTransform={"capitalize"}>Name</Td>
                    <Td textTransform={"capitalize"}>Description</Td>
               </Tr>
                </Thead>
                <Tbody>
                  {savingTargetList.map((each, index) => (
                    <Tr key={index} bg="white">
                         <Td>
                        <Text fontSize={"14px"}>{index+1}</Text>
                      </Td>
                      <Td>
                        <Text fontSize={"14px"}>{each.name}</Text>
                      </Td>

                      <Td>
                        <Text fontSize={"14px"}>{each.description}</Text>
                      </Td>
                    
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
          {isSavingTarget && (
                <Portal>
                    {" "}
                    <CreateSavingTargetModal
                        isOpenSavingTarget={isSavingTarget}
                        onClosed={setisSavingTarget}
                        isSavingTarget={isSavingTarget}
                        reload={reloadData}
                    />{" "}
                </Portal>
            )}
      
        </Box>
      )}
    </>
  );
};

export default SavingsTarget;
