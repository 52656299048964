import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Box,
  useToast,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Flex,
  Button,
  HStack,
  Textarea,
  Image,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useRef, useState, useEffect } from "react";
import { ITransactionValue } from "../../models/transactions/transactions.model";
import { NumericFormat } from "react-number-format";
import { getFullDate, getTimeStamp } from "../../utils/getDate";
import ReactToPrint from "react-to-print";
import logo from "../../img/callNpayLogo.png";
import Loading from "../Loading";

export const ViewTransactionModal: React.FC<any> = ({
  isOpenViewTransaction,
  onClosed,
  isViewTransaction = false,
  reload,
  transaction,
}: {
  isViewTransaction: boolean;
  isOpenViewTransaction: any;
  onClosed: any;
  reload: any;
  transaction: ITransactionValue;
}) => {
  const toast = useToast();
  const cancelRef = useRef().current;
  const [transactionView, settransactionView] = useState<ITransactionValue>();
  console.log(transactionView?.$id, "transactiom id");
  const [isLoading, setIsLoading] = useState(true);
  const componentRef = useRef<any>();
  useEffect(() => {
    console.log(transaction, "transaction modal");
    if (transaction) {
      settransactionView(transaction);
      setIsLoading(false);
    }
  }, [transactionView?.$id]);
  const cloaseModal = () => {
    onClosed(!isOpenViewTransaction);
    settransactionView(undefined);
  };
  return (
    <>
      <Modal
        isOpen={isOpenViewTransaction && isViewTransaction}
        onClose={cloaseModal}
        scrollBehavior="inside"
        closeOnOverlayClick={true}
        size="xl"
        isCentered
      >
        <ModalOverlay />
        <ModalContent borderRadius={5}>
          <ModalHeader></ModalHeader>
          <ModalCloseButton size="sm" />
          <ModalBody pb={"30px"}>
            {isLoading ? (
              <Loading />
            ) : (
              <Box>
                <Box pl={"50px"} ref={componentRef} mt={2}>
                  <Box as="span" height={"30px"} width={"70px"}>
                    <Image src={logo} height="30px" />
                  </Box>
                  <Box mt={"20px"} mb={"20px"}>
                    <Text
                      fontSize={"16x"}
                      color={"black"}
                      textAlign={"center"}
                      fontWeight={"500"}
                    >
                      Transaction Receipt
                    </Text>
                    <Text
                      fontSize={"20px"}
                      color="#011F78"
                      textAlign={"center"}
                      fontWeight={"500"}
                    >
                      <NumericFormat
                        value={transactionView && transactionView.amount}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₦"}
                      />
                    </Text>
                    <Text
                      fontSize={"14px"}
                      color={"#929292"}
                      textAlign={"center"}
                      borderRadius={"15px"}
                      px={"12px"}
                    >
                      {transactionView && transactionView.statusId == 0
                        ? "Requested"
                        : transactionView && transactionView.statusId == 1
                        ? "Processing"
                        : transactionView && transactionView.statusId == 2
                        ? "Routed"
                        : transactionView && transactionView.statusId == 3
                        ? "Success"
                        : transactionView && transactionView.statusId == 4
                        ? "Failed"
                        : transactionView && transactionView.statusId == 5
                        ? "Rejected"
                        : transactionView && transactionView.statusId == 6
                        ? "Cancelled"
                        : transactionView && transactionView.statusId == 7
                        ? "Validated"
                        : transactionView && transactionView.statusId == 8
                        ? "Invalid"
                        : "Validation Required"}
                    </Text>
                  </Box>
                  <Flex
                    px={"5px"}
                    py={"12px"}
                    borderBottom={"1px dotted"}
                    borderColor={"lightgray"}
                    justifyContent={"space-between"}
                  >
                    <Text
                      fontSize={"14px"}
                      color="#929292"
                      textAlign={"center"}
                      fontWeight={"500"}
                    >
                      Transaction Type
                    </Text>
                    <Text fontSize={"14px"} fontWeight={"500"}>
                      {transactionView && transactionView.typeId == 0
                        ? "Fund Movement"
                        : transactionView && transactionView.typeId == 1
                        ? "Fund Transfer"
                        : transactionView && transactionView.typeId == 2
                        ? "Topup"
                        : "Payment"}
                    </Text>
                  </Flex>
                  <Flex
                    px={"5px"}
                    py={"12px"}
                    borderBottom={"1px dotted"}
                    borderColor={"lightgray"}
                    justifyContent={"space-between"}
                  >
                    <Text
                      fontSize={"14px"}
                      color="#929292"
                      textAlign={"center"}
                      fontWeight={"500"}
                    >
                      Transaction Amount
                    </Text>
                    <Text
                      fontSize={"14px"}
                      color="black"
                      textAlign={"center"}
                      fontWeight={"500"}
                    >
                      <NumericFormat
                        value={transactionView && transactionView.amount}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₦"}
                      />
                    </Text>
                  </Flex>
                  <Flex
                    px={"5px"}
                    py={"12px"}
                    borderBottom={"1px dotted"}
                    borderColor={"lightgray"}
                    justifyContent={"space-between"}
                  >
                    <Text
                      fontSize={"14px"}
                      color="#929292"
                      textAlign={"center"}
                      fontWeight={"500"}
                    >
                      Sender
                    </Text>
                    <Text
                      fontSize={"14px"}
                      color={"black"}
                      textAlign={"center"}
                      fontWeight={"500"}
                    >
                      {transactionView && transactionView.sourceOwner}
                    </Text>
                  </Flex>
                  <Flex
                    px={"5px"}
                    py={"12px"}
                    borderBottom={"1px dotted"}
                    borderColor={"lightgray"}
                    justifyContent={"space-between"}
                  >
                    <Text
                      fontSize={"14px"}
                      color="#929292"
                      textAlign={"center"}
                      fontWeight={"500"}
                    >
                      Sender Account Type
                    </Text>
                    <Text
                      fontSize={"14px"}
                      color={"black"}
                      textAlign={"center"}
                      fontWeight={"500"}
                    >
                      {transactionView && transactionView.sourceProductType}
                    </Text>
                  </Flex>
                  <Flex
                    px={"5px"}
                    py={"12px"}
                    borderBottom={"1px dotted"}
                    borderColor={"lightgray"}
                    justifyContent={"space-between"}
                  >
                    <Text
                      fontSize={"14px"}
                      color="#929292"
                      textAlign={"center"}
                      fontWeight={"500"}
                    >
                      Recipient
                    </Text>
                    <Text
                      fontSize={"14px"}
                      color={"black"}
                      textAlign={"center"}
                      fontWeight={"500"}
                    >
                      {transactionView && transactionView.destinationOwner}
                    </Text>
                  </Flex>
                  <Flex
                    px={"5px"}
                    py={"12px"}
                    borderBottom={"1px dotted"}
                    borderColor={"lightgray"}
                    justifyContent={"space-between"}
                  >
                    <Text
                      fontSize={"14px"}
                      color="#929292"
                      textAlign={"center"}
                      fontWeight={"500"}
                    >
                      Recipient Account
                    </Text>
                    <Text
                      fontSize={"14px"}
                      color={"black"}
                      textAlign={"center"}
                      fontWeight={"500"}
                    >
                      {transactionView && transactionView.destination}
                    </Text>
                  </Flex>
                  <Flex
                    px={"5px"}
                    py={"12px"}
                    borderBottom={"1px dotted"}
                    borderColor={"lightgray"}
                    justifyContent={"space-between"}
                  >
                    <Text
                      fontSize={"14px"}
                      color="#929292"
                      textAlign={"center"}
                      fontWeight={"500"}
                    >
                      Recipient Account Type
                    </Text>
                    <Text
                      fontSize={"14px"}
                      color={"black"}
                      textAlign={"center"}
                      fontWeight={"500"}
                    >
                      {transactionView &&
                        transactionView.destinationProductType}
                    </Text>
                  </Flex>
                  <Flex
                    px={"5px"}
                    py={"12px"}
                    borderBottom={"1px dotted"}
                    borderColor={"lightgray"}
                    justifyContent={"space-between"}
                  >
                    <Text
                      fontSize={"14px"}
                      color="#929292"
                      textAlign={"center"}
                      fontWeight={"500"}
                    >
                      Remark
                    </Text>
                    <Text
                      fontSize={"14px"}
                      color={"black"}
                      textAlign={"center"}
                      fontWeight={"500"}
                    >
                      {transactionView && transactionView.description}
                    </Text>
                  </Flex>
                  <Flex
                    px={"5px"}
                    py={"12px"}
                    borderBottom={"1px dotted"}
                    borderColor={"lightgray"}
                    justifyContent={"space-between"}
                  >
                    <Text
                      fontSize={"14px"}
                      color="#929292"
                      textAlign={"center"}
                      fontWeight={"500"}
                    >
                      Date{" "}
                    </Text>
                    <Text
                      fontSize={"14px"}
                      color={"black"}
                      textAlign={"center"}
                      fontWeight={"500"}
                    >
                      {getFullDate(
                        transactionView && transactionView.finishTimestamp
                      )}
                    </Text>
                  </Flex>
                  <Flex
                    px={"5px"}
                    py={"12px"}
                    borderBottom={"1px dotted"}
                    borderColor={"lightgray"}
                    justifyContent={"space-between"}
                  >
                    <Text
                      fontSize={"14px"}
                      color="#929292"
                      textAlign={"center"}
                      fontWeight={"500"}
                    >
                      Time{" "}
                    </Text>
                    <Text
                      fontSize={"14px"}
                      color={"black"}
                      textAlign={"center"}
                      fontWeight={"500"}
                    >
                      {getTimeStamp(
                        transactionView && transactionView.finishTimestamp
                      )}
                    </Text>
                  </Flex>
                  <Box bg={"white"} px={"10px"} py={"20px"}>
                    <Flex mb={"10px"} justify={"space-between"}>
                      <Text
                        textAlign={"left"}
                        color={"black"}
                        fontSize={"10px"}
                        fontWeight={"400"}
                        width={"48%"}
                      >
                        If you did not login to your CallanPay internet account,
                        kindly contact Pillarsalt Integrated System (our 24/7
                        Customer Interactive Centre) on +234 923929482 or send
                        an email to info@callanpay.com.
                      </Text>
                      <Text
                        fontSize={"10px"}
                        color="black"
                        textAlign={"left"}
                        fontWeight={"400"}
                        width={"48%"}
                      >
                        Please Keep your card and Pin information secure. Do not
                        respond to emails or calls requesting for your card/PIN
                        details.Thank you for using our service.
                      </Text>
                    </Flex>
                    <Flex
                      justifyContent={"space-between"}
                      borderTop={"2px solid"}
                      borderColor={"#011F78"}
                    >
                      <Text
                        fontSize={"10px"}
                        color="black"
                        textAlign={"center"}
                        fontWeight={"500"}
                      >
                        Powered by Pillarsalt{" "}
                      </Text>
                      <Text
                        fontSize={"10px"}
                        color="black"
                        textAlign={"center"}
                        fontWeight={"500"}
                      >
                        {" "}
                        www.callanpay.com
                      </Text>
                    </Flex>
                  </Box>
                </Box>
                <Flex mt={12} justifyContent={"space-between"}>
                  <Button
                    px={6}
                    colorScheme="blue"
                    fontSize={"14px"}
                    variant={"outline"}
                    ref={cancelRef}
                    width={"49%"}
                    onClick={() => cloaseModal()}
                  >
                    Cancel
                  </Button>

                  <ReactToPrint
                    trigger={() => (
                      <Button
                        type="button"
                        color={"white"}
                        width={"49%"}
                        bg={"#011F78"}
                        px={6}
                        fontSize={"14px"}
                      >
                        Download
                      </Button>
                    )}
                    content={() => componentRef.current}
                  />
                </Flex>
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
