import {
    Box,
    Button,
    Center,
    Flex,
    FormControl,
    FormErrorMessage,
    Text,
    FormLabel,
    Input,
    InputGroup,
    InputLeftElement,
    useToast,
    Link,
    HStack,
    Image,
    Stack,
} from "@chakra-ui/react";
import ReCAPTCHA from "react-google-recaptcha"
import { useFormik } from "formik";
import { MdLock, MdMail } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import AuthLayout from "./AuthLayout";
import { useEffect, useRef, useState } from "react";
import AdminRoutes from "../routes";
import authService from "../../services/auth/auth.service";

const ForgotPassword = () => {
    const route = useNavigate();
    const captchaRef = useRef(null)
    const toast = useToast();
    const [site_key, setSitekey] = useState<any>()
    useEffect(() => {

        let sitekey = process.env.REACT_APP_SITE_KEY
        console.log(sitekey,'key-key')
        if (sitekey) setSitekey(sitekey)
    }, []);
    const validate = (values: any) => {
        const errors: any = {};
        if (!values.email) {
            errors.email = "Email is required";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = "Invalid email address";
        }


        return errors;
    };

    function onChange(value: any) {
        console.log("Captcha value:", value);
    }
    const formik = useFormik({
        initialValues: { email: "",  },
        validate,
        onSubmit: async (values) => {
            try {
                const data = {     
                    email: values.email,        
                    clientForgotUrl: "https://callnpayblazorui20230120142145.azurewebsites.net/ForgotEmail"
                }
                const forgot_password  : any= await authService.forgot_password(data) 
                console.log(forgot_password.succeeded)
                if(forgot_password.succeeded){
                    toast({
                        title: "Forgot Password",
                        description: `forgot password successful`,
                        status: "success",
                      });
                      route(AdminRoutes.emailSent)
                }else{
                    toast({
                        title: "Forgot Password",
                        description: `${forgot_password.messages.$values[0]}`,
                        status: "error",
                      });
                }
            } catch (error: any) {
                toast({
                    title: "Forgot Password",
                    description: `${error}`,
                    status: "error",
                  });
            }
           
           
        },
    });

    return (
        <>
            <AuthLayout>
                <Flex mx="auto" className="resizable_div" height="100vh">
                    <Box >
                        <Box
                            ml={["30px", "30px", "60px", "60px"]}
                            mr={["30px", "30px", "0px", "0px"]}
                            mt={'100px'}
                            mb="15px"
                        >


                            <Box mb={'40px'}>
                                <Text
                                    mb={'10px'}
                                    fontSize={["20px", "20px", "24px", "24px"]}
                                    fontWeight="700"
                                    color="#011F78"
                                >
                                    Forgot Passowrd
                                </Text>
                                <Text

                                    fontSize={["10px", "10px", "14px", "14px"]}
                                    fontWeight="400"
                                    color="#90979E"
                                >
                                    Enter email to recieve reset password link

                                </Text>
                            </Box>


                            <form onSubmit={formik.handleSubmit}>
                                <FormControl
                                    mb="20px"
                                    isInvalid={
                                        formik.errors.email && formik.touched.email ? true : false
                                    }
                                >
                                    <FormLabel color="#757575" fontSize={15}>
                                        Email
                                    </FormLabel>
                                    <InputGroup>
                                        <InputLeftElement
                                            color="brand.subtitle"
                                            pointerEvents="none"
                                            children={<MdMail />}
                                        />
                                        <Input
                                            width={["300px", "300px", "520px", "520px"]}
                                            type="email"
                                            id="email"
                                            name="email"
                                            placeholder="Enter email"
                                            onChange={formik.handleChange}
                                            fontSize={14}
                                        />
                                    </InputGroup>

                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.email}
                                    </FormErrorMessage>
                                </FormControl>


                                
                              
                                 <Flex mb={'20px'} justifyContent={'flex-end'}>
                                    <Box>
                                    <ReCAPTCHA
                                        sitekey={'6LfTZJMnAAAAAHF8EpPEuTX5IMMn2c7T06gCf7TG'}
                                        ref={captchaRef}
                                        onChange={onChange}
                                        size='normal'
                                    />
                             
                                    </Box>
                                 </Flex>


                                <Stack spacing="12px">
                                    <Button
                                        cursor="pointer"
                                        w="100%"
                                        color={"white"}
                                        bg={"#011F78"}
                                        type="submit"
                                        isLoading={formik.isSubmitting}
                                        fontSize={14}
                                    >
                                        Proceed
                                    </Button>
                                </Stack>
                            </form>
                        </Box>
                    </Box>
                </Flex>

            </AuthLayout>
        </>
    );
};

export default ForgotPassword;
