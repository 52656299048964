import {
    Container,
    Select,
    Flex,
    Button,
    Box,
    Text,
    HStack,
    Center,
    Table,
    TableContainer,
    Tbody,
    Td,
    Thead,
    Tr,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
} from "@chakra-ui/react";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { RiEyeLine, RiSearch2Line } from "react-icons/ri";
import { Pagination, PaginationContainer, PaginationNext, PaginationPage, PaginationPageGroup, PaginationPrevious, usePagination } from "@ajna/pagination";
import usersService from "../../services/user/users.service";
import { Ipage } from "../../models/audit/auditlog.model";
import { IMessagesRes, IMessagesValue } from "../../models/messages/messages.model";
import { NumericFormat } from "react-number-format";
import { getFullDate } from "../../utils/getDate";

const MessagesAdmin = () => {
    const [search, setSearch] = useState("");
    const [query, setQuery] = useState("");
    const [page, setPage] = useState<Ipage>({ page_total: 0, total: 0, page_count: 0 });
    const [messages, setmessages] = useState<IMessagesValue[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pageSize, setPageSize] = useState(10);
    const [start_date, setstart_date] = useState("");
    const [reload, setReload] = useState(false);
    const [end_date, setend_date] = useState('');
    const {
        currentPage,
        setCurrentPage,
        pagesCount,
        pages
    } = usePagination({
        pagesCount: page.page_count,
        initialState: { currentPage: 1, pageSize: 100 },
        total: page.total
    });
    
    useEffect(() => {
        const url = `?pageNumber=${currentPage}&pageSize=${pageSize}&searchString=${query}&startDate=${start_date}&endDate=${end_date}`   
        usersService.getMessages(url)
            .then((data) => {
                const audit: IMessagesRes = data
                setPage({ page_total: audit.pageSize, total: audit.totalCount, page_count: audit.totalPages })
                setmessages(audit.data.$values);
                setIsLoading(false)
            })
            .catch((err) => err)

    }, [currentPage, query, reload, pageSize]);


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (search.length > 0) {
                setQuery(search);
            } else {
                setQuery("");
            }
        }, 700);

        return () => clearTimeout(delayDebounceFn)
    }, [search])

    const changePageSize = (e: any) => {
        setPageSize(e.target.value)
    }

    const dateChange = (event: ChangeEvent<HTMLInputElement>) => {
        let date = event.target.value.toLowerCase();
        setend_date(date);
        setReload(!reload);

    };
    const startdateChange = (event: ChangeEvent<HTMLInputElement>) => {
        let date = event.target.value.toLowerCase();
        setstart_date(date);


    };
    return (
        <>
            <Box minHeight="100vh" pb="30px" >
                <Container
                    width={{ lg: "80%", md: "80%", sm: "95%", base: "95%" }}
                    maxWidth={"84%"}
                    px={0}
                    right={0}
                    bg="F9F8F8"
                    mx="auto"
                >
                    <Text mb={'20px'} fontWeight="700" fontSize="22px" color="#1E1E1E">
                        Messages
                    </Text>
                    <Box
                        bg="white"
                        px="40px"
                        py="25px"
                        boxShadow="sm"
                        rounded="lg"
                        height="auto"
                        mb="30px"
                    >

                        <Flex py={'30px'} justifyContent={'center'} >
                            <Box >
                                <Box>
                                    Messages
                                </Box>
                            </Box>
                        </Flex>
                        <Box width={'100%'}>
                        <Flex py='15px' justifyContent=''>
                            <InputGroup  bg="white" width="30%" mr='20px'>
                                <Input height='40px' placeholder="Search messages here" onChange={e => setSearch(e.target.value)} fontSize={12} size="sm" />
                                <InputRightElement pointerEvents="none" children={<RiSearch2Line color="green" size="0.9rem" />} />
                            </InputGroup>
                            <HStack>
                                <FormLabel   fontSize={'12px'}> From:</FormLabel>

                                <Input
                                    width={"200px"}
                                    bg={"white"}
                                    type={"date"}
                                    fontSize={'12px'}
                                    onChange={(e) => startdateChange(e)}
                                    placeholder="from"
                                />
                            </HStack>
                            <HStack ml={'10px'}>
                                <FormLabel   fontSize={'12px'}> To:</FormLabel>
                                <Input
                                    width={"200px"}
                                    bg={"white"}
                                    type={"date"}
                                    fontSize={'12px'}
                                    onChange={(e) => dateChange(e)}
                                    placeholder="from"
                                />
                            </HStack>
                        </Flex>
                            <TableContainer>
                                <Table size={'sm'}>
                                    <Thead py={'15px'}>
                                        <Tr bg="white" py={'15px'} color={'#A1A1A1'}>
                                            <Td py={'15px'} color={'#A1A1A1'} textTransform={'capitalize'}>Subject</Td>
                                            <Td color={'#A1A1A1'} textTransform={'capitalize'} width={'120px'}>Recieved On</Td>

                                        </Tr>
                                    </Thead>

                                    <Tbody>
                                        {
                                            messages.length > 0 ? messages.map((message, index) => (
                                                <Tr key={index} bg="white" >
                                                     <Td>
                                                        <Text fontSize={'14px'}>{message.subject}</Text>
                                                    </Td>

                                                    <Td>
                                                        <Text fontSize={'14px'}>{getFullDate(message.createdOn)}</Text>
                                                    </Td>                                                                                                                                                                                     
                                                </Tr>

                                            )) : <Box>No data</Box>
                                        }
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </Box>
                        <Flex alignContent={"flex-end"} justifyContent={'flex-end'} mt={4}>

                            <HStack>
                                <Text fontWeight={400} fontSize={'13px'}>Rows per page :</Text>
                                <Select
                                    variant="outline"
                                    size="sm"
                                    width={'70px'}
                                    value={pageSize}
                                    onChange={(e) => { changePageSize(e) }}
                                    fontSize={12}
                                >
                                    <option value={10}>
                                        10
                                    </option>
                                    <option value={25}>
                                        25
                                    </option>
                                    <option value={50}>
                                        50
                                    </option>
                                    <option value={100}>
                                        100
                                    </option>
                                </Select>

                            </HStack>
                            <Pagination
                                pagesCount={pagesCount}
                                currentPage={currentPage}
                                onPageChange={setCurrentPage}
                            >
                                <PaginationContainer>
                                    <PaginationPrevious><BsArrowLeft /></PaginationPrevious>
                                    <PaginationPageGroup>
                                        {pages.map((page: number) => (
                                            <PaginationPage
                                                key={`pagination_page_${page}`}
                                                page={page}
                                            />
                                        ))}
                                    </PaginationPageGroup>
                                    <PaginationNext><BsArrowRight /></PaginationNext>
                                </PaginationContainer>
                            </Pagination>

                        </Flex>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default MessagesAdmin;
