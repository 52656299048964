import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    Box,
    useToast,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Flex,
    Button,
    HStack,
    Textarea,
    InputGroup,
    InputLeftElement,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useRef, useState } from "react";
import { MdLock } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ILoginRes } from "../../../models/auth/login.model";
import AdminRoutes from "../../../pages/routes";
import { getLocalUserProfile } from "../../../services/auth/auth.service";
import userProfileService from "../../../services/user/userProfile.service";


export const ChangePasswordModal: React.FC<any> = ({
    isOpenPassword,
    onClosed,
    isPassword = false,
    reload
}: {
    isPassword: boolean;
    isOpenPassword: any;
    onClosed: any;
    reload: any;
}) => {
    const toast = useToast();
    const cancelRef = useRef().current;
    const route = useNavigate();
    const validate = (values: any) => {
        const errors: any = {};
        if (!values.currentPassword) {
            errors.currentPassword = "currentPassword is required";
        }
        if (!values.password) {
            errors.password = "Password is required";
        }
        return errors;
    };

    const formik = useFormik({
        initialValues: { currentPassword: "", password: "", confirmPassword :"" },
        validate,
        onSubmit: async (values) => {
            try {
                const data = {
                    username :getLocalUserProfile()?.userName,
                    currentPassword: values.currentPassword,
                    newPassword: values.password,
                    confirmNewPassword : values.confirmPassword
                }

                const change_password: ILoginRes = await userProfileService.changePassword(data)

                if (change_password.succeeded) {
                    toast({
                        title: "Password",
                        description: `password changed successfully`,
                        status: "success",
                    });
                    route(AdminRoutes.portal.dashboard)
                } else {
                    toast({
                        title: "Password",
                        description: `${change_password.messages.$values[0]}`,
                        status: "error",
                    });
                }
            } catch (error: any) {
                toast({
                    title: "Password",
                    description: `${error}`,
                    status: "error",
                });
            }
        },
    });

    return (
        <>
            <Modal
                isOpen={isOpenPassword && isPassword}
                onClose={onClosed}
                scrollBehavior="outside"
                closeOnOverlayClick={true}
                size="lg"
            >
                <ModalOverlay />
                <ModalContent borderRadius={5} px={6}>
                    <ModalHeader>
                        {
                            <Text
                                fontSize={'18px'}
                                pb={3}
                                textTransform="capitalize">
                                Change Password
                            </Text>
                        }
                    </ModalHeader>
                    <ModalCloseButton size="sm" />
                    <ModalBody pb={'30px'}>
                        <Box mt={2}>
                        <form onSubmit={formik.handleSubmit}>
                                        <FormControl
                                            mb="20px"
                                            isInvalid={
                                                formik.errors.currentPassword && formik.touched.currentPassword
                                                    ? true
                                                    : false
                                            }
                                        >
                                            <FormLabel color="brand.subtitle" fontSize={15}>
                                                Current Password{" "}

                                            </FormLabel>
                                            <InputGroup>
                                                <InputLeftElement
                                                    color="brand.subtitle"
                                                    pointerEvents="none"
                                                    children={<MdLock />}
                                                />
                                                <Input
                                                    width={["100%", "100%", "520px", "520px"]}
                                                    type="text"
                                                    name="currentPassword"
                                                    id="currentPassword"
                                                    placeholder="Enter  password"
                                                    onChange={formik.handleChange}
                                                    fontSize={14}
                                                />
                                            </InputGroup>
                                            <FormErrorMessage fontSize={12}>
                                                {formik.errors.currentPassword}
                                            </FormErrorMessage>
                                        </FormControl>

                                        <FormControl
                                            mb="20px"
                                            isInvalid={
                                                formik.errors.password && formik.touched.password
                                                    ? true
                                                    : false
                                            }
                                        >
                                            <FormLabel color="brand.subtitle" fontSize={15}>
                                              New  Password{" "}

                                            </FormLabel>
                                            <InputGroup>
                                                <InputLeftElement
                                                    color="brand.subtitle"
                                                    pointerEvents="none"
                                                    children={<MdLock />}
                                                />
                                                <Input
                                                    width={["100%", "100%", "520px", "520px"]}
                                                    type="text"
                                                    name="password"
                                                    id="password"
                                                    placeholder="Enter  password"
                                                    onChange={formik.handleChange}
                                                    fontSize={14}
                                                />
                                            </InputGroup>
                                            <FormErrorMessage fontSize={12}>
                                                {formik.errors.password}
                                            </FormErrorMessage>
                                        </FormControl>

                                        <FormControl
                                            mb="20px"
                                            isInvalid={
                                                formik.errors.confirmPassword && formik.touched.confirmPassword
                                                    ? true
                                                    : false
                                            }
                                        >
                                            <FormLabel color="brand.subtitle" fontSize={15}>
                                              Confirm  Password{" "}

                                            </FormLabel>
                                            <InputGroup>
                                                <InputLeftElement
                                                    color="brand.subtitle"
                                                    pointerEvents="none"
                                                    children={<MdLock />}
                                                />
                                                <Input
                                                    width={["100%", "100%", "520px", "520px"]}
                                                    type="text"
                                                    name="confirmPassword"
                                                    id="confirmPassword"
                                                    placeholder="confirm  password"
                                                    onChange={formik.handleChange}
                                                    fontSize={14}
                                                />
                                            </InputGroup>
                                            <FormErrorMessage fontSize={12}>
                                                {formik.errors.password}
                                            </FormErrorMessage>
                                        </FormControl>
                                        <Flex mt={12} justifyContent={'space-between'} >


<Button

    px={6}
    colorScheme="blue"
    fontSize={'14px'}
    variant={'outline'}
    ref={cancelRef}
    width={'49%'}
    onClick={() => onClosed()}
>
    Cancel
</Button>
<Button

    type="submit"
    isLoading={formik.isSubmitting}
    color={"white"}
    width={'49%'}
    bg={"#011F78"}
    px={6}
    fontSize={'14px'}
>
    Confirm
</Button>


</Flex>
                                    </form>
                         
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
