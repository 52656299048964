import { Box, Flex, HStack, Button, InputGroup, Input, InputRightElement, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Avatar, Text, MenuItem, Menu, MenuButton, MenuList, Center, Select } from '@chakra-ui/react'

import { useNavigate } from 'react-router-dom';
import { RiSearch2Line } from 'react-icons/ri';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import auditlogsService from '../services/auditlogs/auditlogs.service';
import { useState, useEffect } from 'react';
import { Pagination, PaginationContainer, PaginationNext, PaginationPage, PaginationPageGroup, PaginationPrevious, usePagination } from "@ajna/pagination";
import { IAuditLogRes, Ipage, Value } from '../models/audit/auditlog.model';
import Loading from '../components/Loading';

const AuditLogs = () => {
    let navigate = useNavigate();
    const [search, setSearch] = useState("");
    const [query, setQuery] = useState("");
    const [page, setPage] = useState<Ipage>({ page_total: 0, total: 0, page_count: 0 });
    const [auditlogs, setauditlogs] = useState<Value[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [reload, setReload] = useState(false);
    const [pageSize, setPageSize] = useState(25);
    const {
        currentPage,
        setCurrentPage,
        pagesCount,
        pages
    } = usePagination({
        pagesCount: page.page_count,
        initialState: { currentPage: 1, pageSize: 100 },
        total: page.total
    });

    useEffect(() => {
        let url = `?pageNumber=${currentPage}&pageSize=${pageSize}&searchString=${query}`
        auditlogsService.auditLogs(url)
            .then((data) => {
                const audit: IAuditLogRes = data
                setPage({ page_total: audit.pageSize, total: audit.totalCount, page_count: audit.totalPages })
                setauditlogs(audit.data.$values);
                setIsLoading(false)
            })
            .catch((err) => err)

    }, [currentPage, query, reload, pageSize]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (search.length > 0) {
                setQuery(search);
            } else {
                setQuery("");
            }
        }, 700);

        return () => clearTimeout(delayDebounceFn)
    }, [search])

    const changePageSize = (e: any) => {
        setPageSize(e.target.value)
    }
    return (
        <>
            {
                isLoading ? <Loading /> :
                    <Box >
                        <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            py="26px"
                        >
                            <Box color="#2B2B2B" fontWeight="800" fontSize="27px">
                                Audit Logs
                            </Box>

                        </Flex>
                        <Flex py='15px' justifyContent=''>
                            <InputGroup bg="white" width="30%" mr='20px'>
                                <Input height='40px' placeholder="Search input here" onChange={e => setSearch(e.target.value)} fontSize={12} size="sm" />
                                <InputRightElement pointerEvents="none" children={<RiSearch2Line color="green" size="0.9rem" />} />
                            </InputGroup>

                        </Flex>
                        <Box width={'100%'}>
                            <TableContainer>
                                <Table size={'sm'}>
                                    <Thead py={'15px'}>
                                    <Tr bg="#3778D9" color={'white'} py={'15px'} >          <Td py={'15px'}  textTransform={'capitalize'}>Username</Td>
                                            <Td  textTransform={'capitalize'}>Date</Td>
                                            <Td  textTransform={'capitalize'}>Type</Td>
                                            <Td  textTransform={'capitalize'}>Old Value</Td>
                                            <Td  textTransform={'capitalize'}>New Value</Td>

                                        </Tr>
                                    </Thead>
                                    <Tbody>

                                        {
                                            auditlogs.map((each, index) => (
                                                <Tr key={index} bg="white" >
                                                    <Td>
                                                        <Text fontSize={'14px'}>{each.userName}</Text>
                                                    </Td>


                                                    <Td>
                                                        <Text fontSize={'14px'} >{each.dateTime}</Text>
                                                    </Td>
                                                    <Td>
                                                        <Text fontSize={'14px'} >{each.type}</Text>
                                                    </Td>

                                                    <Td>
                                                        <Text fontSize={'14px'} >{each.oldValues}</Text></Td>
                                                    <Td>
                                                        <Text fontSize={'14px'} >{each.newValues}</Text></Td>


                                                </Tr>
                                            ))
                                        }

                                    </Tbody>
                                </Table>
                            </TableContainer>

                        </Box>
                        <Flex alignContent={"flex-end"} justifyContent={'flex-end'} mt={4}>

                            <HStack>
                                <Text fontWeight={400} fontSize={'13px'}>Rows per page :</Text>
                                <Select
                                    variant="outline"
                                    size="sm"
                                    width={'70px'}
                                    value={pageSize}
                                    onChange={(e) => { changePageSize(e) }}
                                    fontSize={12}
                                >
                                    <option value={10}>
                                        10
                                    </option>
                                    <option value={25}>
                                        25
                                    </option>
                                    <option value={50}>
                                        50
                                    </option>
                                    <option value={100}>
                                        100
                                    </option>

                                </Select>

                            </HStack>
                            <Pagination
                                pagesCount={pagesCount}
                                currentPage={currentPage}
                                onPageChange={setCurrentPage}
                            >
                                <PaginationContainer>
                                    <PaginationPrevious><BsArrowLeft /></PaginationPrevious>
                                    <PaginationPageGroup>
                                        {pages.map((page: number) => (
                                            <PaginationPage
                                                key={`pagination_page_${page}`}
                                                page={page}
                                            />
                                        ))}
                                    </PaginationPageGroup>
                                    <PaginationNext><BsArrowRight /></PaginationNext>
                                </PaginationContainer>
                            </Pagination>

                        </Flex>
                    </Box>
            }
        </>
    )
}

export default AuditLogs;