import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    Box,
    useToast,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Flex,
    Button,
    HStack,
    Textarea,
    Select,
    Portal,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { IHandleTransaction } from "../../models/transactions/handleTransaction";
import { IAccountsValue, IAccountsRes } from "../../models/accounts/accounts.model";
import accountsService from "../../services/accounts/accounts.service";
import agentService from "../../services/agent/agent.service";
import NumericInput from 'react-numeric-input';
import { NumericFormat } from "react-number-format";
import { ICustomerAgentRes, ICustomerAgentValue } from "../../models/agents/customerAgents.model";
import { ICustomersWithAccountRes, ICustomersWithAccountValue } from "../../models/audit/customerwithaccount.model";
import { ISearchUsersData } from "../../models/users/searchUsers.model";
import savingsService from "../../services/savings/savings.service";
import Savings from "../../pages/savings/Savings";
import Loading from "../Loading";

export const DepositToSavingModal: React.FC<any> = ({
    isOpenDeposit,
    onClosed,
    isDeposit = false,
    reload,
    username
}: {
    isDeposit: boolean;
    isOpenDeposit: any;
    onClosed: any;
    reload: any;
    username : string
}) => {
    const toast = useToast();
    const cancelRef = useRef().current;
    const [accountList, setacctTypeData] = useState<IAccountsValue[]>();
    const [activeSaving, setActiveSaving] = useState<any[]>();
    const [balance, setBalance] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(true);
    const [value, setValue] = useState({
        displayValue: '0',
        actualNumberValue: 0
    });
    useEffect(() => {
        accountsService.getOwnAccounts()
            .then((data) => {
                const acc_type: IAccountsRes = data
                setacctTypeData(acc_type.data.$values)
                setIsLoading(false)
            })
            .catch((err) => err)
            savingsService.getActiveSavingOfUser(`?userName=${username}`)
            .then((data) => {
                const acc_type: any = data
                setActiveSaving(acc_type.data.$values)
                setIsLoading(false)
            })
            .catch((err) => err)
       
    }, []);
    const formik = useFormik({
        validate: (values: any) => {
            const errors: any = {};
            if (!values.source) {
                errors.source = "select source";
            }
       
            if (!value.actualNumberValue) {
                errors.amount = "amount is required";
            }
            if (balance < parseInt(value.actualNumberValue.toString())) {
                errors.amount = "Available balance is less than required amount";
            }
            return errors;
        },
        initialValues: {
            source: "",
            destination: "",
            amount: "",
            

        },
        onSubmit: async (values) => {
            const transaction: any = {
                sourceAccountId: values.source,
                destinationSavingId: values.destination,
                amount: parseInt(value.actualNumberValue.toString()),
               
            }
            try {
                const create_saving_target: any = await savingsService.depositToSaving(transaction)
                console.log(create_saving_target.succeeded)
                if (create_saving_target.succeeded) {
                    toast({
                        title: "Deposit",
                        description: "Deposit to saving successful",
                        status: "success",
                    });
                    onClosed(!isDeposit)
                    reload()

                } else {
                    toast({
                        title: "Deposit",
                        description: `${create_saving_target.messages.$values[0]}`,
                        status: "error",
                    });
                }
            } catch (error: any) {
                toast({
                    title: "Deposit",
                    description: `${error}`,
                    status: "error",
                });
            }
        }
    });


    const updateMoveAcctList = (account: string) => {
        const selectedAcct = accountList && accountList.find((each) => each.id == account)
        if (selectedAcct) setBalance(selectedAcct.balance)
    }

    const handleChange2 = (event: any) => {
        const strNumber = event.target.value
            .replace(/[^\d.]/g, '') // Remove non-numeric and non-decimal point characters
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'); // Add thousand separators

        const parts = strNumber.split('.');
        const integerPart = parts[0] || '0';
        const decimalPart = parts[1] || '00';

        // Ensure two decimal places
        const formattedNumber = `${integerPart}.${decimalPart.slice(0, 2)}`;

        setValue({
            displayValue: formattedNumber,
            actualNumberValue: parseFloat(formattedNumber.replace(/,/g, '')),
        });
    };


    return (
        <>
            <Modal
                isOpen={isOpenDeposit && isDeposit}
                onClose={onClosed}
                scrollBehavior="inside"
                closeOnOverlayClick={true}
                size="lg"
            >
                <ModalOverlay />
                <ModalContent borderRadius={5} px={6}>
                    <ModalHeader>
                        {
                            <Text
                                fontSize={'18px'}
                                pb={3}
                                textTransform="capitalize"

                            >
                                Deposit To saving
                            </Text>
                        }
                    </ModalHeader>
                    <ModalCloseButton size="sm" />
                    <ModalBody pb={'30px'}>
                      {
                        isLoading  ? <Loading /> :   <Box mt={2}>
                        <form onSubmit={formik.handleSubmit}>

                            <FormControl
                                isInvalid={
                                    formik.errors.source && formik.touched.source
                                        ? true
                                        : false
                                }
                                mb={'20px'}
                            >
                                <FormLabel
                                    fontWeight="normal"
                                    color="brand.3"
                                    fontSize={13}
                                >
                                    Select Account
                                </FormLabel>
                                <Select
                                    variant="outline"
                                    bg="inputText.500"
                                    size="sm"
                                    placeholder="select account"
                                    value={formik.values.source}
                                    name="source"
                                    onClick={() => { updateMoveAcctList(formik.values.source) }}
                                    onChange={formik.handleChange}
                                    fontSize={12}
                                    color="brand.subtitle"
                                >
                                    {accountList && accountList.map((each) => (
                                        <option key={each.id} value={each.id}>
                                            {" "}
                                            {`${each.type}-${each.id}`}
                                        </option>
                                    ))}
                                </Select>
                                <FormErrorMessage fontSize={12}>
                                    {formik.errors.source}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl
                                isInvalid={
                                    formik.errors.source && formik.touched.source
                                        ? true
                                        : false
                                }
                                mb={'20px'}
                            >
                                <FormLabel
                                    fontWeight="normal"
                                    color="brand.3"
                                    fontSize={13}
                                >
                                    Select Saving
                                </FormLabel>
                                <Select
                                    variant="outline"
                                    bg="inputText.500"
                                    size="sm"
                                    placeholder="select saving"
                                    value={formik.values.destination}
                                    name="destination"
                                    onChange={formik.handleChange}
                                    fontSize={12}
                                    color="brand.subtitle"
                                >
                                    {activeSaving && activeSaving.map((each) => (
                                        <option key={each.id} value={each.id}>
                                            {" "}
                                            {`${each.targetName}-${each.targetAmount}`}
                                        </option>
                                    ))}
                                </Select>
                                <FormErrorMessage fontSize={12}>
                                    {formik.errors.destination}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl
                                mt={3}
                                isInvalid={
                                    formik.errors.amount && formik.touched.amount ? true : false
                                }
                            >
                                <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                    Amount
                                </FormLabel>
                                <Input
                                    type="text"
                                    id="amount"
                                    name="amount"
                                    placeholder="Enter amount"
                                    onBlur={handleChange2}
                                    value={value.displayValue}
                                    onChange={(e) => setValue({ ...value, displayValue: e.target.value })}
                                    fontSize={13}
                                />
                                <HStack mt='5px'>
                                    <Text fontSize={'13px'}>Available balance =</Text>
                                    <Text fontSize={'13px'} color={formik.errors.amount ? 'red' : 'green'}>
                                        <NumericFormat
                                            value={balance}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"₦"}
                                        /></Text>
                                </HStack>
                                <FormErrorMessage fontSize={12}>
                                    {formik.errors.amount}
                                </FormErrorMessage>
                            </FormControl>
                        

                            <Flex mt={12} justifyContent={'space-between'} >


                                <Button

                                    px={6}
                                    colorScheme="blue"
                                    fontSize={'14px'}
                                    variant={'outline'}
                                    ref={cancelRef}
                                    width={'49%'}
                                    onClick={() => onClosed()}
                                >
                                    Cancel
                                </Button>
                                <Button

                                    type="submit"
                                    isLoading={formik.isSubmitting}
                                    color={"white"}
                                    width={'49%'}
                                    bg={"#011F78"}
                                    px={6}
                                    fontSize={'14px'}
                                >
                                    Confirm
                                </Button>


                            </Flex>
                        </form>
                    </Box>
                 
                      }
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
