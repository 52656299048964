import {
  Box,
  Flex,
  HStack,
  Button,
  InputGroup,
  Input,
  InputRightElement,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Avatar,
  Text,
  MenuItem,
  Menu,
  MenuButton,
  MenuList,
  Center,
  useDisclosure,
  Portal,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Checkbox,
  Select,
  Stack,
} from "@chakra-ui/react";

import { useNavigate, useParams } from "react-router-dom";
import { RiSearch2Line } from "react-icons/ri";
import { BsArrowLeft, BsArrowRight, BsThreeDotsVertical } from "react-icons/bs";
import { NumericFormat } from "react-number-format";
import { FaJar } from "react-icons/fa6";
import { useState, useEffect } from "react";
import { IAgentsValue, IAgentsRes } from "../../models/agents/agents.model";
import { Ipage } from "../../models/audit/auditlog.model";
import agentService from "../../services/agent/agent.service";
import AdminRoutes from "../routes";
import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
  usePagination,
} from "@ajna/pagination";

import {
  ICustomerAgentData,
  ICustomerAgentRes,
  ICustomerAgentValue,
  IMyCustomers,
} from "../../models/agents/customerAgents.model";
import { HiArrowNarrowLeft } from "react-icons/hi";
import Loading from "../../components/Loading";
import { CreateCustomerModal } from "../../components/agent/CreateCustomerModal";
import { ICustomerAccountsValue } from "../../models/agents/customerAccounts.model";
import { MdSavings } from "react-icons/md";
import { CreateSavingModal } from "../../components/agent/CreateSavingModal";
import { DepositToSavingModal } from "../../components/agent/DepositToSavingModal";
import { FiEye } from "react-icons/fi";

const MyCustomers = () => {
  let navigate = useNavigate();
  const [customerList, setcustomerList] = useState<IMyCustomers[]>([]);
  const [customerAccountList, setcustomerAccountList] = useState<
    ICustomerAccountsValue[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCustomer, setisCustomer] = useState(false);
  const [isSaving, setisSaving] = useState(false);
  const [username, setUsername] = useState<string>();
  const [accountId, setAccountId] = useState<string>();
  const [isDeposit, setisDeposit] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [search, setSearch] = useState("");
  const [query, setQuery] = useState("");

  const [page, setPage] = useState<Ipage>({
    page_total: 0,
    total: 0,
    page_count: 0,
  });

  const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
    pagesCount: page.page_count,
    initialState: { currentPage: 1, pageSize: 100 },
    total: page.total,
  });

  const openCreateCustomerModal = () => {
    setisCustomer(true);
    setisSaving(false);
    setisDeposit(false);
    onOpen();
  };
  const openCreateSavingModal = (user: string) => {
    setUsername(user);
    setisCustomer(false);
    setisSaving(true);
    setisDeposit(false);

    onOpen();
  };
  const openDepositModal = (user: string) => {
    setUsername(user);
    setisCustomer(false);
    setisSaving(false);
    setisDeposit(true);

    onOpen();
  };
  const reloadData = () => {
    setReload(!reload);
  };
  useEffect(() => {
    const url = `?pageNumber=${currentPage}&pageSize=${pageSize}&searchString=${query}`;

    agentService
      .getCustomers()
      .then((data) => {
        const agents: any = data;
        setcustomerList(agents.data.$values);

        setIsLoading(false);
      })
      .catch((err) => err);
    agentService
      .getDependantAccount(url)
      .then((data) => {
        const agents: any = data;
        setcustomerAccountList(agents.data.$values);

        setIsLoading(false);
      })
      .catch((err) => err);
  }, [currentPage, query, reload, pageSize]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search.length > 0) {
        setQuery(search);
      } else {
        setQuery("");
      }
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);
  const changePageSize = (e: any) => {
    setPageSize(e.target.value);
  };
  const viewCustomerSaving = (username: string) => {
    navigate(AdminRoutes.portal.customerSavingsLink(username));
  };
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Box>
          <Flex justifyContent="space-between" alignItems="center" py="26px">
            <Box color="#2B2B2B" fontWeight="800" fontSize="27px">
             My Customers
            </Box>
            <HStack>
              <Button
                cursor="pointer"
                color={"white"}
                bg={"#011F78"}
                fontSize={"13px"}
                onClick={() => {
                  openCreateCustomerModal();
                }}
              >
                Create customer
              </Button>
            </HStack>
          </Flex>
          <Tabs>
            <TabList>
              <Tab>Customers</Tab>
              <Tab>Dependant Accounts</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Box>
                  <Flex py="15px" justifyContent="">
                    <InputGroup bg="white" width="30%" mr="20px">
                      <Input
                        height="40px"
                        placeholder="Search input here"
                        fontSize={12}
                        size="sm"
                      />
                      <InputRightElement
                        pointerEvents="none"
                        children={<RiSearch2Line color="green" size="0.9rem" />}
                      />
                    </InputGroup>
                  </Flex>
                  <Box width={"100%"}>
                    <TableContainer>
                      <Table size={"sm"}>
                        <Thead py={"15px"}>
                          <Tr bg="#3778D9" color={"white"} py={"15px"}>
                            {" "}
                            <Td textTransform={"capitalize"}>Customer Name</Td>
                            <Td py={"15px"} textTransform={"capitalize"}>
                              Username
                            </Td>
                            <Td textTransform={"capitalize"}>Account Count</Td>
                            <Td textTransform={"capitalize"}>Balance</Td>
                            <Td textTransform={"capitalize"}>
                              Savings Balance
                            </Td>
                            <Td textTransform={"capitalize"}>Actions</Td>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {customerList && customerList.length > 0 ? (
                            customerList.map((customer, index) => (
                              <Tr key={index} bg="white">
                                <Td>
                                  <HStack spacing="5px" mt={"10px"}>
                                    <Avatar
                                      size="sm"
                                      name={customer.fullName}
                                    />
                                    <Box>
                                      <Text
                                        fontWeight="500"
                                        textTransform="capitalize"
                                        fontSize="14px"
                                      >
                                        {customer.fullName}
                                      </Text>
                                    </Box>
                                  </HStack>
                                </Td>
                                <Td>
                                  <HStack spacing="5px" mt={"10px"}>
                                    <Box>
                                      <Text fontSize={"14px"}>
                                        {customer.userName.slice(0, 18)}
                                      </Text>
                                      <Text fontSize={"14px"}>
                                        {customer.userName.slice(
                                          18,
                                          customer.userName.length
                                        )}
                                      </Text>
                                    </Box>
                                  </HStack>
                                </Td>
                                <Td>
                                  <Text fontSize={"14px"}>
                                    {customer.accountCount}
                                  </Text>
                                </Td>

                                <Td>
                                  <Text fontSize={"14x"}>
                                    <NumericFormat
                                      value={customer.balanceSum}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"₦"}
                                    />
                                  </Text>
                                </Td>
                                <Td>
                                  <Text fontSize={"14x"}>
                                    <NumericFormat
                                      value={customer.activeSavingsBalanceSum}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"₦"}
                                    />
                                  </Text>
                                </Td>
                                <Td>
                                  <Stack direction="row" spacing={4}>
                                    <Center
                                      cursor={"pointer"}
                                      title="view savings"
                                      onClick={() => {
                                        viewCustomerSaving(customer.userName);
                                      }}
                                    >
                                      <FiEye color="#011F78" fontSize={"22px"} />
                                    </Center>
                                    <Center
                                        cursor={"pointer"}
                                        title="create saving"
                                        onClick={() => {
                                          openCreateSavingModal(
                                            customer.userName
                                          );
                                        }}
                                      >
                                        <MdSavings color="#011F78" fontSize={"22px"} />
                                      </Center>
                                 
                                 
                                    <Button
                                      rightIcon={<FaJar />}
                                      colorScheme="blue"
                                      variant="outline"
                                      size={"sm"}
                                      fontSize={"10px"}
                                      onClick={() => {
                                        openDepositModal(customer.userName);
                                      }}
                                    >
                                      Deposit
                                    </Button>
                                  </Stack>
                                </Td>
                              </Tr>
                            ))
                          ) : (
                            <Box>No data</Box>
                          )}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel>
                <Box>
                  <Flex py="15px" justifyContent="">
                    <InputGroup bg="white" width="30%" mr="20px">
                      <Input
                        height="40px"
                        placeholder="Search accounts here"
                        onChange={(e) => setSearch(e.target.value)}
                        fontSize={12}
                        size="sm"
                      />
                      <InputRightElement
                        pointerEvents="none"
                        children={<RiSearch2Line color="green" size="0.9rem" />}
                      />
                    </InputGroup>
                  </Flex>
                  <Box width={"100%"}>
                    <TableContainer>
                      <Table size={"sm"}>
                        <Thead py={"15px"}>
                          <Tr bg="#3778D9" color={"white"} py={"15px"}>
                            {" "}
                            <Td textTransform={"capitalize"}>Customer Name</Td>
                            <Td py={"15px"} textTransform={"capitalize"}>
                              Acccount Number
                            </Td>
                            <Td textTransform={"capitalize"}>Account Name</Td>
                            <Td textTransform={"capitalize"}>Balance</Td>
                            <Td textTransform={"capitalize"}>
                              Savings Balance
                            </Td>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {customerAccountList &&
                          customerAccountList.length > 0 ? (
                            customerAccountList.map((customer, index) => (
                              <Tr key={index} bg="white">
                                <Td>
                                  <HStack spacing="5px" mt={"10px"}>
                                    <Avatar
                                      size="sm"
                                      name={customer.ownerName}
                                    />
                                    <Box>
                                      <Text
                                        fontWeight="500"
                                        textTransform="capitalize"
                                        fontSize="14px"
                                      >
                                        {customer.ownerName}
                                      </Text>
                                    </Box>
                                  </HStack>
                                </Td>
                                <Td>
                                  <HStack spacing="5px" mt={"10px"}>
                                    <Box>
                                      <Text
                                        fontWeight="500"
                                        textTransform="capitalize"
                                        fontSize="14px"
                                      >
                                        {customer.id}
                                      </Text>
                                    </Box>
                                  </HStack>
                                </Td>
                                <Td>
                                  <Text fontSize={"14px"}>{customer.type}</Text>
                                </Td>

                                <Td>
                                  <Text fontSize={"14x"}>
                                    <NumericFormat
                                      value={customer.balance}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"₦"}
                                    />
                                  </Text>
                                </Td>
                                <Td>
                                  <Text fontSize={"14x"}>
                                    <NumericFormat
                                      value={customer.savingsBalance}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"₦"}
                                    />
                                  </Text>
                                </Td>
                              </Tr>
                            ))
                          ) : (
                            <Box>No data</Box>
                          )}
                        </Tbody>
                      </Table>
                    </TableContainer>
                    <Flex
                      alignContent={"flex-end"}
                      justifyContent={"flex-end"}
                      mt={4}
                    >
                      <HStack>
                        <Text fontWeight={400} fontSize={"13px"}>
                          Rows per page :
                        </Text>
                        <Select
                          variant="outline"
                          size="sm"
                          width={"70px"}
                          value={pageSize}
                          onChange={(e) => {
                            changePageSize(e);
                          }}
                          fontSize={12}
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </Select>
                      </HStack>
                      <Pagination
                        pagesCount={pagesCount}
                        currentPage={currentPage}
                        onPageChange={setCurrentPage}
                      >
                        <PaginationContainer>
                          <PaginationPrevious>
                            <BsArrowLeft />
                          </PaginationPrevious>
                          <PaginationPageGroup>
                            {pages.map((page: number) => (
                              <PaginationPage
                                key={`pagination_page_${page}`}
                                page={page}
                              />
                            ))}
                          </PaginationPageGroup>
                          <PaginationNext>
                            <BsArrowRight />
                          </PaginationNext>
                        </PaginationContainer>
                      </Pagination>
                    </Flex>
                  </Box>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>

          {isCustomer && (
            <Portal>
              {" "}
              <CreateCustomerModal
                isOpenCustomer={isOpen}
                onClosed={onClose}
                isCustomer={isCustomer}
                reload={reloadData}
              />{" "}
            </Portal>
          )}
          {isSaving && (
            <Portal>
              {" "}
              <CreateSavingModal
                isOpenSaving={isSaving}
                onClosed={setisSaving}
                isSaving={isSaving}
                reload={reloadData}
                username={username}
              />{" "}
            </Portal>
          )}

          {isDeposit && (
            <Portal>
              {" "}
              <DepositToSavingModal
                isOpenDeposit={isDeposit}
                onClosed={setisDeposit}
                isDeposit={isDeposit}
                reload={reloadData}
                username={username}
              />{" "}
            </Portal>
          )}
        </Box>
      )}
    </>
  );
};

export default MyCustomers;
