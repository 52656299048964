import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    Box,
    useToast,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Flex,
    Button,
    HStack,
    Textarea,
    Select,
    Portal,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { IHandleTransaction } from "../../models/transactions/handleTransaction";
import { IAccountsValue, IAccountsRes } from "../../models/accounts/accounts.model";
import accountsService from "../../services/accounts/accounts.service";
import { TransferPinModal } from "./TransferPinModal";
import agentService from "../../services/agent/agent.service";
import { NumericFormat } from "react-number-format";
import usersService from "../../services/user/users.service";
import { IUserByNameData } from "../../models/users/userbyname.model";
import { ISearchUsersData } from "../../models/users/searchUsers.model";

export const TransferAmountModal: React.FC<any> = ({
    isOpenTransferAmount,
    onClosed,
    isTransferAmount = false,
    reload
}: {
    isTransferAmount: boolean;
    isOpenTransferAmount: any;
    onClosed: any;
    reload: any;
}) => {
    const toast = useToast();
    const cancelRef = useRef().current;
    const [value, setValue] = useState({
        displayValue: '0',
        actualNumberValue: 0
      });
    const [openPin, setOpenPin] = useState(false)
    const [accountList, setacctTypeData] = useState<IAccountsValue[]>();
    const [beneficiaries, setBeneficiaries] = useState<any[]>();
    const [requestData, setRequestData] = useState<IHandleTransaction>();
    const [balance, setBalance] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(true);
    const [searchInput, setSearchInput] = useState('');
    const [selectedName, setSelectedName] = useState('');
    const [users, setUsers] = useState<string[]>([]);
    const [destinationName, setDestinationName] = useState('');
    let [start_date, setstart_date] = useState("2019-01-01");
    let [end_date, setend_date] = useState('2023-12-31');

    useEffect(() => {
        accountsService.getOwnAccounts()
            .then((data) => {
                const acc_type: IAccountsRes = data
                setacctTypeData(acc_type.data.$values)
                setBeneficiaries(acc_type.data.$values)
                setIsLoading(false)
            })
            .catch((err) => err)
        accountsService.getUserContactTemplates()
            .then((data) => {
                const acc_type: any = data

                setBeneficiaries(acc_type.data.$values)
                setIsLoading(false)
            })
            .catch((err) => err)
       
        if (searchInput) {
            usersService.getUsersByUserName(`?userNamePattern=${searchInput}`).then((data) => {
                const user_data: ISearchUsersData = data.data
                const mappedUser = user_data.$values.map((data => data.userName))
                setUsers(mappedUser)
            })
        } else {
            setUsers([])
        }

    }, [searchInput]);

    const handleInputChange = (event: any) => {
        setSearchInput(event.target.value);
        if (event.target.value == '') {
            setSelectedName('')
            setDestinationName('')
            setUsers([])
        }
    };

    // Function to handle name selection
    const handleNameSelect = (name: string) => {
        setSelectedName(name);
    };
    const formik = useFormik({
        validate: (values: any) => {
            const errors: any = {};
            if (!values.source) {
                errors.source = "select source";
            }
            if (!value.actualNumberValue) {
                errors.amount = "amount is required";
            }
            if (balance < parseInt(value.actualNumberValue.toString())) {
                errors.amount = "Available balance is less than required amount";
            }
            return errors;
        },
        initialValues: {
            source: "",
            destination: "",
            amount: "",
            description: "",
            beneficiary: ''

        },
        onSubmit: async (values) => {
            const transaction: IHandleTransaction = {
                source: values.source,
                destination: selectedName,
                amount: parseInt(value.actualNumberValue.toString()),
                currency: 'NGN',
                description: values.description,
                receiptRequested: true,
                typeId: 1,
            }

            setRequestData(transaction)
            setOpenPin(true)
            //

        }
    });
    const newReload = () => {
        onClosed(false)
        reload()
    }
    const updateMoveAcctList = (account: string) => {
        const selectedAcct = accountList && accountList.find((each) => each.id == account)
        if (selectedAcct) setBalance(selectedAcct.balance)

    }
    const handleDestination = (username: string) => {
        setSelectedName(username)
        const user = beneficiaries && beneficiaries.find((each) => each.userName == username)
        if(user)setDestinationName(user.fullName)
    }

    const handleChange = (event : any) => {
        const strNumber = event.target.value
          .replace(/[^\d.]/g, '') // Remove non-numeric and non-decimal point characters
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'); // Add thousand separators
      
        const parts = strNumber.split('.');
        const integerPart = parts[0] || '0';
        const decimalPart = parts[1] || '00';
      
        // Ensure two decimal places
        const formattedNumber = `${integerPart}.${decimalPart.slice(0, 2)}`;
      
        setValue({
          displayValue: formattedNumber,
          actualNumberValue: parseFloat(formattedNumber.replace(/,/g, '')),
        });
      };
      

    return (
        <>
            <Modal
                isOpen={isOpenTransferAmount && isTransferAmount}
                onClose={onClosed}
                scrollBehavior="inside"
                closeOnOverlayClick={true}
                size="lg"
            >
                <ModalOverlay />
                <ModalContent borderRadius={5} px={6}>
                    <ModalHeader>
                        {
                            <Text
                                fontSize={'18px'}
                                pb={3}
                                textTransform="capitalize"


                            >
                                Transfer Amount
                            </Text>
                        }
                    </ModalHeader>
                    <ModalCloseButton size="sm" />
                    <ModalBody pb={'30px'}>
                        <Box mt={2}>
                            <form onSubmit={formik.handleSubmit}>
                                <FormControl
                                    mb={'20px'}
                                    isInvalid={
                                        formik.errors.source && formik.touched.source
                                            ? true
                                            : false
                                    }
                                >
                                    <FormLabel
                                        fontWeight="normal"
                                        color="brand.3"
                                        fontSize={13}
                                    >
                                        Select Account
                                    </FormLabel>
                                    <Select
                                        variant="outline"
                                        bg="inputText.500"
                                        size="sm"
                                        placeholder="select account"
                                        value={formik.values.source}
                                        name="source"
                                        onClick={() => { updateMoveAcctList(formik.values.source) }}
                                        onChange={formik.handleChange}
                                        fontSize={12}
                                        color="brand.subtitle"
                                    >
                                        {accountList && accountList.map((each) => (
                                            <option key={each.id} value={each.id}>
                                                {" "}
                                                {`${each.type}-${each.id}`}
                                            </option>
                                        ))}
                                    </Select>
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.source}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl
                                    mb={'20px'}
                                    isInvalid={
                                        formik.errors.source && formik.touched.source
                                            ? true
                                            : false
                                    }
                                >
                                    <FormLabel
                                        fontWeight="normal"
                                        color="brand.3"
                                        fontSize={13}
                                    >
                                        Select From Beneficiaries
                                    </FormLabel>
                                    <Select
                                        variant="outline"
                                        bg="inputText.500"
                                        size="sm"
                                        placeholder="select beneficiary"
                                        onClick={() => { handleDestination(formik.values.beneficiary) }}
                                        value={formik.values.beneficiary}
                                        name="beneficiary"
                                        onChange={formik.handleChange}
                                        fontSize={12}
                                        color="brand.subtitle"
                                    >
                                        {beneficiaries && beneficiaries.map((each) => (
                                            <option key={each.id} value={each.userName}>
                                                {" "}
                                                {`${each.fullName}`}
                                            </option>
                                        ))}
                                    </Select>
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.beneficiary}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl mb={'20px'}>
                                    <Input
                                        type="text"
                                        size={'sm'}
                                        placeholder="Search by username"
                                        value={searchInput}
                                        onChange={handleInputChange}
                                        fontSize={'12px'}
                                    />
                                    <Box>
                                        {
                                            !selectedName && users.map((user, index) => (
                                                <Text px={'20px'} py={'10px'} boxShadow={'base'} borderRadius={'10px'} bg={'lightgrey'} mb={'5px'} fontSize={'13px'} cursor={'pointer'} onClick={() => handleDestination(user)} key={index}>
                                                    {user}
                                                </Text>
                                            ))
                                        }
                                    </Box>
                                </FormControl>
                                {selectedName && (
                                    <FormControl
                                        mb={'20px'}
                                        isInvalid={
                                            formik.errors.destination && formik.touched.destination
                                                ? true
                                                : false
                                        }
                                    >
                                        <FormLabel
                                            fontWeight="normal"
                                            color="brand.3"
                                            fontSize={'13px'}
                                        >
                                            Destination
                                        </FormLabel>
                                        <Input
                                            type="text"
                                            value={selectedName}
                                            onChange={(event) => setSelectedName(event.target.value)}
                                            fontSize={'13px'}
                                        />

                                        <FormErrorMessage fontSize={12}>
                                            {formik.errors.destination}
                                        </FormErrorMessage>
                                    </FormControl>
                                )}

                                <FormControl
                                    mt={3}
                                    isInvalid={
                                        formik.errors.amount && formik.touched.amount ? true : false
                                    }
                                >
                                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                        Amount
                                    </FormLabel>
                                    <Input
                                        type="text"
                                        id="amount"
                                        name="amount"
                                        placeholder="Enter amount"
                                        onBlur={handleChange}
                                        value={value.displayValue}
                                        onChange={(e) => setValue({ ...value, displayValue: e.target.value })}
                                        fontSize={13}
                                    />
                                    <HStack mt='5px'>
                                        <Text fontSize={'13px'}>Available balance =</Text>
                                        <Text fontSize={'13px'} color={formik.errors.amount ? 'red' : 'green'}>
                                            <NumericFormat
                                                value={balance}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"₦"}
                                            /></Text>
                                    </HStack>
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.amount}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl
                                    id="description"
                                    mt={6}
                                    isInvalid={
                                        formik.errors.description && formik.touched.description
                                            ? true
                                            : false
                                    }
                                >
                                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                        Description
                                    </FormLabel>
                                    <Textarea
                                        p={4}
                                        fontSize={12}
                                        size="sm"
                                        minH="50px"
                                        placeholder=" Description"
                                        name="description"
                                        onChange={formik.handleChange}
                                        value={formik.values.description}
                                    />
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.description}
                                    </FormErrorMessage>
                                </FormControl>


                                <Flex mt={12} justifyContent={'space-between'} >


                                    <Button

                                        px={6}
                                        colorScheme="blue"
                                        fontSize={'14px'}
                                        variant={'outline'}
                                        ref={cancelRef}
                                        width={'49%'}
                                        onClick={() => onClosed()}
                                    >
                                        Cancel
                                    </Button>
                                    <Button

                                        type="submit"
                                        isLoading={formik.isSubmitting}
                                        color={"white"}
                                        width={'49%'}
                                        bg={"#011F78"}
                                        px={6}
                                        fontSize={'14px'}
                                    >
                                        Confirm
                                    </Button>


                                </Flex>
                            </form>
                        </Box>
                        {openPin && (
                            <Portal>
                                {" "}
                                <TransferPinModal
                                    isOpenPin={openPin}
                                    onClosed={setOpenPin}
                                    isOtp={openPin}
                                    destination={destinationName}
                                    requestBody={requestData}
                                    reload={newReload}
                                />{" "}
                            </Portal>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
