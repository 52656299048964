import {
  Box,
  Flex,
  HStack,
  Button,
  InputGroup,
  Input,
  InputRightElement,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Avatar,
  Text,
  MenuItem,
  Menu,
  MenuButton,
  MenuList,
  Center,
  Select,
} from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";
import { RiSearch2Line } from "react-icons/ri";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { NumericFormat } from 'react-number-format';
import { useState, useEffect } from "react";
import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
  usePagination,
} from "@ajna/pagination";

import Loading from "../../components/Loading";
import { Ipage } from "../../models/audit/auditlog.model";
import savingsService from "../../services/savings/savings.service";
import {
  ISavingListRes,
  ISavingListValue,
} from "../../models/savings/savings.model";
import { getFullDate } from "../../utils/getDate";

const Savings = () => {
  let navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [query, setQuery] = useState("");
  const [page, setPage] = useState<Ipage>({
    page_total: 0,
    total: 0,
    page_count: 0,
  });
  const [savingList, setsavingList] = useState<ISavingListValue[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
    pagesCount: page.page_count,
    initialState: { currentPage: 1, pageSize: 100 },
    total: page.total,
  });

  useEffect(() => {
    let url = `?pageNumber=${currentPage}&pageSize=${pageSize}&searchString=${query}`;
    savingsService
      .getSavings(url)
      .then((data) => {
        const saving: ISavingListRes = data;
        setPage({
          page_total: saving.pageSize,
          total: saving.totalCount,
          page_count: saving.totalPages,
        });
        setsavingList(saving.data.$values);
        setIsLoading(false);
      })
      .catch((err) => err);
  }, [currentPage, query, reload, pageSize]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search.length > 0) {
        setQuery(search);
      } else {
        setQuery("");
      }
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const changePageSize = (e: any) => {
    setPageSize(e.target.value);
  };
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Box>
          <Flex justifyContent="space-between" alignItems="center" py="26px">
            <Box color="#2B2B2B" fontWeight="800" fontSize="27px">
              Savings
            </Box>
          </Flex>
          <Flex py="15px" justifyContent="">
            <InputGroup bg="white" width="30%" mr="20px">
              <Input
                height="40px"
                placeholder="Search input here"
                onChange={(e) => setSearch(e.target.value)}
                fontSize={12}
                size="sm"
              />
              <InputRightElement
                pointerEvents="none"
                children={<RiSearch2Line color="#2B2B2B" size="0.9rem" />}
              />
            </InputGroup>
          </Flex>
          <Box width={"100%"}>
            <TableContainer>
              <Table size={"sm"}>
                <Thead py={"15px"}>
                  <Tr bg="#3778D9" color={"white"} py={"15px"}>
                    {" "}
                    
                    <Td textTransform={"capitalize"}>Name</Td>
                    <Td textTransform={"capitalize"}>Account</Td>
                    <Td textTransform={"capitalize"}>Target</Td>
                    <Td textTransform={"capitalize"}>Balance</Td>
                    <Td textTransform={"capitalize"}>Target Amount</Td>
                    <Td textTransform={"capitalize"}>Start Date</Td>
                    <Td textTransform={"capitalize"}>Maturity Date</Td>
                  </Tr>
                </Thead>
                <Tbody>
                  {savingList.map((each, index) => (
                    <Tr key={index} bg="white">
                      <Td>
                        <Text fontSize={"14px"}>{each.saversFullName}</Text>
                      </Td>

                      <Td>
                        <Text fontSize={"14px"}>{each.accountId}</Text>
                      </Td>
                      <Td>
                        <Text fontSize={"14px"}>{each.targetName}</Text>
                      </Td>

                      <Td>
                        <Text fontSize={"14px"}>
                          {" "}
                          <NumericFormat
                            value={each.balance}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"₦"}
                          />
                        </Text>
                      </Td>
                      <Td>
                        <Text fontSize={"14px"}>
                          {" "}
                          <NumericFormat
                            value={each.targetAmount}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"₦"}
                          />
                        </Text>
                      </Td>
                      <Td>
                        <Text fontSize={"14px"}>{getFullDate(each.startDate)}</Text>
                      </Td>
                      <Td>
                      <Text fontSize={"14px"}>{getFullDate(each.maturityDate)}</Text>
                  
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
          <Flex alignContent={"flex-end"} justifyContent={"flex-end"} mt={4}>
            <HStack>
              <Text fontWeight={400} fontSize={"13px"}>
                Rows per page :
              </Text>
              <Select
                variant="outline"
                size="sm"
                width={"70px"}
                value={pageSize}
                onChange={(e) => {
                  changePageSize(e);
                }}
                fontSize={12}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </Select>
            </HStack>
            <Pagination
              pagesCount={pagesCount}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
            >
              <PaginationContainer>
                <PaginationPrevious>
                  <BsArrowLeft />
                </PaginationPrevious>
                <PaginationPageGroup>
                  {pages.map((page: number) => (
                    <PaginationPage
                      key={`pagination_page_${page}`}
                      page={page}
                    />
                  ))}
                </PaginationPageGroup>
                <PaginationNext>
                  <BsArrowRight />
                </PaginationNext>
              </PaginationContainer>
            </Pagination>
          </Flex>
        </Box>
      )}
    </>
  );
};

export default Savings;
