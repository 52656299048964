import {
  Flex,
  Container,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Box,
  Text,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Avatar,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import Chart from "react-google-charts";
import { BsArrowUp } from "react-icons/bs";
import {
  IUserDashboardData,
  IUserDashboardRes,
} from "../../models/dashboard/userDashboard.model";
import AdminRoutes from "../../pages/routes";
import dashboardService from "../../services/dashboard/dashboard.service";
import usersService from "../../services/user/users.service";
import Loading from "../Loading";

export const options_piechart = {
  pieHole: 0.6,
  is3D: false,
  colors: ["#1DE9B6", "#FF9100", "#2979FF", "red"],
};
const AdminDashboardComponent = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [dataChart, setDataChart] = useState<any[]>();
  const [dashboardData, setdashboardData] = useState<IUserDashboardData>();
  useEffect(() => {
    // let url = `?page=${currentPage}&limit=${10}&search=${query}`
    dashboardService
      .userDashboard()
      .then((data) => {
        const dashboard: IUserDashboardRes = data;
        const dashData = dashboard.data;
        let datachart: any[] = [["Users Spread", "Count"]];
        datachart.push(["Active Users", dashData.activeUserCount]);
        datachart.push(["Offline Users", dashData.offlineUserCount]);
        datachart.push(["Unconfirmed Users", dashData.unconfirmedUserCount]);
        datachart.push(["Deactivated Users", dashData.deactivatedUserCount]);
        setDataChart(datachart);
        setdashboardData(dashboard.data);
        setIsLoading(false);
      })
      .catch((err) => err);

    usersService.getUserCountries().then((user) => {
      console.log(user, "user");
    });
    usersService.getUserStates().then((user) => {
      console.log(user, "user");
    });
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Box>
          <Flex justifyContent="space-between" alignItems="center" pb="20px">
            <Box color="#2B2B2B" fontWeight="800" fontSize="24px">
              Dashboard
            </Box>
          </Flex>
          <Flex justifyContent="space-between" mb="20px">
            <Container
              bg="white"
              px={"20px"}
              py={"15px"}
              boxShadow="base"
              m={0}
            >
              <Box pb={3} pt={1}>
                <Text color="#929292" fontSize={13}>
                  Active
                </Text>
                <Text color="#353535" fontWeight="semibold" fontSize={"24px"}>
                  {dashboardData ? dashboardData.activeUserCount : "Nil"}
                </Text>
                <HStack>
                  <BsArrowUp color="green" />
                  <Text ml={"-10px"} color="green" fontSize={"12px"}>
                    15%
                  </Text>
                  <Text color="#929292" fontSize={"12px"}>
                    Last month
                  </Text>
                </HStack>
              </Box>
            </Container>

            <Spacer mx={3} />
            <Container
              bg="white"
              px={"20px"}
              py={"15px"}
              boxShadow="base"
              m={0}
            >
              <Box
                pb={3}
                pt={1}
                onClick={() => {
                  navigate(AdminRoutes.portal.dashboard);
                }}
              >
                <Text color="#929292" fontSize="13px">
                  Unconfirmed
                </Text>
                <Text color="#353535" fontWeight="semibold" fontSize={"24px"}>
                  {dashboardData ? dashboardData.unconfirmedUserCount : "Nil"}
                </Text>
                <HStack>
                  <BsArrowUp color="green" />
                  <Text ml={"-10px"} color="green" fontSize={"12px"}>
                    15%
                  </Text>
                  <Text color="#929292" fontSize={"12px"}>
                    Last month
                  </Text>
                </HStack>
              </Box>
            </Container>

            <Spacer mx={3} />
            <Container
              px={"20px"}
              py={"15px"}
              boxShadow="base"
              bg="white"
              m={0}
            >
              <Box
                pb={3}
                pt={1}
                onClick={() => {
                  navigate(AdminRoutes.portal.dashboard);
                }}
              >
                <HStack spacing={3}>
                  <Text textAlign={"left"} color="#929292" fontSize="13px">
                    Deactivated
                  </Text>
                </HStack>
                <Text color="#353535" fontWeight="semibold" fontSize={"24px"}>
                  {dashboardData ? dashboardData.deactivatedUserCount : "Nil"}
                </Text>
                <HStack>
                  <BsArrowUp color="green" />
                  <Text ml={"-10px"} color="green" fontSize={"12px"}>
                    15%
                  </Text>
                  <Text color="#929292" fontSize={"12px"}>
                    Last month
                  </Text>
                </HStack>
              </Box>
            </Container>

            <Spacer mx={3} />

            <Container
              px={"20px"}
              py={"15px"}
              boxShadow="base"
              bg="white"
              m={0}
            >
              <Box
                pb={3}
                pt={1}
                onClick={() => {
                  navigate(AdminRoutes.portal.dashboard);
                }}
              >
                <Text color="#929292" fontSize="13px">
                  {" "}
                  Agent
                </Text>
                <Text color="#353535" fontWeight="semibold" fontSize={"24px"}>
                  {dashboardData ? dashboardData.agentCount : "Nil"}
                </Text>
                <HStack>
                  <BsArrowUp color="green" />
                  <Text ml={"-10px"} color="green" fontSize={"12px"}>
                    15%
                  </Text>
                  <Text color="#929292" fontSize={"12px"}>
                    Last month
                  </Text>
                </HStack>
              </Box>
            </Container>
          </Flex>
          <Flex justifyContent="space-between" pb="20px" mb="20px">
            <Container
              bg="white"
              px={"20px"}
              py={"15px"}
              boxShadow="base"
              m={0}
            >
              <Box pb={3} pt={1}>
                <Text color="#929292" fontSize={13}>
                  Customer
                </Text>
                <Text color="#353535" fontWeight="semibold" fontSize={"24px"}>
                  {dashboardData ? dashboardData.customerCount : "Nil"}
                </Text>
                <HStack>
                  <BsArrowUp color="green" />
                  <Text ml={"-10px"} color="green" fontSize={"12px"}>
                    15%
                  </Text>
                  <Text color="#929292" fontSize={"12px"}>
                    Last month
                  </Text>
                </HStack>
              </Box>
            </Container>

            <Spacer mx={3} />
            <Container
              bg="white"
              px={"20px"}
              py={"15px"}
              boxShadow="base"
              m={0}
            >
              <Box
                pb={3}
                pt={1}
                onClick={() => {
                  navigate(AdminRoutes.portal.dashboard);
                }}
              >
                <Text color="#929292" fontSize="13px">
                  Last Login
                </Text>
                <Text color="#353535" fontWeight="semibold" fontSize={"24px"}>
                  {dashboardData ? dashboardData.lastLoginCount : "Nil"}
                </Text>
                <HStack>
                  <BsArrowUp color="green" />
                  <Text ml={"-10px"} color="green" fontSize={"12px"}>
                    15%
                  </Text>
                  <Text color="#929292" fontSize={"12px"}>
                    Last month
                  </Text>
                </HStack>
              </Box>
            </Container>

            <Spacer mx={3} />
            <Container
              px={"20px"}
              py={"15px"}
              boxShadow="base"
              bg="white"
              m={0}
            >
              <Box
                pb={3}
                pt={1}
                onClick={() => {
                  navigate(AdminRoutes.portal.dashboard);
                }}
              >
                <HStack spacing={3}>
                  <Text textAlign={"left"} color="#929292" fontSize="13px">
                    Offline Customers
                  </Text>
                </HStack>
                <Text color="#353535" fontWeight="semibold" fontSize={"24px"}>
                  {dashboardData ? dashboardData.offlineUserCount : "Nil"}
                </Text>
                <HStack>
                  <BsArrowUp color="green" />
                  <Text ml={"-10px"} color="green" fontSize={"12px"}>
                    15%
                  </Text>
                  <Text color="#929292" fontSize={"12px"}>
                    Last month
                  </Text>
                </HStack>
              </Box>
            </Container>

            <Spacer mx={3} />

            <Container
              px={"20px"}
              py={"15px"}
              boxShadow="base"
              bg="white"
              m={0}
            >
              <Box
                pb={3}
                pt={1}
                onClick={() => {
                  navigate(AdminRoutes.portal.dashboard);
                }}
              >
                <Text color="#929292" fontSize="13px">
                  {" "}
                  Agent
                </Text>
                <Text color="#353535" fontWeight="semibold" fontSize={"24px"}>
                  {dashboardData ? dashboardData.agentCount : "Nil"}
                </Text>
                <HStack>
                  <BsArrowUp color="green" />
                  <Text ml={"-10px"} color="green" fontSize={"12px"}>
                    15%
                  </Text>
                  <Text color="#929292" fontSize={"12px"}>
                    Last month
                  </Text>
                </HStack>
              </Box>
            </Container>
          </Flex>
          <Flex mt="20px" mb="20px" justifyContent={"space-between"}>
            <Box px={"25px"} pt="25px" bg="white" width={"48%"}>
              <Chart
                chartType="PieChart"
                width="100%"
                height="350px"
                options={options_piechart}
                data={dataChart}
              />
            </Box>
            <Box px={"25px"} pt="25px" bg="white" width={"48%"}>
              <Flex pr="20px" justifyContent={"space-between"}>
                <Text fontWeight="600" fontSize="18px" color="#636262">
                  Top 5 Agents By Customer No{" "}
                </Text>
              </Flex>

              <TableContainer>
                <Table>
                  <Thead>
                    <Tr fontSize={"13px"} fontWeight={"500"} color={"#929292"}>
                      <Td>Agent </Td>
                      <Td>Customer Count</Td>
                      <Td>Total Account</Td>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {dashboardData ? (
                      dashboardData.top5AgentsByCustomerNo.$values.map(
                        (agent, index) => (
                          <Tr key={index} bg="white">
                            <HStack spacing="5px" mt={"10px"}>
                              <Avatar size="sm" name={agent.agentName} />
                              <Box>
                                <Text
                                  fontWeight="500"
                                  textTransform="capitalize"
                                  fontSize="13px"
                                  color="##353535"
                                >
                                  {agent.agentName}
                                </Text>
                              </Box>
                            </HStack>
                            <Td>
                              {" "}
                              <Text
                                textAlign={"center"}
                                fontWeight="500"
                                textTransform="capitalize"
                                fontSize="13px"
                                color="##353535"
                              >
                                {agent.customerCount}
                              </Text>
                            </Td>
                            <Td>
                              {" "}
                              <Text
                                fontWeight="500"
                                textAlign={"center"}
                                textTransform="capitalize"
                                fontSize="13px"
                                color="##353535"
                              >
                                {agent.totalAccountCount}
                              </Text>
                            </Td>
                          </Tr>
                        )
                      )
                    ) : (
                      <Box>No data</Box>
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          </Flex>
        </Box>
      )}
    </>
  );
};

export default AdminDashboardComponent;
