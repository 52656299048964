import {
  Flex,
  Container,
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  VStack,
  Text,
  useDisclosure,
  Portal,
  useToast,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import AdminRoutes from "../routes";
import { TbWorldBolt } from "react-icons/tb";

import { BiCoinStack } from "react-icons/bi";
import { MdElectricBolt, MdRecycling } from "react-icons/md";
import { BsFillFileTextFill, BsWallet, BsWallet2 } from "react-icons/bs";
import { FaMobile } from "react-icons/fa";
import accountsService from "../../services/accounts/accounts.service";
import {
  IAccountsRes,
  IAccountsValue,
} from "../../models/accounts/accounts.model";
import Loading from "../../components/Loading";
import {
  IAvailableAcctRes,
  IAvailableAcctValue,
} from "../../models/accounts/accountType.model";
import { MoveAmountModal } from "../../components/wallet/MoveAmountModal";
import { TransferAmountModal } from "../../components/wallet/TransferAmount";
import { FundCustomerModal } from "../../components/wallet/FundCustomer";
import { GenerateStatementModal } from "../../components/wallet/GenerateStatementModal";
import { IUserToken } from "../../models/auth/login.model";
import { AccountTransactionModal } from "../../components/wallet/AccountTransactionModal";
import userProfileService from "../../services/user/userProfile.service";
import agentService from "../../services/agent/agent.service";
import { RequestGiftCardModal } from "../../components/wallet/RequestGiftCard";

const AccountsWallet = () => {
  let navigate = useNavigate();
  const toast = useToast();
  const [acctTypeData, setacctTypeData] = useState<IAccountsValue[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [isMoveAmount, setIsMoveAmount] = useState(false);
  const [isFundCustomer, setIsFundCustomoer] = useState(false);
  const [isStatement, setIsStatement] = useState(false);
  const [isTransferAmount, setIsTransferAmount] = useState(false);
  const [isAccountTransaction, setIsAccountTransaction] = useState(false);
  const [isGiftCard, setIsGiftCard] = useState(false);
  const [reload, setReload] = useState(false);
  const [userProfile, setUserProfile] = useState<IUserToken>();
  const [availableAcctTypeData, setavailableAcctTypeData] =
    useState<IAvailableAcctValue[]>();
  const [availableAcctTypeAgent, setavailableAcctTypeAgent] =
    useState<IAvailableAcctValue[]>();
  const [availableAcctTypeCustomer, setavailableAcctTypeCustomer] =
    useState<IAvailableAcctValue[]>();
  const [selectedAccount, setSelectedAccount] = useState<IAccountsValue>();

  const openFundCustomer = async () => {
    if (await hasPin()) {
      setIsMoveAmount(false);
      setIsTransferAmount(false);
      setIsFundCustomoer(true);
      setIsStatement(false);
      setIsAccountTransaction(false);
      setIsGiftCard(false);
    } else {
      toast({
        title: "Pin setup",
        description: `You must setup pin before making a transaction. Kindly click on your profile at the top right corner of the application
            to set up your pin. Thank you.`,
        status: "success",
      });
    }
  };
  const openTransferAmount = async () => {
    if (await hasPin()) {
      setIsMoveAmount(false);
      setIsTransferAmount(true);
      setIsFundCustomoer(false);
      setIsStatement(false);
      setIsAccountTransaction(false);
      setIsGiftCard(false);
    } else {
      toast({
        title: "Pin setup",
        description: `You must setup pin before making a transaction. Kindly click on your profile at the top right corner of the application
            to set up your pin. Thank you.`,
        status: "success",
      });
    }
  };
  const openMoveAmount = async () => {
    if (await hasPin()) {
      setIsMoveAmount(true);
      setIsTransferAmount(false);
      setIsFundCustomoer(false);
      setIsStatement(false);
      setIsGiftCard(false);
    } else {
      toast({
        title: "Pin setup",
        description: `You must setup pin before making a transaction. Kindly click on your profile at the top right corner of the application
            to set up your pin. Thank you.`,
        status: "success",
      });
    }
  };
  const openGenerateStatement = (account: IAccountsValue | undefined) => {
    setIsAccountTransaction(false);
    setSelectedAccount(account);
    setIsMoveAmount(false);
    setIsTransferAmount(false);
    setIsFundCustomoer(false);
    setIsStatement(true);
    setIsGiftCard(false);
  };
  const viewAccountTransaction = () => {
    setIsAccountTransaction(true);
    setIsMoveAmount(false);
    setIsTransferAmount(false);
    setIsFundCustomoer(false);
    setIsStatement(false);
    setIsGiftCard(false);

    
  };
  const openGiftCard = () => {
    setIsAccountTransaction(false);
    setIsMoveAmount(false);
    setIsTransferAmount(false);
    setIsFundCustomoer(false);
    setIsStatement(false);
    setIsGiftCard(true);
  };
  const hasPin = async () => {
    const checkPin = await userProfileService.hasPin();
    if (checkPin.succeeded) {
      return true;
    }
    return false;
  };
  const reloadData = () => {
    setReload(!reload);
  };
  useEffect(() => {
    accountsService
      .getOwnAccounts()
      .then((data) => {
        const acc_type: IAccountsRes = data;
        setacctTypeData(acc_type.data.$values);
        setIsLoading(false);
      })
      .catch((err) => err);
    const userLogged = sessionStorage.getItem("UserFromToken");
    if (userLogged) {
      const parsedUser = JSON.parse(userLogged);
      setUserProfile(parsedUser);
      setIsLoading(false);
      if (parsedUser.assignedRoles.includes("Agent")) {
        agentService
          .getAvailableAccountTypeAgent()
          .then((data) => {
            const acc_type: IAvailableAcctRes = data;
            setavailableAcctTypeData(acc_type.data.$values);
            setIsLoading(false);
          })
          .catch((err) => err);
      } else {
        accountsService
          .getAvailableAccountsType()
          .then((data) => {
            const acc_type: IAvailableAcctRes = data;
            setavailableAcctTypeData(acc_type.data.$values);
            setIsLoading(false);
          })
          .catch((err) => err);
      }
    }
  }, [reload]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Box>
          <Flex justifyContent="space-between" alignItems="center" pb="20px">
            <Box color="#2B2B2B" fontWeight="800" fontSize="24px">
              Wallet
            </Box>
            {availableAcctTypeData && availableAcctTypeData.length > 0 && (
              <HStack>
                <Button
                  cursor="pointer"
                  color={"white"}
                  bg={"#011F78"}
                  fontSize={"13px"}
                  onClick={() => {
                    navigate(AdminRoutes.portal.createWallet);
                  }}
                >
                  Add Wallet
                </Button>
              </HStack>
            )}
          </Flex>
          <Flex justifyContent={"flex-start"} mb="20px">
            {acctTypeData && acctTypeData.length > 0 ? (
              acctTypeData.map((acct, index) => (
                <Container
                  bg="white"
                  key={index}
                  px={"20px"}
                  py={"15px"}
                  boxShadow="base"
                  mr={acctTypeData.length - 1 == index ? "0px" : "30px"}
                >
                  <Flex justifyContent={"space-between"} alignItems={"center"}>
                    <Box pb={3} pt={1}>
                      <Text color="#011F78" fontSize="14px">
                        {acct.type}
                      </Text>
                      <Text
                        color="#353535"
                        fontWeight="semibold"
                        fontSize={"24px"}
                      >
                        <NumericFormat
                          value={acct.balance.toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"₦"}
                        />
                      </Text>
                      <HStack>
                        <Text color="#011F78" fontSize={"12px"}>
                          {acct.id}
                        </Text>
                      </HStack>
                    </Box>
                    <Text
                      onClick={() => {
                        openGenerateStatement(acct);
                      }}
                      cursor={"pointer"}
                    >
                      <BsFillFileTextFill
                        color="#011F78"
                        title="Generate account statement"
                        fontSize={"28px"}
                      />
                    </Text>
                  </Flex>
                </Container>
              ))
            ) : (
              <Box
                borderRadius={"20px"}
                bg={"white"}
                py={"50px"}
                px={"50px"}
                width={"100%"}
              >
                No accounts
              </Box>
            )}
          </Flex>
          <Box
            mt="40px"
            boxShadow={"base"}
            borderRadius={"15px"}
            py="20px"
            bg={"white"}
            px={"30px"}
          >
            <Text
              mb={"20px"}
              color="#353535"
              fontSize={"16px"}
              fontWeight={"600"}
            >
              Quick Actions
            </Text>
            <Flex justifyContent="space-between" mb="20px">
              <Container
                bg="white"
                px={"20px"}
                py={"15px"}
                borderRadius={"10px"}
                boxShadow="base"
                cursor={"pointer"}
                m={0}
                onClick={() => {
                  openMoveAmount();
                }}
              >
                <Box pb={3} pt={1}>
                  <Flex
                    justifyContent={"center"}
                    textAlign={"center"}
                    color="#011F78"
                  >
                    <MdRecycling fontSize={"24px"} />
                  </Flex>
                  <Text
                    color="#353535"
                    textAlign={"center"}
                    fontWeight="500"
                    fontSize={"16px"}
                  >
                    Move Amount
                  </Text>
                </Box>
              </Container>

              <Spacer mx={3} />
              <Container
                bg="white"
                cursor={"pointer"}
                px={"20px"}
                py={"15px"}
                borderRadius={"10px"}
                boxShadow="base"
                m={0}
                onClick={() => {
                  openTransferAmount();
                }}
              >
                <Box pb={3} pt={1}>
                  <Flex
                    justifyContent={"center"}
                    textAlign={"center"}
                    color="#011F78"
                  >
                    <BiCoinStack fontSize={"24px"} />
                  </Flex>
                  <Text
                    color="#353535"
                    textAlign={"center"}
                    fontWeight="500"
                    fontSize={"16px"}
                  >
                    Transfer Amount
                  </Text>
                </Box>
              </Container>
              <Spacer mx={3} />
              {userProfile && userProfile.assignedRoles.includes("Agent") && (
                <Container
                  bg="white"
                  px={"20px"}
                  py={"15px"}
                  borderRadius={"10px"}
                  boxShadow="base"
                  m={0}
                  cursor={"pointer"}
                  onClick={() => {
                    openFundCustomer();
                  }}
                >
                  <Box pb={3} pt={1}>
                    <Flex
                      justifyContent={"center"}
                      textAlign={"center"}
                      color="#011F78"
                    >
                      <BiCoinStack fontSize={"24px"} />
                    </Flex>
                    <Text
                      color="#353535"
                      textAlign={"center"}
                      fontWeight="500"
                      fontSize={"16px"}
                    >
                      Fund Customer
                    </Text>
                  </Box>
                </Container>
              )}

              <Spacer mx={3} />
              <Container
                bg="white"
                onClick={() => {
                  viewAccountTransaction();
                }}
                cursor={"pointer"}
                px={"20px"}
                py={"15px"}
                borderRadius={"10px"}
                boxShadow="base"
                m={0}
              >
                <Box pb={3} pt={1}>
                  <Flex
                    justifyContent={"center"}
                    textAlign={"center"}
                    color="#011F78"
                  >
                    <BsWallet fontSize={"24px"} />
                  </Flex>
                  <Text
                    color="#353535"
                    textAlign={"center"}
                    fontWeight="500"
                    fontSize={"16px"}
                  >
                    Transaction Log
                  </Text>
                </Box>
              </Container>
              <Spacer mx={3} />
              <Container
                bg="white"
                cursor={"pointer"}
                px={"20px"}
                py={"15px"}
                borderRadius={"10px"}
                boxShadow="base"
                m={0}
                onClick={() => {
                  openGenerateStatement(undefined);
                }}
              >
                <Box pb={3} pt={1}>
                  <Flex
                    justifyContent={"center"}
                    textAlign={"center"}
                    color="#011F78"
                  >
                    <BsFillFileTextFill fontSize={"24px"} />
                  </Flex>
                  <Text
                    color="#353535"
                    textAlign={"center"}
                    fontWeight="500"
                    fontSize={"16px"}
                  >
                    Generate Statement
                  </Text>
                </Box>
              </Container>
            </Flex>
            <Flex justifyContent="space-between" mb="20px">
              <Container
                bg="white"
                px={"20px"}
                py={"15px"}
                borderRadius={"10px"}
                boxShadow="base"
                m={0}
              >
                <Box pb={3} pt={1}>
                  <Flex
                    justifyContent={"center"}
                    textAlign={"center"}
                    color="#011F78"
                  >
                    <FaMobile fontSize={"24px"} />
                  </Flex>
                  <Text
                    color="#353535"
                    textAlign={"center"}
                    fontWeight="500"
                    fontSize={"16px"}
                  >
                    Mobile Topup
                  </Text>
                </Box>
              </Container>

              <Spacer mx={3} />
              <Container
                bg="white"
                px={"20px"}
                py={"15px"}
                borderRadius={"10px"}
                boxShadow="base"
                m={0}
              >
                <Box pb={3} pt={1}>
                  <Flex
                    justifyContent={"center"}
                    textAlign={"center"}
                    color="#011F78"
                  >
                    <BiCoinStack fontSize={"24px"} />
                  </Flex>
                  <Text
                    color="#353535"
                    textAlign={"center"}
                    fontWeight="500"
                    fontSize={"16px"}
                  >
                    Buy Data
                  </Text>
                </Box>
              </Container>

              <Spacer mx={3} />
              <Container
                bg="white"
                px={"20px"}
                py={"15px"}
                borderRadius={"10px"}
                boxShadow="base"
                m={0}
              >
                <Box pb={3} pt={1}>
                  <Flex
                    justifyContent={"center"}
                    textAlign={"center"}
                    color="#011F78"
                  >
                    <MdElectricBolt fontSize={"24px"} />
                  </Flex>
                  <Text
                    color="#353535"
                    textAlign={"center"}
                    fontWeight="500"
                    fontSize={"16px"}
                  >
                    Electricity
                  </Text>
                </Box>
              </Container>
              <Spacer mx={3} />
              <Container
                bg="white"
                px={"20px"}
                py={"15px"}
                borderRadius={"10px"}
                boxShadow="base"
                m={0}
              >
                <Box pb={3} pt={1}>
                  <Flex
                    justifyContent={"center"}
                    textAlign={"center"}
                    color="#011F78"
                  >
                    <TbWorldBolt fontSize={"24px"} />
                  </Flex>
                  <Text
                    color="#353535"
                    textAlign={"center"}
                    fontWeight="500"
                    fontSize={"16px"}
                  >
                    Internet
                  </Text>
                </Box>
              </Container>
              <Spacer mx={3} />
              <Container
                bg="white"
                px={"20px"}
                py={"15px"}
                borderRadius={"10px"}
                boxShadow="base"
                cursor={"pointer"}
                m={0}
                onClick={() => {
                  openGiftCard();
                }}
              >
                <Box pb={3} pt={1}>
                  <Flex
                    justifyContent={"center"}
                    textAlign={"center"}
                    color="#011F78"
                    
                  >
                    <BsWallet fontSize={"24px"} />
                  </Flex>
                  <Text
                    color="#353535"
                    textAlign={"center"}
                    fontWeight="500"
                    fontSize={"16px"}
                  >
                    Request Gift Card
                  </Text>
                </Box>
              </Container>
            </Flex>
          </Box>
          {isMoveAmount && (
            <Portal>
              {" "}
              <MoveAmountModal
                isOpenMoveAmount={isMoveAmount}
                onClosed={setIsMoveAmount}
                isMoveAmount={isMoveAmount}
                reload={reloadData}
              />{" "}
            </Portal>
          )}
          {isTransferAmount && (
            <Portal>
              {" "}
              <TransferAmountModal
                isOpenTransferAmount={isTransferAmount}
                onClosed={setIsTransferAmount}
                isTransferAmount={isTransferAmount}
                reload={reloadData}
              />{" "}
            </Portal>
          )}
          {isFundCustomer && (
            <Portal>
              {" "}
              <FundCustomerModal
                isOpenFundCustomer={isFundCustomer}
                onClosed={setIsFundCustomoer}
                isFundCustomer={isFundCustomer}
                reload={reloadData}
              />{" "}
            </Portal>
          )}
          {isStatement && (
            <Portal>
              {" "}
              <GenerateStatementModal
                isOpenGenerateStatement={isStatement}
                onClosed={setIsStatement}
                isGenerateStatement={isStatement}
                reload={reloadData}
                account={selectedAccount}
              />{" "}
            </Portal>
          )}
          {isAccountTransaction && (
            <Portal>
              {" "}
              <AccountTransactionModal
                isOpenAccountTransaction={isAccountTransaction}
                onClosed={setIsAccountTransaction}
                isAccountTransaction={isAccountTransaction}
                reload={reloadData}
              />{" "}
            </Portal>
          )}
            {isGiftCard && (
            <Portal>
              {" "}
              <RequestGiftCardModal
                isOpenGiftCard={isGiftCard}
                onClosed={setIsGiftCard}
                isGiftCard={isGiftCard}
                reload={reloadData}
              />{" "}
            </Portal>
          )}
        </Box>
      )}
    </>
  );
};

export default AccountsWallet;
