import { Box, Flex, HStack, Button, InputGroup, Input, InputRightElement, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Avatar, Text, MenuItem, Menu, MenuButton, MenuList, Center, Tab, TabList, TabPanel, TabPanels, Tabs, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Portal, useToast, Select } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import { BsArrowLeft, BsArrowRight, BsThreeDotsVertical } from 'react-icons/bs'
import { RiSearch2Line } from 'react-icons/ri'
import { NumericFormat } from 'react-number-format'
import usersService from '../../services/user/users.service'
import { IOfflineUserData, IOfflineUserRes, IOfflineUserValue } from '../../models/users/offlineUsers.model'
import Loading from '../Loading'
import EditUserModal from './modal/EditUserModal'
import { IActiveUsersValue } from '../../models/users/activeUsers.model'
import { Pagination, PaginationContainer, PaginationNext, PaginationPage, PaginationPageGroup, PaginationPrevious, usePagination } from "@ajna/pagination";
import { Ipage } from '../../models/audit/auditlog.model'

const OfflineUsers = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [offlineUserData, setofflineUserData] = useState<IOfflineUserValue[]>();
    const [selectedUser, setSelectedUse] = useState<any>();
    const [isLoading, setIsLoading] = useState(true);
    const [parameterName, setParameterName] = useState<string>();
    const [isOpenAlert, setIsOpenAlert] = useState(false);
    const onCloseAlert = () => setIsOpenAlert(false);
    const [reload, setReload] = useState(false);
    const toast = useToast()
    const cancelRef = useRef(null);
    const [pageSize, setPageSize] = useState(25);
    const [search, setSearch] = useState("");
    const [query, setQuery] = useState("");
    const [page, setPage] = useState<Ipage>({ page_total: 0, total: 0, page_count: 0 });
    const {
        currentPage,
        setCurrentPage,
        pagesCount,
        pages
    } = usePagination({
        pagesCount: page.page_count,
        initialState: { currentPage: 1, pageSize: 100 },
        total: page.total
    });
    useEffect(() => {
        const url = `?pageNumber=${currentPage}&pageSize=${pageSize}&searchString=${query}`

        usersService.getOfflineUsers(url)
            .then((data) => {
                const offlineUser: IOfflineUserRes = data
                setPage({ page_total: offlineUser.pageSize, total: offlineUser.totalCount, page_count: offlineUser.totalPages })

                setofflineUserData(offlineUser.data.$values)
                setIsLoading(false)
            })
            .catch((err) => err)
        }, [currentPage, query, reload,pageSize]);
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (search.length > 0) {
                setQuery(search);
            } else {
                setQuery("");
            }
        }, 700);

        return () => clearTimeout(delayDebounceFn)
    }, [search])
    const changePageSize = (e: any) => {
        setPageSize(e.target.value)
    }
    const openDeactivateUser = (param: string) => {
        setParameterName(param)
        setIsOpen(false)
        setIsOpenAlert(true)
    };
    const openEditUser = (param: any) => {
        setSelectedUse(param)
        setIsOpen(true)
        setIsOpenAlert(false)
    };
    const deactivateUser = async () => {
        try {
            const delete_param: any = await usersService.toggleActivation({ username: parameterName, activation: false })
            console.log(delete_param.succeeded)
            if (delete_param.succeeded) {
                toast({
                    title: "User deactivation",
                    description: "User deactivated successfully",
                    status: "success",
                });
                setReload(!reload)
                onCloseAlert();

            } else {
                toast({
                    title: "User deactivation",
                    description: `${delete_param.messages.$values[0]}`,
                    status: "error",
                });
            }
        } catch (error: any) {
            toast({
                title: "User deactivation",
                description: `${error}`,
                status: "error",
            });
        }
    }
    return (
        <>
            {
                isLoading ? <Loading /> :
                    <Box>
                        <Flex py='15px' justifyContent=''>
                            <InputGroup bg="white" width="30%" mr='20px'>
                                <Input height='40px' placeholder="Search input here" onChange={e => setSearch(e.target.value)} fontSize={12} size="sm" />
                                <InputRightElement pointerEvents="none" children={<RiSearch2Line color="green" size="0.9rem" />} />
                            </InputGroup>

                        </Flex>
                        <Box width={'100%'}>
                            <TableContainer>
                                <Table size={'sm'}>
                                    <Thead py={'15px'}>
                                        <Tr bg="#3778D9" color={'white'} >
                                            <Td py={'15px'}  textTransform={'capitalize'}>Name</Td>
                                            <Td py={'15px'}  textTransform={'capitalize'}>Username</Td>
                                            <Td  textTransform={'capitalize'}>Phone No.</Td>
                                            <Td  textTransform={'capitalize'}>Agent Name</Td>

                                            <Td  textTransform={'capitalize'}>Actions</Td>
                                        </Tr>
                                    </Thead>
                                    <Tbody>

                                        {
                                            offlineUserData && offlineUserData.length > 0 ? offlineUserData.map((user, index) => (
                                                <Tr key={index} bg="white" >
                                                    <Td>
                                                        <HStack spacing='5px' mt={'10px'}>
                                                            <Avatar
                                                                size="sm"
                                                                name={`${user.name}`}
                                                            />
                                                            <Box>
                                                                <Text fontWeight='500' textTransform='capitalize' fontSize='13px' >
                                                                    {`${user.name}`}
                                                                </Text>
                                                            </Box>
                                                        </HStack>
                                                    </Td>
                                                    <Td>
                                                        <Text fontSize={'13px'}>{user.userName}</Text>
                                                    </Td>


                                                    <Td>
                                                        <Text fontSize={'13px'} >{user.phoneNumber}</Text>
                                                    </Td>
                                                    <Td>
                                                        <Text fontSize={'13px'}>

                                                            {user.agentName}
                                                        </Text>
                                                    </Td>


                                                    <Td>
                                                        <Button
                                                            cursor="pointer"
                                                            color={"white"}
                                                            bg={"#011F78"}
                                                            size={'sm'}
                                                            fontSize={'13px'}
                                                            onClick={() => openEditUser(user)}
                                                        >
                                                            view
                                                        </Button>
                                                    </Td>
                                                </Tr>
                                            )) : <Box>No data</Box>
                                        }



                                    </Tbody>
                                </Table>
                            </TableContainer>
                            <Flex alignContent={"flex-end"} justifyContent={'flex-end'} mt={4}>

<HStack>
    <Text fontWeight={400} fontSize={'13px'}>Rows per page :</Text>
    <Select
        variant="outline"
        size="sm"
        width={'70px'}
        value={pageSize}
        onChange={(e) => { changePageSize(e) }}
        fontSize={12}
    >
        <option value={10}>
            10
        </option>
        <option value={25}>
            25
        </option>
        <option value={50}>
            50
        </option>
        <option value={100}>
            100
        </option>

    </Select>

</HStack>
<Pagination
    pagesCount={pagesCount}
    currentPage={currentPage}
    onPageChange={setCurrentPage}
>
    <PaginationContainer>
        <PaginationPrevious><BsArrowLeft /></PaginationPrevious>
        <PaginationPageGroup>
            {pages.map((page: number) => (
                <PaginationPage
                    key={`pagination_page_${page}`}
                    page={page}
                />
            ))}
        </PaginationPageGroup>
        <PaginationNext><BsArrowRight /></PaginationNext>
    </PaginationContainer>
</Pagination>

</Flex>
                        </Box>

                        {isOpen && <EditUserModal setIsOpen={setIsOpen} user={selectedUser} reload={setReload} />}
                        <Portal>
                            <AlertDialog
                                isOpen={isOpenAlert}
                                leastDestructiveRef={cancelRef}
                                onClose={onCloseAlert}
                            >
                                <AlertDialogOverlay>
                                    <AlertDialogContent>
                                        <AlertDialogHeader fontSize="md" fontWeight="semibold">
                                            Deactivate {parameterName}
                                        </AlertDialogHeader>

                                        <AlertDialogBody fontSize={15}>
                                            Are you sure? You can't undo this action afterwards.
                                        </AlertDialogBody>

                                        <AlertDialogFooter>
                                            <Button size={"sm"} ref={cancelRef} onClick={onCloseAlert}>
                                                Cancel
                                            </Button>
                                            <Button
                                                size={"sm"}
                                                colorScheme="red"
                                                onClick={deactivateUser}
                                                ml={3}
                                            >
                                                Deactivate
                                            </Button>
                                        </AlertDialogFooter>
                                    </AlertDialogContent>
                                </AlertDialogOverlay>
                            </AlertDialog>
                        </Portal>
                    </Box>
            }
        </>
    )
}

export default OfflineUsers