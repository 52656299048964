import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    Box,
    useToast,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Flex,
    Button,
    HStack,
    Textarea,
    Select,
    Portal,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { IHandleTransaction } from "../../models/transactions/handleTransaction";
import { IAccountsValue, IAccountsRes } from "../../models/accounts/accounts.model";
import accountsService from "../../services/accounts/accounts.service";
import agentService from "../../services/agent/agent.service";
import transactionService from "../../services/transaction/transaction.service";

export const FundAccountModal: React.FC<any> = ({
    isOpenFundAccount,
    onClosed,
    isFundAccount = false,
    reload,
    fundAcct
}: {
    isFundAccount: boolean;
    isOpenFundAccount: any;
    onClosed: any;
    reload: any;
    fundAcct: any
}) => {
    const toast = useToast();
    const cancelRef = useRef().current;
    const [openPin, setOpenPin] = useState(false)
    const [accountList, setacctTypeData] = useState<IAccountsValue[]>();
    const [moveToList, setMoveToLis] = useState<IAccountsValue[]>();
    const [acctDet, setAcctDet] = useState<any>();

    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        setAcctDet(fundAcct)
        accountsService.getOwnAccounts()
            .then((data) => {
                const acc_type: IAccountsRes = data
                setacctTypeData(acc_type.data.$values)
                setMoveToLis(acc_type.data.$values)
                setIsLoading(false)
            })
            .catch((err) => err)
    }, [acctDet]);
    const formik = useFormik({
        validate: (values: any) => {
            const errors: any = {};
           

            return errors;
        },
        initialValues: {
            source: "",
            amount: "",


        },
        onSubmit: async (values) => {
            const transaction: any = {
                accountId: acctDet,
                amountToFund: parseInt(values.amount),
            }

            try {
                const handle_transaction: any = await accountsService.fundAccount(transaction)
                console.log(handle_transaction.succeeded)
                if (handle_transaction.succeeded) {
                    toast({
                        title: "Fund Account",
                        description: "account funded successfully",
                        status: "success",
                    });
                    onClosed(!isFundAccount)
                    reload()

                } else {
                    toast({
                        title: "Fund Account",
                        description: `${handle_transaction.messages.$values[0]}`,
                        status: "error",
                    });
                }
            } catch (error: any) {
                toast({
                    title: "Fund Account",
                    description: `${error}`,
                    status: "error",
                });
            }



        }
    });



    return (
        <>
            <Modal
                isOpen={isOpenFundAccount && isFundAccount}
                onClose={onClosed}
                scrollBehavior="outside"
                closeOnOverlayClick={true}
                size="lg"
            >
                <ModalOverlay />
                <ModalContent borderRadius={5} px={6}>
                    <ModalHeader>
                        {
                            <Text
                                fontSize={'18px'}
                                pb={3}
                                textTransform="capitalize"

                            >
                                Fund Account
                            </Text>
                        }
                    </ModalHeader>
                    <ModalCloseButton size="sm" />
                    <ModalBody pb={'30px'}>
                        <Box mt={2}>
                            <form onSubmit={formik.handleSubmit}>
                                <Text>
                                    Fund : {acctDet}
                                </Text>

                                <FormControl
                                    mt={3}
                                    isInvalid={
                                        formik.errors.amount && formik.touched.amount ? true : false
                                    }
                                >
                                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                        Amount
                                    </FormLabel>
                                    <Input
                                        type="number"
                                        id="amount"
                                        name="amount"
                                        placeholder="Enter amount"
                                        onChange={formik.handleChange}
                                        fontSize={13}
                                    />
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.amount}
                                    </FormErrorMessage>
                                </FormControl>



                                <Flex mt={12} justifyContent={'space-between'} >


                                    <Button

                                        px={6}
                                        colorScheme="blue"
                                        fontSize={'14px'}
                                        variant={'outline'}
                                        ref={cancelRef}
                                        width={'49%'}
                                        onClick={() => onClosed()}
                                    >
                                        Cancel
                                    </Button>
                                    <Button

                                        type="submit"
                                        isLoading={formik.isSubmitting}
                                        color={"white"}
                                        width={'49%'}
                                        bg={"#011F78"}
                                        px={6}
                                        fontSize={'14px'}
                                    >
                                        Confirm
                                    </Button>


                                </Flex>
                            </form>
                        </Box>

                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
