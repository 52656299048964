import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    Box,
    useToast,
    Flex,
    Button,
    Center,
    Image
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import OTPInput from "react-otp-input";
import transfer from '../../img/transfer.svg'
import authService from "../../services/auth/auth.service";
import AdminRoutes from "../../pages/routes";
import { useNavigate } from "react-router-dom";
export const GenerateOneTimeCode: React.FC<any> = ({
    isOpen,
    onClosed,
    isGenerate = false,
    userId,
    reload

}: {
    isGenerate: boolean;
    isOpen: any;
    onClosed: any;
    userId: string;
    reload: any
}) => {
    const [
        { otp, numInputs, separator, minLength, maxLength, placeholder, inputType },
        setConfig,
    ] = useState({
        otp: "",
        numInputs: 6,
        separator: "--",
        minLength: 0,
        maxLength: 40,
        placeholder: "",
        inputType: "number" as const,
    });
    console.log(otp, ' otp')
    const route = useNavigate();
    const toast = useToast()
    const [showVerify, setShowVerify] = useState(false)
    const handleOTPChange = (otp: string) => {
        setConfig((prevConfig) => ({ ...prevConfig, otp }));
    };

    const generateCode = async () => {

        try {
            const data = {
                userId: userId
            }

            const generate_code: any = await authService.generateCode(data)
            console.log(generate_code.succeeded)
            if (generate_code.succeeded) {
                toast({
                    title: "Generate Code",
                    description: `One time code generated successfully`,
                    status: "success",
                });
                setShowVerify(true)

            } else {
                toast({
                    title: "Generate Code",
                    description: `${generate_code.messages.$values[0]}`,
                    status: "error",
                });
            }

        } catch (error: any) {
            toast({
                title: "Generate Code",
                description: `${error}`,
                status: "error",
            });
        }
    };
    const handleSubmit = async () => {

        try {
            const data = {
                userId: userId,
                code: otp,
                doUserReactivation: true,

            }


            const generate_code: any = await authService.verifyCode(data)
            console.log(generate_code.succeeded)
            if (generate_code.succeeded) {
                toast({
                    title: "User Activation",
                    description: `user activated successfully`,
                    status: "success",
                });
                route(AdminRoutes.Login)
                onClosed(!isGenerate)
            } else {
                toast({
                    title: "User Activation",
                    description: `${generate_code.messages.$values[0]}`,
                    status: "error",
                });
            }

        } catch (error: any) {
            toast({
                title: "User Activation",
                description: `${error}`,
                status: "error",
            });
        }
    };
    return (
        <>
            <Modal
                isOpen={isOpen && isGenerate}
                onClose={onClosed}
                scrollBehavior="outside"
                closeOnOverlayClick={true}
                size="lg"
            >
                <ModalOverlay />
                <ModalContent borderRadius={5} px={6}>
                    <ModalHeader>
                        {
                            showVerify ? <Text
                                fontSize={13}
                                pb={3}
                                textTransform="uppercase"
                                pl={3}
                                color="brand.2"
                            >Generate Code</Text> :
                                <Text
                                    fontSize={13}
                                    pb={3}
                                    textTransform="uppercase"
                                    pl={3}
                                    color="brand.2"
                                >Verify Code</Text>
                        }
                    </ModalHeader>
                    <ModalCloseButton size="sm" />
                    <ModalBody pb={6}>
                        {
                            showVerify ?
                                <Box mt={2}>
                                    <Flex mb={'30px'} width={"100%"} justifyContent={"center"}>
                                        <Image src={transfer} />
                                    </Flex>
                                    <Flex width={"100%"} justifyContent={"center"}>
                                        <Box>
                                            <Box >
                                                <OTPInput
                                                    inputStyle="inputStyle"
                                                    numInputs={numInputs}
                                                    onChange={handleOTPChange}
                                                    renderSeparator={<span>{separator}</span>}
                                                    value={otp}
                                                    placeholder={placeholder}
                                                    inputType={inputType}
                                                    renderInput={(props) => <input {...props} />}
                                                    shouldAutoFocus
                                                />
                                            </Box>
                                            <Flex mt={12} justifyContent={'space-between'} >
                                                <Button
                                                    px={6}
                                                    colorScheme="blue"
                                                    fontSize={'14px'}
                                                    variant={'outline'}
                                                    width={'49%'}
                                                    onClick={() => onClosed()}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    type="button"
                                                    color={"white"}
                                                    width={'49%'}
                                                    bg={"#011F78"}
                                                    px={6}
                                                    onClick={() => { handleSubmit() }}
                                                    fontSize={'14px'}
                                                >
                                                    Confirm
                                                </Button>
                                            </Flex>
                                        </Box>
                                    </Flex>
                                </Box> :
                                <Box mt={2}>
                                    <Text>
                                        In order to reactivate your account,
                                        a one time code will be generated by the system and sent to you.
                                        you must verify the one time code in order to be reactivated
                                    </Text>
                                    <Flex mt={12} justifyContent={'space-between'} >
                                        <Button
                                            px={6}
                                            colorScheme="blue"
                                            fontSize={'14px'}
                                            variant={'outline'}
                                            width={'49%'}
                                            onClick={() => onClosed()}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="button"
                                            color={"white"}
                                            width={'49%'}
                                            bg={"#011F78"}
                                            px={6}
                                            onClick={() => { generateCode() }}
                                            fontSize={'14px'}
                                        >
                                            Generate code
                                        </Button>
                                    </Flex>
                                </Box>
                        }
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
