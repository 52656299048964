import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    Box,
    useToast,
    Flex,
    Button,
    Center,
    Image
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import OTPInput from "react-otp-input";
import transfer from '../../img/transfer.svg'
import authService from "../../services/auth/auth.service";
import AdminRoutes from "../../pages/routes";
import { useNavigate } from "react-router-dom";
export const VerifyPhoneModal: React.FC<any> = ({
    isOpen,
    onClosed,
    isPhone = false,
    username,
    code,
    phone
}: {
    isPhone: boolean;
    isOpen: any;
    onClosed: any;
    username: string;
    code: string;
    phone: string
}) => {
    const [
        { otp, numInputs, separator, minLength, maxLength, placeholder, inputType },
        setConfig,
    ] = useState({
        otp: "",
        numInputs: 6,
        separator: "--",
        minLength: 0,
        maxLength: 40,
        placeholder: "",
        inputType: "number" as const,
    });
    console.log(otp,' otp')
    const route = useNavigate();
    const toast = useToast()
    const handleOTPChange = (otp: string) => {
        setConfig((prevConfig) => ({ ...prevConfig, otp }));
    };

    
    const handleSubmit = async () => {

        try {
            const data = {
                userName: username,
                countryCallingCode: code,
                phoneNumber: phone.toString(),
                code: otp
            }

            const register_phone: any = await authService.verifyPhoneNumber(data)
            console.log(register_phone.succeeded)
            if (register_phone.succeeded) {
                toast({
                    title: "Register Phone",
                    description: `phone number registered successful`,
                    status: "success",
                });
                route(AdminRoutes.Login)
            } else {
                toast({
                    title: "Register Phone",
                    description: `${register_phone.messages.$values[0]}`,
                    status: "error",
                });
            }

        } catch (error: any) {
            toast({
                title: "Register Phone",
                description: `${error}`,
                status: "error",
            });
        }
    };
    return (
        <>
            <Modal
                isOpen={isOpen && isPhone}
                onClose={onClosed}
                scrollBehavior="outside"
                closeOnOverlayClick={true}
                size="lg"
            >
                <ModalOverlay />
                <ModalContent borderRadius={5} px={6}>
                    <ModalHeader>
                        {
                            <Text
                                fontSize={13}
                                pb={3}
                                textTransform="uppercase"
                                pl={3}
                                color="brand.2"
                            >Enter Code</Text>
                        }
                    </ModalHeader>
                    <ModalCloseButton size="sm" />
                    <ModalBody pb={6}>
                        <Box mt={2}>
                            <Flex mb={'30px'} width={"100%"} justifyContent={"center"}>
                                <Image src={transfer} />
                            </Flex>
                            <Flex width={"100%"} justifyContent={"center"}>
                                <Box>
                                    <Box >
                                        <OTPInput
                                            inputStyle="inputStyle"
                                            numInputs={numInputs}
                                            onChange={handleOTPChange}
                                            renderSeparator={<span>{separator}</span>}
                                            value={otp}
                                            placeholder={placeholder}
                                            inputType={inputType}
                                            renderInput={(props) => <input {...props} />}
                                            shouldAutoFocus
                                        />
                                    </Box>
                                    <Flex mt={12} justifyContent={'space-between'} >
                                        <Button
                                            px={6}
                                            colorScheme="blue"
                                            fontSize={'14px'}
                                            variant={'outline'}
                                            width={'49%'}
                                            onClick={() => onClosed()}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="button"
                                            color={"white"}
                                            width={'49%'}
                                            bg={"#011F78"}
                                            px={6}
                                            onClick={() => { handleSubmit() }}
                                            fontSize={'14px'}
                                        >
                                            Confirm
                                        </Button>
                                    </Flex>
                                </Box>
                            </Flex>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
