import deleteService from "../crud-requests/deleteService";
import getService from "../crud-requests/getService";
import postService from "../crud-requests/postService";
import putService from "../crud-requests/putService";


const CONFIG_API_URL = "https://callnpayapi20230120085359.azurewebsites.net/api/";

class AccountsService {
  async getAccounts(url: string) {
    const response = await getService.get(
      `${CONFIG_API_URL}account/getaccounts${url}`,
    );
    return response.data;
  }
  async getOwnAccounts() {
    const response = await getService.get(
      `${CONFIG_API_URL}account/getownaccounts`,
    );
    return response.data;
  }
  async getAccountsTypes() {
    const response = await getService.get(
      `${CONFIG_API_URL}account/getaccounttypes`,
    );
    return response.data;
  }
  async getCustomerAccountsType() {
    const response = await getService.get(
      `${CONFIG_API_URL}account/getcustomeraccounttypes`,

    );
    return response.data;
  }
  async getAccountsType(id: string) {
    const response = await getService.get(
      `${CONFIG_API_URL}account/getaccounttype${id}`,
    );
    return response.data;
  }
  async getUserContactTemplates() {
    const response = await getService.get(
      `${CONFIG_API_URL}account/getusercontacttemplates`,
    );
    return response.data;
  }
  async getAvailableAccountsType() {
    const response = await getService.get(
      `${CONFIG_API_URL}account/getavailableaccounttypes`,
    );
    return response.data;
  }
  async getAgentCustomerByUsername(username: string) {
    const response = await getService.get(
      `${CONFIG_API_URL}agent/getagentcustomersummarybyagentusername/${username}`,
    );
    return response.data;
  }
  ///api/agent/getagentcustomersummarybyagentusername
  async updateAccountType(data: any) {
    const response = await putService.put(
      `${CONFIG_API_URL}account/updateaccounttype`,
      data
    );
    return response.data;
  }
  async fundAccount(data: any) {
    const response = await putService.put(
      `${CONFIG_API_URL}account/fundaccount`,
      data
    );
    return response.data;
  }


  async createAccount(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}account/createaccount`,
      data
    );
    return response.data;
  }
  async createUserContactTemplate(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}account/createusercontacttemplate`,
      data
    );
    return response.data;
  }
  async createAccountType(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}account/createaccounttype`,
      data
    );
    return response.data;
  }
}

export default new AccountsService();