import { Box, Flex, HStack, Button, InputGroup, Input, InputRightElement, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Avatar, Text, MenuItem, Menu, MenuButton, MenuList, Center, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';
import Accounts from '../../components/accounts/Accounts';
import AccountsType from '../../components/accounts/AccountsType';
import AdminRoutes from '../routes';



const AdminAccounts = () => {
    let navigate = useNavigate();
    const addAccountPage = () => {
        navigate(AdminRoutes.portal.addAccount);

    }
    return (
        <>
            <Box >
                <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    py="26px"
                >
                    <Box color="#2B2B2B" fontWeight="800" fontSize="27px">
                        Account
                    </Box>

                </Flex>
                <Box>
                    <Tabs colorScheme='blue'>
                        <TabList>
                            <Tab>Accounts</Tab>
                            <Tab>Account Types</Tab>


                        </TabList>

                        <TabPanels>
                            <TabPanel>
                                <Accounts />
                            </TabPanel>
                            <TabPanel>
                                <AccountsType />
                            </TabPanel>

                        </TabPanels>
                    </Tabs>
                </Box>

            </Box>
        </>
    )
}

export default AdminAccounts;