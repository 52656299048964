import {
    Box,
    Button,
    Center,
    Flex,
    FormControl,
    FormErrorMessage,
    Text,
    FormLabel,
    Input,
    InputGroup,
    useToast,
    Link,
    HStack,
    Stack,
    InputLeftElement,
    InputRightElement,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import AuthLayout from "./AuthLayout";
import AdminRoutes from "../routes";
import authService from "../../services/auth/auth.service";
import { IRegRes } from "../../models/auth/registration.model";
import { BiHide, BiShow } from "react-icons/bi";
import { MdLock } from "react-icons/md";
import { useState } from "react";

const Register = () => {
    const route = useNavigate();
    const toast = useToast();
    const [show, setShow] = useState(false)
    const handleClick = () => setShow(!show)
    const [show2, setShow2] = useState(false)
    const handleClick2 = () => setShow2(!show2)
    const validate = (values: any) => {
        const errors: any = {};
        if (!values.email) {
            errors.email = "Email is required";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = "Invalid email address";
        }

        if (!values.password) {
            errors.password = 'Password is required'
        }
        if (!values.confirmPassword) {
            errors.confirmPassword = 'Confirm Password'
        }
        if (values.password !== values.confirmPassword) {
            errors.confirmPassword = 'Password do not match'
        }
        return errors;
    };

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
            confirmPassword: "",
            firstName: '',
            lastName: '',
            username: ""
        },
        validate,
        onSubmit: async (values) => {
            try {
                const data = {
                    username: values.username,
                    email: values.email,
                    password: values.password,
                    confirmPassword: values.confirmPassword,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    clientConfirmationUrl: "https://callanpay.vochus.com/"
                }
                const register_user: IRegRes = await authService.register(data)
                console.log(register_user.succeeded)
                if (register_user.succeeded) {
                    toast({
                        title: "Registration",
                        description: `registration successful`,
                        status: "success",
                    });
                    route(AdminRoutes.emailSent)
                } else {
                    toast({
                        title: "Registration",
                        description: `${register_user.messages.$values[0]}`,
                        status: "error",
                    });
                }
            } catch (error: any) {
                toast({
                    title: "Registration",
                    description: `${error}`,
                    status: "error",
                });
            }
        },
    });

    return (
        <>
            <AuthLayout>
                <Flex mx="auto" className="resizable_div" height="95vh">
                    <Box >
                        <Box
                            ml={["30px", "30px", "60px", "60px"]}
                            mr={["30px", "30px", "0px", "0px"]}
                            mt={'20px'}
                            mb="'14px'px"
                        >


                            <Box mb={'15px'}>
                                <Text
                                    mb={'15px'}
                                    fontSize={["15px", "15px", "24px", "24px"]}
                                    fontWeight="700"
                                    color="#011F78"
                                >
                                    Registration
                                </Text>
                                <Text

                                    fontSize={["15px", "15px", "14px", "14px"]}
                                    fontWeight="400"
                                    color="#90979E"
                                >
                                    Enter your information below to register
                                </Text>
                            </Box>


                            <form onSubmit={formik.handleSubmit}>
                                <FormControl mb={'15px'} isInvalid={(formik.errors.username && formik.touched.username) ? true : false} >
                                    <FormLabel color="#757575" fontSize={'14px'}  >Username </FormLabel>
                                    <Input   w={["300px", "300px", "520px", "520px"]} placeholder="Enter username" value={formik.values.username} name="username" fontSize={'13px'} onChange={formik.handleChange} />
                                    <FormErrorMessage fontSize={12} >{formik.errors.username}</FormErrorMessage>
                                </FormControl>

                                <HStack>
                                    <FormControl mb={'15px'} isInvalid={(formik.errors.firstName && formik.touched.firstName) ? true : false} >
                                        <FormLabel color="#757575" fontSize={'14px'}  >First Name </FormLabel>
                                        <Input placeholder="Enter first name" value={formik.values.firstName} name="firstName" fontSize={'13px'} onChange={formik.handleChange} />
                                        <FormErrorMessage fontSize={12} >{formik.errors.firstName}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl mb={'15px'} isInvalid={(formik.errors.lastName && formik.touched.lastName) ? true : false} >
                                        <FormLabel color="#757575" fontSize={'14px'}>Last Name</FormLabel>
                                        <Input bg="inputText.500" placeholder="Enter last name" value={formik.values.lastName} name="lastName" onChange={formik.handleChange} fontSize={'13px'} />
                                        <FormErrorMessage fontSize={12} >{formik.errors.lastName}</FormErrorMessage>
                                    </FormControl>
                                </HStack>

                                <FormControl
                                    mb="15px"
                                    isInvalid={
                                        formik.errors.email && formik.touched.email ? true : false
                                    }
                                >
                                    <FormLabel color="#757575" fontSize={'14px'}>
                                        Email Address{" "}
                                    </FormLabel>

                                    <Input
                                       
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder="Enter email"
                                        onChange={formik.handleChange}
                                        fontSize={'13px'}
                                    />

                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.email}
                                    </FormErrorMessage>
                                </FormControl>


                              <HStack>
                              <FormControl
                                    mb="15px"
                                    isInvalid={
                                        formik.errors.password && formik.touched.password
                                            ? true
                                            : false
                                    }
                                >
                                    <FormLabel color="#757575" fontSize={'14px'}>
                                        Password{" "}
                                    </FormLabel>

                                    <InputGroup>
                                        <InputLeftElement
                                            color="brand.subtitle"
                                            pointerEvents="none"
                                            children={<MdLock />}
                                        />
                                        <Input
                                           
                                            type={show ? 'text' : 'password'}
                                            name="password"
                                            id="password"
                                            placeholder="Enter  password"
                                            onChange={formik.handleChange}
                                            fontSize={14}
                                        />
                                        <InputRightElement width='4.5rem'>
                                            <Center cursor={'pointer'} h='1.75rem'  onClick={handleClick}>
                                                {show ? <BiHide /> : <BiShow />}
                                            </Center>
                                        </InputRightElement>
                                    </InputGroup>

                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.password}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl mb={'15px'} isInvalid={(formik.errors.confirmPassword && formik.touched.confirmPassword) ? true : false} >
                                    <FormLabel color="#757575" fontSize={'14px'}>Confirm Password </FormLabel>
                                    <InputGroup>
                                        <Input placeholder="Confirm password"    type={show2 ? 'text' : 'password'} bg="inputText.500" name="confirmPassword" value={formik.values.confirmPassword} onChange={formik.handleChange} fontSize={'13px'} />
                                        <InputRightElement width='4.5rem'>
                                            <Center cursor={'pointer'} h='1.75rem'  onClick={handleClick2}>
                                                {show ? <BiHide /> : <BiShow />}
                                            </Center>
                                        </InputRightElement> </InputGroup>
                                </FormControl>
                              </HStack>



                                <Stack spacing="12px">
                                    <Button
                                        cursor="pointer"
                                        w="100%"
                                        color={"white"}
                                        bg={"#011F78"}
                                        type="submit"
                                        isLoading={formik.isSubmitting}
                                        fontSize={'14px'}
                                    >
                                        Register
                                    </Button>
                                </Stack>
                                <HStack mt='15px' mb={'15px'} >
                                    <Text color={'#90979E'}>
                                        Already have an account?
                                    </Text>
                                    <Center >
                                        <Box fontWeight={'600'} alignSelf="center" color="#011F78" fontSize={14}>
                                            <Link onClick={() => { route(AdminRoutes.Login) }}>Login</Link>
                                        </Box>
                                    </Center>
                                </HStack>
                            </form>
                        </Box>
                    </Box>
                </Flex>

            </AuthLayout>
        </>
    );
};

export default Register;
