import { useEffect, useState } from "react";
import {
  Avatar,
  AvatarBadge,
  Box,
  Collapse,
  Flex,
  HStack,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../img/calllogo2.png";

import { MdDashboard, MdKeyboardArrowDown, MdSavings } from "react-icons/md";
import {
  BsBook,
  BsBriefcase,
  BsClipboard,
  BsLayoutTextSidebar,
  BsWallet,
} from "react-icons/bs";
import AdminRoutes from "../../pages/routes";
import { BiSolidDetail, BiTargetLock, BiUser } from "react-icons/bi";
import { FaUsers } from "react-icons/fa";
import { RiAdminLine } from "react-icons/ri";
import { IUserToken } from "../../models/auth/login.model";
const AdminSidebar = () => {
  let location = useLocation();
  const { isOpen: isOpenBoard, onToggle: onToggleBoard } = useDisclosure();
  const { isOpen: isOpenAdmin, onToggle: onToggleAdmin } = useDisclosure();
  const [userProfile, setUserProfile] = useState<IUserToken>();

  useEffect(() => {
    const userLogged = sessionStorage.getItem("UserFromToken");
    if (userLogged) {
      const parsedUser = JSON.parse(userLogged);
      setUserProfile(parsedUser);
    }
  }, []);

  const activeLink = (link: string) => {
    let style = {
      bgColor: "081C4D",
      textColor: "#D6D6D6",
    };

    if (link === location.pathname) {
      style.bgColor = "#FAFAFA";
      style.textColor = "#081C4D";
    }

    return style;
  };

  return (
    <Box
      bg="#081C4D"
      px="10px"
      borderRight="1px solid"
      minH="100vh"
      borderColor="lightgray"
    >
      <Flex ml="5px" pt="15px" mb="15px" alignItems="center">
        <Box as="span" height={"36px"} width={"99px"}>
          <Image src={logo} height="36px" />
        </Box>
      </Flex>

      <HStack
        pl={"10px"}
        spacing="10px"
        bg={activeLink(AdminRoutes.portal.dashboard).bgColor}
        color={activeLink(AdminRoutes.portal.dashboard).textColor}
        mb="7px"
      >
        <Box as="span" pb="3px">
          <MdDashboard />
        </Box>
        <Box mb="3px" mt="4px" pb="5px" pt={"2px"} fontSize="13px" width="100%">
          <Link to={AdminRoutes.portal.dashboard}>Dashboard</Link>
        </Box>
      </HStack>
      <HStack
        pl={"10px"}
        spacing="10px"
        bg={activeLink(AdminRoutes.portal.wallet).bgColor}
        color={activeLink(AdminRoutes.portal.wallet).textColor}
        mb="7px"
      >
        <Box as="span" pb="3px">
          <BsWallet />
        </Box>
        <Box mb="3px" mt="4px" pb="5px" pt={"2px"} fontSize="13px" width="100%">
          <Link to={AdminRoutes.portal.wallet}>Wallet</Link>
        </Box>
      </HStack>
      <HStack
        pl={"10px"}
        spacing="10px"
        bg={activeLink(AdminRoutes.portal.transactions).bgColor}
        color={activeLink(AdminRoutes.portal.transactions).textColor}
        mb="7px"
      >
        <Box as="span" pb="3px">
          <BiSolidDetail />
        </Box>
        <Box mb="3px" mt="4px" pb="5px" pt={"2px"} fontSize="13px" width="100%">
          <Link to={AdminRoutes.portal.transactions}>Transactions</Link>
        </Box>
      </HStack>
      {userProfile && userProfile.assignedRoles.includes("Agent") && (
        <HStack
          pl={"10px"}
          spacing="10px"
          bg={activeLink(AdminRoutes.portal.customers).bgColor}
          color={activeLink(AdminRoutes.portal.customers).textColor}
          mb="7px"
        >
          <Box as="span" pb="3px">
            <FaUsers />
          </Box>
          <Box
            mb="3px"
            mt="4px"
            pb="5px"
            pt={"2px"}
            fontSize="13px"
            width="100%"
          >
            <Link to={AdminRoutes.portal.customers}>My Customers</Link>
          </Box>
        </HStack>
      )}
      {userProfile && userProfile.assignedRoles.includes("Administrator") && (
        <Box>
          <HStack
            pl={"10px"}
            cursor={"pointer"}
            spacing={"100px"}
            mb="5px"
            onClick={onToggleBoard}
            color={"#9F9C9C"}
            borderColor={"lightgray"}
            pt={"10px"}
            fontSize={"14px"}
          >
            <Flex alignItems={"center"}>
              <Box as="span" pb="3px">
                <BsClipboard color={"white"} />
              </Box>
              <Text color={"white"} ml={"10px"}>
                Savings
              </Text>
            </Flex>
            <Box as="span">
              {" "}
              <MdKeyboardArrowDown fontSize={18} />
            </Box>
          </HStack>
          <Collapse in={isOpenBoard}>
            <HStack
              pl={"30px"}
              spacing="10px"
              bg={activeLink(AdminRoutes.portal.savings).bgColor}
              color={activeLink(AdminRoutes.portal.savings).textColor}
              mb="7px"
            >
              <Box as="span" pb="3px">
                <MdSavings />
              </Box>
              <Box
                mb="3px"
                mt="4px"
                pb="5px"
                pt={"2px"}
                fontSize="12px"
                width="100%"
              >
                <Link to={AdminRoutes.portal.savings}>Savings</Link>
              </Box>
            </HStack>
            <HStack
              pl={"30px"}
              spacing="10px"
              bg={activeLink(AdminRoutes.portal.savingTarget).bgColor}
              color={activeLink(AdminRoutes.portal.savingTarget).textColor}
              mb="7px"
            >
              <Box as="span" pb="3px">
                <BiTargetLock />
              </Box>
              <Box
                mb="3px"
                mt="4px"
                pb="5px"
                pt={"2px"}
                fontSize="12px"
                width="100%"
              >
                <Link to={AdminRoutes.portal.savingTarget}>Saving Target</Link>
              </Box>
            </HStack>
          </Collapse>
        </Box>
      )}
      {userProfile &&
        (userProfile.assignedRoles.includes("UserAdministrator") ||
          userProfile.assignedRoles.includes("ParameterAdministrator") ||
          userProfile.assignedRoles.includes("AccountAdministrator") ||
          userProfile.assignedRoles.includes("TransactionAdministrator") ||
          userProfile.assignedRoles.includes("Administrator")) && (
          <Box>
            <HStack
              pl={"10px"}
              cursor={"pointer"}
              spacing={"100px"}
              mb="5px"
              onClick={onToggleBoard}
              color={"#9F9C9C"}
              borderColor={"lightgray"}
              pt={"10px"}
              fontSize={"14px"}
            >
              <Flex alignItems={"center"}>
                <Box as="span" pb="3px">
                  <BsClipboard color={"white"} />
                </Box>
                <Text color={"white"} ml={"10px"}>
                  Boards
                </Text>
              </Flex>
              <Box as="span">
                {" "}
                <MdKeyboardArrowDown fontSize={18} />
              </Box>
            </HStack>
            <Collapse in={isOpenBoard}>
              <HStack
                pl={"30px"}
                spacing="10px"
                bg={activeLink(AdminRoutes.portal.accountBoard).bgColor}
                color={activeLink(AdminRoutes.portal.accountBoard).textColor}
                mb="7px"
              >
                <Box as="span" pb="3px">
                  <BsClipboard />
                </Box>
                <Box
                  mb="3px"
                  mt="4px"
                  pb="5px"
                  pt={"2px"}
                  fontSize="12px"
                  width="100%"
                >
                  <Link to={AdminRoutes.portal.accountBoard}>
                    Account Board
                  </Link>
                </Box>
              </HStack>
            </Collapse>
          </Box>
        )}

      {userProfile &&
        (userProfile.assignedRoles.includes("UserAdministrator") ||
          userProfile.assignedRoles.includes("ParameterAdministrator") ||
          userProfile.assignedRoles.includes("AccountAdministrator") ||
          userProfile.assignedRoles.includes("TransactionAdministrator") ||
          userProfile.assignedRoles.includes("Administrator")) && (
          <HStack
            spacing={"49px"}
            cursor={"pointer"}
            pl={"10px"}
            mb="5px"
            onClick={onToggleAdmin}
            color={"#9F9C9C"}
            borderColor={"lightgray"}
            pt={"10px"}
            fontSize={"14px"}
          >
            <Flex alignItems={"center"}>
              <Box color={"white"} as="span" pb="3px">
                <RiAdminLine />
              </Box>
              <Text color={"white"} ml={"10px"}>
                Administration
              </Text>
            </Flex>
            <Box as="span">
              {" "}
              <MdKeyboardArrowDown fontSize={18} />
            </Box>
          </HStack>
        )}

      <Collapse in={isOpenAdmin}>
        {userProfile &&
          userProfile.assignedRoles.includes("UserAdministrator") && (
            <HStack
              pl={"30px"}
              spacing="10px"
              bg={activeLink(AdminRoutes.portal.users).bgColor}
              color={activeLink(AdminRoutes.portal.users).textColor}
              mb="10px"
            >
              <Box as="span" pb="3px">
                <FaUsers />
              </Box>
              <Box
                mb="3px"
                mt="4px"
                pb="5px"
                pt={"2px"}
                fontSize="13px"
                width="100%"
              >
                <Link to={AdminRoutes.portal.users}>Users</Link>
              </Box>
            </HStack>
          )}

        <HStack
          pl={"30px"}
          spacing="10px"
          bg={activeLink(AdminRoutes.portal.agents).bgColor}
          color={activeLink(AdminRoutes.portal.agents).textColor}
          mb="10px"
        >
          <Box as="span" pb="3px">
            <BiUser />
          </Box>
          <Box
            mb="3px"
            mt="4px"
            pb="5px"
            pt={"2px"}
            fontSize="13px"
            width="100%"
          >
            <Link to={AdminRoutes.portal.agents}>Agents</Link>
          </Box>
        </HStack>
        <HStack
          pl={"30px"}
          spacing="10px"
          bg={activeLink(AdminRoutes.portal.accounts).bgColor}
          color={activeLink(AdminRoutes.portal.accounts).textColor}
          mb="10px"
        >
          <Box as="span" pb="3px">
            <BsBriefcase />
          </Box>
          <Box
            mb="3px"
            mt="4px"
            pb="5px"
            pt={"2px"}
            fontSize="12px"
            width="100%"
          >
            <Link to={AdminRoutes.portal.accounts}>Accounts</Link>
          </Box>
        </HStack>
        {userProfile &&
          userProfile.assignedRoles.includes("TransactionAdministrator") && (
            <HStack
              pl={"30px"}
              spacing="10px"
              bg={activeLink(AdminRoutes.portal.adminTransactions).bgColor}
              color={activeLink(AdminRoutes.portal.adminTransactions).textColor}
              mb="7px"
            >
              <Box as="span" pb="3px">
                <BiSolidDetail />
              </Box>
              <Box
                mb="3px"
                mt="4px"
                pb="5px"
                pt={"2px"}
                fontSize="12px"
                width="100%"
              >
                <Link to={AdminRoutes.portal.adminTransactions}>
                  Transactions
                </Link>
              </Box>
            </HStack>
          )}
        {userProfile && userProfile.assignedRoles.includes("Administrator") && (
          <HStack
            pl={"30px"}
            spacing="10px"
            bg={activeLink(AdminRoutes.portal.roles).bgColor}
            color={activeLink(AdminRoutes.portal.roles).textColor}
            mb="10px"
          >
            <Box as="span" pb="3px">
              <BsLayoutTextSidebar />
            </Box>
            <Box
              mb="3px"
              mt="4px"
              pb="5px"
              pt={"2px"}
              fontSize="12px"
              width="100%"
            >
              <Link to={AdminRoutes.portal.roles}>Roles</Link>
            </Box>
          </HStack>
        )}
        {userProfile &&
          userProfile.assignedRoles.includes("ParameterAdministrator") && (
            <HStack
              pl={"30px"}
              spacing="10px"
              bg={activeLink(AdminRoutes.portal.systemParameter).bgColor}
              color={activeLink(AdminRoutes.portal.systemParameter).textColor}
              mb="10px"
            >
              <Box as="span" pb="3px">
                <BsLayoutTextSidebar />
              </Box>
              <Box
                mb="3px"
                mt="4px"
                pb="5px"
                pt={"2px"}
                fontSize="12px"
                width="100%"
              >
                <Link to={AdminRoutes.portal.systemParameter}>
                  System parameter
                </Link>
              </Box>
            </HStack>
          )}

        <HStack
          pl={"30px"}
          spacing="10px"
          bg={activeLink(AdminRoutes.portal.aduitLogs).bgColor}
          color={activeLink(AdminRoutes.portal.aduitLogs).textColor}
          mb="10px"
        >
          <Box as="span" pb="3px">
            <BsLayoutTextSidebar />
          </Box>
          <Box
            mb="3px"
            mt="4px"
            pb="5px"
            pt={"2px"}
            fontSize="12px"
            width="100%"
          >
            <Link to={AdminRoutes.portal.aduitLogs}>Audit logs</Link>
          </Box>
        </HStack>
      </Collapse>
    </Box>
  );
};

export default AdminSidebar;
