import { Box, Flex, HStack, Button, InputGroup,Select, Input, InputRightElement, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Avatar, Text, MenuItem, Menu, MenuButton, MenuList, Center, Portal } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';
import { RiSearch2Line } from 'react-icons/ri';
import { BsArrowLeft, BsArrowRight, BsCheckLg, BsThreeDotsVertical } from 'react-icons/bs';
import { NumericFormat } from 'react-number-format';
import { Pagination, PaginationContainer, PaginationNext, PaginationPage, PaginationPageGroup, PaginationPrevious, usePagination } from "@ajna/pagination";
import { useState, useEffect } from 'react';
import { Ipage, IAuditLogRes } from '../../models/audit/auditlog.model';
import accountsService from '../../services/accounts/accounts.service';
import { IAccountsRes, IAccountsValue } from '../../models/accounts/accounts.model';
import { FaTimes } from 'react-icons/fa';
import Loading from '../Loading';
import { FundAccountModal } from './FundAccount';
import AdminRoutes from '../../pages/routes';

const Accounts = () => {
    let navigate = useNavigate();
    const [search, setSearch] = useState("");
    const [query, setQuery] = useState("");
    const [page, setPage] = useState<Ipage>({ page_total: 0, total: 0, page_count: 0 });
    const [acoountsList, setacoountsList] = useState<IAccountsValue[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isFundAccount, setIsFundAccount] = useState(false);
    const [reload, setReload] = useState(false);
    const [pageSize, setPageSize] = useState(25);
    const [selectedAccount, setSelectedAccount] = useState<string>();

    const {
        currentPage,
        setCurrentPage,
        pagesCount,
        pages
    } = usePagination({
        pagesCount: page.page_count,
        initialState: { currentPage: 1, pageSize: 100 },
        total: page.total
    });
    const openFundAccount = (account: string) => {
        setSelectedAccount(account)
        setIsFundAccount(true)


    };
    useEffect(() => {
        let url = `?pageNumber=${currentPage}&pageSize=${pageSize}&searchString=${query}`
        accountsService.getAccounts(url)
            .then((data) => {
                const account: IAccountsRes = data
                setPage({ page_total: account.pageSize, total: account.totalCount, page_count: account.totalPages })
                setacoountsList(account.data.$values);
                setIsLoading(false)
            })
            .catch((err) => err)

    }, [currentPage, query, reload, pageSize]);

    const reloadData = () => {
        setReload(!reload)
    }
    const changePageSize = (e: any) => {
        setPageSize(e.target.value)
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (search.length > 0) {
                setQuery(search);
            } else {
                setQuery("");
            }
        }, 700);

        return () => clearTimeout(delayDebounceFn)
    }, [search])
   
    return (
        <>
            {
                isLoading ? <Loading /> : <Box>
                    <Flex py='15px' justifyContent=''>
                        <InputGroup bg="white" width="30%" mr='20px'>
                            <Input height='40px' placeholder="Search accounts here" onChange={e => setSearch(e.target.value)} fontSize={12} size="sm" />
                            <InputRightElement pointerEvents="none" children={<RiSearch2Line color="green" size="0.9rem" />} />
                        </InputGroup>

                    </Flex>
                    <Box width={'100%'}>
                        <TableContainer>
                            <Table size={'sm'}>
                                <Thead py={'15px'}>
                                    <Tr bg="#3778D9" color={'white'} py={'15px'} >
                                        <Td py={'15px'}  textTransform={'capitalize'}>Account No.</Td>
                                        <Td  textTransform={'capitalize'}>Account Name</Td>
                                        <Td  textTransform={'capitalize'}>Type</Td>
                                        <Td  textTransform={'capitalize'}>Balance</Td>
                                        <Td  textTransform={'capitalize'}>Agent Account</Td>
                                        <Td  textTransform={'capitalize'}>Customer Account</Td>
                                        <Td  textTransform={'capitalize'}>Fund Account</Td>

                                    </Tr>
                                </Thead>
                                <Tbody>

                                    {
                                        acoountsList.length > 0 ? acoountsList.map((each, index) => (
                                            <Tr key={index} bg="white" >
                                                <Td>
                                                    <Text fontSize={'14px'}>{each.id}</Text>
                                                </Td>
                                                <Td>
                                                    <HStack spacing='5px' mt={'10px'}>
                                                        <Avatar
                                                            size="sm"
                                                            name={each.ownerName}
                                                        />
                                                        <Box>
                                                            <Text fontWeight='500' textTransform='capitalize' fontSize='14px' >
                                                                {each.ownerName}
                                                            </Text>
                                                        </Box>
                                                    </HStack>
                                                </Td>
                                                <Td>
                                                    <Text fontSize={'14px'} bg={'blue'} color={'white'} textAlign={'center'} borderRadius={'15px'} py={'3px'} px={'5px'}>{each.type}</Text>
                                                </Td>
                                                <Td>
                                                    <Text fontSize={'14x'}>

                                                        <NumericFormat
                                                            value={each.balance}
                                                            displayType={"text"}
                                                            thousandSeparator={true}
                                                            prefix={"₦"}
                                                        />
                                                    </Text>
                                                </Td>
                                                <Td>
                                                    {each.isAgentAccount ? <Text fontSize={'14px'} ><BsCheckLg color='green' /></Text> :
                                                        <Text fontSize={'14px'} ><FaTimes color='red' /></Text>}
                                                </Td>
                                                <Td>
                                                    {each.isCustomerAccount ? <Text fontSize={'14px'} ><BsCheckLg color='green' /></Text> :
                                                        <Text fontSize={'14px'} ><FaTimes color='red' /></Text>}
                                                </Td>
                                                <Td>
                                                    <Button
                                                        cursor="pointer"
                                                        color={"white"}
                                                        width={'100%'}
                                                        bg={"#011F78"}
                                                        size={'sm'}
                                                        fontSize={'13px'}
                                                        onClick={() => { openFundAccount(each.id) }}
                                                    >
                                                        Fund
                                                    </Button>
                                                </Td>

                                            </Tr>

                                        )) : <Box>No data</Box>
                                    }



                                </Tbody>
                            </Table>
                        </TableContainer>
                        <Flex alignContent={"flex-end"} justifyContent={'flex-end'} mt={4}>

                            <HStack>
                                <Text fontWeight={400} fontSize={'13px'}>Rows per page :</Text>
                                <Select
                                    variant="outline"
                                    size="sm"
                                    width={'70px'}
                                    value={pageSize}
                                    onChange={(e) => { changePageSize(e) }}
                                    fontSize={12}
                                >
                                    <option value={10}>
                                        10
                                    </option>
                                    <option value={25}>
                                        25
                                    </option>
                                    <option value={50}>
                                        50
                                    </option>
                                    <option value={100}>
                                        100
                                    </option>

                                </Select>

                            </HStack>
                            <Pagination
                                pagesCount={pagesCount}
                                currentPage={currentPage}
                                onPageChange={setCurrentPage}
                            >
                                <PaginationContainer>
                                    <PaginationPrevious><BsArrowLeft /></PaginationPrevious>
                                    <PaginationPageGroup>
                                        {pages.map((page: number) => (
                                            <PaginationPage
                                                key={`pagination_page_${page}`}
                                                page={page}
                                            />
                                        ))}
                                    </PaginationPageGroup>
                                    <PaginationNext><BsArrowRight /></PaginationNext>
                                </PaginationContainer>
                            </Pagination>

                        </Flex>
                    </Box>
                    {isFundAccount && (
                        <Portal>
                            {" "}
                            <FundAccountModal
                                isOpenFundAccount={isFundAccount}
                                onClosed={setIsFundAccount}
                                isFundAccount={isFundAccount}
                                reload={reloadData}
                                fundAcct={selectedAccount}
                            />{" "}
                        </Portal>
                    )}
                </Box>
            }
        </>
    )
}

export default Accounts