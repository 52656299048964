import { Box, Flex, HStack, Button, InputGroup, Input, InputRightElement, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Avatar, Text, MenuItem, Menu, MenuButton, MenuList, Center, useDisclosure } from '@chakra-ui/react'

import { useNavigate, useParams } from 'react-router-dom';
import { RiSearch2Line } from 'react-icons/ri';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { NumericFormat } from 'react-number-format';
import { usePagination } from '@ajna/pagination';
import { useState, useEffect } from 'react';
import { IAgentsValue, IAgentsRes } from '../../models/agents/agents.model';
import { Ipage } from '../../models/audit/auditlog.model';
import agentService from '../../services/agent/agent.service';
import AdminRoutes from '../routes';
import { ICustomerAgentData, ICustomerAgentRes, ICustomerAgentValue } from '../../models/agents/customerAgents.model';
import { HiArrowNarrowLeft } from 'react-icons/hi';
import Loading from '../../components/Loading';

const AgentCustomer = () => {
    let navigate = useNavigate();
    const { username } = useParams();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isAgent, setisAgent] = useState<ICustomerAgentData>();
    const [customerList, setcustomerList] = useState<ICustomerAgentValue[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [reload, setReload] = useState(false);

    useEffect(() => {

        if (username) {
            const url = `?userName=${username}`
            agentService.getAgentsCustomersByUsername(url)
                .then((data) => {
                    const agents: ICustomerAgentRes = data
                    setcustomerList(agents.data.$values);
                    setisAgent(agents.data)
                    setIsLoading(false)
                })
                .catch((err) => err)
        }
    }, [reload]);




    return (
        <>
            {
                isLoading ? <Loading /> :
                    <Box >
                        <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            py="26px"
                        >
                            <Box color="#2B2B2B" fontWeight="800" fontSize="27px">
                                Agent : {customerList && customerList.length > 0 ? customerList[0].agentName : username}
                            </Box>
                            <Box
                                mb="20px"
                                display="flex"
                                alignItems="center"
                                border="1px solid #616161"
                                padding={"5px 12px"}
                                width="fit-content"
                                borderRadius="5px"
                                cursor="pointer"
                            >
                                <HiArrowNarrowLeft color="#616161" fontSize="20px" />
                                <Text
                                    fontSize="13px"
                                    color="#616161"
                                    pl="5px"
                                    onClick={() => navigate(AdminRoutes.portal.agents)}
                                    fontWeight={'500'}
                                >
                                    Back to agent
                                </Text>
                            </Box>
                        </Flex>
                        <Flex py='15px' justifyContent=''>
                            <InputGroup bg="white" width="30%" mr='20px'>
                                <Input height='40px' placeholder="Search input here" fontSize={12} size="sm" />
                                <InputRightElement pointerEvents="none" children={<RiSearch2Line color="green" size="0.9rem" />} />
                            </InputGroup>

                        </Flex>
                        <Box width={'100%'}>
                            <TableContainer>
                                <Table size={'sm'}>
                                    <Thead py={'15px'}>
                                        <Tr bg="white" py={'15px'} >
                                            <Td py={'15px'}  textTransform={'capitalize'}>Agent Name</Td>
                                            <Td  textTransform={'capitalize'}>Customer Name</Td>
                                            <Td  textTransform={'capitalize'}>Account</Td>
                                            <Td  textTransform={'capitalize'}>Balance</Td>

                                        </Tr>
                                    </Thead>
                                    <Tbody>

                                        {
                                            customerList && customerList.length > 0 ? customerList.map((customer, index) => (
                                                <Tr key={index} bg="white" >
                                                    <Td>
                                                        <HStack spacing='5px' mt={'10px'}>
                                                            <Avatar
                                                                size="sm"
                                                                name={customer.agentName}
                                                            />
                                                            <Box>
                                                                <Text fontWeight='500' textTransform='capitalize' fontSize='14px' >
                                                                    {customer.agentName}
                                                                </Text>
                                                            </Box>
                                                        </HStack>
                                                    </Td>
                                                    <Td>
                                                        <HStack spacing='5px' mt={'10px'}>
                                                            <Avatar
                                                                size="sm"
                                                                name={customer.customerName}
                                                            />
                                                            <Box>
                                                                <Text fontWeight='500' textTransform='capitalize' fontSize='14px' >
                                                                    {customer.customerName}
                                                                </Text>
                                                            </Box>
                                                        </HStack>
                                                    </Td>
                                                    <Td>
                                                        <Text fontSize={'14px'}>{customer.customerAccNo}</Text>
                                                    </Td>

                                                    <Td>
                                                        <Text fontSize={'14x'}>

                                                            <NumericFormat
                                                                value={customer.customerBalSum}
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                                prefix={"₦"}
                                                            />
                                                        </Text>
                                                    </Td>


                                                </Tr>
                                            )) : <Box>No data</Box>
                                        }

                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
            }
        </>

    )
}

export default AgentCustomer;