import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    Box,
    useToast,
    Flex,
    Button,
    Center,
    Image,
    Portal
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import OTPInput from "react-otp-input";
import transfer from '../../img/transfer.svg'
import { IHandleTransaction } from "../../models/transactions/handleTransaction";
import { NumericFormat } from "react-number-format";
import transactionService from "../../services/transaction/transaction.service";
import { useNavigate } from "react-router-dom";
import { IRegRes } from "../../models/auth/registration.model";
import AdminRoutes from "../../pages/routes";
import { SuccefulModal } from "./SuccessfulModal";
import usersService from "../../services/user/users.service";
import { destroyUserSession } from "../../services/auth/auth.service";
import accountsService from "../../services/accounts/accounts.service";

export const TransferPinModal: React.FC<any> = ({
    isOpenPin,
    onClosed,
    isOtp = false,
    requestBody,
    reload,
    destination
}: {
    isOtp: boolean;
    isOpenPin: any;
    onClosed: any;
    requestBody: IHandleTransaction;
    reload: any
    destination : string
}) => {
    const [
        { otp, numInputs, separator, minLength, maxLength, placeholder, inputType },
        setConfig,
    ] = useState({
        otp: "",
        numInputs: 4,
        separator: "--",
        minLength: 0,
        maxLength: 40,
        placeholder: "",
        inputType: "number" as const,
    });
    const [issubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const toast = useToast()
    const [OpenSuccess, setOpenSuccess] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const handleOTPChange = (otp: string) => {
        setConfig((prevConfig) => ({ ...prevConfig, otp }));
    };

    const clearOtp = () => {
        setConfig((prevConfig) => ({ ...prevConfig, otp: "" }));
    };
    const handleLogout = () => {
        destroyUserSession();
        navigate(AdminRoutes.Login);
    };
    const handleSubmit = async () => {
        setIsSubmitting(true)
        try {
            const data: IHandleTransaction = {
                verificationCode: '',
                ...requestBody
            }
            const verify_ppin: any = await usersService.verifyPin({ pin: otp })
            console.log(verify_ppin.succeeded)
            if (verify_ppin.succeeded) {
                const handle_transaction: any = await transactionService.handleTransaction(data)
                if (handle_transaction.succeeded) {
                    setIsSubmitting(false)
                    const saveBeneficiary: any = await accountsService.createUserContactTemplate({
                        destinationUserName: requestBody.destination
                    })
                    if (saveBeneficiary.succeeded) {

                    }
                    toast({
                        title: requestBody.typeId == 0 ? "Move Amount" : requestBody.typeId == 1 ? "Transfer Amount" : requestBody.typeId == 1 ? "Topup" : 'Payment',
                        description: requestBody.typeId == 0 ? "Amount moved successfully" : requestBody.typeId == 1 ? "Amount transferred successfully" : requestBody.typeId == 1 ? "Topup successful" : 'Payment successful',
                        status: "success",
                    });

                    //reload()
                    setOpenSuccess(true)

                    // navigate(AdminRoutes.portal.wallet)
                } else {
                    setIsSubmitting(false)
                    toast({
                        title: requestBody.typeId == 0 ? "Move Amount" : requestBody.typeId == 1 ? "Transfer Amount" : requestBody.typeId == 1 ? "Topup" : 'Payment',
                        description: `${handle_transaction.data.$values[0]}`,
                        status: "error",
                    });
                }
            } else {
                clearOtp()
                setIsSubmitting(false)
                if (verify_ppin.data.isLockedOut) {
                    handleLogout()
                    toast({
                        title: 'User Deactivated',
                        description: `You entered a wrong pin  3 consecutive times, kindly reactivate your account to continue`,
                        status: "error",
                    });
                } else if (verify_ppin.data.failureCount == 0) {
                    handleLogout()
                    toast({
                        title: 'Pin Validation',
                        description: `${verify_ppin.data.errorMessage}`,
                        status: "error",
                    });
                }
                setErrorMessage(`Wrong PIN - ${verify_ppin.data.failureCount}/3 tries before your account is deactivated`)
                toast({
                    title: 'Pin Validation',
                    description: `Pin is incorrect,please try again`,
                    status: "error",
                });
            }


        } catch (error: any) {
            setIsSubmitting(false)
            toast({
                title: requestBody.typeId == 0 ? "Move Amount" : requestBody.typeId == 1 ? "Transfer Amount" : requestBody.typeId == 1 ? "Topup" : 'Payment',
                description: `${error}`,
                status: "error",
            });
        }
    }
    const newReload = () => {
        onClosed(false)
        reload()
    }
    return (
        <>
            <Modal
                isOpen={isOpenPin && isOtp}
                onClose={onClosed}
                scrollBehavior="outside"
                closeOnOverlayClick={true}
                size="lg"
            >
                <ModalOverlay />
                <ModalContent borderRadius={5} px={6}>
                    <ModalHeader>
                        {
                            <Text
                                fontSize={'15px'}
                                pb={3}
                                textTransform="capitalize"
                                pl={3}
                                textAlign={'center'}
                                color="brand.2"
                            >Confirm Transaction to : {destination}</Text>
                        }
                    </ModalHeader>
                    <ModalCloseButton size="sm" />
                    <ModalBody pb={6}>
                        <Box mt={2}>
                            {
                                errorMessage && <Text textAlign={'center'}>{errorMessage}</Text>
                            }
                            <Flex mb={'30px'} width={"100%"} justifyContent={"center"}>
                                <Image src={transfer} />
                            </Flex>
                            <Flex width={"100%"} justifyContent={"center"}>
                                <Box>

                                    <Text textAlign={'center'} fontSize={'15px'}
                                        pb={3}
                                        textTransform="uppercase">Enter Transaction Pin:</Text>
                                    <Box pl={'40px'} >
                                        <OTPInput
                                            inputStyle="inputStyle"
                                            numInputs={numInputs}
                                            onChange={handleOTPChange}
                                            renderSeparator={<span>{separator}</span>}
                                            value={otp}
                                            placeholder={placeholder}
                                            inputType={inputType}
                                            renderInput={(props) => <input {...props} />}
                                            shouldAutoFocus
                                        />
                                    </Box>
                                    <Flex mt={12} justifyContent={'space-between'} >
                                        <Button
                                            px={6}
                                            colorScheme="blue"
                                            fontSize={'14px'}
                                            variant={'outline'}
                                            width={'49%'}
                                            onClick={() => onClosed()}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="button"
                                            color={"white"}
                                            width={'49%'}
                                            bg={"#011F78"}
                                            px={6}
                                            isLoading={issubmitting}
                                            onClick={() => { handleSubmit() }}
                                            fontSize={'14px'}
                                        >
                                            Send  <NumericFormat
                                                value={requestBody.amount}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={'₦'}
                                            />
                                        </Button>
                                    </Flex>
                                </Box>
                            </Flex>
                        </Box>
                        {OpenSuccess && (
                            <Portal>
                                {" "}
                                <SuccefulModal
                                    isOpenSuccess={OpenSuccess}
                                    onClosed={setOpenSuccess}
                                    isSuccess={OpenSuccess}
                                    reload={newReload}
                                />{" "}
                            </Portal>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
