import getService from "../crud-requests/getService";
import postService from "../crud-requests/postService";



const CONFIG_API_URL = "https://callnpayapi20230120085359.azurewebsites.net/api/";

class DashboardService {
  async userDashboard() {
    const response = await getService.get(
      `${CONFIG_API_URL}dashboard/getuserdashboard`,

    );

    return response.data;
  }
  async aacountDashboard() {
    const response = await getService.get(
      `${CONFIG_API_URL}dashboard/getaccountdashboard`,

    );
    return response.data;
  }

}

export default new DashboardService();