import {
    Box,
    Button,
    Center,
    Flex,
    FormControl,
    FormErrorMessage,
    Text,
    FormLabel,
    Input,
    InputGroup,
    useToast,
    Link,
    HStack,
    Stack,
    InputLeftElement,
    InputRightElement,
    Table,
    Tbody,
    Td,
    Thead,
    Tr,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import AuthLayout from "./AuthLayout";
import AdminRoutes from "../routes";
import authService, { getLocalUserProfile } from "../../services/auth/auth.service";
import { IRegRes, IStateRes, IStateValue } from "../../models/auth/registration.model";
import { BiHide, BiShow } from "react-icons/bi";
import { MdLock } from "react-icons/md";
import { useEffect, useRef, useState } from "react";
import { IUserDashboardData } from "../../models/dashboard/userDashboard.model";
import userProfileService from "../../services/user/userProfile.service";
import usersService from "../../services/user/users.service";
import { ILoginData } from "../../models/auth/login.model";
import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import { AnyAaaaRecord } from "dns";
const CompleteProfile = () => {
    const route = useNavigate();
    const toast = useToast();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [show, setShow] = useState(false)
    const [batchFile, setbatchFile] = useState<any>();
    const [filename, setFilename] = useState("");
    const [filetype, setFiletype] = useState<string | undefined>("");
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [photoUrl, setPhotoUrl] = useState('');
    const [genderList, setGenderList] = useState<any[]>([{ value: 0, label: 'Male' }, { value: 1, label: 'Female' }]);
    const [countries, setCountries] = useState<any[]>();
    const [userStates, setUserStates] = useState<any[]>();
    const [userProfile, setUserProfile] = useState<ILoginData | null>();
    const [selectedState, setselectedState] = useState<any>(null);
    const [selectedCountry, setselectedCountry] = useState<any>(null);
    const [selectedGender, setselectedGender] = useState<any>(null);
    const handleChangeState = (selectedState: any) => {
        setselectedState(selectedState);
    };
    const handleChangeGender = (selectedGender: any) => {
        setselectedGender(selectedGender);
    };
    const handleChangeCountry = (selectedCountry: any) => {
        setselectedCountry(selectedCountry);
    };
    useEffect(() => {
        usersService.getUserCountries().then((data) => {
            let countryList: any[] = []
            const country_res: IStateRes = data
            country_res.data.$values.forEach(element => {
                countryList.push({ value: element.$id, label: element.description })
            });

            setCountries(countryList)
            setIsLoading(false)

        })
        usersService.getUserStates().then((data) => {
            let stateList: any[] = []
            const state_res: IStateRes = data
            state_res.data.$values.forEach(element => {
                stateList.push({ value: element.$id, label: element.name })
            });

            setUserStates(stateList)
            setIsLoading(false)

        })
        const user = getLocalUserProfile()
        setUserProfile(user)
    }, []);
    const validate = (values: any) => {
        const errors: any = {};


        if (!values.address1) {
            errors.address1 = 'Address 1 is required'
        }
        if (!values.city) {
            errors.city = 'City is required'
        }
        if (!values.dob) {
            errors.dob = 'Date of birth is required'
        }

        return errors;
    };

    const formik = useFormik({
        initialValues: {
            address1: "",
            address2: "",
            address3: "",
            city: '',
            zipcode: '',
            dob: '',
            gender: ""
        },
        validate,
        onSubmit: async (values) => {
            try {

                const data = {
                    userName: userProfile?.userName,
                    country: parseInt(selectedCountry.value),
                    state: parseInt(selectedState.value),
                    zipCode: values.zipcode,
                    city: values.city,
                    addressLine1: values.address1,
                    addressLine2: values.address2,
                    addressLine3: values.address3,
                    photoUrl: '',
                    dateOfBirth: values.dob,
                    gender: selectedGender.value,
                    notifications: [
                        0, 1, 2
                    ]
                }
                const update_profile: IRegRes = await userProfileService.updateUserProfile(data)
                console.log(update_profile.succeeded)
                if (update_profile.succeeded) {
                    handleUpload()

                } else {
                    toast({
                        title: "Profile Update",
                        description: `${update_profile.messages.$values[0]}`,
                        status: "error",
                    });
                }
            } catch (error: any) {
                toast({
                    title: "Profile Update",
                    description: `${error}`,
                    status: "error",
                });
            }
        },
    });

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        console.log(value, "value");
        if (value && e.target.files) {
            let fileName = e.target.files?.item(0)?.name as string;
            console.log(fileName, "fileName");
            setFilename(fileName);
            let fileType = e.target.files?.item(0)?.type;
            setFiletype(fileType);
            let file = e.target.files[0];
            setbatchFile(file);
        }
    };

    const handleUpload = async () => {
        if (batchFile) {
            const fileName = batchFile.name.split('.').slice(0, -1).join('.'); // Remove extension
            const fileExtension = batchFile.name.split('.').pop(); // Get extension
            const random_uuid = uuidv4();

            const reader = new FileReader();
            reader.onload = async function (event: any) {
                const base64String = event.target.result.split(",")[1]; // Extract the Base64 part
                // Decode base64 to binary data

                try {

                    const requestBody = {
                        fileName: `${fileName}-${random_uuid}.${fileExtension}`,
                        extension: fileExtension,
                        uploadType: 1,
                        data: base64String
                    };
                    const profile_photo: any = await userProfileService.uploadProfilePhoto(requestBody)
                    console.log(profile_photo.succeeded)
                    if (profile_photo.succeeded) {
                        toast({
                            title: "Profile Update",
                            description: `profile update successful`,
                            status: "success",
                        });
                        route(AdminRoutes.portal.dashboard)

                    } else {
                        toast({
                            title: "Profile Photo",
                            description: `${profile_photo.messages.$values[0]}`,
                            status: "error",
                        });
                    }
                } catch (error: any) {
                    toast({
                        title: "Profile Photo",
                        description: `${error}`,
                        status: "error",
                    });
                }
            };

            // Read the selected file as Data URL
            reader.readAsDataURL(batchFile);
        }
    }

    return (
        <>
            <AuthLayout>
                <Flex mx="auto" className="resizable_div" height="95vh">
                    <Box >
                        <Box
                            ml={["30px", "30px", "60px", "60px"]}
                            mr={["30px", "30px", "0px", "0px"]}
                            mt={'20px'}
                            mb="'14px'px"
                        >
                            <Box mb={'15px'}>
                                <Text
                                    mb={'15px'}
                                    fontSize={["15px", "15px", "24px", "24px"]}
                                    fontWeight="700"
                                    color="#011F78"
                                >
                                    Complete your profile
                                </Text>
                                <Text
                                    fontSize={["15px", "15px", "14px", "14px"]}
                                    fontWeight="400"
                                    color="#90979E"
                                >
                                    Youre almost done, kindly complete your profile
                                </Text>
                            </Box>

                            <Box>
                                {filename ? (
                                    <Box>
                                        <Table variant="unstyled">
                                            <Thead fontWeight="semibold">
                                                <Tr color="brand.subtitle" fontSize={14}>
                                                    <Td>Filename</Td>
                                                    <Td>Type</Td>
                                                </Tr>
                                            </Thead>

                                            <Tbody>
                                                <Tr fontSize={13} color="brand.subtitle">
                                                    <Td maxW="20em">{filename}</Td>
                                                    <Td>Image</Td>

                                                </Tr>
                                            </Tbody>
                                        </Table>
                                    </Box>
                                ) : (
                                    <FormControl mr={10}>
                                        <FormLabel fontWeight="600" color="#616161" fontSize={13}>
                                            Upload profile picture
                                        </FormLabel>

                                        <input
                                            accept="image/*"
                                            type="file"
                                            name="cover_image"
                                            ref={inputRef}
                                            onChange={(e) => handleFileChange(e)}
                                            style={{ display: "none" }}
                                        ></input>
                                        <Input
                                            cursor="pointer"
                                            placeholder="No file selected"
                                            defaultValue={filename}
                                            mt="10px"
                                            borderColor="#eeeeee"
                                            fontSize={14}
                                            bg="#fafafa"
                                            onClick={() => inputRef?.current?.click()}
                                            border="2px dashed"
                                            borderRadius={2}
                                        />

                                    </FormControl>
                                )}

                            </Box>
                            <form onSubmit={formik.handleSubmit}>


                                <HStack mt={'20px'} width={'500px'}>
                                    <FormControl mb={'15px'} isInvalid={(formik.errors.city && formik.touched.city) ? true : false} >
                                        <Select
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: state.isFocused ? '#D6D6D6' : '#D6D6D6',
                                                    fontSize: '12px'
                                                }),
                                            }}
                                            value={selectedCountry}
                                            onChange={handleChangeCountry}
                                            options={countries}
                                            isSearchable={true}
                                            placeholder={'Country'}
                                        />
                                    </FormControl>

                                    <FormControl mb={'15px'} >
                                        <Select
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: state.isFocused ? '#D6D6D6' : '#D6D6D6',
                                                    fontSize: '12px'
                                                }),
                                            }}
                                            value={selectedState}
                                            placeholder={'State'}
                                            onChange={handleChangeState}
                                            options={userStates}
                                            isSearchable={true}
                                        />
                                    </FormControl>
                                    <FormControl mb={'15px'} isInvalid={(formik.errors.zipcode && formik.touched.zipcode) ? true : false} >
                                        <Input bg="inputText.500" placeholder="Zip code" value={formik.values.zipcode} name="zipcode" onChange={formik.handleChange} fontSize={'13px'} />
                                        <FormErrorMessage fontSize={12} >{formik.errors.zipcode}</FormErrorMessage>
                                    </FormControl>

                                </HStack>
                                <HStack width={'500px'}>
                                    <FormControl mb={'15px'} isInvalid={(formik.errors.city && formik.touched.city) ? true : false} >
                                        <Input placeholder="City" value={formik.values.city} name="city" fontSize={'13px'} onChange={formik.handleChange} />
                                        <FormErrorMessage fontSize={12} >{formik.errors.city}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl mb={'15px'} isInvalid={(formik.errors.address1 && formik.touched.address1) ? true : false} >
                                        <Input bg="inputText.500" placeholder="Address 1" value={formik.values.address1} name="address1" onChange={formik.handleChange} fontSize={'13px'} />
                                        <FormErrorMessage fontSize={12} >{formik.errors.address1}</FormErrorMessage>
                                    </FormControl>


                                </HStack>
                                <HStack width={'500px'}>

                                    <FormControl mb={'15px'} isInvalid={(formik.errors.address2 && formik.touched.address2) ? true : false} >
                                        <Input bg="inputText.500" placeholder="Address 2" value={formik.values.address2} name="address2" onChange={formik.handleChange} fontSize={'13px'} />
                                        <FormErrorMessage fontSize={12} >{formik.errors.address2}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl mb={'15px'} isInvalid={(formik.errors.address3 && formik.touched.address3) ? true : false} >
                                        <Input bg="inputText.500" placeholder="Address 3" value={formik.values.address3} name="address3" onChange={formik.handleChange} fontSize={'13px'} />
                                        <FormErrorMessage fontSize={12} >{formik.errors.address3}</FormErrorMessage>
                                    </FormControl>


                                </HStack>
                                <HStack width={'500px'}>

                                    <FormControl mb={'15px'} isInvalid={(formik.errors.city && formik.touched.city) ? true : false} >
                                        <Select
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: state.isFocused ? '#D6D6D6' : '#D6D6D6',
                                                    fontSize: '12px'
                                                }),
                                            }}
                                            value={selectedGender}
                                            onChange={handleChangeGender}
                                            options={genderList}
                                            isSearchable={true}
                                            placeholder={'Gender'}
                                        />
                                    </FormControl>
                                    <FormControl mb={'15px'} isInvalid={(formik.errors.dob && formik.touched.dob) ? true : false} >
                                        <Input bg="inputText.500" type="date" placeholder="Date of birth" value={formik.values.dob} name="dob" onChange={formik.handleChange} fontSize={'13px'} />
                                        <FormErrorMessage fontSize={12} >{formik.errors.dob}</FormErrorMessage>
                                    </FormControl>


                                </HStack>

                                <Stack spacing="12px">
                                    <Button
                                        cursor="pointer"
                                        w="100%"
                                        color={"white"}
                                        bg={"#011F78"}
                                        type="submit"
                                        isLoading={formik.isSubmitting}
                                        fontSize={'14px'}
                                    >
                                        Complete profile
                                    </Button>
                                </Stack>

                            </form>
                        </Box>
                    </Box>
                </Flex>

            </AuthLayout>
        </>
    );
};

export default CompleteProfile;
