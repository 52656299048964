import deleteService from "../crud-requests/deleteService";
import getService from "../crud-requests/getService";
import postService from "../crud-requests/postService";
import putService from "../crud-requests/putService";

const CONFIG_API_URL = "https://callnpayapi20230120085359.azurewebsites.net/api/";

class SavingsService {
  async getSavings(url: string) {
    const response = await getService.get(
      `${CONFIG_API_URL}account/getsavings${url}`
    );
    return response.data;
  }
  async getASaving(id: string) {
    const response = await getService.get(
      `${CONFIG_API_URL}account/getsaving/${id}`
    );
    return response.data;
  }
  async getSavingTargets() {
    const response = await getService.get(
      `${CONFIG_API_URL}account/getsavingtargets`
    );
    return response.data;
  }
  async getASavingTarget(id: string) {
    const response = await getService.get(
      `${CONFIG_API_URL}account/getsavingtarget/${id}`
    );
    return response.data;
  }
  async getUserAccountWithSavingOption(username: string) {
    const response = await getService.get(
      `${CONFIG_API_URL}account/getaccountswitsavingoption${username}`
    );
    return response.data;
  }
  async getActiveSavingOfUser(username: string | undefined) {
    const response = await getService.get(
      `${CONFIG_API_URL}account/getactivesavingofuser?userName=${username}`
    );
    return response.data;
  }

  async depositToSaving(data: any) {
    const response = await putService.put(
      `${CONFIG_API_URL}account/deposittosaving`,
      data
    );
    return response.data;
  }

  async createSaving(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}account/createsaving`,
      data
    );
    return response.data;
  }
  async createSavingTarget(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}account/createsavingtarget`,
      data
    );
    return response.data;
  }
}

export default new SavingsService();
