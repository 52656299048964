import {
    Box,
    Button,

    Flex,
    FormControl,
    FormErrorMessage,
    Text,
    FormLabel,
    Input,

    useToast,

    Stack,
} from "@chakra-ui/react";
import { useFormik } from "formik";

import { useNavigate } from "react-router-dom";
import AuthLayout from "./AuthLayout";
import AdminRoutes from "../routes";
import authService, { getAuthToken } from "../../services/auth/auth.service";

const SetPassword = () => {
    const route = useNavigate();
    const toast = useToast();
    const validate = (values: any) => {
        const errors: any = {};

        if (!values.password) {
            errors.password = 'Password is required'
        }
        if (!values.confirmPassword) {
            errors.confirmPassword = 'Confirm Password'
        }
        if (values.password !== values.confirmPassword) {
            errors.confirmPassword = 'Password do not match'
        }
        return errors;
    };

    const formik = useFormik({
        initialValues: { confirmPassword: "", password: "", email: "" },
        validate,
        onSubmit: async (values) => {
            try {
                const data = {

                    email: values.email,
                    password: values.password,
                    confirmPassword: values.confirmPassword,
                    token :getAuthToken()
                }
                const reset_password: any = await authService.resetPassword(data)
                console.log(reset_password.succeeded)
                if (reset_password.succeeded) {
                    toast({
                        title: "Reset Password",
                        description: `password reset successful`,
                        status: "success",
                    });
                    route(AdminRoutes.emailSent)
                } else {
                    toast({
                        title: "Reset Password",
                        description: `${reset_password.messages.$values[0]}`,
                        status: "error",
                    });
                }
            } catch (error: any) {
                toast({
                    title: "Reset Password",
                    description: `${error}`,
                    status: "error",
                });
            }
            route(AdminRoutes.emailSent)
        },
    });

    return (
        <>
            <AuthLayout>
                <Flex mx="auto" className="resizable_div" height="100vh">
                    <Box >
                        <Box
                            ml={["30px", "30px", "60px", "60px"]}
                            mr={["30px", "30px", "0px", "0px"]}
                            mt={'100px'}
                            mb="15px"
                        >


                            <Box mb={'40px'}>
                                <Text
                                    mb={'10px'}
                                    fontSize={["20px", "20px", "24px", "24px"]}
                                    fontWeight="700"
                                    color="#011F78"
                                >
                                    Set Password
                                </Text>
                                <Text

                                    fontSize={["10px", "10px", "14px", "14px"]}
                                    fontWeight="400"
                                    color="#90979E"
                                >
                                    Enter email and password to access your account
                                </Text>
                            </Box>


                            <form onSubmit={formik.handleSubmit}>


                                <FormControl
                                    mb="20px"
                                    isInvalid={
                                        formik.errors.password && formik.touched.password
                                            ? true
                                            : false
                                    }
                                >
                                    <FormLabel color="brand.subtitle" fontSize={15}>
                                        New Password{" "}

                                    </FormLabel>

                                    <Input
                                        width={["100%", "100%", "520px", "520px"]}
                                        type="password"
                                        name="password"
                                        id="password"
                                        placeholder="Enter  password"
                                        onChange={formik.handleChange}
                                        fontSize={14}
                                    />

                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.password}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl
                                    mb="20px"
                                    isInvalid={
                                        formik.errors.confirmPassword && formik.touched.confirmPassword
                                            ? true
                                            : false
                                    }
                                >
                                    <FormLabel fontSize={15}>
                                        Confirm New Password{" "}

                                    </FormLabel>

                                    <Input
                                        width={["100%", "100%", "520px", "520px"]}
                                        type="password"
                                        name="confirmPassword"
                                        id="confirmPassword"
                                        placeholder="confirm password"
                                        onChange={formik.handleChange}
                                        fontSize={14}
                                    />

                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.confirmPassword}
                                    </FormErrorMessage>
                                </FormControl>


                                <Stack spacing="12px">
                                    <Button
                                        cursor="pointer"
                                        w="100%"
                                        color={"white"}
                                        bg={"#011F78"}
                                        type="submit"
                                        isLoading={formik.isSubmitting}
                                        fontSize={14}
                                    >
                                        Set Password
                                    </Button>
                                </Stack>
                            </form>
                        </Box>
                    </Box>
                </Flex>

            </AuthLayout>
        </>
    );
};

export default SetPassword;
