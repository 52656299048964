import {
    Box,
    Button,
    Center,
    Flex,
    FormControl,
    FormErrorMessage,
    Text,
    FormLabel,
    Input,
    InputGroup,
    InputLeftElement,
    useToast,
    Link,
    HStack,
    Image,
    Stack,
} from "@chakra-ui/react";
import ReCAPTCHA from "react-google-recaptcha"
import { useFormik } from "formik";
import { MdLock, MdMail } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import AuthLayout from "./AuthLayout";
import { useEffect, useRef, useState } from "react";
import AdminRoutes from "../routes";
import authService from "../../services/auth/auth.service";

const VerifyPhoneNumber = () => {
    const route = useNavigate();
    const toast = useToast()
    useEffect(() => {


    }, []);
    const validate = (values: any) => {
        const errors: any = {};
        if (!values.username) {
            errors.username = "username is required";
        } else if (!values.phone) {
            errors.phone = "phone number is required";
        } else if (!values.countryCode) {
            errors.countryCode = "Country code is required";
        }
        return errors;
    };

    const formik = useFormik({
        initialValues: { username: "", phone: "", countryCode: "" , code : ""},
        validate,
        onSubmit: async (values) => {
            try {
                const data = {
                    userName: values.username,
                    countryCallingCode: values.countryCode,
                    phoneNumber: '0'+ values.phone.toString(),
                    code : values.code
                }
                
                const register_phone: any = await authService.verifyPhoneNumber(data)
                console.log(register_phone.succeeded)
                if (register_phone.succeeded) {
                    toast({
                        title: "Register Phone",
                        description: `phone number registered successful`,
                        status: "success",
                    });
                    route(AdminRoutes.Login)
                } else {
                    toast({
                        title: "Register Phone",
                        description: `${register_phone.messages.$values[0]}`,
                        status: "error",
                    });
                }

            } catch (error: any) {
                toast({
                    title: "Register Phone",
                    description: `${error}`,
                    status: "error",
                });
            } 

        },
    });

    return (
        <>
            <AuthLayout>
                <Flex mx="auto" className="resizable_div" height="100vh">
                    <Box >
                        <Box
                            ml={["30px", "30px", "60px", "60px"]}
                            mr={["30px", "30px", "0px", "0px"]}
                            mt={'100px'}
                            mb="15px"
                        >
                            <Box mb={'40px'}>
                                <Text
                                    mb={'10px'}
                                    fontSize={["20px", "20px", "24px", "24px"]}
                                    fontWeight="700"
                                    color="#011F78"
                                >
                                    Verify Phone Number
                                </Text>
                                <Text
                                    fontSize={["10px", "10px", "14px", "14px"]}
                                    fontWeight="400"
                                    color="#90979E"
                                >
                                    Enter your phone number and country code
                                </Text>
                            </Box>
                            <form onSubmit={formik.handleSubmit}>

                            <FormControl
                                    mb="20px"
                                    isInvalid={
                                        formik.errors.username && formik.touched.username ? true : false
                                    }
                                >
                                    <FormLabel color="#757575" fontSize={15}>
                                        Username                                       
                                    </FormLabel>                                                         
                                        <Input
                                            width={["300px", "300px", "520px", "520px"]}
                                            type="text"
                                            id="username"
                                            name="username"
                                            placeholder="Enter Username"
                                            onChange={formik.handleChange}
                                            fontSize={14}
                                        />         

                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.username}
                                    </FormErrorMessage>
                                </FormControl>

                                <FormControl
                                    mb="20px"
                                    isInvalid={
                                        formik.errors.username && formik.touched.username ? true : false
                                    }
                                >
                                    <FormLabel color="#757575" fontSize={15}>
                                        Phone Number                        
                                    </FormLabel>                                     
                                        <Input
                                            width={["300px", "300px", "520px", "520px"]}
                                            type="number"
                                            id="phone"
                                            value={formik.values.phone}
                                            name="phone"
                                            placeholder="Enter Phone number"
                                            onChange={formik.handleChange}
                                            fontSize={14}
                                        />                                 
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.phone}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl
                                    mb="20px"
                                    isInvalid={
                                        formik.errors.countryCode && formik.touched.countryCode ? true : false
                                    }
                                >
                                    <FormLabel color="#757575" fontSize={15}>
                                        Country Dialing Code 
                                       
                                    </FormLabel> 
                                        <Input
                                            width={["300px", "300px", "520px", "520px"]}
                                            type="text"
                                            id="countryCode"
                                            name="countryCode"
                                            value={formik.values.countryCode}
                                            placeholder="Enter Country Code"
                                            onChange={formik.handleChange}
                                            fontSize={14}
                                        />                                 

                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.countryCode}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl
                                    mb="20px"
                                    isInvalid={
                                        formik.errors.code && formik.touched.code ? true : false
                                    }
                                >
                                    <FormLabel color="#757575" fontSize={15}>
                                        Code 
                                       
                                    </FormLabel> 
                                        <Input
                                            width={["300px", "300px", "520px", "520px"]}
                                            type="text"
                                            id="code"
                                            name="code"
                                            value={formik.values.code}
                                            placeholder="Enter Code"
                                            onChange={formik.handleChange}
                                            fontSize={14}
                                        />                                 

                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.code}
                                    </FormErrorMessage>
                                </FormControl>


                                <Stack spacing="12px">
                                    <Button
                                        cursor="pointer"
                                        w="100%"
                                        color={"white"}
                                        bg={"#011F78"}
                                        type="submit"
                                        isLoading={formik.isSubmitting}
                                        fontSize={14}
                                    >
                                        Proceed
                                    </Button>
                                </Stack>
                            </form>
                        </Box>
                    </Box>
                </Flex>

            </AuthLayout>
        </>
    );
};

export default VerifyPhoneNumber;
