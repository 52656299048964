import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    Box,
    useToast,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Flex,
    Button,
    HStack,
    Textarea,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useRef, useState } from "react";
import usersService from "../../services/user/users.service";

export const CreateAgentModal: React.FC<any> = ({
    isOpenAgent,
    onClosed,
    isAgent = false,
    reload
}: {
    isAgent: boolean;
    isOpenAgent: any;
    onClosed: any;
    reload: any;
}) => {
    const toast = useToast();
    const cancelRef = useRef().current;

    const formik = useFormik({
        validate: (values: any) => {
            const errors: any = {};
            if (!values.username) {
                errors.username = "username is required";
            }
            if (!values.email) {
                errors.email = "email is required";
            }

            return errors;
        },
        initialValues: {
            username: "",
            email: "",
            firstName: "",
            lastName: "",
        },
        onSubmit: async (values) => {
            try {
                const new_param: any = {
                    username: values.username,
                    email: values.email,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    clientConfirmationUrl: 'https://callanpay.vochus.com/',

                }
             
                const create_agent: any = await usersService.createAgent(new_param)
                console.log(create_agent.succeeded)
                if (create_agent.succeeded) {
                    toast({
                        title: "Create Agent",
                        description: "agent created successfully",
                        status: "success",
                    });
                    onClosed(!isAgent)
                    reload()
                  } else {
                    toast({
                        title: "Create Agent",
                        description: `${create_agent.messages.$values[0]}`,
                        status: "error",
                    });
                }
            } catch (error: any) {
                toast({
                    title: "Create Agent",
                    description: `${error}`,
                    status: "error",
                });
            }

        },
    });

    return (
        <>
            <Modal
                isOpen={isOpenAgent && isAgent}
                onClose={onClosed}
                scrollBehavior="outside"
                closeOnOverlayClick={true}
                size="lg"
            >
                <ModalOverlay />
                <ModalContent borderRadius={5} px={6}>
                    <ModalHeader>
                        {
                            <Text
                                fontSize={'18px'}
                                pb={3}
                                textTransform="capitalize"
                                
                                
                            >
                                Create User As Agent
                            </Text>
                        }
                    </ModalHeader>
                    <ModalCloseButton size="sm" />
                    <ModalBody pb={'30px'}>
                        <Box mt={2}>
                            <form onSubmit={formik.handleSubmit}>
                                <FormControl
                                    mt={3}
                                    isInvalid={
                                        formik.errors.username && formik.touched.username ? true : false
                                    }
                                >
                                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                        Username
                                    </FormLabel>
                                    <Input
                                        type="text"
                                        id="username"
                                        name="username"
                                        placeholder="Enter username"
                                        onChange={formik.handleChange}
                                        fontSize={13}
                                    />
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.username}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl
                                    mt={3}
                                    isInvalid={
                                        formik.errors.email && formik.touched.email ? true : false
                                    }
                                >
                                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                        Email
                                    </FormLabel>
                                    <Input
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder="Enter email"
                                        onChange={formik.handleChange}
                                        fontSize={13}
                                    />
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.email}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl
                                    mt={3}
                                    isInvalid={
                                        formik.errors.username && formik.touched.username ? true : false
                                    }
                                >
                                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                        First Name
                                    </FormLabel>
                                    <Input
                                        type="text"
                                        id="firstName"
                                        name="firstName"
                                        placeholder="Enter first name"
                                        onChange={formik.handleChange}
                                        fontSize={13}
                                    />
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.firstName}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl
                                    mt={3}
                                    isInvalid={
                                        formik.errors.username && formik.touched.username ? true : false
                                    }
                                >
                                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                                        Last Name
                                    </FormLabel>
                                    <Input
                                        type="text"
                                        id="lastName"
                                        name="lastName"
                                        placeholder="Enter last name"
                                        onChange={formik.handleChange}
                                        fontSize={13}
                                    />
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.lastName}
                                    </FormErrorMessage>
                                </FormControl>

                                <Flex mt={12} justifyContent={'space-between'} >


                                    <Button

                                        px={6}
                                        colorScheme="blue"
                                        fontSize={'14px'}
                                        variant={'outline'}
                                        ref={cancelRef}
                                        width={'49%'}
                                        onClick={() => onClosed()}
                                    >
                                        Cancel
                                    </Button>
                                    <Button

                                        type="submit"
                                        isLoading={formik.isSubmitting}
                                        color={"white"}
                                        width={'49%'}
                                        bg={"#011F78"}
                                        px={6}
                                        fontSize={'14px'}
                                    >
                                        Confirm
                                    </Button>


                                </Flex>
                            </form>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
