
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import { Box } from "@chakra-ui/react";
import { IUserToken } from "../models/auth/login.model";
import AdminDashboardComponent from "../components/dashboard/AdminDashboard";
import UserAccountsBoard from "../components/dashboard/UserDashboard";

const AdminDashboard = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [userProfile, setUserProfile] = useState<IUserToken>();

    useEffect(() => {
    const userLogged = sessionStorage.getItem('UserFromToken')
    if (userLogged){
      const  parsedUser = JSON.parse(userLogged)
      setUserProfile(parsedUser)
      setIsLoading(false)
    }
    }, []);





    return (
        <>
            {
                isLoading ? <Loading /> : userProfile ? userProfile.assignedRoles.includes('UserAdministrator') 
                || userProfile.assignedRoles.includes('ParameterAdministrator') 
                || userProfile.assignedRoles.includes('AccountAdministrator') 
                || userProfile.assignedRoles.includes('TransactionAdministrator') 
                || userProfile.assignedRoles.includes('Administrator')  ?
                  <AdminDashboardComponent /> : <UserAccountsBoard /> : <UserAccountsBoard />
            }

        </>
    )
}

export default AdminDashboard;