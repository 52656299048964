import {
    Container,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Flex,

    Box,
    Text,
    Stack,
    InputGroup,
    useToast,
    RadioGroup,
    Radio,
    Avatar,
    HStack,
    InputLeftElement,
    Image,
    Checkbox,
    Wrap,
    WrapItem,
    Center,
    Button

} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import nig from '../img/nig.svg'
import userProfileService from "../../services/user/userProfile.service";
import { IStateRes } from "../../models/auth/registration.model";
import { IRolesData } from "../../models/roles/roles.model";
import { IUserByNameData } from "../../models/users/userbyname.model";
import rolesService from "../../services/roles/roles.service";
import usersService from "../../services/user/users.service";
import { getFullDateNumeric } from "../../utils/getDate";
import { IUserToken } from "../../models/auth/login.model";
import { IProfilePhotoRes } from "../../models/users/profilephoto.model";
import Loading from "../../components/Loading";
import { IUserProfileNotification, IUserProfileRes } from "../../models/users/userProfile.model";
import { BsUpload } from "react-icons/bs";
import { v4 as uuidv4 } from 'uuid';
const AdminProfile = () => {
    let navigate = useNavigate();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const toast = useToast()
    const [userProfile, setUserProfile] = useState<IUserToken>();
    const [userProfileNot, setUserProfileNot] = useState<number[]>();
    const [userPhoto, setUserPhoto] = useState<IProfilePhotoRes | undefined>();
    const [theUser, setUser] = useState<IUserByNameData>();
    const [selectedState, setselectedState] = useState<any>(null);
    const [selectedCountry, setselectedCountry] = useState<any>(null);
    const [selectedGender, setselectedGender] = useState<any>(null);
    const [countries, setCountries] = useState<any[]>();
    const [userStates, setUserStates] = useState<any[]>();
    const [genderList, setGenderList] = useState<any[]>([{ value: 0, label: 'Male' }, { value: 1, label: 'Female' }]);
    const [userRoles, setUserRoles] = useState<string[]>([])
    const [rolesI, setRoles] = useState<string[]>();
    const [isLoading, setIsLoading] = useState(true);
    const [isPush, setIsPush] = useState(false);
    const [isSms, setIsSms] = useState(false);
    const [reload, setReload] = useState(false);
    const [isEmail, setIsEmail] = useState(false);
    const [batchFile, setbatchFile] = useState<any>();
    const [filename, setFilename] = useState("");
    const [filetype, setFiletype] = useState<string | undefined>("");
    const handleChangeState = (selectedState: any) => {
        setselectedState(selectedState);
    };
    const handleChangeGender = (selectedGender: any) => {
        setselectedGender(selectedGender);
    };
    const handleChangeCountry = (selectedCountry: any) => {
        setselectedCountry(selectedCountry);
    };
    useEffect(() => {
        userProfileService.userProfilePhoto()
            .then((data) => {
                setUserPhoto(data)
                setIsLoading(false)
            })
            .catch((err) => err)
        userProfileService.userProfile()
            .then((data) => {
                const user: IUserProfileNotification[] = data.data.notifications.$values
                console.log(user, ' not types')
                if (user.length > 0) {
                    const mappedUser = user.map((data => data.messageFormat))
                    setUserProfileNot(mappedUser)
                    console.log(mappedUser, ' not types')

                    mappedUser.includes(0) ? setIsPush(true) : setIsPush(false)
                    mappedUser.includes(1) ? setIsSms(true) : setIsSms(false)
                    mappedUser.includes(2) ? setIsEmail(true) : setIsEmail(false)
                } else {
                    setUserProfileNot([])
                }

                setIsLoading(false)
            })
            .catch((err) => err)


        const userLogged = sessionStorage.getItem('UserFromToken')
        if (userLogged) {
            const parsedUser = JSON.parse(userLogged)
            setUserProfile(parsedUser)

            usersService.getUserByName(`?userName=${parsedUser.username}`).then((data) => {
                let user_data: IUserByNameData = data.data
                setUser(data.data)
                setUserRoles(user_data.assignedRoles.$values)
                user_data.gender == 0 ? setselectedGender(genderList[0]) : setselectedGender(genderList[1])
                usersService.getUserCountries().then((data) => {
                    let countryList: any[] = []
                    const country_res: IStateRes = data
                    country_res.data.$values.forEach(element => {
                        countryList.push({ value: element.$id, label: element.description })
                    });
                    setselectedCountry(countryList.find(val => val.value == user_data.country))
                    setCountries(countryList)


                })
                usersService.getUserStates().then((data) => {
                    let stateList: any[] = []
                    const state_res: IStateRes = data
                    state_res.data.$values.forEach(element => {
                        stateList.push({ value: element.$id, label: element.name })
                    });

                    setselectedState(stateList.find(val => val.value == user_data.state))
                    setUserStates(stateList)
                    setIsLoading(false)

                })
                formik.setValues({
                    zipcode: user_data.zipCode,
                    city: user_data.city,
                    date: getFullDateNumeric(user_data.dateOfBirth),
                    address1: user_data.addressLine1,
                    address2: user_data.addressLine2,
                    firstname: user_data.firstName,
                    lastname: user_data.lastName,
                    username: user_data.userName,
                    email: user_data.email,
                    phone: user_data.phoneNumber,
                    address3: user_data.addressLine3
                })
            })
        }





        rolesService.getroles()
            .then((data) => {
                const roles: IRolesData = data.data;
                const mappedRoles = roles.$values.map(obj => obj.name);
                setRoles(mappedRoles)
                setIsLoading(false)
            })
            .catch((err) => err)

    }, [reload]);
    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        console.log(value, "value");
        if (value && e.target.files) {
            let fileName = e.target.files?.item(0)?.name as string;
            console.log(fileName, "fileName");
            setFilename(fileName);
            let fileType = e.target.files?.item(0)?.type;
            setFiletype(fileType);
            let file = e.target.files[0];
            setbatchFile(file);
        }
    };

    const handleUpload = async () => {
        if (batchFile) {
            const fileName = batchFile.name.split('.').slice(0, -1).join('.'); // Remove extension
            const fileExtension = batchFile.name.split('.').pop(); // Get extension

            const random_uuid = uuidv4();
            //   const buffer: Uint8Array = new Uint8Array(await batchFile.arrayBuffer());
            const reader = new FileReader();

            reader.onload = async function (event: any) {
                const base64String = event.target.result.split(",")[1]; // Extract the Base64 part

                try {
                    const requestBody = {
                        fileName: `${fileName}-${random_uuid}.${fileExtension}`,
                        extension: `.${fileExtension}`,
                        uploadType: 1,
                        data: base64String
                    };
                    // const jsonreq = JSON.stringify(requestBody)
                    const profile_photo: any = await userProfileService.uploadProfilePhoto(requestBody)
                    console.log(profile_photo.succeeded)
                    if (profile_photo.succeeded) {
                        toast({
                            title: "Profile Update",
                            description: `profile update successful`,
                            status: "success",
                        });
                        setReload(!reload)


                    } else {
                        toast({
                            title: "Profile Photo",
                            description: `${profile_photo.messages.$values[0]}`,
                            status: "error",
                        });
                    }
                } catch (error: any) {
                    toast({
                        title: "Profile Photo",
                        description: `${error}`,
                        status: "error",
                    });
                }

            };

            // Read the selected file as Data URL
            reader.readAsDataURL(batchFile);



        }
    }

    const formik = useFormik({
        validate: (values: any) => {
            const errors: any = {};

            return errors;
        },
        initialValues: {

            zipcode: "",
            city: "",
            date: "",
            address1: "",
            address2: "",
            firstname: "",
            lastname: "",
            username: "",
            email: "",
            phone: "",
            address3: ""
        },
        onSubmit: async (values) => {
            try {
                const notUpdate = [];
                if (isPush) notUpdate.push(0)
                if (isEmail) notUpdate.push(2)
                if (isSms) notUpdate.push(1)
                const data = {
                    userName: values.username,
                    country: parseInt(selectedCountry.value),
                    state: parseInt(selectedState.value),
                    zipCode: values.zipcode,
                    city: values.city,
                    addressLine1: values.address1,
                    addressLine2: values.address2,
                    addressLine3: values.address3,
                    photoUrl: '',
                    dateOfBirth: values.date,
                    gender: selectedGender.value,
                    notifications: [
                        ...notUpdate
                    ]
                }
                const update_profile: any = await userProfileService.updateUserProfile(data)
                console.log(update_profile.succeeded)
                if (update_profile.succeeded) {
                    toast({
                        title: "Profile Update",
                        description: `Profile updated successfully`,
                        status: "success",
                    });

                } else {
                    toast({
                        title: "Profile Update",
                        description: `${update_profile.messages.$values[0]}`,
                        status: "error",
                    });
                }
            } catch (error: any) {
                toast({
                    title: "Profile Update",
                    description: `${error}`,
                    status: "error",
                });
            }
        },
    });

    const assignRole = (role: string, checked: boolean, rolename: string) => {
        let roles: string[] = [];
        console.log(role, checked, 'role selected')
        userRoles !== undefined ? roles = [...userRoles] : roles = [];
        if (checked) {
            roles.push(role)
            setUserRoles([...userRoles, role])
        } else if (!checked) {
            let ind = roles.indexOf(role)
            let useroleindex = userRoles.indexOf(role)
            userRoles.splice(useroleindex, 1)
            setUserRoles([...userRoles])
            roles.splice(ind, 1)
            console.log(roles)
        }


    }
    return (
        <>
            {
                isLoading ? <Loading /> :
                    <Box minHeight="100vh" pb="30px" >
                        <Container
                            width={{ lg: "80%", md: "80%", sm: "95%", base: "95%" }}
                            maxWidth={"84%"}
                            px={0}
                            right={0}
                            bg="F9F8F8"
                            mx="auto"
                        >
                            <Text mb={'20px'} fontWeight="700" fontSize="28px" color="#1E1E1E">
                                Profile
                            </Text>
                            <Box
                                bg="white"
                                px="40px"
                                py="25px"
                                boxShadow="sm"
                                rounded="lg"
                                height="auto"
                                mb="30px"
                            >
                                <Box alignItems={'center'} justifyContent={'space-between'}>
                                    <HStack fontSize={13}>
                                        <Avatar
                                            size="lg"
                                            name={userProfile && `${userProfile.firstname} ${userProfile.lastname}`}
                                            src={userPhoto && userPhoto.data}
                                            onClick={() => inputRef?.current?.click()}
                                            cursor="pointer"
                                        />
                                        <Flex direction="column" alignItems="flex-end">
                                            <Box>
                                                <Text fontSize={'16px'} color={'#202020'}>
                                                    {" "}
                                                    {userProfile && `${userProfile.firstname} ${userProfile.lastname}`}
                                                </Text>
                                                <Text textAlign={'left'} mt={'-7px'} fontSize={'14px'} color={'#898989'}>
                                                    {" "}
                                                    {userProfile && userProfile.email}
                                                </Text>
                                            </Box>
                                        </Flex>

                                    </HStack>
                                    <Text fontSize={'11px'}>
                                        {filename}
                                    </Text>
                                    {filename && <Button size='sm'
                                        color={"white"}
                                        width={'50%'}
                                        bg={"#011F78"} onClick={() => handleUpload()}>Change picture</Button>
                                    }
                                </Box>

                                <FormControl mr={10}>


                                    <input
                                        accept="image/*"
                                        type="file"
                                        name="cover_image"
                                        ref={inputRef}
                                        onChange={(e) => handleFileChange(e)}
                                        style={{ display: "none" }}
                                    ></input>


                                </FormControl>
                                <Box mt={4}>
                                    <form onSubmit={formik.handleSubmit}>
                                        <Stack
                                            mb={["10px", "10px", "20px", "20px"]}
                                            direction={["column", "column", "row", "row"]}
                                        >
                                            <FormControl mb={'15px'}>
                                                <Select
                                                    value={selectedCountry}
                                                    onChange={handleChangeCountry}
                                                    options={countries}
                                                    isSearchable={true}
                                                    placeholder={'Country'}
                                                />
                                            </FormControl>

                                            <FormControl mb={'15px'} >
                                                <Select
                                                    value={selectedState}
                                                    placeholder={'State'}
                                                    onChange={handleChangeState}
                                                    options={userStates}
                                                    isSearchable={true}
                                                />
                                            </FormControl>
                                        </Stack>
                                        <FormControl
                                            mb={["10px", "10px", "20px", "20px"]}
                                            isInvalid={
                                                formik.errors.city && formik.touched.city
                                                    ? true
                                                    : false
                                            }
                                        >
                                            <FormLabel
                                                fontWeight="normal"
                                                color="brand.3"
                                                fontSize={13}
                                            >
                                                Zipcode

                                            </FormLabel>
                                            <Input
                                                bg="inputText.500"
                                                placeholder="10002"
                                                value={formik.values.zipcode}
                                                name="zipcode"
                                                onChange={formik.handleChange}
                                                fontSize={12}
                                            />
                                            <FormErrorMessage fontSize={12}>
                                                {formik.errors.zipcode}
                                            </FormErrorMessage>
                                        </FormControl>
                                        <FormControl
                                            mb={["10px", "10px", "20px", "20px"]}
                                            isInvalid={
                                                formik.errors.city && formik.touched.city
                                                    ? true
                                                    : false
                                            }
                                        >
                                            <FormLabel
                                                fontWeight="normal"
                                                color="brand.3"
                                                fontSize={13}
                                            >
                                                City

                                            </FormLabel>
                                            <Input
                                                bg="inputText.500"
                                                placeholder="Enter city"
                                                value={formik.values.city}
                                                name="city"
                                                onChange={formik.handleChange}
                                                fontSize={12}
                                            />
                                            <FormErrorMessage fontSize={12}>
                                                {formik.errors.city}
                                            </FormErrorMessage>
                                        </FormControl>

                                        <FormControl
                                            mb={["10px", "10px", "20px", "20px"]}
                                            isInvalid={
                                                formik.errors.address1 && formik.touched.address1
                                                    ? true
                                                    : false
                                            }
                                        >
                                            <FormLabel
                                                fontWeight="normal"
                                                color="brand.3"
                                                fontSize={13}
                                            >
                                                Address Line 1

                                            </FormLabel>
                                            <Input
                                                bg="inputText.500"
                                                placeholder="Enter Address 1"
                                                value={formik.values.address1}
                                                name="address1"
                                                onChange={formik.handleChange}
                                                fontSize={12}
                                            />
                                            <FormErrorMessage fontSize={12}>
                                                {formik.errors.address1}
                                            </FormErrorMessage>
                                        </FormControl>
                                        <FormControl
                                            mb={["10px", "10px", "20px", "20px"]}
                                            isInvalid={
                                                formik.errors.address2 && formik.touched.address2
                                                    ? true
                                                    : false
                                            }
                                        >
                                            <FormLabel
                                                fontWeight="normal"
                                                color="brand.3"
                                                fontSize={13}
                                            >
                                                Address Line 2

                                            </FormLabel>
                                            <Input
                                                bg="inputText.500"
                                                placeholder="Enter Address 2"
                                                value={formik.values.address2}
                                                name="address2"
                                                onChange={formik.handleChange}
                                                fontSize={12}
                                            />
                                            <FormErrorMessage fontSize={12}>
                                                {formik.errors.address2}
                                            </FormErrorMessage>
                                        </FormControl>

                                        <FormControl
                                            mb={["10px", "10px", "20px", "20px"]}
                                            isInvalid={
                                                formik.errors.date && formik.touched.date ? true : false
                                            }
                                        >
                                            <FormLabel
                                                fontWeight="normal"
                                                color="brand.3"
                                                fontSize={13}
                                            >
                                                Date of Birth{" "}
                                                <Text as="span" color="red">
                                                    *
                                                </Text>
                                            </FormLabel>
                                            <InputGroup>
                                                <Input
                                                    placeholder="Date of Birth"
                                                    type="date"
                                                    bg="inputText.500"
                                                    name="date"
                                                    value={formik.values.date}
                                                    onChange={formik.handleChange}
                                                    fontSize={12}
                                                />
                                                {/* <InputRightElement pointerEvents="none" children={<VscCalendar fontSize={20} color="#7883A7" />} /> */}
                                            </InputGroup>
                                            <FormErrorMessage fontSize={12}>
                                                {formik.errors.date}
                                            </FormErrorMessage>
                                        </FormControl>
                                        <FormControl mb={'15px'} isInvalid={(formik.errors.city && formik.touched.city) ? true : false} >
                                            <Select
                                                value={selectedGender}
                                                onChange={handleChangeGender}
                                                options={genderList}
                                                isSearchable={true}
                                                placeholder={'Gender'}
                                            />
                                        </FormControl>
                                        <Box mb={'30px'} >
                                            <Text mb={'15px'} fontSize={'14px'} color={'#000000'} fontWeight={'400'}>User Roles</Text>
                                            <Wrap spacing='30px'>
                                                {
                                                    rolesI?.map((role, index) => (
                                                        <WrapItem>
                                                            <Checkbox color={'#575757'} isChecked={userRoles.includes(role)}
                                                                value={role}
                                                                onChange={(e) =>
                                                                    assignRole(e.target.value, e.target.checked, role)
                                                                } colorScheme='blue' >
                                                                <Text fontSize={'13px'}> {role}</Text>
                                                            </Checkbox>
                                                        </WrapItem>
                                                    ))
                                                }

                                            </Wrap>

                                        </Box>
                                        <Box >
                                            <Text mb={'7px'} fontSize={'16px'} color={'#000000'}>Nofitication</Text>
                                            <Stack fontSize={'12px'} color={'#575757'} spacing={5} direction='row'>
                                                <Checkbox fontSize={'12px'} isChecked={isPush} onChange={(e) => setIsPush(e.target.checked)} color={'#575757'} colorScheme='blue' >
                                                    Push notification
                                                </Checkbox>
                                                <Checkbox fontSize={'12px'} color={'#575757'} isChecked={isEmail} onChange={(e) => setIsEmail(e.target.checked)} colorScheme='blue' >
                                                    Email
                                                </Checkbox>
                                                <Checkbox fontSize={'12px'} color={'#575757'} isChecked={isSms} onChange={(e) => setIsSms(e.target.checked)} colorScheme='blue' >
                                                    SMS
                                                </Checkbox>
                                            </Stack>
                                        </Box>


                                        <Flex mt={8} justifyContent={'center'}>
                                            <Button

                                                size='sm'
                                                color={"white"}
                                                width={'50%'}
                                                bg={"#011F78"}
                                                type="submit"
                                                isLoading={formik.isSubmitting}
                                                fontSize={"14px"}
                                                borderRadius={'4px'}
                                            >
                                                Save changes
                                            </Button>
                                        </Flex>
                                    </form>
                                </Box>
                            </Box>
                        </Container>
                    </Box>
            }
        </>
    );
};

export default AdminProfile;
