import {
    Box,
    Button,
    Center,
    Flex,
    FormControl,
    FormErrorMessage,
    Text,
    FormLabel,
    Input,
    InputGroup,
    InputLeftElement,
    useToast,
    Link,
    HStack,
    Image,
    Stack,
    useDisclosure,
    Portal,
    Select,
} from "@chakra-ui/react";
import ReCAPTCHA from "react-google-recaptcha"
import { useFormik } from "formik";
import { MdLock, MdMail } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import AuthLayout from "./AuthLayout";
import { useEffect, useRef, useState } from "react";
import AdminRoutes from "../routes";
import authService from "../../services/auth/auth.service";
import { VerifyPhoneModal } from "../../components/auth/verifyPhoneModal";

const SetPhoneNumber = () => {
    const route = useNavigate();
    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isPhone, setisPhone] = useState(false);
    const [countryCode, setcountryCode] = useState('');
    useEffect(() => {

    }, []);
    const validate = (values: any) => {
        const errors: any = {};
        if (!values.username) {
            errors.username = "username is required";
        } else if (!values.phone) {
            errors.phone = "phone number is required";
        } else if (!countryCode) {
            errors.countryCode = "Country code is required";
        }
        return errors;
    };
    const changecountryCode = (e: any) => {
        setcountryCode(e.target.value)
    }
    const openVerifyModal = async () => {
        setisPhone(true);
        onOpen();
    };
    const formik = useFormik({
        initialValues: { username: "", phone: "", countryCode: "" },
        validate,
        onSubmit: async (values) => {
            try {
                const data = {
                    userName: values.username,
                    countryCallingCode: countryCode,
                    phoneNumber: values.phone.toString(),
                }
                const register_phone: any = await authService.newPhoneNumber(data)
                console.log(register_phone.succeeded)
                if (register_phone.succeeded) {
                    toast({
                        title: "Register Phone",
                        description: `phone number registered successful`,
                        status: "success",
                    });
                    openVerifyModal()
                } else {
                    toast({
                        title: "Register Phone",
                        description: `${register_phone.messages.$values[0]}`,
                        status: "error",
                    });
                }
            } catch (error: any) {
                toast({
                    title: "Register Phone",
                    description: `${error}`,
                    status: "error",
                });
            }

        },
    });

    return (
        <>
            <AuthLayout>
                <Flex mx="auto" className="resizable_div" height="100vh">
                    <Box >
                        <Box
                            ml={["30px", "30px", "60px", "60px"]}
                            mr={["30px", "30px", "0px", "0px"]}
                            mt={'100px'}
                            mb="15px"
                        >
                            <Box mb={'40px'}>
                                <Text
                                    mb={'10px'}
                                    fontSize={["20px", "20px", "24px", "24px"]}
                                    fontWeight="700"
                                    color="#011F78"
                                >
                                    Set Phone Number
                                </Text>
                                <Text
                                    fontSize={["10px", "10px", "14px", "14px"]}
                                    fontWeight="400"
                                    color="#90979E"
                                >
                                    Enter your phone number and country code
                                </Text>
                            </Box>
                            <form onSubmit={formik.handleSubmit}>

                                <FormControl
                                    mb="20px"
                                    isInvalid={
                                        formik.errors.username && formik.touched.username ? true : false
                                    }
                                >
                                    <FormLabel color="#757575" fontSize={15}>
                                        Username
                                    </FormLabel>
                                    <Input
                                        width={["300px", "300px", "520px", "520px"]}
                                        type="text"
                                        id="username"
                                        name="username"
                                        placeholder="Enter Username"
                                        onChange={formik.handleChange}
                                        fontSize={14}
                                    />

                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.username}
                                    </FormErrorMessage>
                                </FormControl>

                                <FormControl
                                    mb="20px"
                                    isInvalid={
                                        formik.errors.username && formik.touched.username ? true : false
                                    }
                                >
                                    <FormLabel color="#757575" fontSize={15}>
                                        Phone Number
                                    </FormLabel>
                                    <Input
                                        width={["300px", "300px", "520px", "520px"]}
                                        type="number"
                                        id="phone"
                                        value={formik.values.phone}
                                        name="phone"
                                        placeholder="Enter Phone number"
                                        onChange={formik.handleChange}
                                        fontSize={14}
                                    />
                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.phone}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl
                                    mb="20px"
                                    isInvalid={
                                        formik.errors.countryCode && formik.touched.countryCode ? true : false
                                    }
                                >
                                    <FormLabel color="#757575" fontSize={15}>
                                        Country Dialing Code

                                    </FormLabel>
                                    <Select
                                        width={["300px", "300px", "520px", "520px"]}
                                        variant="outline"
                                        value={countryCode}
                                        onChange={(e) => { changecountryCode(e) }}
                                        fontSize={14}
                                        mb="20px"
                                    >
                                        <option value={''}>
                                          select country code
                                        </option>
                                        <option value={'+234'}>
                                            +234
                                        </option>


                                    </Select>

                                    <FormErrorMessage fontSize={12}>
                                        {formik.errors.countryCode}
                                    </FormErrorMessage>
                                </FormControl>


                                <Stack spacing="12px">
                                    <Button
                                        cursor="pointer"
                                        w="100%"
                                        color={"white"}
                                        bg={"#011F78"}
                                        type="submit"
                                        isLoading={formik.isSubmitting}
                                        fontSize={14}
                                    >
                                        Proceed
                                    </Button>
                                </Stack>
                            </form>
                        </Box>
                        {isPhone && (
                            <Portal>
                                {" "}
                                <VerifyPhoneModal
                                    isOpen={isOpen}
                                    onClosed={onClose}
                                    isPhone={isPhone}
                                    username={formik.values.username}
                                    code={countryCode}
                                    phone={formik.values.phone}
                                />{" "}
                            </Portal>
                        )}
                    </Box>
                </Flex>

            </AuthLayout>
        </>
    );
};

export default SetPhoneNumber;
