import axios from "axios";
import { destroyUserSession, getAuthToken, getClientToken, getRefreshToken } from "./auth/auth.service";

// Create an Axios instance with interceptors
const axiosInstance = axios.create();

// Add a request interceptor
axiosInstance.interceptors.request.use(async function (config) {
    // Do something before request is sent
    
    if (config.headers) {
        let clientToken = await getClientToken()
        let authToken = getAuthToken()
        if(clientToken !== undefined){
         config.headers.Authorization = `Bearer ${authToken}`;
         config.headers.clientid = 'QX1208H' 
         config.headers['x-token'] = `${clientToken}` 
        }
        config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});


export default axiosInstance;