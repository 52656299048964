import {
    Container,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Select,
    Flex,
    Button,
    Box,
    Text,
    Stack,
    InputGroup,
    useToast,
    RadioGroup,
    Radio,
    Avatar,
    HStack,
    InputLeftElement,
    Image,
    Checkbox

} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import nig from '../img/nig.svg'
import { ILoginRes } from "../../models/auth/login.model";
import authService from "../../services/auth/auth.service";
import AdminRoutes from "../routes";
import { MdLock } from "react-icons/md";
import userProfileService from "../../services/user/userProfile.service";
const SetPin = () => {
    const route = useNavigate();
    const toast = useToast();
    const validate = (values: any) => {
        const errors: any = {};
        if (!values.confirmPin) {
            errors.confirmPin = "confirm pin is required";
        }
        if (!values.pin) {
            errors.pin = "pin is required";
        }
        return errors;
    };

    const formik = useFormik({
        initialValues: { confirmPin: "", pin: "" },
        validate,
        onSubmit: async (values) => {
            try {
                const data = {
                    confirmNewPIN: values.confirmPin.toString(),
                    newPin: values.pin.toString(),
                }
                const set_pin: ILoginRes = await userProfileService.setPin(data)

                if (set_pin.succeeded) {
                    toast({
                        title: "Pin",
                        description: `Pin set successfully`,
                        status: "success",
                    });
                   
                    route(AdminRoutes.portal.dashboard)
                } else {
                    toast({
                        title: "Pin",
                        description: `${set_pin.messages.$values[0]}`,
                        status: "error",
                    });
                }
            } catch (error: any) {
                toast({
                    title: "Pin",
                    description: `${error}`,
                    status: "error",
                });
            }
        },
    });

    return (
        <>
            <Box minHeight="100vh" pb="30px" >
                <Container
                    width={{ lg: "80%", md: "80%", sm: "95%", base: "95%" }}
                    maxWidth={"84%"}
                    px={0}
                    right={0}
                    bg="F9F8F8"
                    mx="auto"
                >
                    <Text mb={'20px'} fontWeight="700" fontSize="22px" color="#1E1E1E">
                        Set Pin
                    </Text>
                    <Box
                        bg="white"
                        px="40px"
                        py="25px"
                        boxShadow="sm"
                        rounded="lg"
                        height="auto"
                        mb="30px"
                    >

                        <Flex py={'30px'} justifyContent={'center'} >
                            <Box >
                                <Box>
                                    <form onSubmit={formik.handleSubmit}>

                                        <FormControl
                                            mb="20px"
                                            isInvalid={
                                                formik.errors.pin && formik.touched.pin
                                                    ? true
                                                    : false
                                            }
                                        >
                                            <FormLabel color="brand.subtitle" fontSize={15}>
                                                New  pin{" "}

                                            </FormLabel>
                                            <InputGroup>
                                                <InputLeftElement
                                                    color="brand.subtitle"
                                                    pointerEvents="none"
                                                    children={<MdLock />}
                                                />
                                                <Input
                                                    width={["100%", "100%", "520px", "520px"]}
                                                    type="number"
                                                    name="pin"
                                                    id="pin"
                                                    placeholder="Enter  pin"
                                                    onChange={formik.handleChange}
                                                    fontSize={14}
                                                />
                                            </InputGroup>
                                            <FormErrorMessage fontSize={12}>
                                                {formik.errors.pin}
                                            </FormErrorMessage>
                                        </FormControl>

                                        <FormControl
                                            mb="20px"
                                            isInvalid={
                                                formik.errors.pin && formik.touched.pin
                                                    ? true
                                                    : false
                                            }
                                        >
                                            <FormLabel color="brand.subtitle" fontSize={15}>
                                                Confirm  Pin{" "}

                                            </FormLabel>
                                            <InputGroup>
                                                <InputLeftElement
                                                    color="brand.subtitle"
                                                    pointerEvents="none"
                                                    children={<MdLock />}
                                                />
                                                <Input
                                                    width={["100%", "100%", "520px", "520px"]}
                                                    type="number"
                                                    name="confirmPin"
                                                    id="confirmPin"
                                                    placeholder="confirm  pin"
                                                    onChange={formik.handleChange}
                                                    fontSize={14}
                                                />
                                            </InputGroup>
                                            <FormErrorMessage fontSize={12}>
                                                {formik.errors.confirmPin}
                                            </FormErrorMessage>
                                        </FormControl>
                                        <Stack spacing="12px">
                                            <Button
                                                cursor="pointer"
                                                w="100%"
                                                color={"white"}
                                                bg={"#011F78"}
                                                type="submit"
                                                isLoading={formik.isSubmitting}
                                                fontSize={14}
                                            >
                                                Set pin
                                            </Button>
                                        </Stack>
                                    </form>
                                </Box>
                            </Box>
                        </Flex>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default SetPin;
