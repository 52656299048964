import deleteService from "../crud-requests/deleteService";
import getService from "../crud-requests/getService";
import postService from "../crud-requests/postService";
import putService from "../crud-requests/putService";



const CONFIG_API_URL = "https://callnpayapi20230120085359.azurewebsites.net/api/";

class TransactionService {
  async getTransactions(url: string) {
    const response = await getService.get(
      `${CONFIG_API_URL}transaction/gettransactions${url}`,

    );
    return response.data;
  }

  async getTransactionStatuses() {
    const response = await getService.get(
      `${CONFIG_API_URL}transaction/gettransactionstatuses`,

    );
    return response.data;
  }
  async getTransactionTypes() {
    const response = await getService.get(
      `${CONFIG_API_URL}transaction/gettransactiontypes`,

    );
    return response.data;
  }

  async getOwnTransactions(url: string) {
    const response = await getService.get(
      `${CONFIG_API_URL}transaction/getowntransactions${url}`,

    );
    return response.data;
  }
  async getTransactionsOfAccount(url: string) {
    const response = await getService.get(
      `${CONFIG_API_URL}transaction/gettransactionsofaccount${url}`,

    );
    return response.data;
  }
  async generateAccountStatement(url: string) {
    const response = await getService.get(
      `${CONFIG_API_URL}transaction/generateaccountstatement${url}`,

    );
    return response.data;
  }

  async handleTransaction(data: any) {
    const response = await postService.post(
      `${CONFIG_API_URL}transaction/handletransaction`,
      data
    );
    return response.data;
  }
}

export default new TransactionService();